import React from 'react';
import { Box, Button, ErrorSmall, Stack, Typography, styled, useTheme } from '@harmon.ie-ce/storybook';
import { XSmall } from '@harmon.ie-ce/storybook';
import { NotificationsVariantProps } from './NotificationsVariant';

interface NotificationsTitleProps {
  variant: NotificationsVariantProps;
  title: string;
  hasCloseDilaog: boolean;
  onClose?: ((event, reason: 'escapeKeyDown' | 'backdropClick') => void) | undefined;
}

export const NotificationsTitle = ({ variant, title, hasCloseDilaog, onClose }: NotificationsTitleProps) => {
  const theme = useTheme();
  const Icon = styled(ErrorSmall)`
    margin-left: 2px;
    margin-top: 2px;
  `;
  return (
    <Stack direction={'row'} width={'100%'}>
      <Stack direction={'row'} spacing={2} width={'100%'}>
        <Box
          sx={{
            backgroundColor: variant.iconBackground,
            width: theme.spacing(4),
            height: theme.spacing(4),
          }}
        >
          <Icon />
        </Box>
        <Typography variant="H1Regular" color={variant.titleColor} sx={{ width: theme.spacing(36) }}>
          {title}
        </Typography>
      </Stack>
      {hasCloseDilaog && (
        <Button
          onClick={e => onClose?.(e, 'escapeKeyDown')}
          sx={{ paddingTop: 2.5, ml: theme.spacing(2) }}
          variant="IconButtons"
        >
          <XSmall />
        </Button>
      )}
    </Stack>
  );
};
