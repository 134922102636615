import React from 'react';
import { Stack, styled, useTheme } from '@mui/system';
import { StackProps, Typography } from '@mui/material';
import { strings } from '@vendor/languages';
import { AttachmentSmall, SaveEmailMedium } from '../../../assets/icons';
import { Root } from './styled';

export interface SaveEmailPopoverProps extends StackProps {
  fileName?: string;
  sharedBy?: string | undefined;
  attachmentsCount?: number | undefined;
  date?: string;
  isMultipleEmails?: boolean;
}

const CustomizedSaveEmailIcon = styled(SaveEmailMedium)`
  path {
    fill: ${({ theme }) => theme.palette.texts.onAccentPrimary};
  }
`;
const CustomizedAttachmentIcon = styled(AttachmentSmall)`
  path {
    fill: ${({ theme }) => theme.palette.texts.onAccentPrimary};
  }
`;
const SaveEmailPopover = ({ sharedBy, attachmentsCount, fileName, date, isMultipleEmails }: SaveEmailPopoverProps) => {
  const theme = useTheme();
  return (
    <Root
      sx={{
        width: '100%',
        maxWidth: 280,
        minWidth: 250,
        paddingY: theme.spacing(2),
        paddingX: theme.spacing(3),
      }}
    >
      <Stack spacing={2} direction={'row'}>
        <CustomizedSaveEmailIcon />
        <Typography color="texts.onAccentPrimary" variant="H1Regular" sx={{ flex: 1 }} noWrap>
          {Boolean(isMultipleEmails)
            ? strings.lang.emailPreview.emailsPreviewTitle
            : strings.lang.emailPreview.emailPreviewTitle}{' '}
          &nbsp;
        </Typography>
      </Stack>
      <Stack
        direction="row"
        spacing={2}
        sx={{
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          width: '100%',
        }}
      >
        <Stack
          direction="column"
          sx={{
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            width: '100%',
          }}
        >
          <Stack
            direction={'row'}
            alignItems="center"
            spacing={2}
            sx={{ justifyContent: 'space-between', width: '100%' }}
          >
            <Typography
              color="texts.onAccentPrimary"
              variant="H1SemiBold"
              noWrap
              align="left"
              sx={{
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                flex: 1,
              }}
            >
              {sharedBy}
            </Typography>
            {attachmentsCount !== undefined && attachmentsCount > 0 && (
              <CustomizedAttachmentIcon sx={{ flexShrink: 'inherit', marginLeft: 'auto' }} />
            )}
          </Stack>
          {fileName && (
            <Stack
              direction="row"
              justifyContent="space-between"
              spacing={2}
              sx={{
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                width: '100%',
              }}
            >
              <Typography
                color="texts.onAccentSecondary"
                variant="H3Medium"
                noWrap
                align="left"
                sx={{
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                }}
              >
                {fileName}
              </Typography>
              {date && (
                <Typography color="texts.onAccentSecondary" variant="H3Medium">
                  {date}
                </Typography>
              )}
            </Stack>
          )}
        </Stack>
      </Stack>
    </Root>
  );
};

export default SaveEmailPopover;
