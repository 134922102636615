import { Theme, Components } from '@mui/material/styles';

export default (theme: Theme) => {
  const styleOverrides: Components['MuiAlert'] = {
    styleOverrides: {
      root: {
        ...theme.typography.H3Regular,
      },
      standardError: {
        backgroundColor: 'transparent',
        color: theme.palette.status.dangerPrimary,
      },
      standardWarning: {
        backgroundColor: 'transparent',
        color: theme.palette.status.warningPrimary,
      },
      standardInfo: {
        backgroundColor: 'transparent',
        color: theme.palette.status.defaultPrimary,
      },
      standardSuccess: {
        backgroundColor: 'transparent',
        color: theme.palette.status.successPrimary,
      },
      outlinedError: {
        backgroundColor: 'transparent',
        color: theme.palette.status.dangerPrimary,
        borderColor: theme.palette.status.dangerPrimary,
      },
      outlinedWarning: {
        backgroundColor: 'transparent',
        color: theme.palette.status.warningPrimary,
        borderColor: theme.palette.status.warningPrimary,
      },
      outlinedInfo: {
        backgroundColor: 'transparent',
        color: theme.palette.status.defaultPrimary,
        borderColor: theme.palette.status.defaultPrimary,
      },
      outlinedSuccess: {
        backgroundColor: 'transparent',
        color: theme.palette.status.successPrimary,
      },
      filledError: {
        backgroundColor: theme.palette.status.dangerSecondary,
        color: theme.palette.status.dangerPrimary,
      },
      filledWarning: {
        backgroundColor: theme.palette.status.warningSecondary,
        color: theme.palette.status.warningPrimary,
      },
      filledInfo: {
        backgroundColor: theme.palette.status.defaultSecondary,
        color: theme.palette.status.defaultPrimary,
      },
      filledSuccess: {
        backgroundColor: theme.palette.status.successSecondary,
        color: theme.palette.status.successPrimary,
      },
    },
  };
  return styleOverrides;
};
