import { TooltipProps as MuiTooltipProps, Tooltip as MuiTooltip } from '@mui/material';
import React from 'react';
import { Typography } from '../Typography';
export interface TooltipProps extends Omit<MuiTooltipProps, 'title' | 'arrow'> {
  disabled?: boolean;
  title?: React.ReactNode;
  tooltip?: boolean;
  className?: string;
}

export const NativeTooltip = ({ children, title = '', ...rest }: TooltipProps) => {
  const container = document.getElementById('poppoverContainer') || document.body;

  return (
    <MuiTooltip
      disableTouchListener
      disableInteractive
      arrow={false}
      PopperProps={{ container, className: 'preview-tooltip' }}
      title={title}
      {...rest}
    >
      {children}
    </MuiTooltip>
  );
};

const Tooltip = ({ children, className, title = '', ...rest }: TooltipProps) => {
  return (
    <MuiTooltip
      disableTouchListener
      disableInteractive
      arrow={false}
      PopperProps={{ className: className || 'default-tooltip' }}
      title={!className ? <Typography variant="H3Regular">{title}</Typography> : title}
      {...rest}
    >
      {children}
    </MuiTooltip>
  );
};

export const LocationLabelTooltip = ({ children, className, title = '', ...rest }: TooltipProps) => {
  return (
    <MuiTooltip
      disableInteractive
      arrow={false}
      PopperProps={{ className: className || 'location-label-tooltip' }}
      {...rest}
      title={title}
    >
      {children}
    </MuiTooltip>
  );
};
export default Tooltip;
