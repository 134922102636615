import { SharedWithMeMedium } from '@storybook';
import { strings } from '@vendor/languages';
import { BaseContainer, IconProps, ItemDataWithPaging } from '../itemTypes';

export class AllLocations extends BaseContainer {
  static readonly instance = new AllLocations();

  private constructor() {
    super({ type: 'home', id: 'home', name: strings.lang.nodeNames.sharedWithMe });
  }

  get locationHaveQuickSearch(): boolean {
    return true;
  }

  override getNumberOfQuickActions(): number {
    return 0;
  }

  override get itemCanSelected(): boolean {
    return false;
  }

  override async fetchChildren(): Promise<ItemDataWithPaging> {
    return {
      items: [],
    };
  }

  override getIcon(_expanded: boolean): IconProps {
    return { icon: SharedWithMeMedium, isColorable: true };
  }
}
