import React, { PropsWithChildren } from 'react';
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { createTheme, useGlobalStyles } from '../../theme';

const Provider = ({ children, themeMode = 'light' }: PropsWithChildren<{ themeMode?: string }>) => {
  useGlobalStyles();
  const theme = createTheme(themeMode);
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {children}
    </ThemeProvider>
  );
};

export default Provider;
