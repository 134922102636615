import React from 'react';
import FormControlLabel from '@mui/material/FormControlLabel';
import MuiCheckbox, { CheckboxProps as MuiCheckboxProps } from '@mui/material/Checkbox';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import { CheckBoxFillSmall, CheckBoxMixedSmall } from '../../assets/icons';

export type CheckboxProps = MuiCheckboxProps & {
  className?: string;
  label?: string;
  alignItems?: string;
  LabelComponent?: React.ReactElement;
  indeterminate?: boolean;
};

const Checkbox = ({ label, className, alignItems, LabelComponent, indeterminate, ...rest }: CheckboxProps) => {
  const theme = useTheme();
  return (
    <FormControlLabel
      sx={{ alignItems: alignItems || 'flex-start', margin: 0 }}
      slotProps={{
        typography: {
          sx: { maxWidth: 'calc(100% - 25px)' },
        },
      }}
      control={
        <MuiCheckbox
          className={className}
          indeterminate={indeterminate}
          indeterminateIcon={<CheckBoxMixedSmall width={16} height={16} />}
          checkedIcon={<CheckBoxFillSmall width={16} height={16} />}
          {...rest}
        />
      }
      label={
        <>
          {LabelComponent}
          {label && (
            <Typography color={theme.palette.texts.secondary} fontWeight={440} variant="H3Medium">
              {label}
            </Typography>
          )}
        </>
      }
    />
  );
};

export default Checkbox;
