import * as React from 'react';
import { Accordion, AccordionDetails, AccordionSummary, Box, Button, ButtonProps, InputAdornment } from '@mui/material';
import Typography from '@mui/material/Typography';
import { Stack } from '@mui/system';
import { useTheme } from '@mui/material/styles';
import classNames from 'classnames';
import { strings as storyStrings } from '@vendor/languages';
import Drawer from '../Drawer/Drawer';
import { ArrowDownMedium, SVGComponent, SearchMedium, XSmall } from '../../assets/icons';
import { VirtualList } from '../VirtualList';
import { ButtonsStack } from '../ButtonsStack';
import { EmptyState } from '../EmptyState';
import ManageItemRender, { OperatedList } from './ManageItem';
import { useWindowSize } from '../../hooks/render';
import SearchField from '../SearchBar';
import { Tooltip } from '../Tooltip';
import { Scrollbar } from '../Scollbar';
import { Checkbox } from '../Checkbox';

const DynamicScrollWrap: React.FC<React.PropsWithChildren<{ needToWrap: boolean; height: number }>> = ({
  children,
  needToWrap,
  height,
}) => {
  return needToWrap ? (
    <Box sx={{ height }}>
      <Scrollbar horizontalOffset={-10} showVertical noHorizontal>
        {children}
      </Scrollbar>
    </Box>
  ) : (
    <>{children}</>
  );
};

export interface ManageListStrings {
  title: string;
  addedItems: string;
  availabeItems: string;
  description?: string;
  emptyState: string;
  placeholderSearch?: string;
  showMoreHint: string;
  errorMessage: string;
}

type MoreResults =
  | { kind: 'none' }
  | { kind: 'paging'; loadNextPage: () => void; hasMore: boolean }
  | { kind: 'hint'; hintCount: number; hasMore: boolean };

interface ManageListsInterface {
  strings: ManageListStrings;
  filterFunction: (value: string) => boolean;
  filterValue?: string;
  error?: boolean;
  setFilterValue: (value: string) => void;
  items: Array<OperatedList>;
  listMoreResults: MoreResults;
  listLoading: boolean;
  isOpen: boolean;
  setOpen: (value: boolean) => void;
  operatedListItems?: Array<OperatedList>;
  container: React.RefObject<HTMLDivElement>;
  onSave: (newState: OperatedList[], hasNew: boolean) => void;
  onCancel?: () => void;
  icon: React.ReactNode;
  images?: { light: SVGComponent; dark: SVGComponent };
  toggle?: { text: string; onToggle: (val: boolean) => void };
}

// Note that the component needs to be reset from the outside. See relevant story for the use of isOpen' as React key.
// If the available items is fetched asynchronously then he caller should ensure that the ManageLists is disabled until this is finished
export const ManageLists: React.FC<ManageListsInterface> = ({
  strings,
  filterValue,
  error,
  setFilterValue,
  items,
  listLoading,
  listMoreResults,
  operatedListItems,
  isOpen,
  setOpen,
  container,
  onCancel,
  onSave,
  filterFunction,
  icon,
  images,
  toggle,
}) => {
  const { height } = useWindowSize();

  const manageHeight = height > 700 ? height - 260 : height - 140;
  const myItemsMaxHeighgt = React.useMemo(() => manageHeight / 2 - 80, [manageHeight]);
  const theme = useTheme();
  const [myListAccordion, setMyListAccordion] = React.useState<'init' | 'open' | 'close'>('init');
  const [operatedList, setOperatedList] = React.useState<Array<OperatedList>>([]);
  const [isFocused, setIsFocused] = React.useState(false);
  const disabled = operatedListItems === undefined;
  const renderItemsList = React.useMemo(() => {
    let newItems = items.filter(item => !operatedList.find(my => my.id == item.id));
    if (listMoreResults.kind == 'hint') {
      const addHint = !listLoading && (listMoreResults.hasMore || newItems.length > listMoreResults.hintCount);
      if (addHint) {
        newItems = newItems.slice(0, listMoreResults.hintCount);
        newItems.push({
          name: strings.showMoreHint,
          id: 'hint',
          isHint: true,
        });
      }
    }
    return newItems;
  }, [items, listLoading, listMoreResults, operatedList, strings.showMoreHint]);

  const filteredItems = React.useMemo(
    () => operatedList.filter(e => filterFunction(e.name)),
    [operatedList, filterFunction]
  );

  const addItem = React.useCallback(
    (item: OperatedList) => {
      setOperatedList(myList => [{ ...item, isNew: true, timeStamp: Date.now() }, ...myList]);
      if (myListAccordion === 'init') {
        setMyListAccordion('open');
      }
      if (operatedList.length > 5) {
        const anchor = document.querySelector('#back-to-top');
        if (anchor) {
          anchor.scrollIntoView({
            block: 'center',
            behavior: 'smooth',
          });
        }
      }
    },
    [myListAccordion, operatedList.length]
  );

  const remove = React.useCallback(
    (itemToRemove: OperatedList) => {
      setOperatedList(currentList => currentList.filter(listItem => listItem.id !== itemToRemove.id));
    },
    [setOperatedList]
  );

  const searchFieldRef = React.useRef<HTMLInputElement>(null);

  React.useEffect(() => {
    // For supporting async fetching of operatedItems
    if (operatedListItems) setOperatedList(operatedListItems);
    setFilterValue('');
    searchFieldRef?.current?.focus();
  }, [operatedListItems, setFilterValue]);

  const buttonRow: ButtonProps[] = React.useMemo(
    () => [
      {
        children: 'Cancel',
        variant: 'text',
        onClick: () => {
          setOpen(false);
          onCancel?.();
        },
      },
      {
        children: 'Save',
        variant: 'primary',
        onClick: () => {
          setOpen(false);
          const hasNew = Boolean(operatedList.find(item => item.isNew));
          onSave(operatedList, hasNew);
        },
      },
    ],
    [onCancel, onSave, operatedList, setOpen]
  );
  const addedItemsTitle = `${strings.addedItems} (${filteredItems.length})`.replace(/ \(\)$/, '');
  const noResults = filterValue && storyStrings.lang.manageLists.noResults({ name: filterValue });

  return (
    <Drawer container={container.current} open={isOpen} toggleDrawer={operatedList.length === 0 ? setOpen : undefined}>
      <Typography color={theme.palette.texts.primary} variant="H1SemiBold">
        {strings.title}
      </Typography>
      <Box pt={1} />
      {strings.description && (
        <Typography color={theme.palette.texts.secondary} variant="H3Regular">
          {strings.description}
        </Typography>
      )}
      <Box pt={3} />
      <Stack spacing={2} mb={3} sx={{ height: manageHeight }}>
        <SearchField
          className={classNames({ isFilled: Boolean(filterValue) })}
          disabled={disabled}
          placeholder={!isFocused ? strings.placeholderSearch : ''}
          value={filterValue}
          inputRef={searchFieldRef}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setFilterValue(event.target.value);
          }}
          onFocus={() => {
            setIsFocused(true);
            if (!filterValue) {
              setFilterValue('');
            }
          }}
          onBlur={() => {
            setIsFocused(false);
          }}
          inputProps={{
            autoCapitalize: 'off',
            autoCorrect: 'off',
          }}
          InputProps={
            filterValue
              ? {
                  endAdornment: (
                    <InputAdornment position="end">
                      <Tooltip title={storyStrings.lang.manageLists.clear}>
                        <Button variant="IconButtons" onClick={() => setFilterValue('')}>
                          <XSmall />
                        </Button>
                      </Tooltip>
                    </InputAdornment>
                  ),
                }
              : !isFocused
                ? {
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchMedium className="SearchIcon" />
                      </InputAdornment>
                    ),
                  }
                : {}
          }
          sx={{ '&.Mui-focused .MuiIconButton-root': { color: 'primary.main' } }}
        />
        {toggle && (
          <Box>
            <Checkbox
              alignItems="left"
              className="default-checkbox left-aligned-checkbox"
              onChange={event => toggle.onToggle(event.target.checked)}
              label={toggle.text}
            />
          </Box>
        )}
        <Accordion
          disabled={disabled}
          expanded={myListAccordion === 'open'}
          onChange={(_, expanded) => setMyListAccordion(expanded ? 'open' : 'close')}
          disableGutters
          elevation={0}
        >
          <AccordionSummary
            expandIcon={
              <Button variant="IconButtons">
                <ArrowDownMedium />
              </Button>
            }
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography variant="H3Medium">{addedItemsTitle}</Typography>
          </AccordionSummary>
          <AccordionDetails sx={{ maxHeight: myItemsMaxHeighgt }}>
            <DynamicScrollWrap height={myItemsMaxHeighgt} needToWrap={myItemsMaxHeighgt <= filteredItems.length * 36}>
              {!operatedList.length ? (
                <EmptyState mt={1} centredText title={noResults || strings.emptyState} images={images} />
              ) : (
                <React.Fragment>
                  <span id="back-to-top" />
                  {filteredItems.map(item => (
                    <Stack
                      minHeight={36}
                      position="relative"
                      justifyContent="stretch"
                      direction="row"
                      alignItems="center"
                      key={item.id}
                    >
                      <ManageItemRender
                        item={item}
                        action={remove}
                        icon={icon}
                        toDelete={true}
                        tooltipClassName="location-label-tooltip-manage-item"
                      />
                    </Stack>
                  ))}
                </React.Fragment>
              )}
            </DynamicScrollWrap>
          </AccordionDetails>
        </Accordion>
        <Accordion disabled={disabled} defaultExpanded disableGutters elevation={0} data-fullheight>
          <AccordionSummary
            expandIcon={
              <Button variant="IconButtons">
                <ArrowDownMedium />
              </Button>
            }
            aria-controls="panel2a-content"
            id="panel2a-header"
          >
            <Typography variant="H3Medium">{strings.availabeItems}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            {error || (!renderItemsList.length && !listLoading) ? (
              error ? (
                <EmptyState mt={2} mb={2} centredText message={strings.errorMessage} />
              ) : (
                <EmptyState mt={2} mb={2} centredText message={noResults || strings.emptyState} />
              )
            ) : (
              <VirtualList
                render={item => <ManageItemRender item={item} action={addItem} icon={icon} />}
                size={36}
                hasNextPage={listMoreResults.kind == 'paging' && listMoreResults.hasMore}
                isNextPageLoading={listLoading}
                items={renderItemsList}
                loadNextPage={listMoreResults.kind == 'paging' ? listMoreResults.loadNextPage : undefined}
              />
            )}
          </AccordionDetails>
        </Accordion>
      </Stack>
      <ButtonsStack buttons={buttonRow} />
    </Drawer>
  );
};

ManageLists.defaultProps = {
  filterValue: '',
};
