import { GraphClient, useGraphClient } from '@services';
import { ContainerHandlers } from '@storybook';
import React, { useCallback } from 'react';
import { strings } from '@vendor/languages';
import { useSafeCallback } from '~/hooks/UseSafeCallback';
import { useSubscribe } from '~/hooks/useSubscribe';
import { BaseAction, ItemData, copyLink, emailWithLink, shareToTeams, wrapAsyncActionCallback } from '~/utilities';
import { CopyLink } from '~/utilities/actions/CopyLink';
import { EmailWithLink } from '~/utilities/actions/EmailWithLink';
import { ShareToTeams } from '~/utilities/actions/ShareToTeams';

interface KeyPressSubscribeProps {
  handlers: ContainerHandlers<ItemData>;
  children: any;
}

const KeyPressSubscribe = ({ handlers, children }: KeyPressSubscribeProps) => {
  const gcl = useGraphClient();
  const wrapCallback = useCallback(
    async (callback: (gcl: GraphClient, data: ItemData) => Promise<void>, action: BaseAction) => {
      const selectedItems = handlers.getSelectedNodes();
      const data = selectedItems[0]?.data;
      action.isHandled(selectedItems) &&
        (await wrapAsyncActionCallback(callback, action, 'Shortcut', selectedItems, handlers)(gcl, data));
    },
    [gcl, handlers]
  );

  useSubscribe(
    'SUBSCRIPTION_EMAIL_WITH_LINK',
    useSafeCallback(async () => await wrapCallback(emailWithLink, new EmailWithLink()), [wrapCallback], false)
  );
  useSubscribe(
    'SUBSCRIPTION_COPY_LINK',
    useSafeCallback(async () => await wrapCallback(copyLink, new CopyLink()), [wrapCallback], false, {
      onSuccessTitle: strings.lang.notifications.copyLink,
    })
  );
  useSubscribe(
    'SUBSCRIPTION_SHARE_TO_TEAMS',
    useSafeCallback(async () => await wrapCallback(shareToTeams, new ShareToTeams()), [wrapCallback], false)
  );

  return <React.Fragment>{children}</React.Fragment>;
};

export default KeyPressSubscribe;
