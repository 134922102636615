import React, { useMemo, useState } from 'react';
import { Stack } from '@mui/system';
import { strings } from '@vendor/languages';
import { Tooltip } from '@mui/material';
import OnboardingListItem, { OnboardingListItemType } from '../OnboardingListItem/OnboardingListItem';
import { Button } from '../Button';
import { ArrowDownSmall, ArrowUpSmall } from '../../assets/icons';
import { OperatedList } from '../ManageLists/ManageItem';
import { Separator } from '../Separator';

interface OnboardingListProps {
  listItems: Array<OperatedList>;
  onChangeFunction: (item: OperatedList) => void;
  listType: OnboardingListItemType; // TODO: Any item should include the icon to render should not be manage as props
}

const MAX_ITEMS = 25;

export const OnboardingList: React.FunctionComponent<OnboardingListProps> = ({
  listItems,
  onChangeFunction,
  listType,
}) => {
  const collapseRequier = useMemo(() => listItems.length > MAX_ITEMS, [listItems.length]);
  const [collapsed, setCollapsed] = useState<boolean>(listItems.length > MAX_ITEMS);

  return (
    <Stack spacing={1} width={'100%'} marginBottom={4}>
      <Separator />
      {listItems.slice(0, collapsed ? MAX_ITEMS : listItems.length).map((item, index) => (
        <OnboardingListItem
          key={item.id || index}
          id={item.id}
          name={item.name}
          description={item.description}
          checked={item.checked}
          onChange={() => onChangeFunction(item)}
          type={listType}
        />
      ))}
      {collapsed && collapseRequier && (
        <Tooltip title={strings.lang.onBoarding.loadMore}>
          <Button sx={{ mt: 1, width: '100px' }} fullWidth onClick={() => setCollapsed(!collapsed)} variant="text">
            {strings.lang.onBoarding.loadMore}
            {collapsed ? <ArrowDownSmall /> : <ArrowUpSmall />}
          </Button>
        </Tooltip>
      )}
    </Stack>
  );
};

export default OnboardingList;
