import React, { createContext, Dispatch, FC, PropsWithChildren, SetStateAction, useCallback, useState } from 'react';
export interface MouseEventsInterface {
  actionDone?: () => void;
  waitForAction?: () => void;
  isWaitingForAction?: boolean;
  setIsWaitingForAction?: Dispatch<SetStateAction<boolean>>;
}

export const MouseEventsContext = createContext<MouseEventsInterface>({});

export const MouseEvents: FC<PropsWithChildren> = ({ children }) => {
  const [isWaitingForAction, setIsWaitingForAction] = useState<boolean>(false);

  const waitForAction = () => setIsWaitingForAction(true);

  const actionDone = useCallback(() => {
    setIsWaitingForAction(false);
  }, []);
  return (
    <MouseEventsContext.Provider value={{ isWaitingForAction, waitForAction, actionDone }}>
      {children}
    </MouseEventsContext.Provider>
  );
};
