import { ButtonPropsVariantOverrides } from '@mui/material';

export interface BAVariant {
  variant: keyof ButtonPropsVariantOverrides;
  withText: boolean;
  useMediumIcon?: boolean;
}

export const emptyStateButton: BAVariant = {
  variant: 'EmptyStateAction',
  withText: true,
  useMediumIcon: false,
};
export const emptyStateDrilldownButton: BAVariant = {
  variant: 'text',
  withText: true,
  useMediumIcon: false,
};

export const itemFastActions: BAVariant = {
  variant: 'fastActions',
  withText: false,
  useMediumIcon: false,
};

export const itemFastActionsOnSelect: BAVariant = {
  variant: 'fastActionsOnAccent',
  withText: false,
  useMediumIcon: false,
};

export const toolbarQuickActions: BAVariant = {
  variant: 'IconButtons',
  withText: false,
  useMediumIcon: true,
};

export const DrawerButton: BAVariant = {
  variant: 'DrawerButton',
  withText: false,
  useMediumIcon: false,
};

export const threeDotsQuickActions: BAVariant = {
  variant: 'threeDotsButtons',
  withText: false,
  useMediumIcon: false,
};

export const threeDotsQuickActionsOnSelect: BAVariant = {
  variant: 'threeDotsButtonsOnAccent',
  withText: false,
  useMediumIcon: false,
};

export const favoriteQuickActions: BAVariant = {
  variant: 'favoriteFastAction',
  withText: false,
  useMediumIcon: false,
};

export const MenuActions: BAVariant = {
  variant: 'MenuActions',
  withText: true,
  useMediumIcon: true,
};

export const MenuActionsWarningColor: BAVariant = {
  variant: 'MenuActionsWarningColor',
  withText: true,
  useMediumIcon: true,
};
