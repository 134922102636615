import React, { useEffect, useState } from 'react';
import Lottie from 'react-lottie';

export interface LottieAnimationProps {
  options: any; // First animation options
  secondaryOptions?: any; // Second animation options, optional
  height?: number;
  width?: number;
  handleAnimationComplete?: () => void; // Additional callback for when either animation completes
}

const LottieAnimation: React.FC<LottieAnimationProps> = ({
  options,
  secondaryOptions,
  height,
  width,
  handleAnimationComplete,
}) => {
  const [currentOptions, setCurrentOptions] = useState(options);

  // Event listener callback for when the initial animation completes
  const onInitialAnimationComplete = () => {
    if (secondaryOptions) {
      setCurrentOptions(secondaryOptions); // Switch to secondary animation
    }
    if (handleAnimationComplete) {
      handleAnimationComplete();
    }
  };

  useEffect(() => {
    setCurrentOptions(options);
  }, [options]);

  return (
    <Lottie
      options={currentOptions}
      height={height}
      width={width}
      eventListeners={[
        {
          eventName: 'complete',
          callback: onInitialAnimationComplete,
        },
      ]}
    />
  );
};

export default LottieAnimation;
