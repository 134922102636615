import { Theme, Components } from '@mui/material/styles';

const createSvgStyles = color => ({
  'svg:not([data-nofill]),svg:not([data-nofill]) path': {
    fill: color,
  },
});

export default (theme: Theme) => {
  const styleOverrides: Components['MuiMenuItem'] = {
    defaultProps: {
      disableRipple: true,
    },
    styleOverrides: {
      root: {
        ...theme.typography.H3Regular,
        color: theme.palette.texts.primary,
        minHeight: 30,
        maxHeight: 30,
        height: 30,
        width: '100%',
        borderRadius: 6,
        padding: theme.spacing(1, 2),
        '&.divider': {
          padding: 0,
          minHeight: 0,
          borderRadius: 0,
          height: 'auto',
        },
        '&+.MuiMenuItem-root:not(.divider)': {
          marginTop: theme.spacing(1),
        },
        ...createSvgStyles(theme.palette.texts.primary),
        '&:hover:not(.divider)': {
          color: theme.palette.texts.accentSecondary,
          backgroundColor: theme.palette.buttons.primary,
          ...createSvgStyles(theme.palette.texts.accentSecondary),
        },
        '&:active': {
          color: theme.palette.texts.accentSecondary,
          backgroundColor: theme.palette.buttons.secondary,
          ...createSvgStyles(theme.palette.texts.accentSecondary),
        },
        '&.error': {
          color: theme.palette.status.dangerPrimary,
          ...createSvgStyles(theme.palette.status.dangerPrimary),
          '&:hover': {
            backgroundColor: theme.palette.status.dangerSecondary,
          },
          '&:active': {
            color: theme.palette.status.dangerPrimary,
            backgroundColor: theme.palette.buttons.dangerSecondary,
            ...createSvgStyles(theme.palette.status.dangerPrimary),
          },
        },
        '&.Mui-disabled': {
          backgroundColor: 'transparent',
          color: theme.palette.texts.primary,
          ...createSvgStyles(theme.palette.texts.primary),
        },
      },
    },
  };
  return styleOverrides;
};
