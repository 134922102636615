import React from 'react';
import { Box, Stack, styled, useTheme } from '@mui/system';
import { Typography } from '../Typography';
import { Button } from '../Button';
import { UploadStatus } from './Indicator';
import { UploadSmall, VSmall, XSmall, ExclamationMarkSmall, ErrorSmall } from '../../assets/icons';

export interface IndicatorHeaderProps {
  status: UploadStatus;
  title: string;
  onClose: () => void;
}

const StatusStack = styled(Stack, {
  shouldForwardProp: prop => prop !== 'status',
})<{ status: UploadStatus }>(({ theme, status }) => ({
  ['& .MuiBox-root']: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: theme.spacing(1),
    width: '22px',
    height: '22px',
    padding: '2px',

    backgroundColor:
      status === 'canceled'
        ? theme.palette.status.warningSecondary
        : status === 'success'
          ? theme.palette.status.successSecondary
          : status === 'error'
            ? theme.palette.status.dangerSecondary
            : theme.palette.status.defaultSecondary,
    [' svg path']: {
      fill:
        status === 'canceled'
          ? theme.palette.status.warningPrimary
          : status === 'success'
            ? theme.palette.status.successPrimary
            : status === 'error'
              ? theme.palette.status.dangerPrimary
              : theme.palette.status.defaultPrimary,
    },
  },
  ['& .MuiTypography-root']: {
    flex: 1,
    color:
      status === 'canceled'
        ? theme.palette.status.warningPrimary
        : status === 'success'
          ? theme.palette.status.successPrimary
          : status === 'error'
            ? theme.palette.status.dangerPrimary
            : theme.palette.status.defaultPrimary,
  },
}));

export const IndicatorHeader = ({ status, title, onClose }: IndicatorHeaderProps) => {
  const theme = useTheme();
  return (
    <StatusStack status={status} alignItems="center" spacing={2} direction="row">
      <Box>
        {status === 'progress' && <UploadSmall />}
        {status === 'success' && <VSmall />}
        {status === 'error' && <ErrorSmall />}
        {status === 'canceled' && <ExclamationMarkSmall />}
      </Box>
      <Typography color={status} variant="H1Regular">
        {title}
      </Typography>
      <Button size="small" variant="IconButtons" onClick={onClose}>
        <XSmall fill={theme.palette.texts.tertiary} />
      </Button>
    </StatusStack>
  );
};
