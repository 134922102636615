/* eslint-disable @typescript-eslint/no-use-before-define */
import { ListView, OrderByInfo } from '../itemTypes/ListView';

export class SPListView implements ListView {
  private static FilterByType = (type: number) =>
    `<Eq><FieldRef Name="FSObjType"/><Value Type="Text">${type}</Value></Eq>`;

  static DocumentOnlyQuery = SPListView.FilterByType(0);
  static FolderOnlyQuery = SPListView.FilterByType(1);
  static LastModifiedField = 'Modified';
  static NameLinkField = 'LinkFilename';
  static OrderByName: OrderByInfo[] = [{ field: SPListView.NameLinkField, dir: true }];
  static OrderByLastModified: OrderByInfo[] = [{ field: SPListView.LastModifiedField, dir: false }];

  get whereQuery(): string {
    return '';
  }

  get orderBy(): OrderByInfo[] {
    return [];
  }

  get viewFields(): string[] {
    return [];
  }

  exportXml(rowLimit: number): string {
    const allFieldsExp = this.viewFields.map(f => `<FieldRef Name="${f}"/>`).join('');
    const orderByExp = this.orderBy.map(o => `<FieldRef Name="${o.field}" Ascending="${o.dir}"/>`).join('');
    return `<View Scope=""><ViewFields>${allFieldsExp}</ViewFields><Query><OrderBy>${orderByExp}</OrderBy>${this.whereQuery}</Query><RowLimit Paged=\"TRUE\">${rowLimit}</RowLimit></View>`;
  }

  combineWithFields(inViewFields: string[]): ListView {
    return new SPListViewWithFields(this, inViewFields);
  }

  combineWithOrderBy(inOrderBy: OrderByInfo[]): ListView {
    return new SPListViewWithOrderBy(this, inOrderBy);
  }

  combineWithQuery(query: string): ListView {
    return new SPListViewWithQuery(this, query);
  }
}

class SPListViewForwarder extends SPListView {
  constructor(protected readonly next: SPListView) {
    super();
  }

  override get orderBy(): OrderByInfo[] {
    return this.next.orderBy;
  }
  override get viewFields(): string[] {
    return this.next.viewFields;
  }
  override get whereQuery(): string {
    return this.next.whereQuery;
  }
}

class SPListViewWithFields extends SPListViewForwarder {
  constructor(
    next: SPListView,
    private readonly inViewFields: string[]
  ) {
    super(next);
  }

  get viewFields() {
    return this.inViewFields;
  }
}

class SPListViewWithOrderBy extends SPListViewForwarder {
  constructor(
    next: SPListView,
    private readonly inOrderBy: OrderByInfo[]
  ) {
    super(next);
  }

  get orderBy() {
    return this.inOrderBy;
  }
}

class SPListViewWithQuery extends SPListViewForwarder {
  constructor(
    next: SPListView,
    private readonly inQuery
  ) {
    super(next);
  }

  get whereQuery() {
    return `<Where>${this.inQuery}</Where>`;
  }
}
