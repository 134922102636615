import React from 'react';
import { Toolbar as MuiToolbar, ToolbarProps as MuiToolbarProps } from '@mui/material';

export type ToolbarProps = MuiToolbarProps;

const Toolbar = ({ children, className, ...rest }: ToolbarProps) => {
  return (
    <MuiToolbar {...rest} className={className}>
      {children}
    </MuiToolbar>
  );
};

export default Toolbar;
