import React, { useEffect, useState } from 'react';
import { Stack, useTheme } from '@storybook';

const LoadingScreen = (props: any) => {
  const [isLoading, setIsLoading] = useState(true);
  const theme = useTheme();

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 2000);

    return () => {
      clearTimeout(timer);
    };
  }, []);
  const path =
    theme.palette.mode === 'light'
      ? '../../assets/gifs/HarmonieLogoLoaderWhite.gif'
      : '../../assets/gifs/HarmonieLogoLoaderBlack.gif';
  return isLoading ? (
    <Stack background={theme.palette.backgrounds.primary} height={'100%'} width={'100%'}>
      <Stack
        direction="column"
        mt={40}
        spacing={2}
        alignItems="center"
        justifyContent="center"
        background={theme.palette.backgrounds.primary}
      >
        <img src={path} alt="Loading" height={200} />
      </Stack>
    </Stack>
  ) : (
    props.children
  );
};

export default LoadingScreen;
