import React from 'react';
import { Stack, Typography } from '@harmon.ie-ce/storybook';

interface TooltipContentProps {
  name: string;
  description?: string;
}

export const TooltipContent = ({ name, description }: TooltipContentProps) => {
  return (
    <Stack spacing={'3px'}>
      <Typography
        variant="H3SemiBold"
        sx={{
          // TODO - Web kit is not standard & we shouldn't use it
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          display: '-webkit-box',
          WebkitLineClamp: 2,
          WebkitBoxOrient: 'vertical',
        }}
      >
        {name}
      </Typography>
      <Typography
        variant="H3Regular"
        sx={{
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          display: '-webkit-box',
          WebkitLineClamp: 4,
          WebkitBoxOrient: 'vertical',
        }}
      >
        {description}
      </Typography>
    </Stack>
  );
};
