import React from 'react';
import Typography, { TypographyProps } from '@mui/material/Typography';
import { styled } from '@mui/system';
import { Box } from '@mui/material';

const HighlightSpan = styled('span')(({ theme }) => ({
  position: 'relative',
  overflow: 'hidden',
  '&:after': {
    content: '""',
    position: 'absolute',
    bottom: '-2px',
    width: '100%',
    height: '1.5px',
    background: theme.palette.buttons.accentSecondary,
    borderRadius: '1px',
    left: 0,
    zIndex: 10000,
  },
}));

HighlightSpan.defaultProps = { className: 'HighlightSpan' };

interface HighlightedTypographyProps extends TypographyProps {
  highlight?: string;
}
interface HighlightedSummaryProps extends TypographyProps {
  skipHighlight?: boolean;
}

export const StyledItemName = styled(Box)`
  padding-right: ${({ theme }) => theme.spacing(1)};
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow-x: clip;
  flex-shrink: inherit;
  margin-top: 0 !important;
  .navigationItem:hover & {
    flex-shrink: initial;
  }
  .MuiTypography-root {
    overflow: hidden;
  }
`;

const StyledBox = styled(Box)`
  display: flex;
  padding-right: ${({ theme }) => theme.spacing(1)};
  justify-content: start;
  align-content: center;
  align-items: center;
  flex-direction: row;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow-x: clip;
  flex-shrink: inherit;
  margin-top: 0 !important;
  .navigationItem:hover & {
    flex-shrink: initial;
  }
  .MuiTypography-root {
    overflow: hidden;
  }
`;

const HighlightedTypography: React.FC<HighlightedTypographyProps> = ({ highlight, children, ...props }) => {
  if (!highlight || !doesHighlightExist(String(children), highlight)) {
    return (
      <StyledItemName>
        <Typography sx={{ whiteSpace: 'nowrap', overflowX: 'hidden', textOverflow: 'ellipsis' }} {...props}>
          {children}
        </Typography>
      </StyledItemName>
    );
  }

  const isPhraseSearch = highlight.startsWith('"') && highlight.endsWith('"');
  const phraseToFind = isPhraseSearch ? highlight.slice(1, -1) : highlight;
  const escapedPhrase = phraseToFind.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&');

  const regexPattern = isPhraseSearch ? `(${escapedPhrase})` : `(${escapedPhrase.split(' ').join('|')})`;
  const splitRegex = new RegExp(regexPattern, 'gi');

  const parts = String(children)
    .split(splitRegex)
    .map((part, index) => {
      if (part.match(splitRegex)) {
        return <HighlightSpan key={index}>{part}</HighlightSpan>;
      }
      return part;
    });

  return (
    <StyledBox>
      <Typography sx={{ whiteSpace: 'nowrap', overflowX: 'hidden', textOverflow: 'ellipsis' }} {...props}>
        {parts}
      </Typography>
    </StyledBox>
  );
};

export const HighlightedSummary: React.FC<HighlightedSummaryProps> = ({ children, skipHighlight, ...props }) => {
  if (skipHighlight) {
    return (
      <StyledBox>
        <Typography sx={{ whiteSpace: 'nowrap', overflowX: 'hidden', textOverflow: 'ellipsis' }} {...props}>
          {children}
        </Typography>
      </StyledBox>
    );
  }
  const parts = String(children)
    .split('<ddd/>')
    .map((part, index) => {
      const tokens = part.split(/<c0>|<\/c0>/).map((token, tokenIndex) => {
        const isHighlighted = part.indexOf(`<c0>${token}</c0>`) > -1;
        return isHighlighted ? <HighlightSpan key={`${index}-${tokenIndex}`}>{token}</HighlightSpan> : token;
      });

      return [<React.Fragment key={index}>{tokens}</React.Fragment>, ' '];
    });

  return (
    <StyledBox>
      <Typography sx={{ whiteSpace: 'nowrap', overflowX: 'hidden', textOverflow: 'ellipsis' }} {...props}>
        {parts}
      </Typography>
    </StyledBox>
  );
};

export function doesHighlightExist(text: string, highlight: string): boolean {
  if (!highlight) return false;

  const isPhraseSearch = highlight.startsWith('"') && highlight.endsWith('"');
  const phraseToFind = isPhraseSearch ? highlight.slice(1, -1) : highlight;

  const escapedPhrase = phraseToFind.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&');
  const regexPattern = isPhraseSearch
    ? `${escapedPhrase}`
    : escapedPhrase
        .split(' ')
        .map(word => `(${word})`)
        .join('|');
  const regex = new RegExp(regexPattern, 'gi');

  return regex.test(text);
}

export default HighlightedTypography;
