import React from 'react';
import {
  Menu as MuiMenu,
  MenuProps as MuiMenuProps,
  MenuItem as MuiMenuItem,
  MenuItemProps as MuiMenuItemProps,
  MenuList as MuiMenuList,
  MenuListProps as MuiMenuListProps,
  ListItemIcon as MuiListItemIcon,
  ListItemIconProps as MuiListItemIconProps,
} from '@mui/material';

export type MenuProps = MuiMenuProps;
export type MenuItemProps = MuiMenuItemProps;
export type MenuListProps = MuiMenuListProps;
export type ListItemIconProps = MuiListItemIconProps;

export const Menu = ({ children, ...rest }: MenuProps) => <MuiMenu {...rest}>{children}</MuiMenu>;

export const MenuItem = ({ children, ...rest }: MenuItemProps) => <MuiMenuItem {...rest}>{children}</MuiMenuItem>;

export const MenuList = ({ children, ...rest }: MuiMenuListProps) => <MuiMenuList {...rest}>{children}</MuiMenuList>;

export const MenuItemIcon = ({ children, ...rest }: ListItemIconProps) => (
  <MuiListItemIcon {...rest}>{children}</MuiListItemIcon>
);
