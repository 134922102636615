import { ViewItem, CopyLinkMedium } from '@storybook';
import { useGraphClient } from '@services';
import { strings } from '@vendor/languages';
import { ItemData } from '../itemTypes';
import { copyLink } from './ActionsUtils';
import { ActionRendererHookType, BaseReadActionClass } from './BaseAction';
import { InsightItem } from '../graphTypes';

const useCopyLink: ActionRendererHookType = ({ useSafeCallback, nodes }) => {
  const gcl = useGraphClient();
  const node = nodes[0];
  const onClick = useSafeCallback(
    async () => {
      await copyLink(gcl, node.data);
    },
    [gcl, node],
    false,
    {
      onSuccessTitle: strings.lang.notifications.copyLink,
    }
  );
  return {
    title: strings.lang.actionToolTip.copyLink,
    icon: CopyLinkMedium,
    onClick,
    node,
  };
};

export class CopyLink extends BaseReadActionClass {
  readonly trackedName = 'CopyLink';
  readonly useRenderer = useCopyLink;

  isHandled(nodes: ViewItem<ItemData>[]): boolean {
    return (
      nodes.length === 1 &&
      nodes[0].data.canOpenInBrowser &&
      nodes[0].id !== 'oneDrive:OneDrive:' &&
      !Boolean((nodes[0].data as InsightItem).isEmailAttachment)
    );
  }
}
