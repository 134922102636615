import React from 'react';
import { Stack, styled } from '@mui/material';
import { useTheme } from '@mui/material';
import { DarkListDivider, LightListDivider } from '../../assets/icons';

const SeparatorContainer = styled(Stack)`
  flex-direction: row;
  width: 100%;
  align-content: center;
  align-items: center;
  flex-direction: row;
  padding: 3px 0px 0px 0px;
`;

const Separator: React.FC = () => {
  const theme = useTheme();
  return (
    <SeparatorContainer>
      {theme.palette.mode === 'light' ? <LightListDivider /> : <DarkListDivider />}
    </SeparatorContainer>
  );
};

export default Separator;
