import { Theme, Components } from '@mui/material/styles';

export default (theme: Theme) => {
  const styleOverrides: Components['MuiAccordionDetails'] = {
    styleOverrides: {
      root: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        padding: 0,
        paddingTop: theme.spacing(1),
        background: theme.palette.backgrounds.primary,
        overflow: 'hidden',
        ['[data-fullheight] &']: {
          maxHeight: 'none',
        },
        ['& > div']: {
          margin: 0,
        },
      },
    },
  };
  return styleOverrides;
};
