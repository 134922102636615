import React from 'react';
import { Stack, StackProps } from '@mui/system';
import { styled } from '@mui/material';
import { Checkbox } from '../Checkbox';
import { Tooltip } from '../Tooltip';
import { Typography } from '../Typography';
import { SiteMedium, TeamMedium } from '../../assets/icons';

export enum OnboardingListItemType {
  teams = 'TEAMS',
  sharepoint = 'SHAREPOINT',
}

interface OnboardingListItemProps extends StackProps {
  id: string;
  name: string;
  type: OnboardingListItemType;
  description?: string | null; // like MS graph properties that can be null
  checked?: boolean;
  onChange?: () => void;
}

const OnboardingListItemRoot = styled('span', {
  shouldForwardProp: prop => prop !== 'checked',
})<{ checked: boolean }>(({ theme, checked }) => ({
  cursor: 'pointer',
  color: checked ? theme.palette.texts.accentPrimary : theme.palette.texts.primary,
  ['.MuiFormControlLabel-root']: {
    width: '100%',
    padding: theme.spacing(1, 2),
    ...(checked ? { background: theme.palette.buttons.secondary } : {}),
    // TODO: why it's 6px?
    borderRadius: '6px',
  },
  ['.MuiButtonBase-root']: {
    padding: 0,
    marginLeft: 0,
  },
  ['svg.checkIcon']: {
    fill: checked ? theme.palette.texts.accentPrimary : theme.palette.texts.primary,
  },
  ['&:hover']: {
    color: theme.palette.texts.accentPrimary,
    ['.MuiFormControlLabel-root']: {
      background: theme.palette.buttons.primary,
    },
    ['svg.checkIcon']: {
      fill: theme.palette.texts.accentPrimary,
    },
  },
}));

export const OnboardingListItem: React.FunctionComponent<OnboardingListItemProps> = ({
  name,
  checked,
  description,
  onChange,
  type,
  ...props
}) => {
  return (
    <Tooltip title={description || name}>
      <OnboardingListItemRoot checked={!!checked}>
        <Checkbox
          onChange={onChange}
          alignItems="center"
          className="default-checkbox"
          checked={checked}
          disableRipple
          LabelComponent={
            <Stack direction="row" spacing={1} {...props}>
              <Stack>
                {type === OnboardingListItemType.teams ? (
                  <TeamMedium className="checkIcon" />
                ) : (
                  <SiteMedium className="checkIcon" />
                )}
              </Stack>
              <Typography overflow="hidden" whiteSpace="nowrap" textOverflow="ellipsis" variant="H2Regular">
                {name}
              </Typography>
            </Stack>
          }
        />
      </OnboardingListItemRoot>
    </Tooltip>
  );
};

export default OnboardingListItem;
