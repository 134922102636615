import React, { useRef, useState } from 'react';
import { DragAndDropGlobalWrapper } from '@storybook';

const DragAndDropGlobal: React.FC<React.PropsWithChildren> = ({ children }) => {
  const [isOnDrag, setIsOnDrag] = useState(false);
  const counter = useRef(0);
  const handleDragEnter = (e: React.DragEvent<HTMLDivElement>) => {
    counter.current += 1;
    e.stopPropagation();
    setIsOnDrag(true);
    e.preventDefault();
    if (!Boolean(e['active'])) e.dataTransfer.dropEffect = 'none';
  };

  const onDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    if (!Boolean(e['active'])) e.dataTransfer.dropEffect = 'none';
    e.preventDefault();
  };

  const handleDragLeave = () => {
    counter.current -= 1;
    if (counter.current === 0) setIsOnDrag(false);
  };

  return (
    <DragAndDropGlobalWrapper
      onDragEnter={handleDragEnter}
      onDragLeave={handleDragLeave}
      onDragOver={onDragOver}
      isDragging={isOnDrag}
      onDrop={handleDragLeave}
    >
      {children}
    </DragAndDropGlobalWrapper>
  );
};

export default DragAndDropGlobal;
