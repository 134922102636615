import { ViewItem, SupportIconMedium } from '@storybook';
import { strings } from '@vendor/languages';
import { ActionRendererHookType, BaseReadActionClass, ItemData } from '~/utilities';

const useGoToSetting: ActionRendererHookType = ({ useCallback }) => {
  const onClick = useCallback(() => window.open('https://harmon.ie/support', '_blank'), []);
  return {
    title: strings.lang.allLocatiosToolbar.getSupport,
    icon: SupportIconMedium,
    onClick,
  };
};

export class GetSupportLink extends BaseReadActionClass {
  readonly trackedName = 'GetSupport';
  readonly useRenderer = useGoToSetting;

  isHandled(_nodes: ViewItem<ItemData>[]): boolean {
    return true;
  }
}
