import { BaseItem } from './BaseItem';
import { ItemData } from './ItemData';

export class SeparatorItem extends BaseItem implements ItemData {
  readonly size = 13;
  constructor() {
    super({ id: '', name: '', type: 'separator' });
  }

  get isVirtual(): boolean {
    return true;
  }

  override getIcon(_expanded: boolean): undefined {
    return undefined;
  }
}
