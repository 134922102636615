import { Theme, Components } from '@mui/material/styles';

export default (theme: Theme) => {
  const appBarStyleOverrides: Components['MuiAppBar'] = {
    styleOverrides: {
      root: {
        backgroundColor: theme.palette.backgrounds.primary,
        boxShadow: 'none',
      },
    },
  };
  return appBarStyleOverrides;
};
