import React from 'react';
import {
  Dialog as MuiDialog,
  DialogActions,
  DialogContent,
  DialogProps as MuiDialogProps,
  DialogTitle,
} from '@mui/material';
import { Stack } from '@mui/system';

export type NotificationsProps = MuiDialogProps & {
  actions: React.ReactNode;
  notificationsTitle: any;
};

const Notifications = ({ children, actions, notificationsTitle, ...rest }: NotificationsProps) => {
  return (
    <MuiDialog {...rest} maxWidth={'xs'}>
      <DialogTitle direction="row" component={Stack} className="error-dialog-title">
        {notificationsTitle}
      </DialogTitle>
      <DialogContent sx={{ paddingLeft: '47px !important' }} className="error-dialog-content">
        {children}
      </DialogContent>
      <DialogActions className="error-dialog-actions">{actions}</DialogActions>
    </MuiDialog>
  );
};

export default Notifications;
