import React from 'react';
import { Stack, styled } from '@mui/system';

export interface ShareDialogProps {
  actions: React.ReactNode;
  ShareDialogTitle: any;
  children: any;
}

const ShareDialoComponent = styled(Stack)`
  background: ${({ theme }) => theme.palette.backgrounds.primary};
`;

const ShareDialog = ({ actions, ShareDialogTitle, children }: ShareDialogProps) => {
  return (
    <ShareDialoComponent width={'100%'} maxWidth={490} height={'100%'} spacing={4} padding={4} minHeight={77}>
      <Stack>{ShareDialogTitle}</Stack>
      <Stack>{children}</Stack>
      <Stack>{actions}</Stack>
    </ShareDialoComponent>
  );
};

export default ShareDialog;
