import { GraphRequest, GraphRequestCallback } from '@microsoft/microsoft-graph-client';
import { KeyValuePairObjectStringNumber, OdataRequest } from './OdataRequest';

export abstract class BaseOdataRequest implements OdataRequest {
  private hasQueryPart: boolean;

  constructor(public request: string) {
    this.hasQueryPart = request.indexOf('?') !== -1;
  }

  skip(from: number): OdataRequest {
    return this.appendQueryParam('$skip', from.toString());
  }

  select(properties: string | string[]): OdataRequest {
    return this.appendQueryParam('$select', properties.toString());
  }

  expand(properties: string | string[]) {
    return this.appendQueryParam('$expand', properties.toString());
  }

  orderby(properties: string | string[]) {
    return this.appendQueryParam('$orderby', properties.toString());
  }

  filter(filterStr: string) {
    return this.appendQueryParam('$filter', filterStr);
  }

  search(searchStr: string) {
    return this.appendQueryParam('$search', searchStr);
  }

  top(n: number) {
    return this.appendQueryParam('$top', n.toString());
  }

  abstract headers(headers: KeyValuePairObjectStringNumber | HeadersInit | GraphRequest): OdataRequest;

  abstract get(_?: GraphRequestCallback | undefined): Promise<any>;
  abstract getStream(callback?: GraphRequestCallback | undefined): Promise<any>;
  abstract post(content: any, _?: GraphRequestCallback | undefined): Promise<any>;
  abstract delete(callback?: GraphRequestCallback | undefined): Promise<any>;
  abstract patch(content: any, callback?: GraphRequestCallback): Promise<any>;

  protected appendQueryParam(key: string, value: string) {
    const separator = this.hasQueryPart ? '&' : '?';
    // eslint-disable-next-line prefer-template
    this.request += separator + key + '=' + encodeURIComponent(value);
    this.hasQueryPart = true;
    return this;
  }
}
