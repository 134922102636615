import { BaseAction, BaseReadActionClass } from './BaseAction';

export class GroupAction extends BaseReadActionClass {
  readonly trackedName = 'Group';
  constructor(public actions: BaseAction[]) {
    super();
  }

  isHandled(): boolean {
    return true;
  }
}
