import { Button, Snackbar, Typography, useTheme } from '@harmon.ie-ce/storybook';
import React, { useEffect } from 'react';
import { SnackbarOrigin } from 'material-ui-core';
import { strings } from '@vendor/languages';
import { UndoAction } from '../../contexts/NotificationsContext';

export const SnackbarNotifications: React.FC<{
  showNotifications: boolean;
  setShowNotifications: React.Dispatch<React.SetStateAction<boolean>>;
  title: string;
  message?: string;
  cancelAction?: UndoAction;
  anchorOrigin?: SnackbarOrigin;
  timeOut?: number;
  className?: string;
}> = ({
  showNotifications,
  setShowNotifications,
  title,
  cancelAction,
  timeOut = 5000,
  anchorOrigin = {
    vertical: 'bottom',
    horizontal: 'center',
  },
  className,
}) => {
  const handleClose = (_event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') return;
    setShowNotifications(false);
  };
  const undoAction = (event: React.SyntheticEvent | Event) => {
    cancelAction?.run();
    handleClose(event);
  };
  useEffect(() => {
    if (showNotifications) {
      const timeoutId = setTimeout(() => {
        setShowNotifications(false);
      }, timeOut);

      // Clear the timeout when the component is unmounted or when showNotifications changes
      return () => clearTimeout(timeoutId);
    }
  }, [showNotifications, setShowNotifications, timeOut]);
  const theme = useTheme();
  const action = cancelAction && (
    // Set the color hardcoded  for light and dark
    <Button variant="text" onClick={undoAction} sx={{ mr: 1, color: theme.palette.texts.quaternary }}>
      <Typography variant="H2Regular">{strings.lang.actiosNames.undo}</Typography>
    </Button>
  );
  const message = <Typography variant="H2Regular">{title}</Typography>;
  return (
    <Snackbar
      open={showNotifications}
      autoHideDuration={100000000000000000000}
      onClose={handleClose}
      message={message}
      anchorOrigin={anchorOrigin}
      action={action}
      className={className}
    />
  );
};
