import { NullableOption } from '@microsoft/microsoft-graph-types';
import _ from 'lodash';

interface HasName {
  name: string;
}

export const toTitleCase = (value: string | object) => {
  if (typeof value === 'string') return _.startCase(value);
  return Object.keys(value).reduce((res, key) => ({ ...res, [_.startCase(key)]: value[key] }), {});
};

export const getSortByName = (sortDir: boolean) => {
  const dir = sortDir ? 1 : -1;
  const collator = new Intl.Collator(undefined, { sensitivity: 'accent' });
  return (a: HasName, b: HasName) => dir * collator.compare(a.name, b.name);
};

export const oDirname = (path: NullableOption<string> | undefined): string => {
  return path?.slice(0, path.lastIndexOf('/')) || '';
};

export const logError = (error: any, msg?: string) => {
  msg = msg || '';
  if (msg.length > 0) {
    if (!msg.endsWith('.')) msg += '.';
    msg += ' ';
  }

  console.warn(`${msg}More Details: ${error?.message || 'none'}`, error);
};

export function findMaxArrayLength(arr: any[]): number {
  let maxLength = arr.length * 30;

  function checkElement(element: any) {
    if (element?.childs?.length) {
      maxLength = Math.max(maxLength, +element.childs.length * 30 + 55);
      element.childs.forEach(checkElement);
    }
  }

  arr.forEach(checkElement);
  return maxLength;
}

export function findParentWithClass(
  event: React.SyntheticEvent,
  className: string,
  maxDepth: number
): HTMLElement | null {
  let depth = 0;
  let target = event.target as HTMLElement;

  while (target && depth < maxDepth) {
    if (target.classList.contains(className)) {
      return target;
    }
    target = target.parentElement as HTMLElement;
    depth++;
  }

  return null;
}
