import { AddMedium, ViewItem } from '@storybook';
import { strings } from '@vendor/languages';
import { ItemData } from '../itemTypes';
import { BaseMenuActionClass } from './BaseMenuAction';

export class NewAction extends BaseMenuActionClass {
  readonly title = strings.lang.actionToolTip.new;
  readonly icon = AddMedium;

  isHandled(_nodes: ViewItem<ItemData>[]): boolean {
    return true;
  }
}
