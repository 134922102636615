import React, { ReactNode } from 'react';
import { Box } from '@mui/material';
import { Stack } from '@mui/system';
import { styled } from '@mui/material/styles';
import { Button } from '../Button';
import { Typography } from '../Typography';
import { LocationLabelTooltip, TooltipContent } from '../Tooltip';
import { newItemDecorator } from '../NewItemDecorator';

const ManageItem = styled(Stack)`
  ${({ theme }) => `
  width: 100%;
  flex:1;
  cursor: pointer;
  flex-direction: row;
  align-items: center;
  user-select: none; /* Prevent text selection */
  padding: ${theme.spacing(0, 1, 0, 1)};
  height: 20px;
  color: ${theme.palette.texts.primary};
  & .withIcon ${newItemDecorator(theme)}
  & > div {
    z-index: 2;
  }
  & svg,& svg path {
    fill: ${theme.palette.texts.primary};
  }
  &.myItem {
    color: ${theme.palette.texts.accentSecondary};
    & svg, & svg path {
      fill: ${theme.palette.texts.accentSecondary};
    }    
  }
  &.hint {
    cursor: default;
    color: ${theme.palette.texts.placeHolder};
  }
  & .MuiButton-root {
    z-index: 2;
    display: none;
    cursor: pointer;
  } 
  &:not(.hint):hover {
    color: ${theme.palette.texts.accentSecondary};
    & button {
      display: block;
    } 
    & svg,& svg path {
      fill: ${theme.palette.texts.accentSecondary};
    }
    & .withIcon::after {
      border-color: ${theme.palette.backgrounds.secondary};
    }
    &::before {
      border-radius: 8px;
      z-index: 1;
      content: " ";
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      background-color: ${theme.palette.backgrounds.secondary};
  }`}
`;

export interface OperatedList {
  id: string;
  name: string;
  isUserManaged?: boolean;
  isNew?: boolean;
  isHint?: boolean;
  description?: string | null; // like MS graph properties that can be null
  timeStamp?: number;
  checked?: boolean;
}

const manageItemRender: React.FC<{
  item: OperatedList;
  action: (item: OperatedList) => void;
  icon: ReactNode;
  toDelete?: boolean;
  tooltipClassName?: string;
}> = ({ item, action, icon, toDelete, tooltipClassName }) => {
  const tooltip = (
    <TooltipContent
      name={item.name}
      description={item.name !== item.description && item.description ? item.description : ''}
    />
  );
  if (item.isHint)
    return (
      <ManageItem direction="row" className="hint">
        <Typography noWrap variant="H1Regular">
          {item.name}
        </Typography>
      </ManageItem>
    );
  return (
    <LocationLabelTooltip title={tooltip} className={tooltipClassName}>
      <ManageItem direction="row" spacing={1} className={toDelete ? 'myItem' : ''} onClick={() => action(item)}>
        <Stack overflow="hidden" flex={1} alignItems="center" direction="row" spacing={2}>
          <Box className={item?.isNew ? 'withIcon' : ''}>{icon}</Box>
          <Typography noWrap variant="H1Regular">
            {item.name}
          </Typography>
        </Stack>
        <Button size="small" variant={toDelete ? 'secondary' : 'primary'}>
          {toDelete ? 'Remove' : 'Add'}
        </Button>
      </ManageItem>
    </LocationLabelTooltip>
  );
};

export default manageItemRender;
