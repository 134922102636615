import React from 'react';
import { Stack, StackProps } from '@mui/material';

export const Root = ({ children, sx, ...rest }: StackProps) => {
  return (
    <Stack
      alignContent="center"
      sx={{ padding: 1, backgroundColor: 'tooltips.secondary', borderRadius: '8px', boxShadow: 10, ...sx }}
      spacing={2}
      {...rest}
    >
      {children}
    </Stack>
  );
};
