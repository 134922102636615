import React, { useCallback } from 'react';
import { NewFolderSmall, NewItemDialog, ViewItem, NewFolderMedium } from '@storybook';
import { GraphClient } from '@services';
import { strings } from '@vendor/languages';
import { useNewItemDialog } from './NewOfficeDocument';
import { ActionItemType, ActionRendererHookType, BaseAddActionClass, TrackedEventType } from './BaseAction';
import { ItemContainer, ItemData } from '../itemTypes';
import { publishItemListChanged } from './UploadAction';

const useNewFolderAction: ActionRendererHookType = ({ nodes, useSafeCallback }) => {
  const location = nodes[0].data as ItemContainer;
  const createFolder = useCallback(
    async (gcl: GraphClient, folderName: string) => {
      const folder = await location.getFolder(gcl);
      const newFolder = await folder.createNewFolder(gcl, encodeURIComponent(folderName));
      publishItemListChanged({ added: { [newFolder.apiIdKey]: newFolder }, location });
    },
    [location]
  );
  const { setNewItemDialogVisible, ...props } = useNewItemDialog({
    location,
    texts: strings.lang.newItemDialogTexts.folder,
    actionCallback: createFolder,
    useSafeCallback,
  });

  return {
    title: strings.lang.actionToolTip.newFolder,
    icon: [NewFolderSmall, NewFolderMedium],
    onClick: () => setNewItemDialogVisible(true),
    SideUI: <NewItemDialog {...props} title={strings.lang.itemDialogTexts.folderTitle} maxWidth="xs"></NewItemDialog>,
  };
};

export class NewFolderAction extends BaseAddActionClass {
  readonly useRenderer = useNewFolderAction;
  transformEvent({ data, eventName }: TrackedEventType) {
    return {
      eventName,
      data: {
        ...data,
        itemType: 'Folder' as ActionItemType,
      },
    };
  }

  isHandled(nodes: ViewItem<ItemData>[]): boolean {
    const data = nodes[0]?.data;
    return nodes.length === 1 && data.hasFolder && !data.isSearchItem;
  }
}
