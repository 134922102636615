import { Theme, Components } from '@mui/material/styles';

export default (theme: Theme) => {
  const styleOverrides: Components['MuiListItemIcon'] = {
    defaultProps: {},
    styleOverrides: {
      root: {
        minWidth: 'auto !important',
        paddingRight: theme.spacing(2),
      },
    },
  };
  return styleOverrides;
};
