import React from 'react';
import {
  Dialog as MuiDialog,
  DialogActions,
  DialogContent,
  DialogProps as MuiDialogProps,
  DialogTitle,
  Typography,
} from '@mui/material';
import { Stack } from '@mui/system';
import { IconButton } from 'material-ui-core';
import { XSmall } from '../../assets/icons';

export type DialogProps = MuiDialogProps & {
  hasCloseAction: boolean;
  actions?: React.ReactNode;
  title?: string;
  titleClassName?: string;
};

const Dialog = ({ children, actions, title, className, titleClassName, hasCloseAction, ...rest }: DialogProps) => {
  return (
    <MuiDialog className={className} {...rest}>
      <DialogTitle direction="row" alignItems="center" component={Stack} className={titleClassName}>
        <Typography flex={1} variant="H1Regular" mt={1}>
          {title}
        </Typography>
        {hasCloseAction && (
          <IconButton disableRipple disableFocusRipple onClick={e => rest.onClose?.(e, 'backdropClick')}>
            <XSmall fontSize={16} />
          </IconButton>
        )}
      </DialogTitle>
      <DialogContent>{children}</DialogContent>
      {actions && <DialogActions>{actions}</DialogActions>}
    </MuiDialog>
  );
};

export default Dialog;
