import { InteractionType, PublicClientApplication } from '@azure/msal-browser';
import { useMsal } from '@azure/msal-react';
import { Client } from '@microsoft/microsoft-graph-client';
import { AuthCodeMSALBrowserAuthenticationProvider } from '@microsoft/microsoft-graph-client/authProviders/authCodeMsalBrowser';
import { useMemo } from 'react';
import { Drive, BaseItem as GBaseItem2 } from '@microsoft/microsoft-graph-types';
import { applicationScopes } from '../config';
import { CachedOdataRequest } from './CachedOdataRequest';

export class GraphClient {
  readonly client: Client;

  constructor(readonly msal: PublicClientApplication) {
    const authProvider = new AuthCodeMSALBrowserAuthenticationProvider(msal, {
      account: msal.getActiveAccount() || msal.getAllAccounts()[0],
      scopes: applicationScopes.scopes,
      interactionType: InteractionType.Silent,
    });

    this.client = Client.initWithMiddleware({ authProvider });
  }

  api(path: string) {
    return new CachedOdataRequest(this.client.api(path), 'https://graph.microsoft.com/v1.0', path);
  }

  directApi(url: string) {
    return this.client.api(url);
  }
}

export const useGraphClient = () => {
  const { instance } = useMsal();
  return useMemo(() => new GraphClient(instance as PublicClientApplication), [instance]);
};

export interface GValue<T> {
  value: T[];
}

export type GBaseItem = GBaseItem2;

export const GraphWebUrlKeys: (keyof GBaseItem)[] = ['webUrl'];
export const GraphParentRefAndWebUrlKeys: (keyof Drive)[] = ['id', 'webUrl', 'parentReference'];
export const GraphWebUrlNameKeys = ['name', ...GraphWebUrlKeys];
export const GraphSharePointIds: keyof Drive = 'sharePointIds';
export const GraphWebUrlIdsKeys: (keyof Drive)[] = ['id', 'sharePointIds', ...GraphWebUrlKeys];
export const GraphSiteKeys = ['description', 'displayName', 'id', ...GraphWebUrlKeys];
export const GraphIdDisplayNameKeys = ['id', 'displayName', 'webUrl'];
export const GraphTeamKeys = [...GraphIdDisplayNameKeys, 'description'];
export const GraphChannelKeys = [...GraphIdDisplayNameKeys, 'membershipType'];
export const GraphRemoteItemKeys = ['remoteItem'];
export const GraphPerson = ['displayName', 'id', 'jobTitle', 'scoredEmailAddresses'];
