import React from 'react';
import { Box, Stack, TeamsMedium, Typography, useTheme } from '@harmon.ie-ce/storybook';

interface ShareDialogTitleProps {
  titleStart: string;
  titleEnd: string;
  Icon?: React.ReactNode;
}

export const ShareDialogTitle = ({ titleStart, titleEnd, Icon }: ShareDialogTitleProps) => {
  const theme = useTheme();
  return (
    <Stack direction={'row'} spacing={2} alignItems={'center'} width={'100%'}>
      <Box>{Icon && Icon}</Box>
      <Stack direction={'row'} spacing={1}>
        <Typography color={theme.palette.texts.primary} variant="H1SemiBold">
          {titleStart}
        </Typography>
        <Stack direction={'row'} alignItems={'center'}>
          <TeamsMedium />
          <Typography variant="H1SemiBold" color={theme.palette.texts.teams}>
            {titleEnd}
          </Typography>
        </Stack>
      </Stack>
    </Stack>
  );
};
