import { Theme, Components } from '@mui/material/styles';
export default (theme: Theme) => {
  const styleOverrides: Components['MuiToolbar'] = {
    styleOverrides: {
      root: {
        '&.all-locations-toolbar': {
          paddingBottom: '8px',
          paddingTop: theme.spacing(1),
          minHeight: theme.spacing(4),
        },
        minHeight: theme.spacing(4),
        paddingBottom: theme.spacing(3),
        paddingTop: theme.spacing(1),
        '.MuiStack-root': {
          '.more-actions-wrapper': {
            marginLeft: `${theme.spacing(1)}`,
          },
        },
      },
    },
  };
  return styleOverrides;
};
