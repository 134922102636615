import { UploadRequestHandler, UploadStatus } from '@storybook';
import { createContext } from 'react';
import { useSafeCallbackType } from '~/hooks/UseSafeCallback';
export interface ProgressData {
  dateKey?: string;
  showDialog: boolean;
  status: UploadStatus;
  activeFileIndex: number;
  streamFiles?: string[];
  fileProgress: boolean[];
  currentProgress: number;
  errorMessage?: string; // Add this line
}

export interface UploadAnsNotifications {
  setProgressData: React.Dispatch<React.SetStateAction<ProgressData>> | null;
  progressRequestHandler?: UploadRequestHandler;
  startProgressIndication?: (fileCount: number, streamFiles?: string[]) => void;
  oneItemStartIndication?: (activeFileIndex: number) => void;
  oneItemCompletedIndication?: () => void;
  successfulProcessIndication?: () => void;
  errorfulProcessIndication?: (message: string) => void;
  resetOneItemProgress?: (activeFileIndex: number) => void;
  handleFileUpload?: Parameters<useSafeCallbackType>;
}

export const UploadContext = createContext<UploadAnsNotifications>({
  setProgressData: null,
  progressRequestHandler: undefined,
});

export default UploadContext;
