import React, { useCallback, useContext, useRef, useMemo } from 'react';
import {
  MouseEventsContext,
  MouseEventsInterface,
  UploadSmall,
  ViewItem,
  UploadMedium,
  getFileMediaType,
} from '@storybook';
import { strings } from '@vendor/languages';
import UploadContext from '~/context/UploadContext';
import { ActionRendererHookType, BaseAddActionClass, TrackedEventType } from './BaseAction';
import { Folder, ItemContainer, ItemData } from '../itemTypes';
import { convertFileListToFileArray } from './ActionsUtils';
export interface ItemListChangesType {
  location: ItemContainer;
  added?: {
    [key: string]: ItemData;
  };
  updated?: {
    [key: string]: ItemData;
  };
  deleted?: {
    [key: string]: ItemData;
  };
}

export const ItemsListChangedEvent = 'ItemListChanges';

export const publishItemListChanged = (changes: ItemListChangesType) => {
  PubSub.publish(ItemsListChangedEvent, changes);
};

const useUpdloadAction: ActionRendererHookType = ({ actionRef, nodes, useSafeCallback }) => {
  actionRef.current = [];
  const node = nodes[0];

  const { actionDone, waitForAction } = useContext<MouseEventsInterface>(MouseEventsContext);
  const fileUpload = useRef<HTMLInputElement>(null);
  const uploadKey = useRef<number>(Date.now());
  const { handleFileUpload } = useContext(UploadContext);
  const uploadFile = useSafeCallback(...handleFileUpload!);

  const handleEvent = useCallback(
    async (e: React.ChangeEvent<HTMLInputElement>) => {
      const filesList = e.target?.files;
      if (filesList !== null) {
        await uploadFile(convertFileListToFileArray(filesList), node.data as ItemContainer, actionRef);
        uploadKey.current = Date.now();
      }
      actionDone?.();
    },
    [actionDone, actionRef, node.data, uploadFile]
  );
  const handleUpload = useCallback(() => {
    waitForAction?.();
    fileUpload.current?.click();
  }, [waitForAction]);

  const SideUI = useMemo(
    () => (
      <>
        <input
          key={uploadKey.current}
          onClick={e => e.stopPropagation()}
          accept="/*"
          type="file"
          ref={fileUpload}
          aria-label="input-upload"
          hidden={true}
          onChange={handleEvent}
          multiple
        />
      </>
    ),
    [handleEvent]
  );

  return {
    title: strings.lang.actionToolTip.upload,
    icon: [UploadSmall, UploadMedium],
    onClick: handleUpload,
    SideUI,
  };
};

export class UploadAction extends BaseAddActionClass {
  readonly trackedName = 'UploadTransaction';
  readonly useRenderer = useUpdloadAction;
  constructor(private readonly uploadMethod: string = 'Side Bar Upload') {
    super();
  }
  isHandled(nodes: ViewItem<ItemData>[]): boolean {
    const data = nodes[0]?.data;
    return nodes.length === 1 && data.hasFolder && (data.type !== 'folder' || !(data as Folder).isOneNote);
  }

  transformEvent({ data, eventName }: TrackedEventType, ctx: any) {
    const media = getFileMediaType((ctx[0] as string) || '');
    return {
      eventName,
      data: {
        ...data,
        uploadType: media == 'Mail' ? 'Email' : 'Document',
        numberOfFiles: ctx.length,
        itemType: 'Document',
        uploadMethod: this.uploadMethod,
        itemsCount: ctx.length,
      },
    } as TrackedEventType;
  }
}
