import { Theme, Components } from '@mui/material/styles';
import typography from '../typography';

export default (theme: Theme) => {
  const styleOverrides: Components['MuiAvatar'] = {
    styleOverrides: {
      root: {
        ...typography.H3Medium,
        color: theme.palette.status.defaultPrimary,
      },
    },
  };
  return styleOverrides;
};
