import { ViewItem, ShareLinkMedium } from '@storybook';
import { useGraphClient } from '@services';
import { strings } from '@vendor/languages';
import { OfficeMode, officeMode } from '~/misc';
import { ItemData } from '../itemTypes';
import { ActionRendererHookType, BaseReadActionClass } from './BaseAction';
import { emailWithLink } from './ActionsUtils';
import { InsightItem } from '../graphTypes';

const useEmailWithLink: ActionRendererHookType = ({ nodes, useSafeCallback }) => {
  const gcl = useGraphClient();
  const data = nodes[0].data;
  const onClick = useSafeCallback(
    async () => {
      await emailWithLink(gcl, data as ItemData);
    },
    [gcl, data],
    false
  );
  return {
    title:
      officeMode == OfficeMode.Read ? strings.lang.actionToolTip.emailWithLink : strings.lang.actionToolTip.addLink,
    icon: ShareLinkMedium,
    onClick,
  };
};

export class EmailWithLink extends BaseReadActionClass {
  readonly trackedName = 'EmailWithLink';
  readonly useRenderer = useEmailWithLink;

  isHandled(nodes: ViewItem<ItemData>[]): boolean {
    return (
      nodes.length === 1 &&
      nodes[0].data.canOpenInBrowser &&
      nodes[0].id !== 'oneDrive:OneDrive:' &&
      !Boolean((nodes[0].data as InsightItem).isEmailAttachment)
    );
  }
}
