import { LocationLabelTooltip, RenderTooltipProps, Stack, TooltipContent } from '@storybook';
import React from 'react';
import { AllowedItemType, ItemData } from '~/utilities';
import { TreeNodeRenderer } from './GetNodeRenderer';

interface TransientLocationLabelTooltipProps extends RenderTooltipProps<ItemData> {
  style?: any;
  hideTooltip?: boolean;
}
export const TransientLocationLabelTooltip = ({
  children,
  data,
  style,
  hideTooltip,
}: TransientLocationLabelTooltipProps) => {
  const pathOrDescription = data.pathOrDescription?.replace(/^SP/, 'SharePoint');
  const tooltip = hideTooltip ? undefined : <TooltipContent name={data.name} description={pathOrDescription || ''} />;
  return (
    <LocationLabelTooltip title={tooltip}>
      <Stack sx={style}>{children}</Stack>
    </LocationLabelTooltip>
  );
};

export const transientNodeRenderer = (_type: AllowedItemType, nextRenderer: TreeNodeRenderer): TreeNodeRenderer => {
  return props => {
    const data = props.node.data;
    const RenderTooltip = data.isTransientLocation && data.pathOrDescription && TransientLocationLabelTooltip;
    return nextRenderer({ ...props, RenderTooltip });
  };
};
