import { GraphClient } from '@services';
import { logError } from '@vendor/utils/misc';
import { UploadRequestHandler } from '@storybook';
import MsgReader from '@kenjiuno/msgreader';
import { SelectedItemType, convertToEwsId, officeMode } from '~/misc';
import { AddCategory, UseUniqueNames } from '~/modules/Settings/SettingContext';
import { ItemContainer } from '../itemTypes';
import { RefreshAction } from './RefreshAction';
import { publishItemListChanged } from './UploadAction';
import { formatDateTime } from './ActionsUtils';

export interface UploadItemProps {
  name: string;
  data: Blob;
  itemId?: string;
  senderName?: string;
  date?: string;
}
export interface EmailProps {
  subject?: string;
  from?: string;
  isFromExist?: boolean;
}
export const readableStreamToBlob = async readableStream => {
  const chunks: Uint8Array[] = [];
  const reader = readableStream.getReader();
  while (true) {
    const { done, value } = await reader.read();
    if (done) {
      break;
    }
    chunks.push(value as Uint8Array);
  }
  return new Blob(chunks);
};

export const extractSubjectAndFrom = (input: string): EmailProps => {
  // Split the string by newline to get each line
  const lines = input.split('\n');
  const isFromExist = lines[0].split('\t')[0] === 'From';
  // Check if there is more than one line (to avoid header line)
  if (lines.length > 1) {
    // Split the second line (first data line) by tab
    const data = lines[1].split('\t');

    // Extract the 'From' and 'Subject' fields
    const from = data[0];
    const subject = data[1];

    return { from, subject, isFromExist: isFromExist };
  }

  // Return undefined if the fields cannot be extracted
  return { from: undefined, subject: undefined, isFromExist: false };
};

export const uploadDocumentToSharePoint = async (
  gcl: GraphClient,
  location: ItemContainer,
  fileName: string,
  fileIndex: number,
  data: Blob,
  override?: boolean,
  progressRequestHandler?: UploadRequestHandler
) => {
  try {
    const folder = await location.getFolder(gcl);
    const newItem = await folder.upload({
      data,
      fileName,
      fileIndex,
      gcl,
      override,
      progressRequestHandler,
    });
    RefreshAction.markRefreshStamp(location, Date.now());
    publishItemListChanged({
      location,
      added: {
        [newItem.apiIdKey]: newItem,
      },
    });
  } catch (e) {
    throw e;
  }
};

export const getOfficeItem = (selectedItem?: SelectedItemType) => {
  const item = Office.context.mailbox.item;
  return selectedItem || item;
};

const getEmailId = async (gcl: GraphClient, itemMessageId: string): Promise<string> => {
  const emailProps = await gcl
    .directApi(`/me/messages`)
    .filter(`internetMessageId eq '${itemMessageId}'`)
    .select(['id'])
    .get();
  // Email from Gmail/and other with diff id's.
  if (emailProps.value.length === 0) {
    let filter = '';
    const imid: string[] = itemMessageId.split('+');
    for (let index = 0; index < imid.length; index++) {
      if (index === 0) {
        filter += `startsWith(internetMessageId, '${imid[index]}')`;
      } else {
        filter += ` and contains(internetMessageId, '${imid[index]}')`;
      }
    }
    const res = await gcl.directApi(`/me/messages`).filter(filter).select(['id']).get();
    if (res.value.length === 0) throw new Error('Email not supported.');
    return res.value[0].id;
  }
  return emailProps.value[0].id;
};

const readFileAsArrayBuffer = (file: File): Promise<ArrayBuffer> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = (loadEvent: ProgressEvent<FileReader>) => {
      if (loadEvent.target?.result instanceof ArrayBuffer) {
        resolve(loadEvent.target.result);
      } else {
        reject(new Error('Array buffer error'));
      }
    };
    reader.onerror = () => reject(new Error('File reader error'));
    reader.readAsArrayBuffer(file);
  });
};

export const getEmailData = async (
  selectedItem?: SelectedItemType,
  gcl?: GraphClient
): Promise<UploadItemProps | undefined> => {
  try {
    const emailItem = getOfficeItem(selectedItem);
    const itemID = convertToEwsId(emailItem?.itemId);
    const emailData = gcl && (await gcl.api(`/me/messages/${itemID}/$value`).getStream());
    const emailProps = gcl && (await gcl.api(`/me/messages/${itemID}`).select(['sender', 'sentDateTime']).get());
    const fileData = await readableStreamToBlob(emailData);
    let fileName = `${emailItem?.subject || 'Untitled'}`;
    if (UseUniqueNames.value === 'true' && emailProps.sender.emailAddress.name && emailProps.sentDateTime) {
      fileName = `${fileName}_${formatDateTime(emailProps.sentDateTime)}_${emailProps.sender.emailAddress.name}`;
    }
    return {
      data: fileData,
      name: `${fileName}.eml`,
      itemId: itemID,
      senderName: emailProps.sender.emailAddress.name,
      date: emailProps.sentDateTime,
    };
  } catch (e: any) {
    if (e.statusCode === 404) {
      throw new Error('Email not supported.');
    } else throw e;
  }
};

const readMsgEmailAndGetBlob = async (file: File, gcl: GraphClient): Promise<UploadItemProps | undefined> => {
  const arrayBuffer = await readFileAsArrayBuffer(file);
  const msg = new MsgReader(arrayBuffer);
  const msgInfo = msg.getFileData();
  if (msgInfo.messageId) {
    const itemId = await getEmailId(gcl, msgInfo.messageId);
    return await getEmailData(
      {
        itemId: itemId,
        subject: msgInfo.subject || '',
        itemMode: officeMode.toString(),
        itemType: 'Message',
      },
      gcl
    );
  }
  return undefined;
};

export const readFile = (item: File, _gcl: GraphClient): Promise<UploadItemProps> | UploadItemProps => {
  const data = new Blob([item], { type: item.type });
  return { data: data, name: item.name };
};

export const getItemName = (item: File | Office.SelectedItemDetails, isDragAndDropEmail?: boolean): string => {
  if (isDragAndDropEmail && item instanceof File) return item.name.replace('.msg', '.eml');
  else if (item instanceof File) return item.name;
  else return `${item.subject || 'Untitled'}.eml`;
};

export const getItemBlob = (
  item: any,
  gcl: GraphClient,
  isDragAndDropEmail?: boolean
): Promise<UploadItemProps | undefined> | UploadItemProps => {
  if (Boolean(isDragAndDropEmail) && item instanceof File) return readMsgEmailAndGetBlob(item, gcl);
  if (item instanceof File) return readFile(item, gcl);
  else return getEmailData(item, gcl);
};

export const addCategoryToMessage = async (gcl: GraphClient, messageId: string, folder: ItemContainer) => {
  const itemPath =
    folder.OfficeAppLocated === 'SP'
      ? `${folder.pathOrDescription?.replace('SP/', 'SharePoint/')}/`
      : folder.pathOrDescription;
  const normalizedItemPath = itemPath?.endsWith('/') ? itemPath?.slice(0, -1) : itemPath;
  if (AddCategory.value === 'true') {
    try {
      const res = await gcl.api(`/me/messages/${messageId}`).select('categories').get();
      if (res.categories.includes(normalizedItemPath)) return;
      await gcl.api(`/me/messages/${messageId}`).patch({ categories: [normalizedItemPath, ...res.categories] });
    } catch (e) {
      logError(e);
    }
  }
};
