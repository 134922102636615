import React, { MouseEventHandler, useCallback, useContext } from 'react';
import { ContextMenuContext, MouseEventsContext, MouseEventsInterface } from '@storybook';
import {
  AllowedItemType,
  generateActionMenu,
  getAllContextActions,
  getQuickActionsList,
  isSameLocationForParent,
  ItemData,
  useCombinedHandlers,
} from '~/utilities';
import { useOpenContaingLocation } from '~/utilities/actions/OpenContainingLocation';
import { ItemDataRenderer, ItemDataRenderProps, ItemDataRenderWithNextProps } from './GetNodeRenderer';
import { TransientLocationLabelTooltip } from './TransientNodeRenderer';

const SelectionHandlerComponent = (props: ItemDataRenderWithNextProps) => {
  const { node, handlers, onSelect, nextRenderer } = props;
  const nData = node.data as ItemData;

  const onParentClick = useOpenContaingLocation(node, 'Breadcrumbs', handlers);
  const onSecondLineClick = useCallback<MouseEventHandler<HTMLDivElement>>(
    event => {
      event.preventDefault();
      event['no-open'] = true;
      onParentClick(event);
    },
    [onParentClick]
  );
  const secondLine = (nData.secondLineContent && nData.secondLineContent) || '';
  const { handleContextMenu } = useContext(ContextMenuContext);
  const { waitForAction, actionDone } = useContext<MouseEventsInterface>(MouseEventsContext);
  const handleSelection = useCallback<MouseEventHandler<HTMLDivElement>>(
    event => {
      const isContextMenu = event.type === 'contextmenu';
      const isContextAction = event['contextmenu'];
      const isCtrlKey = event.metaKey || event.ctrlKey;
      if ((!nData.itemCanSelected || nData.isRootSection) && !isContextMenu && !isContextAction) {
        handlers.unselectAll();
        return;
      }
      if (isContextMenu) {
        event.preventDefault();
      } else {
        event.stopPropagation();
      }

      if (isContextMenu) {
        let selectedNodes = handlers.getSelectedNodes(); // Compute just once!
        if (!handlers.isSelected(node)) selectedNodes = [node];
        const { actionsStructure } = getQuickActionsList(selectedNodes, getAllContextActions(), false);
        const actions = generateActionMenu(actionsStructure, selectedNodes, 'Context Menu', handlers);
        if (actions.length) {
          waitForAction?.();
          handleContextMenu?.(event, actions, actionDone);
        }
        if (!handlers.isSelected(node)) {
          handlers.unselectAll();
          if (actions.length || !nData.isRootSection) handlers.setSelected(node, true);
        }

        return;
      } else if (isCtrlKey) {
        handlers.setSelected(node, !handlers.isSelected(node));
        return;
      } else {
        handlers.unselectAll();
        handlers.setSelected(node, true);
      }
    },
    [actionDone, handleContextMenu, handlers, nData.isRootSection, nData.itemCanSelected, node, waitForAction]
  );

  const combinedHandler = useCombinedHandlers(handleSelection, onSelect);
  return nextRenderer({
    ...(!nData.isDocument && nData.isAdvancedSearchItem ? { RenderTooltip: TransientLocationLabelTooltip } : {}),
    ...props,
    secondLine:
      nData.secondLineContent && nData.isAdvancedSearchItem
        ? {
            text: secondLine,
            handle: !isSameLocationForParent(nData.parent, nData.searchedLocationId) ? onSecondLineClick : null,
          }
        : undefined,
    onSelect: combinedHandler,
  });
};

export const itemSelectionHandler = (_type: AllowedItemType, nextRenderer: ItemDataRenderer): ItemDataRenderer => {
  const res = (props: ItemDataRenderProps) => <SelectionHandlerComponent {...{ ...props, nextRenderer }} />;
  return res;
};
