import { Theme, Components } from '@mui/material/styles';

export default (theme: Theme) => {
  const styleOverrides: Components['MuiDialogActions'] = {
    styleOverrides: {
      root: {
        '&.hotjar-dialog-actions': {
          padding: theme.spacing(0, 0),
        },
        '&.error-dialog-actions': {
          paddingBottom: theme.spacing(3),
          paddingLeft: theme.spacing(3),
          paddingRight: theme.spacing(3),
          paddingTop: theme.spacing(0),
        },
        padding: theme.spacing(4, 4, 3, 4),
      },
    },
  };
  return styleOverrides;
};
