import PubSub from 'pubsub-js';

export interface StateManager<T> {
  get value(): T;
  get sKey(): string;
}

export class LocalStateManager<T> implements StateManager<T> {
  private _value: T;
  private initialGetter: () => T;
  private needsReset = true;

  get sKey() {
    return `stateOf.${this.key}`;
  }
  get value() {
    if (this.needsReset) this.reset();
    return this._value;
  }
  set value(val) {
    this._value = val;
    this.save();
  }

  public save() {
    localStorage.setItem(this.sKey, this.stringify(this.value));
    PubSub.publish(this.sKey, this.value);
  }

  protected stringify(val: T) {
    return JSON.stringify(val);
  }

  protected parse(val: string): T {
    return JSON.parse(val);
  }

  reset(resetSetting = false) {
    if (resetSetting) localStorage.removeItem(this.sKey);
    this.needsReset = false;
    const lVal = localStorage.getItem(this.sKey);
    this.value = (lVal && this.parse(lVal)) || this.initialGetter();
    if (resetSetting) this.save();
  }

  constructor(
    readonly key: string,
    initVal: () => T
  ) {
    this.initialGetter = initVal;
    PubSub.subscribe('onSigin', () => this.reset());
    PubSub.subscribe('resetSetting', () => this.reset(true));
  }
}
