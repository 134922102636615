import { Stack, styled } from '@mui/material';

const ItemNodeWrap = styled(Stack)`
  width: 100%;
  height: 100%;
  &.isSelected.itemColorable {
    border-radius: 8px;
    background-color: ${({ theme }) => theme.palette.buttons.accentPrimary}!important;
    color: ${({ theme }) => theme.palette.texts.onAccentPrimary} !important;
  }
`;

export default ItemNodeWrap;
