import React, { FC, useCallback } from 'react';
import { Box, Stack, styled } from '@mui/system';
import { useTheme } from '@mui/material/styles';
import { AttachmentSmall, SVGComponent } from '../../assets/icons';
import { Typography } from '../Typography';
import { Button } from '../Button';
import { Separator } from '../Separator';
import { CustomIcon } from '../Icons';

export interface AdaptivecardsProps {
  Icon: SVGComponent;
  mailAttribule: string[];
  itemTitle: string;
  text: string;
  emailHasAttachments?: boolean;
  shareUrl?: string;

  viewMailText: string;
}

const AdaptivecardsWrapper = styled(Stack)`
  border-radius: 12px;
  width: 100%;
  padding: ${({ theme }) => theme.spacing(1)};
  background: ${({ theme }) => theme.palette.backgrounds.secondary};
  &:focus {
    box-shadow: ${({ theme }) => theme.palette.shadow.focus};
    outline: 0;
  }
`;

const AdaptivecardsBody = styled(Stack)`
  border-radius: 8px;
  padding: ${({ theme }) => theme.spacing(2)};
  background: ${({ theme }) => theme.palette.backgrounds.tertiary};
`;

const Adaptivecards: FC<AdaptivecardsProps> = ({
  Icon,
  itemTitle,
  mailAttribule,
  text,
  emailHasAttachments,
  shareUrl,
  viewMailText,
}) => {
  const theme = useTheme();

  const openLink = useCallback(() => {
    if (shareUrl) window.open(shareUrl, '_blank', 'noopener noreferrer');
  }, [shareUrl]);
  const EmailIcon = CustomIcon(Icon);
  const AttachmentIcon = CustomIcon(AttachmentSmall);
  return (
    <AdaptivecardsWrapper tabIndex={0} id="adaptivecard">
      <AdaptivecardsBody spacing={2} minHeight={166}>
        <Stack>
          <Stack spacing={2} alignItems="center" direction="row">
            <EmailIcon />
            <Box flex={1} sx={{ overflow: 'hidden' }}>
              <Typography component={'div'} maxWidth="100%" textOverflow="ellipsis" noWrap variant="H1SemiBold">
                {itemTitle}
              </Typography>
            </Box>
            {emailHasAttachments ? <AttachmentIcon /> : null}
            <Button onClick={openLink} variant="text">
              {viewMailText}
            </Button>
          </Stack>
          <Stack spacing={2} direction="row">
            <Stack sx={{ overflow: 'hidden' }} spacing={0}>
              {mailAttribule.map((item, index) => (
                <Typography
                  component={'div'}
                  maxWidth="100%"
                  textOverflow="ellipsis"
                  noWrap
                  key={index}
                  color={theme.palette.texts.primary}
                  variant="H3Medium"
                >
                  {item}
                </Typography>
              ))}
            </Stack>
          </Stack>
          <Stack mt={2} mb={2}>
            <Separator />
          </Stack>
          <Box>
            <Typography
              sx={{
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                display: '-webkit-box',
                WebkitLineClamp: '3',
                WebkitBoxOrient: 'vertical',
              }}
              color={theme.palette.texts.secondary}
              variant="H3Medium"
            >
              {text}
            </Typography>
          </Box>
        </Stack>
      </AdaptivecardsBody>
    </AdaptivecardsWrapper>
  );
};

export default Adaptivecards;
