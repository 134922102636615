import { UndoAction, NotificationContext } from '@storybook';
import { DependencyList, useCallback, useContext } from 'react';
import { logError } from '@vendor/utils/misc';
import { SafeCallbackStrings, strings } from '@vendor/languages';
import { ErrorTokens } from '~/utilities/networkAndCache/ErrorTokens';

// eslint-disable-next-line @typescript-eslint/ban-types

export type VoidPromiseFunc = (...args) => Promise<void | boolean | undefined | UndoAction>;
const infinity = 1000000;
export function useSafeCallback<T extends VoidPromiseFunc>(
  wrapped: T,
  deps: DependencyList,
  hasAction: boolean = false,
  messages?: Partial<SafeCallbackStrings>,
  isEnabled?: () => boolean,
  withUploadInicator?: (errorMessage: string) => void
): T {
  const { showError, showNotification } = useContext(NotificationContext);
  const {
    errorTitle: title,
    errorMessage: message,
    onStartTitle,
    onSuccessTitle,
  } = { ...strings.lang.actionDefaults, ...messages };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  return useCallback(
    (async (...args: any[]) => {
      if (isEnabled && !isEnabled()) return;
      const showIndications = withUploadInicator !== undefined;
      try {
        const startTime = Date.now();
        if (onStartTitle && !showIndications) showNotification(onStartTitle, infinity);

        const res = await wrapped(...args);
        const cancelAction = typeof res === 'object' && res !== null ? (res as UndoAction) : undefined;

        // keep start notification title at least for 2 seconds
        const endTime = Date.now();
        const timeDiff = endTime - startTime;
        const timeLeft = 2000 - timeDiff;

        if (onSuccessTitle && !showIndications) {
          setTimeout(() => {
            showNotification(onSuccessTitle, 5000, cancelAction);
            timeLeft < 0 || !onStartTitle ? 0 : timeLeft;
          }, timeLeft);
        }

        return res;
      } catch (error: any) {
        logError(error, title);
        if (showIndications) {
          withUploadInicator(error.code === ErrorTokens.NetworkError ? message : error.message);
        } else {
          showError(title, error.code !== undefined ? error.message || error.body : message, hasAction);
        }
      }
    }) as unknown as T,
    [...deps, title, message, hasAction, onSuccessTitle, showError, showNotification, isEnabled]
  );
}
export type useSafeCallbackType = typeof useSafeCallback;
