import React, { useRef, useMemo, useCallback } from 'react';
import { VirtualListRender } from '../VirtualList/VirtualList';
import SearchInput from '../SearchInput/SearchInput';
import Shortcut from '../Shortcuts/Shortcut';
import { Filter, QuickSearchBody, SearchProps } from './QuickSearchBody';
import DropdownWithInput from '../DropdownWithInput/DropdownWithInput';

const SHOW_COUNT = 5;

interface SearchComponentProps {
  render: VirtualListRender;
  searchResults: any;
  isSearching?: boolean;
  searchHistory: Array<string>;
  showMoreLink?: boolean;
  onHistoryItemAdd?: (e?: React.MouseEvent<Element, MouseEvent>, text?: string) => void;
  placeholder?: string;
  clearTooltipText?: string;
  clearFilterText?: string;
  historyTitle?: string;
  showMoreText?: string;
  onEnter: any;
  filtersUI: Filter[];
  disabled?: boolean;
  isSearchInit: boolean;
  searchState: [SearchProps, React.Dispatch<React.SetStateAction<SearchProps>>];
  resetState: () => void;
}

const SearchComponent: React.FC<SearchComponentProps> = ({
  searchResults = [],
  isSearching,
  render,
  searchHistory,
  onHistoryItemAdd,
  placeholder = 'Search Microsoft 365',
  clearTooltipText = 'Clear',
  clearFilterText = 'Click to remove',
  historyTitle = 'HISTORY',
  onEnter,
  filtersUI,
  disabled,
  isSearchInit,
  searchState,
  resetState,
}) => {
  const [searchProps, setSearchProps] = searchState;
  const inputRef = useRef<HTMLInputElement | null>(null);
  const renderItems = useMemo(() => searchResults.slice(0, SHOW_COUNT), [searchResults]);

  const activeFilter = useMemo(
    () =>
      searchProps?.filters || searchProps?.entityTypes?.length
        ? filtersUI.find(item =>
            searchProps.entityTypes?.length && searchProps.entityTypes?.length !== 3
              ? item.filterString === searchProps.filters && searchProps.entityTypes[0] == item.entityTypes?.[0]
              : item.filterString === searchProps.filters
          )
        : undefined,
    [filtersUI, searchProps]
  );

  const historyItemClick = useCallback(
    (e: React.MouseEvent<Element, MouseEvent>, searchTerm: string) => {
      setSearchProps(data => ({ ...data, searchTerm }));
      onHistoryItemAdd?.(e, searchTerm);
      inputRef.current?.focus();
    },
    [onHistoryItemAdd, setSearchProps]
  );

  const showResultOnClick = useCallback(() => {
    if (searchProps.searchTerm) {
      onEnter();
      onHistoryItemAdd?.(undefined, searchProps.searchTerm);
    }
  }, [onEnter, onHistoryItemAdd, searchProps.searchTerm]);

  return (
    <DropdownWithInput
      withBackDrop
      disabled={disabled}
      renderInput={({ handlePopoverClose, openDrop, isDropOpen, closeDrop, inputComponentRef }) => (
        <SearchInput
          searchState={searchState}
          inputRef={inputRef}
          isWrapped={isDropOpen}
          ref={inputComponentRef}
          handleSearchFocus={openDrop}
          withChip={<Shortcut label="Ctrl+K" />}
          onEnter={() => {
            showResultOnClick();
            closeDrop();
          }}
          onLeave={handlePopoverClose}
          placeholder={placeholder}
          clearTooltipText={clearTooltipText}
          clearFilterText={clearFilterText}
          withFilter={activeFilter?.Icon}
          disabled={disabled}
          resetState={resetState}
        />
      )}
      renderBody={({ closeDrop }) => (
        <QuickSearchBody
          historyItemClick={historyItemClick}
          render={render}
          activeFilter={activeFilter}
          setFilters={data => {
            setSearchProps(dt => ({ ...dt, ...data }));
            inputRef.current?.focus();
          }}
          searchHistory={searchHistory}
          historyTitle={historyTitle}
          isSearchInit={isSearchInit}
          filtersUI={filtersUI}
          renderItems={renderItems}
          isSearching={!!isSearching}
          searchTerm={searchProps.searchTerm}
          showResultOnClick={() => {
            showResultOnClick();
            closeDrop();
          }}
        />
      )}
    />
  );
};

export default SearchComponent;
