import React from 'react';
import { styled } from '@mui/material/styles';
import { CircularProgress as MuiCircularProgress, ButtonBase as MuiButtonBase } from '@mui/material';
import MuiButton, { ButtonProps as MuiButtonProps } from '@mui/material/Button';
import { useTheme } from '@harmon.ie-ce/storybook';
export interface ButtonProps extends MuiButtonProps {
  loading?: boolean;
  tooltip?: string;
  cyData?: string;
  selected?: boolean;
  opened?: boolean;
  disabled?: boolean;
}

export const ButtonBase = ({ children, ...rest }: MuiButtonProps) => (
  <MuiButtonBase {...rest}>{children}</MuiButtonBase>
);

const Loader = styled(MuiCircularProgress)`
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
`;

const Button = (
  { children, loading, startIcon, disabled, endIcon, cyData, selected, opened, ...rest }: ButtonProps,
  ref: any
) => {
  const icon = !loading ? { startIcon, endIcon } : {};
  // TOOD: think why we do this
  const isIconButton = React.isValidElement(children);
  const theme = useTheme();
  return (
    <MuiButton
      ref={ref}
      cy-data={cyData}
      {...rest}
      {...icon}
      style={{
        ...rest.style,
        ...(isIconButton ? { padding: 0, color: opened ? theme.palette.texts.accentSecondary : '' } : {}),
      }}
      disabled={loading || disabled}
      className={selected ? 'MuiButton-selected' : ''}
    >
      {loading ? <Loader size={rest.size} /> : children}
    </MuiButton>
  );
};

export default React.forwardRef<HTMLButtonElement, ButtonProps>(Button);
