/* eslint-disable @typescript-eslint/no-use-before-define */
import {
  BatchRequestContent,
  BatchResponseContent,
  Client,
  GraphRequestCallback,
} from '@microsoft/microsoft-graph-client';
import { BaseOdataRequest } from './BaseOdataRequest';
import { CachedOdataRequest } from './CachedOdataRequest';
import { OdataRequest } from './OdataRequest';

export interface IBatchRequest {
  get canAddRequests(): boolean;
  addRequest(sub: BatchedRequest, request: Request);
  api(url: string): CachedBatchRequest;
  getResponse(sub: BatchedRequest): Response;
  runAll(): Promise<void>;
}

export class GraphBatchRequest implements IBatchRequest {
  private curId = 1;
  private activeRequests = 0;
  private readonly request: BatchRequestContent;
  private response: BatchResponseContent | undefined;

  constructor(private readonly gcl: Client) {
    this.request = new BatchRequestContent();
  }

  get canAddRequests(): boolean {
    return this.activeRequests < 20;
  }

  addRequest(sub: BatchedRequest, request: Request) {
    this.activeRequests++;
    this.request.addRequest({ id: sub.id, request });
  }

  api(url: string) {
    return new CachedBatchRequest(
      new BatchedRequest(this, url, (this.curId++).toString()),
      'https://graph.microsoft.com/v1.0',
      url
    );
  }

  async runAll() {
    if (this.request.requests.size === 0) return;
    const content = await this.request.getContent();
    const res = await this.gcl.api('/$batch').post(content);
    this.response = new BatchResponseContent(res);
  }

  getResponse(sub: BatchedRequest) {
    const resp = this.response?.getResponseById(sub.id);
    if (resp?.ok) return resp;

    throw new Error(`Error in request: ${sub.request}`);
  }
}

export class BatchedRequest extends BaseOdataRequest {
  constructor(
    readonly owner: IBatchRequest,
    url: string,
    readonly id: string
  ) {
    super(url);
  }

  headers(headers: HeadersInit): OdataRequest {
    this._headers = headers;
    return this;
  }

  async get(_?: GraphRequestCallback | undefined): Promise<() => Promise<any>> {
    this.owner.addRequest(this, new Request(this.request, { method: 'GET', headers: this._headers }));
    return async () => await this.owner.getResponse(this).json();
  }

  async patch(content: any, _callback?: GraphRequestCallback | undefined): Promise<any> {
    this.owner.addRequest(
      this,
      new Request(this.request, {
        method: 'PATCH',
        headers: this._headers,
        body: content,
      })
    );
    return async () => await this.owner.getResponse(this).json();
  }
  private _headers?: HeadersInit;
  async post(body: any, _?: GraphRequestCallback | undefined): Promise<() => Promise<any>> {
    this.owner.addRequest(
      this,
      new Request(this.request, {
        method: 'POST',
        headers: this._headers,
        body,
      })
    );
    return async () => await this.owner.getResponse(this).json();
  }

  override async delete(): Promise<any> {
    this.owner.addRequest(this, new Request(this.request, { method: 'DELETE', headers: this._headers }));
    return async () => await this.owner.getResponse(this);
  }

  override async getStream(): Promise<any> {
    this.owner.addRequest(this, new Request(this.request, { method: 'GET', headers: this._headers }));
    return async () => await this.owner.getResponse(this).blob();
  }
}

export class CachedBatchRequest extends CachedOdataRequest {
  constructor(theRequest, provider, path) {
    super(theRequest, provider, path);
  }

  protected override applyResult(key: string, res: any, isCached: boolean): Promise<any> {
    return Promise.resolve(async () => {
      const aRes = isCached ? res : await res();
      return super.applyResult(key, aRes, isCached);
    });
  }
}
