import { MouseEventHandler, useCallback, useEffect, useState } from 'react';
import {
  CaretClosedSmall,
  CaretOpenedSmall,
  LottieAnimation,
  Stack,
  TreeExpandIcon,
  TreeRootExpandIcon,
  TreeViewLoader,
  dropCloseAnimationData,
  dropOpenAnimationData,
  unselectChildren,
} from '@storybook';
import React from 'react';
import { TreeNode } from 'react-hyper-tree/dist/helpers/node';
import {
  AllowedItemType,
  ItemContainer,
  ItemData,
  LocalStateManager,
  isItemContainer,
  useCombinedHandlers,
} from '~/utilities';
import { useSettings } from '~/modules/Settings/SettingContext';
import { RenderNodeProps, RenderNodeWithNextProps, TreeNodeRenderer } from './GetNodeRenderer';
import { getHarmonieTree } from '../Tree';

interface NodeState {
  isOpened: boolean;
}
export interface RootNodeStates {
  [key: string]: NodeState;
}

const treeExpansionState = new LocalStateManager<RootNodeStates>('main-state', () => ({
  'recent:root': { isOpened: true },
}));

export const updateNewItemsCount = (node: TreeNode | undefined, delta: number) => {
  while (node) {
    (node.data as ItemContainer).newItemsCount = ((node.data as ItemContainer).newItemsCount || 0) + delta;
    node = node.getParent();
  }
};
export const saveNodeState = (n: TreeNode, isOpened: boolean) => {
  if (!isOpened) delete treeExpansionState.value[n.id];
  else treeExpansionState.value[n.id] = { isOpened };
  treeExpansionState.save();
};

export const resetTreeNodeState = () => {
  treeExpansionState.value = {};
};
const ExpandHandlerComponent = (props: RenderNodeWithNextProps) => {
  const { showTreeHistory } = useSettings();
  const { onToggle, onSelect, node, handlers, ItemPrefix, nextRenderer } = props;
  const nData = node.data as ItemContainer;
  const [playAnimation, setPlayAnimation] = useState(false);

  useEffect(() => {
    if (playAnimation) {
      setTimeout(() => {
        setPlayAnimation(false);
      }, 50); // Adjust the timeout duration to match the duration of your animation
    }
  }, [playAnimation]);

  useEffect(() => {
    const isNodeOpened = handlers.isExpanded(node);
    const state = treeExpansionState.value[node.id];
    if (state?.isOpened && !isNodeOpened && showTreeHistory) {
      handlers.setOpen(node, true);
      (node.data as ItemData).resetNewItems(true);
    }
  }, [handlers, node, showTreeHistory]);

  const onExpand = useCallback(
    (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      const isNodeOpened = handlers.isExpanded(node);
      event.stopPropagation();
      if (nData.isRootSection) {
        setPlayAnimation(true);
        getHarmonieTree()
          .instance.getOpenedNodes()
          .forEach(n => {
            const data = n.data as ItemData;
            if (n !== node && data.isRootSection) {
              data.resetNewItems(
                (data as ItemContainer).newItemsCount !== undefined && nData.apiIdKey !== data.apiIdKey && n.isOpened()
              );
              saveNodeState(n, false);
              n.setOpened(false);
            }
          });
      }

      unselectChildren(node, handlers);
      if (node.isOpened() && nData.newItemsCount) {
        // Update parents that nData.needsUpdateCount items were handled.
        updateNewItemsCount(node, -nData.newItemsCount);
        node.setAsyncDataLoaded(false);
      }
      nData.resetNewItems(isNodeOpened && nData.newItemsCount !== undefined);
      onToggle(event);
      saveNodeState(node, !isNodeOpened);
    },
    [nData, node, handlers, onToggle]
  );

  const onClickExpand: MouseEventHandler<HTMLDivElement> = useCallback(
    event => {
      if (nData.isRootSection && event.type !== 'contextmenu' && !event['no-expand']) onExpand(event);
    },
    [nData.isRootSection, onExpand]
  );
  const options = node.options;

  const treeExpander = useCallback(
    () => (
      <>
        {(options.loading && <TreeViewLoader />) ||
          (node.data.isRootSection ? (
            <TreeRootExpandIcon>
              {options.opened || playAnimation ? (
                <Stack className="lottie-animation">
                  <LottieAnimation
                    options={{
                      loop: false,
                      autoplay: true,
                      animationData: playAnimation ? dropCloseAnimationData : dropOpenAnimationData,
                      rendererSettings: {
                        preserveAspectRatio: 'none',
                      },
                    }}
                    height={64}
                    width={16}
                  />
                </Stack>
              ) : (
                <TreeExpandIcon>
                  <CaretClosedSmall />
                </TreeExpandIcon>
              )}
            </TreeRootExpandIcon>
          ) : (
            <TreeExpandIcon onClick={onExpand}>
              {options.opened ? <CaretOpenedSmall /> : <CaretClosedSmall />}
            </TreeExpandIcon>
          ))}
        {ItemPrefix && <ItemPrefix />}
      </>
    ),
    [ItemPrefix, node.data.isRootSection, onExpand, options.loading, options.opened, playAnimation]
  );

  const combinedSelectionHandler = useCombinedHandlers(onClickExpand, onSelect);
  return nextRenderer({
    ...props,
    onSelect: combinedSelectionHandler,
    ItemPrefix: treeExpander,
  });
};

export const treeExpandHandler = (type: AllowedItemType, nextRenderer: TreeNodeRenderer): TreeNodeRenderer => {
  if (!isItemContainer(type)) return nextRenderer;
  const res = (props: RenderNodeProps) => <ExpandHandlerComponent {...{ ...props, nextRenderer }} />;
  return res;
};
