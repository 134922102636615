import { Theme } from '@mui/material';

export const newItemDecorator = (theme: Theme, bg?: string, fg?: string) => `{
    position: relative;
    &::after {
      content: " ";
      position: absolute;
      right: 0;
      top: 0;
      width: 10px;
      height: 10px;
      border-radius: 5px;
      border: 2px solid ${bg || theme.palette.backgrounds.primary};
      background-color: ${fg || theme.palette.texts.accentSecondary};
    }
  }
`;
