import React, { ReactElement } from 'react';
import {
  ExcelAppMedium,
  PowerPointAppMedium,
  ProjectAppMedium,
  VisioAppMedium,
  WordAppMedium,
  ViewItem,
  SVGComponent,
  MediaKeysType,
} from '@storybook';
import { useGraphClient } from '@services';
import { strings } from '@vendor/languages';
import { DocumentItem, isOfficeAppDocument, ItemData } from '../itemTypes';
import { ActionRendererHookType, BaseReadActionClass, TrackedEventType } from './BaseAction';

const useOpenInOfficeApp: ActionRendererHookType = ({ nodes, action, useSafeCallback, actionRef }) => {
  const that = action as OpenInOfficeAppAction;
  const gcl = useGraphClient();
  const node = nodes[0];
  const data = node.data;
  const onClick = useSafeCallback(async () => {
    actionRef.current = data.isDocument ? (data as DocumentItem).mediaType : undefined;
    const urls = await data.getAccessUrls(gcl);
    urls && window.open(urls.appUrl);
  }, [actionRef, data, gcl]);
  return {
    title: that.title,
    icon: that.getIcon(data!),
    onClick,
  };
};

export class OpenInOfficeAppAction extends BaseReadActionClass {
  readonly trackedName = 'OpenDocument';
  readonly title = strings.lang.actionToolTip.openInApp;

  readonly useRenderer = useOpenInOfficeApp;
  getIcon(data: ItemData): ReactElement | SVGComponent | SVGComponent[] {
    const app = (data as DocumentItem).officeApp;
    const Icon = // eslint-disable-next-line prettier/prettier
      app == 'Word' ? WordAppMedium : // eslint-disable-next-line prettier/prettier
      app == 'Excel' ? ExcelAppMedium : // eslint-disable-next-line prettier/prettier
      app == 'PowerPoint' ? PowerPointAppMedium : // eslint-disable-next-line prettier/prettier
      app == 'Project' ? ProjectAppMedium : VisioAppMedium;
    const fill = { 'data-nofill': true };
    return <Icon {...fill} key="OpenInApp" />;
  }

  transformEvent({ data, eventName }: TrackedEventType, mediaType?: MediaKeysType): TrackedEventType {
    return {
      eventName,
      data: {
        ...data,
        mode: 'Desktop',
        mediaType,
      },
    } as TrackedEventType;
  }
  isHandled(nodes: ViewItem<ItemData>[]): boolean {
    return nodes.length == 1 && isOfficeAppDocument(nodes[0].data);
  }
}
