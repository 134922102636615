import React from 'react';
import { Stack } from '@mui/system';
import { Button } from '../Button';

export interface IndicatorFooterProps {
  isCanceling: boolean | undefined;
  onCancel: (() => void) | undefined;
  cancel: string;
  hasCancel: boolean;
}

export const IndicatorFooter = ({ cancel, isCanceling, onCancel, hasCancel }: IndicatorFooterProps) => {
  return (
    <Stack width={'100%'} justifyContent="flex-end" direction="row" minHeight={32}>
      {hasCancel && (
        <Button disabled={isCanceling} variant="text" onClick={onCancel} color="secondary">
          {cancel}
        </Button>
      )}
    </Stack>
  );
};
