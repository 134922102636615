import { ShareSmall, ShareMedium, ViewItem } from '@storybook';
import { strings } from '@vendor/languages';
import { ItemData } from '../itemTypes';
import { BaseMenuActionClass } from './BaseMenuAction';

export class ShareAction extends BaseMenuActionClass {
  readonly title = strings.lang.actionToolTip.share;
  readonly icon = [ShareSmall, ShareMedium];

  isQuickAction(data: ItemData, isTopBarItem: boolean) {
    return isTopBarItem ? undefined : data.type === 'site' ? 'only' : 'both';
  }

  isHandled(nodes: ViewItem<ItemData>[]): boolean {
    const data = nodes[0]?.data;
    return nodes.length == 1 && data.canOpenInBrowser && !data.isRootSection;
  }
}
