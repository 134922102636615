import { FunctionComponent, SVGProps } from 'react';
import { GraphClient } from '@services';
import { UndoAction } from '@storybook';
import { ItemContainer } from './ItemContainer';

export enum MicrosoftApps {
  OneDrive = 'OneDrive',
  SharePoint = 'SP',
  Teams = 'Teams',
  Default = 'Microsoft 365',
}

export type AllowedItemType =
  | 'list'
  | 'site'
  | 'folder'
  | 'team'
  | 'channel'
  | 'document'
  | 'error'
  | 'empty'
  | 'favoritesroot'
  | 'insight'
  | 'sharepointroot'
  | 'teamsroot'
  | 'onedriveroot'
  | 'recentroot'
  | 'sharedwithmeroot'
  | 'home'
  | 'separator'
  | 'teamschatfiles'
  | 'SearchResults'
  | 'viewfiles';

export type ByType<T> = {
  [key in AllowedItemType]?: T;
};

export type getChildrenType = ({ node }: any, refreshStamp?: number) => any[] | Promise<any[]>;

export interface IconProps {
  icon: FunctionComponent<SVGProps<SVGSVGElement>>;
  isColorable?: boolean;
  noPreview?: boolean;
}

export interface AccessUrls {
  webUrl: string;
  appUrl?: string;
  address?: string;
}

export enum treeitemFirstParent {
  OneDrive = 'OneDrive',
  SharePoint = 'SharePoint',
  Teams = 'Teams',
  Recent = 'Recent',
  Favorite = 'Favorite',
  SharedWithMe = 'SharedWithMe',
}

export interface ItemData {
  readonly type: AllowedItemType;
  readonly name: string;
  id: string;
  size?: number;

  rootPrefix: string;
  isTransientLocation?: boolean;
  isVirtualItem?: boolean;
  isSearchItem?: boolean;
  isAdvancedSearchItem?: boolean;
  isNew?: boolean;
  itemFirstParent?: treeitemFirstParent;
  searchedLocationId?: string;
  timeStamp?: number;
  itemColorable: boolean;
  additionalActionHandler?: (e: React.MouseEvent) => void;
  summary?: string;
  getChildren?: getChildrenType;
  objectify: (props: any) => ItemData;

  // Computed criteria for logical computations on items.
  OfficeAppLocated?: MicrosoftApps;
  get parent(): ItemContainer | undefined;
  get isRootSection(): boolean;
  get isVirtual(): boolean;
  get isUserManaged(): boolean;
  get isDocument(): boolean;
  get isLibraryItem(): boolean;
  get isShareable(): boolean;
  get hasFolder(): boolean;
  get rootSite(): string;
  get apiId(): string;
  get apiIdKey(): string;
  get canRefresh(): boolean;
  get itemCanSelected(): boolean;
  get canOpenInBrowser();
  get canDownload();
  get isTeamsEntity(): boolean;
  get isAdvancedSearchContainer(): boolean;
  get canRemove(): boolean;
  get canUndoRemove(): boolean;
  get pathOrDescription(): string | null;
  get secondLineContent(): string | null;
  get hasNewChildren(): boolean;
  get locationHaveQuickSearch(): boolean;
  get canReorder(): boolean;
  get sortDir(): boolean;

  getNumberOfQuickActions(isDrilldown: boolean): number;
  resetNewItems(isOpened: boolean): void;
  getIcon(expanded: boolean): IconProps | undefined;
  getAccessUrls(gcl: GraphClient): Promise<AccessUrls | undefined>;
  toJson(): any;
  removeItem(gcl: GraphClient): Promise<UndoAction | void>;
}

export const NoRootSite = 'none';
