import React, { useCallback, useEffect } from 'react';
import { ContextMenu2, Stack, TreeRootTopLevel, useTheme, Scrollbar, WhatsNewNotifications } from '@storybook';
import { useGraphClient } from '@services';
import { getHarmonieTreeHandlers } from '~/components/Tree/Tree';
import QuickSearch from '~/components/QuickSearch/QuickSearch';
import { Channel } from '~/utilities';
import { AllLocations } from '~/utilities/virtualRoots/AllLocations';
import { SidebarInitKey } from '~/utilities/analytics/tracking';
import { useWhatsNewNotifications } from '~/hooks/useWhatsNewNotifications';
import { TopBar, Tree } from '../../components';

const style = { height: '100%', width: '100%' };

const Home = () => {
  const theme = useTheme();
  const gcl = useGraphClient();
  const allChannels = useCallback(async () => {
    await Channel.setAllChannels(gcl);
  }, [gcl]);

  useEffect(() => {
    allChannels();
    PubSub.publish(SidebarInitKey);
  }, [allChannels]);

  return (
    <Stack sx={style} className="hidden-scrollbar" background={theme.palette.backgrounds.primary}>
      <WhatsNewNotifications useWhatsNewNotifications={useWhatsNewNotifications} />
      <ContextMenu2>
        <QuickSearch location={AllLocations.instance} />
        <TopBar isSettingsPage={false} />
        <Scrollbar showDebugger={Boolean(hrmProvisioning?.scrollDebugger)}>
          <TreeRootTopLevel onClick={() => getHarmonieTreeHandlers().unselectAll()}>
            <Tree />
          </TreeRootTopLevel>
        </Scrollbar>
      </ContextMenu2>
    </Stack>
  );
};

export default Home;
