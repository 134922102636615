interface ExternalApi {
  newWindow: (webUrl: string) => void;
  close: () => void;
  resizeTo: (x: number, y: number) => void;
  resizeBy: (x: number, y: number) => void;
}
const external: ExternalApi = window.external as any as ExternalApi;

export const openNewWindow = (webUrl: string) => {
  if (external && external.newWindow) external.newWindow(webUrl);
  else window.open(webUrl, '_blacnk');
};

export const closeWindow = () => {
  if (external && external.close) external.close();
  else window.close();
};

export const resizeWindowTo = (x: number, y: number) => {
  if (external && external.resizeTo) external.resizeTo(x, y);
  else window.resizeTo(x, y);
};

export const resizeWindowBy = (x: number, y: number) => {
  if (external && external.resizeBy) external.resizeBy(x, y);
  else window.resizeBy(x, y);
};
