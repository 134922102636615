import { NewItemDialog, RenameMedium, ViewItem, getExtensionAndName } from '@storybook';
import React, { useCallback } from 'react';
import { strings } from '@vendor/languages';
import { GraphClient } from '@services';
import { ItemContainer, ItemData } from '../itemTypes';
import { ActionRendererHookType, BaseUpdateActionClass } from './BaseAction';
import { useNewItemDialog } from './NewOfficeDocument';
import { publishItemListChanged } from './UploadAction';
import { LibraryItem } from '../itemTypes/LibraryItem';

const useRenameItemAction: ActionRendererHookType = ({ nodes, handlers, useSafeCallback }) => {
  const item = nodes[0].data as LibraryItem;
  const parent = handlers?.getParent(nodes[0])?.data as ItemContainer;
  const location = parent || item.parent;
  const { extension: ext, name } = item.isDocument
    ? getExtensionAndName(item.name)
    : { name: item.name, extension: '' };
  const renameFile = useCallback(
    async (gcl: GraphClient, newName: string) => {
      if (newName == name) return; // Do nothing if name wasn't modified.
      const renamedItem = await item.rename(gcl, newName + ext);
      publishItemListChanged({ updated: { [item.apiIdKey]: renamedItem }, location });
    },
    [name, item, ext, location]
  );
  const texts = item.isDocument
    ? strings.lang.newItemDialogTexts.renameFile
    : strings.lang.newItemDialogTexts.renameFolder;
  const { setNewItemDialogVisible, ...props } = useNewItemDialog({
    location,
    texts,
    actionCallback: renameFile,
    initialValue: name,
    useSafeCallback,
  });

  return {
    title: 'Rename',
    icon: RenameMedium,
    onClick: () => setNewItemDialogVisible(true),
    SideUI: <NewItemDialog {...props} title={texts.title} maxWidth="xs" extensions={ext}></NewItemDialog>,
  };
};

export class RenameItemAction extends BaseUpdateActionClass {
  readonly trackedName = 'Rename';
  readonly useRenderer = useRenameItemAction;

  isHandled(nodes: ViewItem<ItemData>[]): boolean {
    return (
      nodes.length === 1 &&
      nodes[0].data.isLibraryItem &&
      !nodes[0].data.isSearchItem &&
      !nodes[0].data.isAdvancedSearchItem
    );
  }
}
