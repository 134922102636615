import React, { useEffect, useMemo } from 'react';
import { Stack } from '../Stack';
import { OnboardingList } from '../OnboardingList';
import { OperatedList } from '../ManageLists/ManageItem';
import { OnboardingListItemType } from '../OnboardingListItem/OnboardingListItem';

interface OnboardingManageProps {
  selectedItems: OperatedList[] | undefined;
  setSelectedItems: React.Dispatch<React.SetStateAction<OperatedList[] | undefined>>;
  items: OperatedList[];
  listType: OnboardingListItemType;
}

export const OnboardingManage = ({ selectedItems, setSelectedItems, items, listType }: OnboardingManageProps) => {
  useEffect(() => {
    setSelectedItems(items.slice(0, 5));
  }, [items, setSelectedItems]);

  const listOfItems = useMemo(
    () =>
      items.map(item => ({
        ...item,
        checked: selectedItems?.some(selected => selected.id === item.id) ?? false,
      })),
    [items, selectedItems]
  );

  const handleSelectionChange = (item: OperatedList) => {
    setSelectedItems(prev => {
      const index = prev?.findIndex(selected => selected.id === item.id) ?? -1;
      if (index > -1) {
        return prev?.filter((_, i) => i !== index) ?? [];
      } else {
        return [...(prev ?? []), item];
      }
    });
  };

  return (
    <Stack justifyItems="center" direction="row" height={'100%'} width={'100%'} marginTop={4}>
      <OnboardingList listItems={listOfItems} onChangeFunction={handleSelectionChange} listType={listType} />
    </Stack>
  );
};
