import React, {
  Dispatch,
  MouseEventHandler,
  SetStateAction,
  useCallback as useCallbackOrig,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { NavigateFunction, useLocation, useNavigate } from 'react-router-dom';
import { keyBy as _keyBy } from 'lodash';
import { LocationEmptyStateLight, LocationEmptyStateDark } from '@storybook';
import { logError } from '@vendor/utils/misc';
import { strings } from '@vendor/languages';
import { ViewItem, ContainerHandlers } from '@storybook';
import {
  AllowedItemType,
  EmptyStateItem,
  ItemContainer,
  ItemData,
  ItemDataWithPaging,
  ItemListChangesType,
  ItemsListChangedEvent,
  UploadAction,
  createUseCallbackWrap,
  typeHasFolder,
  useCombinedHandlers,
} from '~/utilities';
import { OrderByInfo } from '~/utilities/itemTypes/ListView';
import { ViewFilesItem } from '~/utilities/itemTypes/ViewFilesItem';
import { useSafeCallback } from '~/hooks/UseSafeCallback';
import { useSubscribe } from '~/hooks/useSubscribe';
import { SeparatorItem } from '~/utilities/itemTypes/SeparatorItem';
import { SearchProps } from '~/utilities/search/Search';
import { NavigateAction } from '~/utilities/actions/NavigateAction';
import { createActionEvent, trackErrorEvent } from '~/utilities/analytics/tracking';
import { ItemDataRenderProps, ItemDataRenderWithNextProps, ItemDataRenderer } from './GetNodeRenderer';

export interface DrilldownState {
  state: {
    location: ItemContainer;
    refreshStamp?: number;
    orderBy?: OrderByInfo[];
    newItems?: ItemData[];
    itemCount?: number;
  };
}

export const navigateDrilldown = (
  navigate: NavigateFunction,
  inData: ItemContainer | ViewFilesItem,
  replace = false,
  orderBy?: OrderByInfo[],
  refreshStamp?: number,
  newItems?: ItemData[]
) => {
  const data = inData?.type == 'viewfiles' ? (inData as ViewFilesItem).data : (inData as ItemContainer);
  navigate(data?.hasFolder ? `/drilldown/folderDrilldown` : '/drilldown/simpleDrilldown', {
    replace: replace,
    state: {
      location: data,
      refreshStamp,
      orderBy,
      newItems,
      itemCount: data.fetchChildrenCount,
    },
  } as DrilldownState);
  PubSub.publish('resetNewItems');
};

type SelectionState = Map<string | number, ViewItem<ItemData>>;

const getListHandlers = (
  location: ItemContainer,
  selection: SelectionState,
  setSelection?: Dispatch<SetStateAction<SelectionState>>
): ContainerHandlers<ItemData> => {
  return {
    isExpanded: () => false,
    isSelected: item => {
      return selection.get(item.id) !== undefined;
    },
    selectAll: () => undefined,
    setSelected: (item: ViewItem<ItemData>, selected) => {
      if (selected == (selection.get(item.id) !== undefined)) return;
      if (selected) selection.set(item.id, item);
      else selection.delete(item.id);
      setSelection?.(new Map<string | number, ViewItem<ItemData>>(selection));
    },
    unselectAll: () => {
      selection.clear();
      setSelection?.(new Map<string | number, ViewItem<ItemData>>());
    },
    getParent: data => (data.id === location.id ? undefined : { data: location, id: location.id }),
    getSelectedNodes: () => {
      return [...selection.values()];
    },
  };
};

export const useDrilldown = (
  getItems: (token: string | undefined) => Promise<ItemDataWithPaging>,
  location: ItemContainer,
  inpNewItems?: ItemData[],
  useCallback = useCallbackOrig
) => {
  const [resultCount, setResultCount] = useState<number>(0);
  const [items, setItems] = useState<ItemData[]>([]);
  const [nextToken, setNextToken] = useState<string | undefined>();
  const [pagginationText, setPagginationText] = useState<string | undefined>();
  const [newItems, setNewItems] = useState<ItemData[]>(inpNewItems || []);
  const [selection, setSelection] = useState(new Map<string | number, ViewItem<ItemData>>());
  const loadingRef = useRef(false);
  const counter = useRef(0);
  const setItemsWithEmptyState = useCallback(
    (items: ItemData[], extarCond = true) => {
      setItems(
        new EmptyStateItem({
          name: 'drilldown',
          images: { light: LocationEmptyStateLight, dark: LocationEmptyStateDark },
          action: new UploadAction(),
          size: 220,
          isEmptyFolder: true,
          location: location.hasFolder ? { id: location.id, data: location } : undefined,
        }).apply(items, extarCond && items.length === 0 && !location.isAdvancedSearchContainer)
      );
    },
    [location]
  );

  const listHandlers = useMemo(
    () => getListHandlers(location, selection, setSelection),
    [location, selection, setSelection]
  );

  const mappedItems = useMemo(() => {
    const newItemsSet = _keyBy(newItems, 'apiIdKey');
    return (
      newItems.length > 0
        ? [...newItems, new SeparatorItem(), ...items.filter(i => i.type != 'empty' && !newItemsSet[i.apiIdKey])]
        : items
    ).map(data => ({ data, id: data.id }));
  }, [items, newItems]);
  // TODO: Rework the new items to go over the list once to replace exisiting items
  useSubscribe(
    ItemsListChangedEvent,
    useCallback(
      ({ added, updated, deleted, location: targetLocation }: ItemListChangesType) => {
        counter.current = counter.current + 1;
        if (location.apiIdKey !== targetLocation?.apiIdKey) return;
        let itemsAfterUpdate = items.filter(i => i.type != 'empty');
        let newItemsAfterUpdate = newItems;
        if (added) {
          const addedItems = Object.values(added);
          const existingItemsMap = new Map(newItemsAfterUpdate.map(item => [item.apiIdKey, item]));
          const updatedItems: ItemData[] = [];
          // Add new or updated items at the start
          addedItems.forEach(item => {
            // Directly add to the start of the updatedItems array
            if (existingItemsMap.has(item.apiIdKey)) {
              // If it exists, remove from the map to avoid duplication later
              existingItemsMap.delete(item.apiIdKey);
            }
            updatedItems.push(item);
          });
          // Now add remaining items that were not updated
          existingItemsMap.forEach(item => {
            updatedItems.push(item);
          });

          // Replace the old newItemsAfterUpdate array with the updated one
          newItemsAfterUpdate = updatedItems;
        }

        if (updated) {
          itemsAfterUpdate = itemsAfterUpdate.map(v => updated[v.apiIdKey] || v);
          newItemsAfterUpdate = newItemsAfterUpdate.map(v => updated[v.apiIdKey] || v);
        }
        if (deleted) {
          itemsAfterUpdate = itemsAfterUpdate.filter(v => !deleted[v.apiIdKey]);
          newItemsAfterUpdate = newItemsAfterUpdate.filter(v => !deleted[v.apiIdKey]);
        }
        setNewItems(newItemsAfterUpdate);
        setItemsWithEmptyState(itemsAfterUpdate, newItemsAfterUpdate.length == 0);
      },
      [location.apiIdKey, items, newItems, setItemsWithEmptyState]
    )
  );

  useSubscribe(
    'resetNewItems',
    useCallback(() => setNewItems(inpNewItems || []), [inpNewItems])
  );

  const fetchPage = useSafeCallback(
    async (token, inp: ItemData[], isCanceled?: () => boolean) => {
      if (loadingRef.current) return;
      loadingRef.current = true;
      try {
        const data = await getItems(token);
        setResultCount(data.resultCount || 0);
        if (isCanceled?.()) return;
        setItemsWithEmptyState([...inp, ...data.items]);
        setNextToken(data.pageToken);
        setPagginationText(data.pagginationString);
        return true;
      } catch (error: any) {
        logError(error, 'Drilldown navigation failed.');
        const trackedEvent = createActionEvent(
          new NavigateAction(),
          'Other',
          [{ data: location, id: location.id }],
          // We don't want to depend on the current selection as this will fetch the items every time the selection changes.
          getListHandlers(location, new Map<string | number, ViewItem<ItemData>>())
        );
        trackErrorEvent(trackedEvent, error);
        setItems([]);
        throw error;
      } finally {
        loadingRef.current = false;
      }
    },
    [getItems, location, setItemsWithEmptyState],
    true,
    {
      errorTitle: strings.lang.notificationsError.drilldownFailedTitle,
      errorMessage: strings.lang.notificationsError.drilldownFailedMessage,
    }
  );

  useEffect(() => {
    setItems([]);
    setNewItems(inpNewItems || []);
    setNextToken(undefined);
    counter.current = 0;
    setSelection(new Map<string | number, ViewItem<ItemData>>());
    let canceled = false;
    fetchPage(undefined, [], () => canceled);
    return () => {
      canceled = true;
    };
  }, [fetchPage, inpNewItems, location]);

  return {
    newItems,
    listHandlers,
    resultCount,
    nextToken,
    mappedItems,
    selection,
    fetchPage,
    counter,
    loadingRef,
    items,
    pagginationText,
  };
};

export const generateQuickSearchKey = (location: ItemContainer, searchProps?: SearchProps) =>
  `${location.apiIdKey}-${searchProps?.searchTerm}-${searchProps?.filters}-${searchProps?.entityTypes}`;

export const generateVirtualListKey = (
  location: ItemContainer,
  tempViewCount: number,
  isEmptyState: boolean,
  orderBy?: OrderByInfo[],
  searchProps?: SearchProps
) =>
  `${location.id}-${searchProps?.searchTerm}-${orderBy?.[0].field}-${orderBy?.[0].dir}-${isEmptyState}-${tempViewCount}`;

export const useDrilldownNavigation = ({
  data: inData,
  replace = false,
  orderBy,
  refreshStamp,
  newItems,
  useCallback = useCallbackOrig,
}: {
  data?: ItemContainer | ViewFilesItem;
  replace?: boolean;
  orderBy?: OrderByInfo[];
  refreshStamp?: number;
  newItems?: ItemData[];
  useCallback?: typeof useCallbackOrig;
}) => {
  const navigate = useNavigate();
  const state = useLocation()?.state as DrilldownState['state'];
  const data = inData?.type == 'viewfiles' ? (inData as ViewFilesItem).data : (inData as ItemContainer);

  return useCallback<MouseEventHandler>(
    () => navigateDrilldown(navigate, data, replace, orderBy || state?.orderBy, refreshStamp, newItems),
    [navigate, data, replace, orderBy, state?.orderBy, refreshStamp, newItems]
  );
};

const DrilldownComponent = (props: ItemDataRenderWithNextProps) => {
  const { node, onDoubleClick, nextRenderer, handlers } = props;
  const useCallbackWrap = createUseCallbackWrap(
    new NavigateAction(),
    'Double Click',
    [props.node],
    useCallbackOrig,
    handlers
  );
  const { data } = node;
  const onDoubleClickOpenFolder = useDrilldownNavigation({
    data: data as ItemContainer,
    useCallback: useCallbackWrap,
  });
  const combinedDoubleClickHandler = useCombinedHandlers(onDoubleClickOpenFolder, onDoubleClick);
  return nextRenderer({ ...props, onDoubleClick: combinedDoubleClickHandler });
};

export const drilldownHandler = (type: AllowedItemType, nextRenderer: ItemDataRenderer): ItemDataRenderer => {
  if (!typeHasFolder(type) && type !== 'team' && type !== 'site' && type !== 'teamschatfiles') return nextRenderer;
  const res = (props: ItemDataRenderProps) => <DrilldownComponent {...{ ...props, nextRenderer, type }} />;
  return res; // Strange that res is needed here...
};
