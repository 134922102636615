import { ViewItem, EditDetailsMedium } from '@storybook';
import { useGraphClient } from '@services';
import { strings } from '@vendor/languages';
import { ItemData, ShareableItem } from '../itemTypes';
import { ActionRendererHookType, BaseReadActionClass } from './BaseAction';

const useEditOnSharepoint: ActionRendererHookType = ({ nodes, useSafeCallback }) => {
  const node = nodes[0];
  const data = node.data as unknown as ShareableItem;
  const gcl = useGraphClient();
  const onClick = useSafeCallback(async () => {
    const url = await data.getEditDetailsUrl(gcl);
    url && window.open(url);
  }, [data, gcl]);

  return {
    title: strings.lang.actionToolTip.editOnSharepoint,
    icon: EditDetailsMedium,
    onClick,
  };
};

export class EditOnSharepointAction extends BaseReadActionClass {
  readonly trackedName = 'EditOnSharepoint';
  readonly useRenderer = useEditOnSharepoint;

  isHandled(nodes: ViewItem<ItemData>[]): boolean {
    const data = nodes[0]?.data;
    return nodes.length === 1 && data.isShareable && (data.isAdvancedSearchItem || !data.isSearchItem);
  }
}
