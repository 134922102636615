import React from 'react';
import { Container as MuiContainer, ContainerProps as MuiContainerProps } from '@mui/material';

export type ContainerProps = MuiContainerProps;

const Container = ({ children, ...rest }: ContainerProps) => {
  return <MuiContainer {...rest}>{children}</MuiContainer>;
};

export default Container;
