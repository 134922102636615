import { Theme, Components } from '@mui/material/styles';

export default (theme: Theme) => {
  const styleOverrides: Components['MuiSwitch'] = {
    defaultProps: {
      disableRipple: true,
    },
    styleOverrides: {
      root: {
        width: theme.spacing(6),
        height: '16px',
        padding: 0,
        borderRadius: theme.spacing(6),
        backgroundColor: theme.palette.buttons.primary,
      },
      thumb: {
        width: '12px',
        height: '12px',
        background: theme.palette.texts.onAccentPrimary,
      },
      switchBase: {
        padding: '2px',
        filter: 'drop-shadow(1px 1px 4px rgba(17, 36, 59, 0.20))',
        ['&.Mui-checked']: {
          transform: `translateX(${theme.spacing(3)})`,
          ['+.MuiSwitch-track']: {
            backgroundColor: theme.palette.buttons.accentPrimary,
            opacity: 1,
          },
        },
        ['&.Mui-checked:hover']: {
          ['+.MuiSwitch-track']: {
            backgroundColor: theme.palette.buttons.accentSecondary,
            opacity: 1,
          },
        },
        ['&:not(.Mui-checked):hover']: {
          ['+.MuiSwitch-track']: {
            backgroundColor: theme.palette.buttons.secondary,
            opacity: 1,
          },
        },
      },
      track: {
        backgroundColor: theme.palette.buttons.primary,
        border: `1px solid ${theme.palette.outlinesAndStrokes.primary}`,
      },
    },
  };
  return styleOverrides;
};
