import React, { FC } from 'react';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/system';
import { Box } from 'material-ui-core';

const ShortcutBody = styled(Box)`
  ${({ theme }) => `
    display: flex;
    aling-items: center;
    padding: ${theme.spacing(0, 1)};
    border-radius: ${theme.spacing(1)};
    height: ${theme.spacing(4)};
    background: ${theme.palette.backgrounds.quaternary};
  `}
`;

export type ShortcutsType = {
  label?: string;
};

const Shortcut: FC<ShortcutsType> = ({ label }) => {
  return (
    <ShortcutBody>
      <Typography color="texts.secondary" variant="H2Regular">
        {label}
      </Typography>
    </ShortcutBody>
  );
};

export default Shortcut;
