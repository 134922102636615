import { Line, TreeLine } from '@storybook';
import React, { useCallback } from 'react';
import { AllowedItemType, isItemContainer } from '~/utilities';
import { RenderNodeProps, RenderNodeWithNextProps, TreeNodeRenderer } from './GetNodeRenderer';

const LinePainterComponent = (props: RenderNodeWithNextProps) => {
  const { ItemPrefix, nextRenderer } = props;
  const lineDecorator = useCallback(
    () => (
      <>
        <TreeLine>
          <Line />
        </TreeLine>
        {ItemPrefix && <ItemPrefix />}
      </>
    ),
    [ItemPrefix]
  );

  return nextRenderer({
    ...props,
    ItemPrefix: lineDecorator,
  });
};

export const itemLinePainter = (type: AllowedItemType, nextRenderer: TreeNodeRenderer): TreeNodeRenderer => {
  if (isItemContainer(type)) return nextRenderer;
  const res = (props: RenderNodeProps) => <LinePainterComponent {...{ ...props, nextRenderer }} />;
  return res;
};
