import { Stack, styled } from '@mui/material';

// TODO - check maybe move to abs higlight
const ItemNode = styled(Stack)`
  flex-direction: row;
  width: 100%;
  align-content: center;
  align-items: center;
  padding: 3px 3px 3px 5px;
  overflow: hidden;
  border-radius: 8px;

  .navigationItem.itemColorable:hover & {
    background-color: ${({ theme }) => theme.palette.backgrounds.secondary};
  }

  .navigationItem.isSelected.itemColorable & {
    background-color: ${({ theme }) => theme.palette.buttons.accentPrimary};
    color: ${({ theme }) => theme.palette.texts.onAccentPrimary};
    box-shadow: ${({ theme }) => theme.palette.shadow.item};
  }

  .navigationItem.isSelected.itemColorable:hover & {
    border-radius: 8px;
    background-color: ${({ theme }) => theme.palette.buttons.accentPrimary};
  }
`;

export default ItemNode;
