import React from 'react';
import { Stack as MuiStack, StackProps as MuiStackProps } from '@mui/material';

export interface StackProps extends MuiStackProps {
  background?: string;
}
const Stack = ({ children, ...rest }: StackProps, ref: any) => {
  return (
    <MuiStack ref={ref} {...rest} style={{ background: rest?.background }}>
      {children}
    </MuiStack>
  );
};

export default React.forwardRef(Stack);
