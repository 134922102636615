import React from 'react';

export const sleep = (n: number): Promise<void> => new Promise(f => setTimeout(f, n));

export const useInterval = (callback: () => void, delay: number) => {
  const intervalRef = React.useRef<number | undefined>();
  const savedCallback = React.useRef(callback);
  React.useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);
  React.useEffect(() => {
    const tick = () => savedCallback.current();

    if (typeof delay === 'number' && delay >= 0) {
      intervalRef.current = window.setInterval(tick, delay);
      return () => window.clearInterval(intervalRef.current);
    }
  }, [delay]);
  return intervalRef;
};

export const useTimeout = (callback: () => void, delay: number) => {
  const intervalRef = React.useRef<number | undefined>();
  const savedCallback = React.useRef(callback);
  React.useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);
  React.useEffect(() => {
    const tick = () => savedCallback.current();

    if (typeof delay === 'number' && delay >= 0) {
      intervalRef.current = window.setTimeout(tick, delay);
      return () => window.clearTimeout(intervalRef.current);
    }
  }, [delay]);
  return intervalRef;
};
