import React, { useEffect } from 'react';

// Mapping for keyboard shortcuts. Can be later enhanced to allow Alt, Meta, etc.
const ctrlKeyMap = {
  e: 'SUBSCRIPTION_EMAIL_WITH_LINK',
  l: 'SUBSCRIPTION_COPY_LINK',
  t: 'SUBSCRIPTION_SHARE_TO_TEAMS',
};

const KeyPressPublish: React.FC<React.PropsWithChildren> = ({ children }) => {
  useEffect(() => {
    const handleKeyPress = (event: KeyboardEvent) => {
      if (!event.ctrlKey || event.altKey || event.shiftKey || event.metaKey) return;
      event.preventDefault();
      ctrlKeyMap[event.key] && PubSub.publish(ctrlKeyMap[event.key]);
    };

    window.addEventListener('keydown', handleKeyPress);
    return () => window.removeEventListener('keydown', handleKeyPress);
  }, []);

  return <React.Fragment>{children}</React.Fragment>;
};

export default KeyPressPublish;
