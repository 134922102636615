import { Components, Theme } from '@mui/material/styles';

// TODO overight MuiIconButton not working for some reason
export default (theme: Theme) => {
  const styleOverrides: Components['MuiDialog'] = {
    styleOverrides: {
      container: {
        backgroundColor: theme.palette.backgrounds.overlay,
        zIndex: 1400,
      },
      paper: {
        borderRadius: 8,
        margin: 0,
        boxShadow: `0px 5px 30px ${theme.palette.shadow.menu}, 1px 2px 5px ${theme.palette.shadow.menu}`,
        '& .MuiIconButton-root': {
          'svg, path': {
            fill: theme.palette.texts.quaternary,
          },
          '&:hover': {
            background: 'none',
            'svg, path': {
              fill: theme.palette.buttons.accentPrimary,
            },
          },
          '&:active': {
            background: 'none',
            'svg, path': {
              fill: theme.palette.buttons.accentSecondary,
            },
          },
        },
      },
      paperWidthXs: {
        width: '290px',
      },
    },
  };
  return styleOverrides;
};
