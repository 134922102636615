import { asyncSubscribeOnce } from '~/hooks/useSubscribe';
import { OnOfficeReady } from '~/taskpane';

const doInitUser = async () => {
  const { initUser } = await import('~/utilities/analytics/tracking');

  initUser(Office.context?.mailbox?.userProfile);
};

export const TrackerInit = () => {
  asyncSubscribeOnce(OnOfficeReady, doInitUser);
};
