import * as React from 'react';
import Menu from '@mui/material/Menu';
import { Divider, ListItemText, MenuItem, PopoverPosition } from '@mui/material';
import { animated, useTransition } from '@react-spring/web';
import { findMaxArrayLength, findParentWithClass } from '@vendor/utils/misc';
import { ArrowRightMedium, BackMedium } from '../../assets/icons';
import { MenuItemIcon } from '../Menu';

export interface ContextMenuInterface {
  handleClose?: () => void;
  handleContextMenu?: (event: React.MouseEvent, actions: MenuAction[], onClose?: () => void) => void;
  open?: boolean;
}

export const ContextMenuContext = React.createContext<ContextMenuInterface>({});

type OnClose = undefined | (() => void);

export type MenuAction = {
  type?: 'item' | 'divider';
  title?: string;
  render?: React.JSX.Element;
  className?: string;
  Icon?: React.ReactNode;
  shortCut?: React.ReactNode;
  children?: Array<MenuAction>;
};

const AnimatedMenuItem = animated(MenuItem);

const ContextMenu: React.FC<React.PropsWithChildren> = ({ children }) => {
  const onClose = React.useRef<OnClose>(undefined);
  const [menuStack, setMenuStack] = React.useState<MenuAction[][]>([]);
  const [menuItems, setMenuItems] = React.useState<MenuAction[]>([]);
  const [minHeight, setMinHeight] = React.useState<number>(0);
  const [innerClass, setInnerClass] = React.useState<'onTop' | 'onBottom'>('onTop');
  const [anchorPosition, setAnchorPosition] = React.useState<PopoverPosition | undefined>(undefined);
  const [open, setOpen] = React.useState<boolean>(false);

  const handleContextMenu = (event: React.MouseEvent, actions: MenuAction[], onCloseFun?: OnClose) => {
    event.preventDefault();
    const navighationItem = findParentWithClass(event, 'navigationItem', 5);
    const target = (navighationItem || event.target)['getBoundingClientRect']?.();
    const maxMenuHeight = findMaxArrayLength(actions);

    const isTop = window.innerHeight >= maxMenuHeight + event.clientY;
    const elementPostion = isTop ? target.bottom + 10 : target.top - 10;
    const verticalPositions = elementPostion;
    setMenuStack([]);
    setOpen(true);
    setMenuItems(actions);
    setMinHeight(maxMenuHeight);
    setInnerClass(isTop ? 'onTop' : 'onBottom');
    setAnchorPosition(
      isTop
        ? { top: verticalPositions, left: event.clientX }
        : { top: verticalPositions - maxMenuHeight, left: event.clientX }
    );
    if (onCloseFun) onClose.current = onCloseFun;
  };

  const handleClose = () => {
    onClose.current?.();
    onClose.current = undefined;
    setMenuStack([]);
    setMenuItems([]);
    setAnchorPosition(undefined);
    setOpen(false);
  };

  const transitions = useTransition(menuItems, {
    from: () => ({
      opacity: 0,
      transform: `translateX(${menuStack.length ? 5 : -5}0%)`,
      timeFrame: 0.1,
    }),
    enter: { position: 'relative' as any, opacity: 1, transform: `translateX(0px)` },
    leave: () => ({
      position: 'absolute' as any,
      opacity: 0,
      immediate: true,
      transform: `translateX(${menuStack.length ? -5 : 5}0%)`,
    }),
    keys: menuItems.map((item, index) => `${menuStack.length}-${index}`),
    delay: 0,
    timeFrame: 0.1,
  });

  const handleClick = (item: MenuAction, e: React.MouseEvent) => {
    e['contextmenu'] = true;
    if (item.children && item.children.length > 0) {
      setMenuStack([...menuStack, menuItems]);
      setMenuItems(item.children);
    } else {
      setAnchorPosition(undefined);
      setOpen(false);
      onClose.current?.();
    }
  };

  const handleBack = (e: React.MouseEvent) => {
    e['contextmenu'] = true;
    const previousMenu = menuStack.pop();
    if (previousMenu) {
      setMenuItems(previousMenu);
    }
  };

  return (
    <ContextMenuContext.Provider value={{ handleContextMenu, open, handleClose }}>
      {children}
      <Menu
        marginThreshold={null}
        autoFocus={false}
        variant="menu"
        keepMounted={true}
        sx={{ width: '100%' }}
        onClose={handleClose}
        slotProps={{
          paper: { sx: { minHeight } },
        }}
        className={innerClass}
        anchorPosition={anchorPosition}
        anchorOrigin={{ vertical: 0, horizontal: 0 }}
        anchorReference="anchorPosition"
        open={open}
      >
        {menuStack.length > 0 && (
          <>
            <MenuItem onClick={handleBack}>
              <MenuItemIcon>
                <BackMedium />
              </MenuItemIcon>
              <ListItemText>Back</ListItemText>
            </MenuItem>
            <Divider />
          </>
        )}
        {transitions((styles, action) =>
          action.render ? (
            <animated.div onClick={e => handleClick(action, e)} style={styles}>
              {action.render}
            </animated.div>
          ) : action.type === 'item' ? (
            <AnimatedMenuItem style={styles} onClick={e => handleClick(action, e)} className={action.className}>
              {action.Icon ? <MenuItemIcon>{action.Icon}</MenuItemIcon> : null}
              <ListItemText primaryTypographyProps={{ variant: 'H2Medium', noWrap: true }}>{action.title}</ListItemText>
              {action.shortCut ? action.shortCut : null}
              {!!action.children?.length && <ArrowRightMedium />}
            </AnimatedMenuItem>
          ) : (
            <animated.li style={styles}>
              <Divider />
            </animated.li>
          )
        )}
      </Menu>
    </ContextMenuContext.Provider>
  );
};

export default ContextMenu;
