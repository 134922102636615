import { lightOrDark } from '../misc';
import { trackEvent } from './tracking';

export const sidebarInit = async () => {
  const { SharePointSitesRoot, TeamsRoot } = await import('../virtualRoots');
  trackEvent('SidebarInit', {
    registeredSites: SharePointSitesRoot.Settings.value?.registeredItems?.length,
    registeredTeams: TeamsRoot.Settings.value?.registeredItems?.length,
    officeTheme: lightOrDark(Office.context.officeTheme?.bodyBackgroundColor) ? 'Dark' : 'Light',
    officeVersion: Office.context.diagnostics.version,
    officeLanguage: Office.context.displayLanguage.replace(/-.*/, ''),
  });
};
