import { DeleteMedium, MenuActionsWarningColor, ViewItem, XMedium, useYesNoDialog } from '@storybook';
import { useGraphClient } from '@services';
import { strings } from '@vendor/languages';
import { useCallback } from 'react';
import { ItemContainer, ItemData } from '../itemTypes';
import { ActionRendererHookType, BaseDeletActionClass } from './BaseAction';
import { RefreshAction } from './RefreshAction';
import { publishItemListChanged } from './UploadAction';

const useRemoveItemAction: ActionRendererHookType = ({ nodes, handlers }) => {
  const gcl = useGraphClient();
  const node = nodes[0];
  const nData = node.data as ItemData;

  const deleteItem = useCallback(async () => {
    const res = await nData.removeItem(gcl);
    nData.parent && (await RefreshAction.markRefreshStamp(nData.parent, Date.now()));
    publishItemListChanged({
      location: handlers?.getParent(node)?.data as ItemContainer,
      deleted: {
        [nData.apiIdKey]: nData,
      },
    });
    return res;
  }, [nData, gcl, handlers, node]);
  const { YesNoDialog, setIsDialogVisible } = useYesNoDialog({
    blockOutsideClick: false,
    primaryActionCallback: deleteItem,
    texts: strings.lang.deleteDialog,
  });

  const title = strings.lang.removeItem[node.data.type]?.actionTitle || '';
  const icon = node.data.isUserManaged ? XMedium : DeleteMedium;
  const onClick = useCallback(async () => {
    if (node.data.isUserManaged) {
      await deleteItem();
    } else {
      setIsDialogVisible(true);
    }
  }, [deleteItem, node.data.isUserManaged, setIsDialogVisible]);
  return { title, icon, onClick, actionVariant: MenuActionsWarningColor, SideUI: YesNoDialog };
};

export class RemoveItemAction extends BaseDeletActionClass {
  readonly useRenderer = useRemoveItemAction;

  isHandled(nodes: ViewItem<ItemData>[]): boolean {
    return (
      nodes.length == 1 && nodes[0].data.canRemove && !nodes[0].data.isSearchItem && !nodes[0].data.isAdvancedSearchItem
    );
  }
}
