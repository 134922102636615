import React, { useState } from 'react';
import { useTheme } from '@mui/material/styles';
import { Stack } from '../Stack';
import { XSmall } from '../../assets/icons';
import { Button } from '../Button';

export interface Notification {
  id: string;
  url: string;
}

export interface IuseWhatsNewNotification {
  currentNotification: Notification | null;
  isLoading: boolean;
  markNotificationAsSeen: (id: string) => void;
}

interface WhatsNewModalProps {
  useWhatsNewNotifications: () => IuseWhatsNewNotification;
}

const WhatsNewModal = ({ useWhatsNewNotifications }: WhatsNewModalProps) => {
  const [onlyOnce, setOnlyOnce] = useState(true);
  const { currentNotification, markNotificationAsSeen } = useWhatsNewNotifications();
  const theme = useTheme();

  if (!currentNotification) {
    return null;
  }

  const handleClose = () => {
    if (currentNotification) {
      markNotificationAsSeen(currentNotification.id);
    }
    setOnlyOnce(false);
  };

  return (
    <>
      {currentNotification && onlyOnce && (
        <Stack
          className="whatsnew-notification-holder"
          sx={{
            width: '100%',
            height: '100%',
            position: 'fixed',
            zIndex: 1000,
            background: theme.palette.backgrounds.overlay,
          }}
        >
          <Stack
            className="whatsnew-notification"
            sx={{
              position: 'fixed',
              top: '0',
              bottom: '0',
              right: '0',
              left: '0',
              width: '295px',
              height: '505px',
              maxHeight: '90%',
              margin: 'auto',
              zIndex: 1000,
              backgroundColor: theme.palette.backgrounds.primary,
              padding: '0',
              borderRadius: '8px',
              overflowX: 'hidden',
              overflowY: 'auto',
              border: `1px solid ${theme.palette.outlinesAndStrokes.tertiary}`,
            }}
          >
            <Stack direction="row-reverse" sx={{ paddingTop: '2px' }}>
              <Button onClick={handleClose} variant="IconButtons" sx={{ right: '7px', top: '5px' }}>
                <XSmall fontSize={16} />
              </Button>
            </Stack>
            <iframe
              src={currentNotification.url}
              title="Notification"
              style={{
                border: 'none',
                overflowX: 'hidden',
                overflowY: 'auto',
                width: '100%',
                height: '100%',
                borderRadius: '8px',
              }}
            ></iframe>
          </Stack>
        </Stack>
      )}
    </>
  );
};

export default WhatsNewModal;
