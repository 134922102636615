import React from 'react';
import { ViewItem, TeamsMedium } from '@storybook';
import { useGraphClient } from '@services';
import { strings } from '@vendor/languages';
import { ItemData } from '../itemTypes';
import { ActionRendererHookType, BaseReadActionClass } from './BaseAction';
import { shareToTeams } from './ActionsUtils';
import { InsightItem } from '../graphTypes';

const useShareToTeams: ActionRendererHookType = ({ nodes, useSafeCallback }) => {
  const gcl = useGraphClient();
  const data = nodes[0].data;
  const onClick = useSafeCallback(
    async () => {
      await shareToTeams(gcl, data as ItemData);
    },
    [gcl, data],
    false
  );
  const fill = { 'data-nofill': true };
  return {
    title: strings.lang.actionToolTip.shareToTeams,
    icon: <TeamsMedium {...fill} />,
    onClick,
  };
};

export class ShareToTeams extends BaseReadActionClass {
  readonly trackedName = 'ShareToTeams';
  readonly useRenderer = useShareToTeams;

  isHandled(nodes: ViewItem<ItemData>[]): boolean {
    return (
      nodes.length === 1 &&
      nodes[0].data.canOpenInBrowser &&
      !nodes[0].data.isTeamsEntity &&
      nodes[0].id !== 'oneDrive:OneDrive:' &&
      !Boolean((nodes[0].data as InsightItem).isEmailAttachment)
    );
  }
}
