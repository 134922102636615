import React from 'react';
import {
  Box,
  Dialog as MuiDialog,
  DialogActions,
  DialogContent,
  DialogProps as MuiDialogProps,
  DialogTitle,
  TextField,
  styled,
} from '@mui/material';
import { Button, CustomIcon, Stack, Typography, useTheme } from '@harmon.ie-ce/storybook';
import { Transition } from 'react-transition-group';
import { ErrorItemMedium, ErrorMedium, LinkSmall, XSmall } from '../../assets/icons';
const duration = 200; // Duration of the animation

const defaultStyle = {
  transition: `opacity ${duration}ms ease-in-out, max-height ${duration}ms ease-in-out`,
  opacity: 0,
  maxHeight: 0, // Adjust as needed
};

const transitionStyles = {
  entering: { opacity: 1, maxHeight: '200px' }, // Adjust max-height according to your content
  entered: { opacity: 1, maxHeight: '200px' },
  exiting: { opacity: 0, maxHeight: 0 },
  exited: { opacity: 0, maxHeight: 0 },
};
export const CustomErrorItemMedium = styled(ErrorItemMedium)`
  path {
    fill: ${({ theme }) => theme.palette.status.dangerPrimary};
  }
`;

export type NewItemDialogProps = MuiDialogProps & {
  actions: React.ReactNode;
  title: string;
  extensions?: string;
  subTitle: string;
  itemName: string | undefined;
  urlPath?: string;
  handleItemNameChange: (value: string) => void;
  handleUrlPathChange?: (value: string) => void;
  errorMessage?: string;
  twoFields?: boolean;
  secondFieldSubTitle?: string;
  secondFieldTitle?: string;
  urlErrorMessage?: string;
  defaultUrl?: string;
};

export const NewItemDialog = ({
  children,
  actions,
  title,
  subTitle,
  itemName,
  urlPath,
  extensions,
  handleItemNameChange,
  handleUrlPathChange,
  errorMessage,
  twoFields = false,
  secondFieldTitle,
  secondFieldSubTitle,
  urlErrorMessage,
  defaultUrl,
  ...rest
}: NewItemDialogProps) => {
  const theme = useTheme();
  const EndAdornment = CustomIcon(LinkSmall);
  return (
    <MuiDialog {...rest}>
      <DialogTitle direction="row" alignItems="flex-start" component={Stack} className="new-item-dialog-title">
        <Stack direction={'row'} alignItems={'center'}>
          <Box flex={1} pr={2} width={theme.spacing(43)} height={'22px'} marginRight={theme.spacing(2)}>
            <Typography variant="H1Regular">{title}</Typography>
          </Box>
          <Button
            disableRipple
            disableFocusRipple
            variant="IconButtonsDialog"
            onClick={e => rest.onClose?.(e, 'backdropClick')}
          >
            <XSmall fontSize={16} />
          </Button>
        </Stack>
      </DialogTitle>
      <Stack spacing={theme.spacing(1)} ml={theme.spacing(4)} mr={theme.spacing(4)}>
        {twoFields && (
          <TextField
            size="small"
            variant="outlined"
            value={urlPath}
            InputLabelProps={{ shrink: false }}
            error={urlErrorMessage !== undefined}
            fullWidth
            inputProps={{
              autoCapitalize: 'off',
              autoCorrect: 'off',
            }}
            InputProps={{
              endAdornment: <EndAdornment />,
            }}
            placeholder={`${secondFieldSubTitle}*`}
            onChange={e => handleUrlPathChange && handleUrlPathChange(e.target.value)}
            onFocus={() => {
              if (!urlPath) {
                handleUrlPathChange && handleUrlPathChange(defaultUrl || '');
              }
            }}
          />
        )}
        <Transition in={!!urlErrorMessage} timeout={duration}>
          {state => (
            <Box
              style={{
                ...defaultStyle,
                ...transitionStyles[state],
              }}
            >
              <Stack direction="row" spacing={theme.spacing(1)} alignItems={'center'}>
                <ErrorMedium />
                <Typography variant="H3Regular" color={theme.palette.status.dangerPrimary}>
                  {urlErrorMessage}
                </Typography>
              </Stack>
            </Box>
          )}
        </Transition>
        <Stack>
          {twoFields && (
            <Typography variant="H2Medium" sx={{ mt: theme.spacing(3), mb: theme.spacing(1) }}>
              {secondFieldTitle}
            </Typography>
          )}
          <Stack direction={'row'} sx={{ width: '100%' }}>
            <TextField
              size="small"
              variant="outlined"
              sx={{ width: extensions ? '216px' : '100%' }}
              value={itemName}
              InputLabelProps={{ shrink: false }}
              autoFocus={!twoFields}
              error={errorMessage !== undefined}
              inputProps={{
                autoCapitalize: 'off',
                autoCorrect: 'off',
              }}
              placeholder={`${subTitle}*`}
              onChange={e => handleItemNameChange(e.target.value)}
            />

            {extensions && (
              <Typography
                variant="H2Regular"
                sx={{ marginLeft: 1, marginTop: '8px', color: theme.palette.texts.secondary }}
              >
                {extensions}
              </Typography>
            )}
          </Stack>
        </Stack>
        <Transition in={!!errorMessage} timeout={duration}>
          {state => (
            <Box
              style={{
                ...defaultStyle,
                ...transitionStyles[state],
              }}
            >
              <Stack direction="row" spacing={theme.spacing(1)} alignItems={'center'}>
                <ErrorMedium />
                <Typography variant="H3Regular" color={theme.palette.status.dangerPrimary}>
                  {errorMessage}
                </Typography>
              </Stack>
            </Box>
          )}
        </Transition>
      </Stack>
      <DialogContent>{children}</DialogContent>
      <DialogActions>{actions}</DialogActions>
    </MuiDialog>
  );
};
