import { NotificationContext, useYesNoDialog } from '@storybook';
import React, { useCallback, useContext, useMemo, useRef, useState } from 'react';
import Indicator from '@harmon.ie-ce/storybook/src/components/Indicator/Indicator';
import { strings } from '@vendor/languages';
import { ItemContainer, getItemBlob, getItemName, uploadDocumentToSharePoint, UploadItemProps } from '~/utilities';
import { useSafeCallbackType } from '~/hooks/UseSafeCallback';
import { ErrorTokens } from '~/utilities/networkAndCache/ErrorTokens';
import UploadContext, { ProgressData } from './UploadContext';
import { NetworkError, useGraphClient } from '../../../services/src/networkAndCache';

const initialProgressData: ProgressData = {
  dateKey: '',
  showDialog: false,
  activeFileIndex: -1,
  status: 'progress',
  currentProgress: 0,
  fileProgress: [],
  streamFiles: [],
};

export const UploadProvider = (props: any) => {
  const needAbortCurrentRequest = useRef<boolean>(false);
  const [progressData, setProgressData] = useState<ProgressData>(initialProgressData);
  const inProgress = useRef(false);
  const dialogPromiseRef = useRef<{ resolve: (value: boolean) => void; reject: (reason?: any) => void } | null>(null);
  const applyToAll = useRef<boolean>(false);
  const overrideAll = useRef<boolean | null>(null); // null indicates no choice made yet
  const index = useRef<number>(0);
  const hasCheckbox = useRef<boolean>(false);
  const gcl = useGraphClient();
  const { showNotification } = useContext(NotificationContext);
  const savedProgress = useRef<ProgressData>(initialProgressData); // Reference to save progress state
  const currentName = useRef('');
  const onCloseCallback = useCallback(() => {
    needAbortCurrentRequest.current = false;
    setProgressData(data => ({
      ...data,
      showDialog: false,
    }));
  }, []);

  const startProgressIndication = useCallback(
    (fileCount: number, streamFiles?: string[]) => {
      onCloseCallback();
      inProgress.current = true;
      setProgressData(data => ({
        ...data,
        dateKey: new Date().toISOString(),
        showDialog: true,
        streamFiles: streamFiles || [],
        currentProgress: 0,
        fileProgress: [...new Array(fileCount).fill(false)],
        status: 'progress',
      }));
    },
    [onCloseCallback]
  );

  const oneItemStartIndication = useCallback((activeFileIndex: number) => {
    setProgressData(data => ({
      ...data,
      activeFileIndex,
      currentProgress: 0,
    }));
  }, []);

  const oneItemCompletedIndication = useCallback(() => {
    setProgressData(data => ({
      ...data,
      activeFileIndex: -1,
      fileProgress: [...data.fileProgress.slice(0, index.current), true, ...data.fileProgress.slice(index.current + 1)],
    }));
  }, []);

  const successfulProcessIndication = useCallback(() => {
    inProgress.current = false;
    setProgressData(data =>
      data.status === 'progress'
        ? {
            ...data,
            activeFileIndex: -1,
            status: 'success',
          }
        : data
    );
  }, []);

  const errorfulProcessIndication = useCallback((message: string) => {
    inProgress.current = false;
    setProgressData(data =>
      data.status === 'progress'
        ? {
            ...data,
            currentProgress: 0,
            status: 'error',
            activeFileIndex: -1,
            errorMessage: message,
          }
        : data
    );
  }, []);

  const progressRequestHandler = useMemo(() => {
    return {
      updateProgress: (index: number, currentProgress: number) => {
        setProgressData(data => ({
          ...data,
          currentProgress: index === data.activeFileIndex ? currentProgress : data.currentProgress,
        }));
      },
      saveProgressState: () => {
        savedProgress.current = { ...progressData };
      },
      resetProgressState: () => {
        setProgressData(savedProgress.current);
      },
      needToCancel: () => needAbortCurrentRequest.current,
      inProgress: () => inProgress.current,
    };
  }, [progressData]);

  const handleReplace = useCallback(async () => {
    if (applyToAll.current) {
      overrideAll.current = true;
    }
    dialogPromiseRef.current?.resolve(true);
  }, []);

  const handleKeepBoth = useCallback(async () => {
    if (applyToAll.current) {
      overrideAll.current = false;
    }
    dialogPromiseRef.current?.resolve(false);
  }, []);

  const onChecked = () => {
    applyToAll.current = !applyToAll.current;
  };

  const { YesNoDialog, setIsDialogVisible } = useYesNoDialog({
    texts: {
      dialogTitle: strings.lang.replaceDialog.title,
      dialogSubTitle: progressData.streamFiles ? strings.lang.replaceDialog.message({ name: currentName.current }) : '',
      primaryActionText: strings.lang.replaceDialog.replace,
      secondaryActionText: strings.lang.replaceDialog.keepBoth,
      checkboxLabel: strings.lang.replaceDialog.checkbox,
    },
    primaryActionCallback: handleReplace,
    secondryActionCallback: handleKeepBoth,
    onChecked: onChecked,
    hasCheckbox: hasCheckbox.current,
    onCloseAction: handleKeepBoth,
    blockOutsideClick: true,
  });

  const handleSingleFileUpload = useCallback(
    async (
      itemContainer: ItemContainer,
      actionRef: React.MutableRefObject<any>,
      itemProps: UploadItemProps,
      override?: boolean
    ) => {
      try {
        await uploadDocumentToSharePoint(
          gcl,
          itemContainer,
          itemProps.name,
          index.current,
          itemProps.data,
          override,
          progressRequestHandler
        );
        actionRef.current?.push(name);
      } catch (e: any) {
        if (ErrorTokens.itemExist === e.code) {
          progressRequestHandler?.updateProgress(index.current, index.current);
          setIsDialogVisible(true);
          const userResponse = await new Promise<boolean>((resolve, reject) => {
            dialogPromiseRef.current = { resolve, reject };
          });
          setIsDialogVisible(false);
          await handleSingleFileUpload(itemContainer, actionRef, itemProps, userResponse);
        } else throw e;
      }
    },
    [gcl, progressRequestHandler, setIsDialogVisible]
  );

  const handleFileUpload: Parameters<useSafeCallbackType> = [
    async (
      items: File[] | Office.SelectedItemDetails[],
      itemContainer: ItemContainer,
      actionRef: React.MutableRefObject<any>,
      isDragAndDropEmail = false
    ) => {
      const inProgress = progressRequestHandler?.inProgress();
      if (Boolean(inProgress)) {
        showNotification(
          strings.lang.uploadIndicator.unableToUpload,
          undefined,
          undefined,
          'indicator-still-uploading'
        );
        return;
      } else {
        overrideAll.current = null;
        applyToAll.current = false;
      }
      hasCheckbox.current = items.length > 1;
      const names = items.map(item => {
        const name = getItemName(item, isDragAndDropEmail);
        return name;
      });
      startProgressIndication?.(items.length, names);
      for (let i = 0; i < items.length; i++) {
        if (progressRequestHandler?.needToCancel()) {
          return;
        }
        index.current = i;
        const item = items[i];
        oneItemStartIndication?.(i);
        const itemProps = await getItemBlob(item, gcl, isDragAndDropEmail);
        if (itemProps) {
          const replace = overrideAll.current !== null ? overrideAll.current : undefined;
          currentName.current = itemProps.name;
          await handleSingleFileUpload(itemContainer, actionRef, itemProps, replace);
          oneItemCompletedIndication?.();
        } else {
          throw new NetworkError({ code: '0', message: { lang: '', value: 'General error' } });
        }
      }
      successfulProcessIndication?.();
    },
    [
      gcl,
      handleSingleFileUpload,
      oneItemCompletedIndication,
      oneItemStartIndication,
      progressRequestHandler,
      showNotification,
      startProgressIndication,
      successfulProcessIndication,
    ],
    false,
    {},
    undefined,
    errorfulProcessIndication,
  ];

  return (
    <UploadContext.Provider
      value={{
        progressRequestHandler,
        setProgressData,
        oneItemCompletedIndication,
        startProgressIndication,
        oneItemStartIndication,
        errorfulProcessIndication,
        successfulProcessIndication,
        handleFileUpload,
      }}
    >
      {props.children}
      <Indicator
        dateKey={progressData.dateKey}
        streamFiles={progressData.streamFiles}
        status={progressData.status}
        fileProgress={progressData.fileProgress}
        currentProgress={progressData.currentProgress}
        activeFileIndex={progressData.activeFileIndex}
        onComplete={successfulProcessIndication}
        onCancel={() => {
          inProgress.current = false;
          setProgressData(data => ({ ...data, status: 'canceled' }));
          needAbortCurrentRequest.current = true;
        }}
        onClose={onCloseCallback}
        onExited={() => setProgressData(initialProgressData)}
        open={progressData.showDialog}
        isCanceling={progressData.status === 'canceled'}
        errorMessage={progressData.errorMessage}
        currentProgressIndex={index.current + 1}
      />
      {YesNoDialog}
    </UploadContext.Provider>
  );
};
