import { useCallback } from 'react';
import { useGetChildren } from '~/hooks';
import { RefreshAction, StateManager } from '~/utilities';
import { useSubscribe } from '~/hooks/useSubscribe';
import { TreeNodeRenderer } from './GetNodeRenderer';

export const renderWithReload = <T,>(
  stateManager: () => StateManager<T>,
  NextRenderer: TreeNodeRenderer
): TreeNodeRenderer => {
  return props => {
    const { node, handlers } = props;
    const getChildren = useGetChildren();
    useSubscribe(
      stateManager().sKey,
      useCallback(
        async () => RefreshAction.refresh(node, handlers, getChildren, true, 0),
        [getChildren, handlers, node]
      )
    );

    return NextRenderer && NextRenderer(props);
  };
};
