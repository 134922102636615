import { useEffect, useMemo, useState } from 'react';

export function useWindowSize() {
  const container = useMemo(() => window, []);
  const [windowSize, setWindowSize] = useState<{ width: number; height: number }>({ height: 0, width: 0 });
  useEffect(() => {
    function handleResize() {
      if (container && container.addEventListener) {
        setWindowSize({
          width: container.innerWidth,
          height: container.innerHeight,
        });
        container.addEventListener('resize', handleResize);
      }
    }

    handleResize();

    return () => {
      container && container.removeEventListener && container.removeEventListener('resize', handleResize);
    };
  }, [container]);
  return windowSize;
}
