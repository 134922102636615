import { ViewItem, SettingsMedium } from '@storybook';
import { useNavigate } from 'react-router-dom';
import { strings } from '@vendor/languages';
import { ActionRendererHookType, BaseReadActionClass, ItemData } from '~/utilities';

const useGoToSetting: ActionRendererHookType = ({ useCallback }) => {
  const navigate = useNavigate();
  const onClick = useCallback(() => navigate('/settings'), [navigate]);
  return {
    title: strings.lang.allLocatiosToolbar.gotoSettings,
    icon: SettingsMedium,
    onClick,
  };
};

export class GoToSetting extends BaseReadActionClass {
  readonly trackedName = 'GotoSettings';
  readonly useRenderer = useGoToSetting;

  isHandled(_nodes: ViewItem<ItemData>[]): boolean {
    return true;
  }
}
