interface ODataError {
  code: string;
  message: {
    lang: string;
    value: string;
  };
}

export class NetworkError extends Error {
  public readonly code: string;
  public readonly lang: string;
  public readonly value: string;

  constructor(errorObj: ODataError) {
    super(errorObj?.message?.value);
    this.code = errorObj?.code;
    this.lang = errorObj?.message?.lang;
    this.value = errorObj?.message?.value;
  }
}
