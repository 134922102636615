import { BackMedium, ViewItem } from '@storybook';
import { useNavigate } from 'react-router-dom';
import { strings } from '@vendor/languages';
import { ActionRendererHookType, BaseReadActionClass, ItemData, TrackedEventType, isItemContainer } from '~/utilities';

export const useGoHome: ActionRendererHookType = ({ useCallback }) => {
  const navigate = useNavigate();
  const onClick = useCallback(() => navigate(-1), [navigate]);
  return {
    title: strings.lang.toolbarActions.back,
    icon: BackMedium,
    onClick,
  };
};

export class GoBackAction extends BaseReadActionClass {
  readonly trackedName = 'NavigationHistory';
  useRenderer = useGoHome;
  transformEvent({ data, eventName }: TrackedEventType, _ctx?: any): TrackedEventType {
    return {
      eventName,
      data: {
        ...data,
        itemType: 'History Item',
        direction: 'Back',
      },
    } as TrackedEventType;
  }

  isQuickAction() {
    return 'only';
  }

  isHandled(nodes: ViewItem<ItemData>[]): boolean {
    return isItemContainer(nodes[0].data.type);
  }
}
