import { EllipseSelected, Typography } from '@harmon.ie-ce/storybook';
import { FormControlLabel } from '@mui/material';
import React from 'react';
import { Ellipse, SortCheckbox } from './SortMenuAction';

export interface OrderByInfo {
  field: string;
  dir: boolean;
}

export interface SortActiongProps {
  actionName: string;
  itemValue: OrderByInfo;
  onSelect: (value: OrderByInfo) => void;
  orderBy: OrderByInfo;
}

export const SortAction = ({ actionName, itemValue, onSelect, orderBy }: SortActiongProps) => {
  return (
    <FormControlLabel
      sx={{ width: '100%' }}
      label={
        <Typography variant="H2Regular" noWrap>
          {actionName}
        </Typography>
      }
      control={
        <SortCheckbox
          checked={orderBy.field === itemValue.field}
          onClick={() => orderBy.field !== itemValue.field && onSelect(itemValue)}
          value={itemValue.field}
          icon={<Ellipse />}
          checkedIcon={<EllipseSelected />}
        />
      }
    />
  );
};
