import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { OperatedList } from '@storybook';
import { useGraphClient } from '@services';
import { Onboarding, Stack, useTheme } from '@storybook';
import { BaseItemProps, OfficeUserSettingManager, SPSiteProps, SharePointSitesRoot, TeamsRoot } from '~/utilities';

export const onBoardingState = new OfficeUserSettingManager<number>('onBoardingState', () => 1);
export const onBoardingDone = new OfficeUserSettingManager<boolean>('onBoardingDone', () => false);
const OnboardingFlow = () => {
  const navigate = useNavigate();
  const [currentSlide, setCurrentSlide] = useState(onBoardingState.value || 1);
  const gcl = useGraphClient();
  const theme = useTheme();
  useEffect(() => {
    new SharePointSitesRoot();
    new TeamsRoot();
  }, []);

  const onComplete = (sitesItems?: OperatedList[], teamsItems?: OperatedList[]) => {
    SharePointSitesRoot.save(sitesItems as SPSiteProps[] | [], false);
    TeamsRoot.save(teamsItems as BaseItemProps[] | [], false);
    onBoardingDone.value = true;
    navigate('/home');
  };

  const getOnboardingTeams = useCallback(async () => {
    return await TeamsRoot.getOnBoardingTeams(gcl);
  }, [gcl]);
  const getOnboardingSites = useCallback(async () => {
    return await SharePointSitesRoot.getOnBoardingSites(gcl, Date.now());
  }, [gcl]);

  const saveSlideState = useCallback((slide: number) => {
    if (slide > 0 && slide < 7) {
      onBoardingState.value = slide;
      setCurrentSlide(slide);
    } else {
      onBoardingState.value = 0;
      setCurrentSlide(0);
    }
  }, []);

  return (
    <Stack
      sx={{ height: '100%', width: '100%' }}
      className="hidden-scrollbar"
      background={theme.palette.backgrounds.primary}
    >
      <Onboarding
        currentSlide={currentSlide !== undefined ? currentSlide : 1}
        setCurrentSlide={saveSlideState}
        onComplete={onComplete}
        getOnboardingSites={getOnboardingSites}
        getOnboardingTeams={getOnboardingTeams}
      />
    </Stack>
  );
};

export default OnboardingFlow;
