import { Box, styled } from '@mui/material';
import { newItemDecorator } from '../../NewItemDecorator';

const ItemNodeContentIcon = styled(Box)`
  ${({ theme }) => `
  display: flex;
  align-content: center;
  align-items: center;
  flex-direction: row;
  justify-content: center;
  min-width: 20px;
  height: 20px;
  margin-right: ${theme.spacing(1)} !important;
  &+.MuiBox-root {
    width: calc(100% - 25px);
  };
  .navigationItem.isNew:not(.isSelected):not(:hover) & ${newItemDecorator(theme)}
  .isSelected.isNew & ${newItemDecorator(
    theme,
    theme.palette.buttons.accentPrimary,
    theme.palette.texts.onAccentPrimary
  )}
  .navigationItem.isNew:not(.isSelected):hover & ${newItemDecorator(theme, theme.palette.backgrounds.secondary)}
  .navigationItem.isColorable & {
    svg,
    svg path {
      fill: ${theme.palette.texts.primary};
    }
  }
  .navigationItem.isSelected.isColorable &,
  .navigationItem.isSelected.isColorable:hover & {
    svg,
    svg path {
      fill: ${theme.palette.texts.onAccentPrimary};
    }
  }
  .navigationItem.isExpanded.isColorable:not(.isSelected) &{
    svg,
    svg path {
      fill: ${theme.palette.texts.accentPrimary};
    }
  }
  
  .navigationItem.isColorable:not(.isSelected):hover & {
    svg,
    svg path {
      fill: ${theme.palette.texts.accentSecondary};
    }
  }`}
`;

export default ItemNodeContentIcon;
