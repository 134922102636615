import { Theme, Components } from '@mui/material/styles';

export default (theme: Theme) => {
  const styleOverrides: Components['MuiDialogTitle'] = {
    styleOverrides: {
      root: {
        padding: theme.spacing(3, 4, 0, 4),
        '&.new-item-dialog-title': {
          marginBottom: theme.spacing(3),
        },
        '&.user-question-dialog-title': {
          marginBottom: theme.spacing(2),
        },
        '&.error-dialog-title': {
          paddingTop: theme.spacing(3),
          paddingLeft: theme.spacing(3),
          paddingRight: theme.spacing(3),
          paddingBottom: theme.spacing(0),
          marginBottom: theme.spacing(1),
        },
        [' .MuiTypography-root']: {
          fontWeight: '440',
        },
        ['.MuiIconButton-root']: {
          padding: theme.spacing(1),
          paddingLeft: 0,
          marginRight: theme.spacing(-1),
        },
      },
    },
  };
  return styleOverrides;
};
