import { Stack, styled } from '@mui/material';

const TreeLine = styled(Stack)`
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  height: 100%;
  padding-right: 10px;
  padding-left: 12px;
  max-height: 28px;
  opacity: 0;
  svg, path {
    stroke: ${({ theme }) => theme.palette.outlinesAndStrokes.primary};
  },
`;

TreeLine.defaultProps = {
  className: 'tree-line',
};
export default TreeLine;
