import { ArrowSmall, Button, Stack, Typography, useTheme } from '@storybook';
import React from 'react';
import styled from 'styled-components';
import { strings } from '@vendor/languages';
import { useNavigateAction } from '~/utilities/actions/NavigateAction';
import { ItemDataRenderProps } from './GetNodeRenderer';

const CustomArrowSmall = styled(ArrowSmall)`
  margin-top: 2px;
`;

export const RenderViewFiles = ({ node, handlers }: ItemDataRenderProps) => {
  const onClick = useNavigateAction(node, 'Other', handlers);
  const theme = useTheme();
  return (
    <Stack
      direction="row"
      spacing={3}
      alignItems="center"
      sx={{ paddingLeft: 5, cursor: 'pointer', marginBottom: theme.spacing(1) }}
    >
      <Button variant="text" onClick={onClick}>
        <Typography variant="H2Regular" mr={1}>
          {strings.lang.nodeNames.viewAllFiles}
        </Typography>
        <CustomArrowSmall />
      </Button>
    </Stack>
  );
};
