import { strings } from '@vendor/languages';
import React from 'react';
import { SharepointMedium, TeamsMedium } from '../../assets/icons';
import { Typography } from '../Typography';
import { Stack } from '../Stack';

export const SliderHeader = ({ isSharePoint }: { isSharePoint: boolean }) => {
  return (
    <Stack direction={'row'} spacing={2} pt={2} alignItems={'center'} pl={3} mb={4}>
      {isSharePoint ? <SharepointMedium /> : <TeamsMedium />}
      <Typography variant="H2Regular">
        {isSharePoint ? strings.lang.onBoarding.sharePointPageHeader : strings.lang.onBoarding.teamsPageHeader}
      </Typography>
    </Stack>
  );
};
