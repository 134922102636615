import React from 'react';
import { css, styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { SVGComponent, useTheme } from '@harmon.ie-ce/storybook';
import { Stack, StackProps } from '../Stack';

export interface EmtpyStateProps extends StackProps {
  withVisual?: boolean;
  title?: string;
  message?: string;
  images?: { light: SVGComponent; dark: SVGComponent };
  button?: React.ReactNode;
  centredText?: boolean;
  stackmargin?: number;
  nobackground?: boolean;
  isSearchItem?: boolean;
  inTree?: boolean;
  nestingLevel?: number;
  isEmptyFolder?: boolean;
}

const Container = styled(Stack)`
  text-align: center;
`;

export const Actions = ({ children, ...rest }: StackProps) => {
  return (
    <Stack direction="row" justifyContent="center" alignItems="center" sx={{ padding: 4, flex: ' 0 0 auto' }} {...rest}>
      {children}
    </Stack>
  );
};

interface EmptyStateContainerProps extends StackProps {
  nobackground?: boolean;
  stackmargin: number;
  inTree?: boolean;
  nestingLevel: number;
  isSearchItem?: boolean; // For search empty state set paddingY to 10px
  isEmptyFolder?: boolean;
}

const notForwardedProps: { [key in keyof EmptyStateContainerProps]?: boolean } = {
  nobackground: true,
  stackmargin: true,
  nestingLevel: true,
  isSearchItem: true,
  isEmptyFolder: true,
  inTree: true,
};
// TODO:
const EmptyStateContainer = styled(Stack, {
  shouldForwardProp: prop => notForwardedProps[prop] !== true,
})<EmptyStateContainerProps>`
  ${({ theme, nobackground, stackmargin, isSearchItem, inTree, nestingLevel, isEmptyFolder }) => css`
    width: 100%;
    max-width: ${inTree ? `calc(100vw - ${stackmargin * 5}px - ${nestingLevel * 20}px)` : '100%'};
    flex-direction: column;
    align-items: center;
    border-radius: 8px;
    border: 1px dashed ${isEmptyFolder ? theme.palette.outlinesAndStrokes.secondary : 'transparent'};
    margin-left: ${isEmptyFolder ? '2px' : 'none'};
    padding: ${isSearchItem ? theme.spacing(2, 2) : theme.spacing(4, 4)};
    margin-top: ${inTree ? theme.spacing(2) : ''};
    margin-bottom: ${inTree && !isSearchItem ? theme.spacing(2) : ''};
    color: ${theme.palette.texts.tertiary};
    background-color: ${nobackground ? 'transparent' : theme.palette.backgrounds.emptyStateBackground};
  `}
`;

const EmptyState: React.FC<EmtpyStateProps> = ({
  nestingLevel = 1,
  stackmargin = 4,
  centredText,
  title,
  message,
  images,
  button,
  inTree,
  nobackground = false,
  isSearchItem = false,
  isEmptyFolder,
  ...rest
}) => {
  const theme = useTheme();
  return (
    <Stack sx={{ height: '100%', width: '98%' }} mr={stackmargin}>
      <EmptyStateContainer
        inTree={inTree}
        nestingLevel={nestingLevel}
        spacing={3}
        nobackground={nobackground}
        stackmargin={stackmargin}
        isSearchItem={isSearchItem}
        isEmptyFolder={isEmptyFolder}
        {...rest}
      >
        {images && theme.palette.mode === 'light' ? <images.light /> : images && <images.dark />}
        <Stack>
          {Boolean(title) && (
            <Typography
              sx={{
                textAlign: centredText ? 'center' : undefined,
                marginX: theme.spacing(3),
              }}
              variant="H3SemiBold"
            >
              {title}
            </Typography>
          )}
          {Boolean(message) && (
            <Typography
              variant="H3Regular"
              sx={{
                whiteSpace: 'pre-line',
                textAlign: centredText ? 'center' : undefined,
                maxWidth: '230px',
              }}
            >
              {message}
            </Typography>
          )}
        </Stack>
        {button && <Container>{button}</Container>}
      </EmptyStateContainer>
    </Stack>
  );
};

export default EmptyState;
