import { UIEventHandler } from 'react';

export const MIN_THUMB_SIZE = 50;

export const calculateThumbPosition = (
  currentScroll: number,
  maxScroll: number,
  scrollWrapSize: number,
  thumbSize = MIN_THUMB_SIZE
) => {
  const maxThumbPosition = scrollWrapSize - thumbSize;
  const ratio = currentScroll / maxScroll;

  return Math.max(0, Math.min(Math.round(ratio * maxThumbPosition), maxThumbPosition));
};

export interface ScrollbarsProps {
  onScroll?: UIEventHandler<HTMLDivElement>;
  noHorizontal?: boolean;
  treeStartDepth?: number;
  style?: React.CSSProperties;
  withThrottling?: boolean;
  horizontalOffset?: number;
  verticalOffset?: number;
  showVertical?: boolean;
  showDebugger?: boolean;
  className?: string;
}

export type ScrollShot = {
  containerScroll?: number;
  lastScroll?: number;
  type?: 'horizontal' | 'vertical';
};

export interface ScrollBarContextInterface {
  resizeObserver: ResizeObserver | null;
  scrollTo: (element?: HTMLElement) => null;
}
