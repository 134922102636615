import { keyBy as _keyBy, uniqBy as _uniqBy, isEqual as _isEqual } from 'lodash';
import { TeamsEmptyStateDark, TeamsEmptyStateLight, TeamsMedium } from '@storybook';
import { Team as GTeam, NullableOption } from '@microsoft/microsoft-graph-types';
import {
  TeamExpirationWindow,
  GValue,
  GraphClient,
  GraphBatchRequest,
  EternalWindow,
  GraphWebUrlKeys,
  GraphTeamKeys,
  GBaseItem,
} from '@services';
import { oDirname, getSortByName, logError } from '@vendor/utils/misc';
import { strings } from '@vendor/languages';
import { Team, TeamProps } from '../graphTypes';
import {
  BaseContainer,
  FetchChildrenProps,
  EmptyStateItem,
  IconProps,
  ItemDataWithPaging,
  BaseItemProps,
  ItemData,
} from '../itemTypes';
import { TeamChatFiles } from '../graphTypes';
import { LocalStateManager } from '../stateManager';
import { createItemDataFromJson } from '../misc';
import { ManageTeamsAction } from '../actions/ManageTeamsAction';
import { OneDriveRoot } from './OneDriveRoot';
import { SharePointSitesRoot } from './SharePointRoot';
import { RootSectionManager } from './RootSectionManager';
import { SharePointClient } from '../sharePointAPI';
import { publishItemListChanged } from '../actions';

export class TeamsRoot extends BaseContainer {
  private static _instance: TeamsRoot;
  static Settings = new RootSectionManager<TeamProps>('TeamsSettings', () => null);
  static AllTeams = new LocalStateManager<TeamProps[] | null>('AllTeams', () => null);
  static NewChildren: boolean;
  static timeStamp: number;
  constructor() {
    super({ type: 'teamsroot', id: 'root', name: strings.lang.nodeNames.teams });
    this.rootPrefix = 'teams:';
    TeamsRoot.timeStamp = Date.now();
  }

  get isRootSection(): boolean {
    return true;
  }
  get supportsSortDirection(): boolean {
    return true;
  }

  override get canReorder(): boolean {
    return true;
  }

  override getNumberOfQuickActions() {
    return 1;
  }

  get locationHaveQuickSearch(): boolean {
    return false;
  }

  static get instance() {
    if (this._instance === undefined) this._instance = new TeamsRoot();
    return this._instance;
  }

  static readonly allTeamLocations = {
    byLibraryUrl: new Map<NullableOption<string> | undefined, TeamProps>(),
    bySiteUrl: new Map<string, TeamProps>(),
    byId: new Map<string, TeamProps>(),
  };

  static save(newState: BaseItemProps[], hasNew: boolean) {
    TeamsRoot.Settings.update(newState, hasNew);
    TeamsRoot.NewChildren = hasNew;
    hasNew &&
      newState.map(item => {
        if (item.isNew) {
          const team = new Team(item) as ItemData;
          publishItemListChanged({ added: { [team.apiIdKey]: team }, location: this._instance });
        }
      });
  }

  override get hasNewChildren(): boolean {
    return TeamsRoot.NewChildren;
  }

  override resetNewItems(isOpened: boolean) {
    if (isOpened) {
      TeamsRoot.Settings.resetNewItems();
      TeamsRoot.timeStamp = Date.now();
    }
    TeamsRoot.NewChildren = false;
  }

  static async collectAllTeamsLocations(gcl: GraphClient, refreshStamp?: number) {
    const teams = await this.getAllTeams(gcl, refreshStamp);
    const teamLibraryFutures: (() => Promise<GBaseItem>)[] = [];

    let gb = new GraphBatchRequest(gcl.client);
    for (const team of teams) {
      if (!gb.canAddRequests) {
        await gb.runAll();
        gb = new GraphBatchRequest(gcl.client);
      }
      teamLibraryFutures.push(
        await gb.api(`/groups/${team.id}/drive`).cache(EternalWindow).select(GraphWebUrlKeys).get()
      );
    }
    await gb.runAll();
    this.allTeamLocations.bySiteUrl.clear();
    this.allTeamLocations.byLibraryUrl.clear();
    this.allTeamLocations.byId.clear();
    for (let i = 0; i < teams.length; i++) {
      try {
        const libraryUrl = await teamLibraryFutures[i]();
        this.allTeamLocations.byLibraryUrl.set(libraryUrl.webUrl, teams[i]);
        this.allTeamLocations.bySiteUrl.set(oDirname(libraryUrl.webUrl), teams[i]);
        this.allTeamLocations.byId.set(teams[i].id, teams[i]);
      } catch (e) {
        logError(e);
      }
    }
    return this.allTeamLocations;
  }

  static async getAllTeams(gcl: GraphClient, refreshStamp?: number): Promise<TeamProps[]> {
    try {
      const items: GValue<GTeam> = await gcl
        .api('/me/joinedTeams')
        .cache(TeamExpirationWindow, refreshStamp)
        .select(GraphTeamKeys)
        .get();
      const res: TeamProps[] = items.value.map(team =>
        new Team({
          name: team.displayName || '',
          id: team.id || '',
          description: team.description,
        }).toJson()
      );
      TeamsRoot.AllTeams.value = res;
      return res;
    } catch (e) {
      logError(e);
      return [];
    }
  }

  override get sortDir() {
    return TeamsRoot.Settings?.value?.sortDir || false;
  }

  override reorderItems(): void {
    TeamsRoot.NewChildren = false;
    TeamsRoot.timeStamp = Date.now();
    TeamsRoot.Settings.reorderItems();
  }

  static async getOnBoardingTeams(gcl: GraphClient, refreshStamp?: number): Promise<TeamProps[]> {
    // Prepare for frequent sites call.
    const frequentRoot = (await OneDriveRoot.getRootLibrary(gcl)).rootSite;
    const spc = new SharePointClient(gcl, frequentRoot);

    const joinTeams = await TeamsRoot.getAllTeams(gcl);
    // We run in parallel - followed sites, frequent sites & fetching all teams' urls.
    const [knownTeams, followed, frequent] = await Promise.all([
      TeamsRoot.collectAllTeamsLocations(gcl),
      SharePointSitesRoot.getFollowedSites(gcl, refreshStamp, 25),
      SharePointSitesRoot.getFrequentSites(spc, refreshStamp, 25),
    ]);
    const frequentsTeams = [
      ...followed.slice(0, hrmProvisioning.followedSitesCount),
      ...frequent.slice(0, hrmProvisioning.frequentsSitesCount),
    ]
      .map(s => knownTeams.bySiteUrl.get(s.site))
      .filter(t => t)
      .map(ti => ({
        id: ti?.id || '',
        name: ti?.name || '',
        type: 'team',
        description: ti?.description,
      }));
    return _uniqBy([...frequentsTeams, ...joinTeams], 'id').slice(0, 25);
  }

  static async getRegisteredTeams(gcl: GraphClient, refreshStamp?: number) {
    if (TeamsRoot.Settings.value === null) {
      const initialValue = await this.getOnBoardingTeams(gcl, refreshStamp);
      this.Settings.value = {
        registeredItems: initialValue.sort(getSortByName(true)),
        sortDir: true,
      };
    }
    return TeamsRoot.Settings.registeredItems;
  }

  override async fetchChildren({ gcl, refreshStamp }: FetchChildrenProps): Promise<ItemDataWithPaging> {
    if (refreshStamp) {
      if (TeamsRoot.timeStamp < refreshStamp) {
        TeamsRoot.timeStamp = refreshStamp;
        this.resetNewItems(true);
      }
      const allTeams = await TeamsRoot.getAllTeams(gcl, refreshStamp);
      // Refresh registrations - handle deleted teams & renamed teams.
      const keyedTeams = _keyBy(allTeams, 'id');
      const newValue = (await TeamsRoot.getRegisteredTeams(gcl))
        .map(v => {
          if (v.timeStamp && v.timeStamp > TeamsRoot.timeStamp) {
            keyedTeams[v.id].isNew = true;
            keyedTeams[v.id].timeStamp = v.timeStamp;
          }
          return keyedTeams[v.id];
        })
        .filter(v => v);
      if (!_isEqual(newValue, TeamsRoot.Settings.registeredItems))
        TeamsRoot.Settings.value = {
          registeredItems: newValue,
          sortDir: this.sortDir,
        };
    }

    const items = (await TeamsRoot.getRegisteredTeams(gcl)).map(t => {
      const item = createItemDataFromJson(t) as BaseContainer;
      item.isNew = t.timeStamp && t.timeStamp > TeamsRoot.timeStamp ? true : false;
      item.timeStamp = t.timeStamp;
      return item;
    });

    const registeredTeams = new EmptyStateItem({
      name: 'teams',
      inTree: true,
      images: { light: TeamsEmptyStateLight, dark: TeamsEmptyStateDark },
      action: new ManageTeamsAction(),
      location: { id: this.id, data: this },
    }).apply(items, items.length === 0);
    registeredTeams.unshift(new TeamChatFiles());
    return { items: registeredTeams };
  }

  override getIcon(_expanded: boolean): IconProps {
    return { icon: TeamsMedium, isColorable: false };
  }
}
