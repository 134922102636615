import { ViewItem, RefreshMedium } from '@storybook';
import { useLocation, useNavigate } from 'react-router-dom';
import { strings } from '@vendor/languages';
import { ActionRendererHookType, BaseReadActionClass, ItemContainer, ItemData } from '~/utilities';
import { DrilldownState, navigateDrilldown } from '~/components/Tree/Renderers/DrilldownHandler';

const useLocationRefreshAction: ActionRendererHookType = ({ nodes, useCallback }) => {
  const location = nodes[0].data as ItemContainer;
  const { state } = useLocation() as DrilldownState;
  const navigate = useNavigate();
  const onClick = useCallback(() => {
    navigateDrilldown(navigate, location, true, state.orderBy, Date.now());
  }, [location, navigate, state.orderBy]);
  return {
    title: strings.lang.actionToolTip.refresh,
    icon: RefreshMedium,
    onClick,
  };
};

export class LocationRefreshAction extends BaseReadActionClass {
  readonly trackedName = 'RefreshItem';
  readonly useRenderer = useLocationRefreshAction;

  isQuickAction(data: ItemData) {
    return data.isRootSection ? 'only' : undefined;
  }

  isHandled(nodes: ViewItem<ItemData>[]): boolean {
    return nodes[0].data.canRefresh;
  }
}
