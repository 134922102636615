import React, { useMemo, useState } from 'react';
import { AttachmentSmall, CustomIcon, NavigationItem } from '@storybook';
import { Channel, countParents, FavoritesRoot, InsightItem, QuickActionManager } from '~/utilities';
import { ItemDataRenderer, ItemDataRenderProps } from './GetNodeRenderer';

const EmptyDiv = ({ children }: any) => <>{children}</>;

export const RenderViewItem: ItemDataRenderer = ({
  node,
  handlers,
  className,
  RenderTooltip,
  ItemPrefix,
  onSelect,
  onDoubleClick,
  highlightString,
  secondLine,
  only3DotsAction,
}: ItemDataRenderProps) => {
  const { data } = node;
  const [showTooltip, setShowTooltip] = useState(true);

  RenderTooltip = (showTooltip && RenderTooltip) || EmptyDiv;
  ItemPrefix = ItemPrefix || EmptyDiv;

  const isSelected = handlers.isSelected(node);
  const isExpanded = handlers.isExpanded(node);
  const isNew = data.isNew || (data.hasNewChildren && !handlers.isExpanded(node));
  const isEmailAttachment = Boolean((data as InsightItem).isEmailAttachment);
  const hasBreadcrumbs =
    data.isTransientLocation && (data.pathOrDescription !== null || isEmailAttachment) && data.type !== 'error';
  const isFavorite = FavoritesRoot.AllFavorites.isFavorite(data);
  const Icon = data.getIcon(isExpanded)?.icon;
  const isErrorItem = data.type === 'error';
  const nestingLevel = useMemo(() => {
    if (node?.data.isVirtualItem) return 0.71;
    const isSearchItem = node?.data.isSearchItem;
    return isSearchItem ? -1 : countParents(node) || 0;
  }, [node]);
  const AttachmentIcon = CustomIcon(AttachmentSmall, true);
  return (
    <RenderTooltip data={data}>
      <NavigationItem
        nestingLevel={nestingLevel}
        itemColorable={data.itemColorable}
        className={className || ''}
        onDoubleClick={onDoubleClick}
        onClick={onSelect}
        isNew={isNew}
        isSelected={isSelected}
        isExpanded={isExpanded}
        isTeamsRoot={data.type === 'teamsroot'}
        isAdvancedSearchItem={data.isAdvancedSearchItem}
        isRootSection={data.isRootSection}
        isColorable={data.getIcon(false)?.isColorable}
        name={data.name}
        isErrorItem={isErrorItem}
        Icon={Icon}
        hasBreadcrumbs={hasBreadcrumbs}
        itemBreadcrumbs={isEmailAttachment ? <AttachmentIcon /> : data.pathOrDescription || ''}
        ItemPrefix={ItemPrefix}
        secondLine={secondLine}
        highlightString={highlightString}
        summary={data.summary}
        onMouseOverSecondLine={() => setShowTooltip(false)}
        onMouseLeaveSecondLine={() => setShowTooltip(true)}
        size={data.size}
        highLightSecondLine={data instanceof Channel}
      >
        <QuickActionManager
          only3DotsAction={only3DotsAction}
          node={node}
          handlers={handlers}
          onMouseOver={() => setShowTooltip(false)}
          onMouseLeave={() => setShowTooltip(true)}
          isFavoriteItem={isFavorite}
        />
      </NavigationItem>
    </RenderTooltip>
  );
};
