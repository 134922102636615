import { useYesNoDialog } from '@storybook';
import { strings } from '@vendor/languages';
import { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { signOutdialogFallback } from '~/utilities';
import { SearchHistoryState } from './SettingContext';

export const useSettingsController = (resetSetting?: () => void) => {
  const [dialogType, setDialogType] = useState<'resetDialog' | 'clearDialog' | 'signoutDialog'>('resetDialog');
  const navigate = useNavigate();
  const afterSignOut = useCallback(async () => {
    navigate('/login');
  }, [navigate]);

  const callback = useCallback(() => {
    signOutdialogFallback(afterSignOut);
  }, [afterSignOut]);

  const actions = {
    signoutDialog: () => signOutdialogFallback(afterSignOut),
    resetDialog: () => resetSetting?.(),
    clearDialog: () => SearchHistoryState.reset(true),
  };

  const { YesNoDialog, setIsDialogVisible } = useYesNoDialog({
    texts: strings.lang.userSetting[dialogType],
    blockOutsideClick: false,
    isWarningAction: true,
    primaryActionCallback: actions[dialogType],
  });

  const showDialog = useCallback(
    (type: 'resetDialog' | 'clearDialog' | 'signoutDialog') => {
      setDialogType(type);
      setIsDialogVisible(true);
    },
    [setIsDialogVisible]
  );

  return {
    YesNoDialog,
    setIsDialogVisible,
    callback,
    showDialog,
  };
};
