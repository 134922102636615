import { Theme, Components } from '@mui/material/styles';

export default (theme: Theme) => {
  const styleOverrides: Components['MuiPopover'] = {
    styleOverrides: {
      root: {},
      paper: {
        '&.search-popover': {
          backgroundColor: theme.palette.backgrounds.primary,
          backgroundImage: 'none',
        },
        borderRadius: theme.spacing(2),
        marginTop: theme.spacing(0),
        ['.AnimationfadeInScaleUp &']: {
          boxShadow: `1px 2px 5px 0px ${theme.palette.shadow.menu}, 0px 5px 30px 0px ${theme.palette.shadow.menu}`,
        },
      },
    },
  };
  return styleOverrides;
};
