import React from 'react';
import { StackBaseProps } from '@mui/system';
import Stack from '@mui/system/Stack/Stack';
import { Button, ButtonProps } from '../Button';
export interface ButtonsStackInterface extends StackBaseProps {
  buttons: ButtonProps[];
}

const ButtonsStack = ({ buttons, ...props }: ButtonsStackInterface) => {
  return buttons.length ? (
    <Stack justifyContent="flex-end" {...props} direction="row" alignItems="center">
      {buttons.map(({ children, ...props }, key) => (
        <Button key={`button-${key}`} {...props}>
          {children}
        </Button>
      ))}
    </Stack>
  ) : null;
};

ButtonsStack.defaultProps = {
  spacing: 2,
};

export default ButtonsStack;
