import React, { useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import { useGraphClient } from '@services';
import { RefreshAction } from '~/utilities';
import { SPListView } from '~/utilities/sharePointTypes/SPListViewFactory';
import { DrilldownState } from '~/components/Tree/Renderers/DrilldownHandler';
import { SPCFolderKeys } from '~/utilities/sharePointAPI';
import Drilldown from './Drilldown';

const FolderDrilldown = () => {
  const {
    state: { location, orderBy, refreshStamp, newItems },
  } = useLocation() as DrilldownState;
  const theOrderBy = orderBy || SPListView.OrderByName;
  const gcl = useGraphClient();
  const get = useCallback(
    async (token?: string) => {
      const stamp = await RefreshAction.markRefreshStamp(location, refreshStamp);
      const folder = await location.getFolder(gcl);
      const view = new SPListView().combineWithFields(SPCFolderKeys).combineWithOrderBy(theOrderBy);
      return await folder.getItemsFromView(gcl, view, 50, stamp, token);
    },
    [location, gcl, theOrderBy, refreshStamp]
  );
  return (
    <Drilldown key={location.apiIdKey} newItems={newItems} location={location} getItems={get} orderBy={theOrderBy} />
  );
};

export default FolderDrilldown;
