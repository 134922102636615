import React, { FC, useCallback } from 'react';
import { QuickSearch as QuickSearchComponent, Stack, ViewItem } from '@storybook';
import { strings } from '@vendor/languages';
import { ItemContainer, ItemData } from '~/utilities';
import { SearchProps } from '~/utilities/search';
import { getSearchViewItemRenderer } from '../Tree/Renderers/GetNodeRenderer';
import { useQuickSearch } from '../Tree/Renderers/QuickSearchHandler';

const QuickSearch: FC<{
  location: ItemContainer;
  placeholder?: string;
  disabled?: boolean;
  searchProps?: SearchProps;
}> = ({ location, placeholder, searchProps: initProps }) => {
  const {
    handleSearchResultClick,
    listHandlers,
    isSearchInit,
    isLoaading,
    onSearchApply,
    searchState,
    filtersUI,
    findItems,
    historyState,
    resetState,
  } = useQuickSearch(initProps, location);

  const render = useCallback(
    (node: ViewItem<ItemData>) => {
      node.data.itemColorable = false;
      node.data.additionalActionHandler = handleSearchResultClick;
      return getSearchViewItemRenderer(node.data.type)?.({
        node,
        handlers: listHandlers,
        onSelect: handleSearchResultClick,
        onToggle: () => undefined,
        highlightString: searchState[0].searchTerm?.replace(/^"(.*)"$/, '$1'),
      });
    },
    [handleSearchResultClick, listHandlers, searchState]
  );

  return (
    <Stack direction="row" width="100%" justifyContent="space-between" p={3}>
      <QuickSearchComponent
        clearTooltipText={strings.lang.quickSearch.clearTooltipText}
        clearFilterText={strings.lang.quickSearch.clearFilterText}
        historyTitle={strings.lang.quickSearch.historyTitle}
        placeholder={placeholder}
        searchHistory={historyState.value}
        render={render}
        onEnter={onSearchApply}
        isSearchInit={isSearchInit}
        isSearching={isLoaading}
        searchResults={findItems}
        onHistoryItemAdd={(e, text) => handleSearchResultClick(e, text)}
        searchState={searchState}
        resetState={resetState}
        filtersUI={filtersUI}
        disabled={location && !location?.supportSearch}
      />
    </Stack>
  );
};

export default QuickSearch;
