import { Theme, Components } from '@mui/material/styles';

export default (theme: Theme) => {
  const styleOverrides: Components['MuiOutlinedInput'] = {
    styleOverrides: {
      root: {
        borderRadius: '8px',
        '&.MuiInputBase-multiline': {
          padding: 0,
        },
        '&:hover': {
          '.MuiOutlinedInput-notchedOutline': {
            borderColor: theme.palette.outlinesAndStrokes.tertiary,
          },
        },
        '&.Mui-focused': {
          '.MuiOutlinedInput-notchedOutline': {
            borderRadius: '8px',
            borderWidth: 1,
            borderColor: theme.palette.outlinesAndStrokes.accent,
            boxShadow: `0px 0px 0px 2px ${theme.palette.status.defaultSecondary}`,
          },
        },
        '&.Mui-disabled': {
          borderColor: theme.palette.buttons.disabled,
          backgroundColor: theme.palette.buttons.disabled,
          color: theme.palette.texts.disabled,
          '.MuiOutlinedInput-notchedOutline': {
            borderColor: theme.palette.buttons.disabled,
          },
        },
      },

      input: {
        padding: '8px 10px',
        borderRadius: '8px',
        ...theme.typography.H2Regular,
      },
    },
  };
  return styleOverrides;
};
