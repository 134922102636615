import React, { MouseEventHandler, useEffect, useMemo, useRef, useState } from 'react';
import classNames from 'classnames';
import { StackProps } from '@mui/system';
import { Tooltip, useTheme } from '@harmon.ie-ce/storybook';
import { strings } from '@vendor/languages';
import { BreadCrumbsLocationsLabel } from '../Breadcrumbs';
import { MouseEvents } from '../MouseEvents';
import ItemNode from './components/ItemNode';
import ItemNodeRoot from './components/ItemNodeRoot';
import ItemNodeContainer from './components/ItemNodeContainer';
import ItemNodeContentIcon from './components/ItemNodeContentIcon';
import { SecondLine } from '../ContainerTypes';
import Stack from '../Stack/Stack';
import ItemNodeWrap from './components/ItemNodeWrap';
import HighlightedTypography, {
  HighlightedSummary,
  doesHighlightExist,
} from '../HighlightedTypography/HighlightedTypography';

interface NavigationItemProps extends StackProps {
  name: string;
  ItemPrefix?: React.FC;
  itemBreadcrumbs?: string | React.ReactNode;
  isSelected?: boolean;
  isNew?: boolean;
  isExpanded?: boolean;
  isColorable?: boolean;
  Icon?: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  hasBreadcrumbs?: boolean;
  onDoubleClick?: MouseEventHandler<HTMLDivElement>;
  onClick?: MouseEventHandler<HTMLDivElement>;
  className?: string;
  isTeamsRoot?: boolean;
  isErrorItem?: boolean;
  highlightString?: string;
  secondLine?: SecondLine;
  isRootSection?: boolean;
  itemColorable: boolean;
  onMouseOverSecondLine?: MouseEventHandler | undefined;
  onMouseLeaveSecondLine?: MouseEventHandler | undefined;
  nestingLevel: number;
  isAdvancedSearchItem?: boolean;
  summary?: string;
  size?: number;
  highLightSecondLine?: boolean;
}

export const NavigationItem: React.FunctionComponent<React.PropsWithChildren<NavigationItemProps>> = ({
  onDoubleClick,
  className,
  onClick,
  name,
  itemBreadcrumbs,
  isNew,
  isSelected,
  isExpanded,
  isErrorItem,
  isColorable,
  hasBreadcrumbs,
  children,
  Icon,
  ItemPrefix,
  highlightString,
  secondLine,
  isTeamsRoot,
  isRootSection,
  nestingLevel,
  itemColorable,
  onMouseOverSecondLine,
  onMouseLeaveSecondLine,
  isAdvancedSearchItem,
  summary,
  size,
  highLightSecondLine = false,
  ...props
}) => {
  const theme = useTheme();
  const itemRef = useRef<HTMLDivElement | null>(null);
  const [showTooltip, setShowTooltip] = useState(false);
  const handleMouseEnter = () => {
    onMouseLeaveSecondLine;
    setShowTooltip(true);
  };

  const handleMouseLeave = () => {
    onMouseOverSecondLine;
    setShowTooltip(false);
  };
  const styledClass = classNames(
    'navigationItem',
    className,
    { secondLine: Boolean(secondLine) },
    { isSelected },
    { isExpanded },
    { isColorable },
    { isNew },
    { isRootSection },
    { isErrorItem },
    { isTeamsRoot },
    { isAdvancedSearchItem: Boolean(isAdvancedSearchItem) },
    { itemColorable }
  );

  useEffect(() => {
    let observer: IntersectionObserver;
    if (isRootSection && itemRef.current) {
      const el = itemRef.current;
      const parent = el?.parentElement?.parentElement;
      if (parent) {
        parent.style.position = 'sticky';
        parent.style.top = isTeamsRoot ? '-16px' : '-3px';
        parent.style.zIndex = '10';
      }

      observer = new IntersectionObserver(
        ([e]) =>
          e.target?.parentElement?.parentElement?.classList.toggle('isPinned', e.intersectionRatio < 1 && isExpanded),
        {
          threshold: [1],
        }
      );
      observer.observe(el);
    }

    return () => {
      if (isRootSection) {
        observer?.disconnect?.();
      }
    };
  }, [isRootSection, isExpanded, isTeamsRoot]);

  const needToHighlightBreadcrumbs = useMemo(
    () => highlightString && !doesHighlightExist(name, highlightString),
    [highlightString, name]
  );
  return (
    <MouseEvents>
      <ItemNodeRoot
        nestingLevel={nestingLevel}
        ref={itemRef}
        className={styledClass}
        onClick={onClick}
        onContextMenu={onClick}
        onDoubleClick={onDoubleClick}
        maxHeight={size !== undefined ? size : Boolean(secondLine) ? '55px' : '28px'}
        flexWrap={Boolean(secondLine) ? 'wrap' : 'nowrap'}
        {...props}
      >
        {ItemPrefix && <ItemPrefix />}
        <ItemNodeWrap className={`${styledClass} drag-and-drop`}>
          <ItemNode>
            <ItemNodeContainer width={0}>
              <ItemNodeContentIcon>{Icon && <Icon />}</ItemNodeContentIcon>
              <HighlightedTypography highlight={highlightString} variant="H1Regular">
                {name}
              </HighlightedTypography>
              {hasBreadcrumbs && !secondLine && (
                <BreadCrumbsLocationsLabel
                  highlightString={needToHighlightBreadcrumbs ? highlightString : undefined}
                  itemBreadcrumbs={itemBreadcrumbs}
                  isSelected={!!isSelected}
                />
              )}
            </ItemNodeContainer>
            {children}
          </ItemNode>
          {secondLine && (
            <Stack
              direction={'column'}
              spacing={0.5}
              sx={{ overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}
            >
              {summary && isAdvancedSearchItem && (
                <HighlightedSummary
                  skipHighlight={!needToHighlightBreadcrumbs}
                  paddingLeft={theme.spacing(6)}
                  variant="H3Italic"
                >
                  {summary}
                </HighlightedSummary>
              )}
              <ItemNodeContainer
                className={classNames({ onHadle: secondLine.handle, 'disabled-cursor': secondLine.handle === null })}
                onClick={secondLine.handle}
              >
                <Tooltip title={strings.lang.actiosNames.breadcrumbsAction} open={showTooltip}>
                  <Stack onMouseOver={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                    <HighlightedTypography
                      paddingLeft={theme.spacing(6)}
                      highlight={needToHighlightBreadcrumbs ? highlightString : undefined}
                      className={highLightSecondLine ? 'underline' : ''}
                      onMouseOver={onMouseOverSecondLine}
                      onMouseLeave={onMouseLeaveSecondLine}
                      variant="H3Regular"
                    >
                      {secondLine.text}
                    </HighlightedTypography>
                  </Stack>
                </Tooltip>
              </ItemNodeContainer>
            </Stack>
          )}
        </ItemNodeWrap>
      </ItemNodeRoot>
    </MouseEvents>
  );
};

export default NavigationItem;
