import { SharedInsight } from '@microsoft/microsoft-graph-types';
import { ChatMedium } from '@storybook';
import { GValue, RecentExpirationWindow } from '@services';
import { strings } from '@vendor/languages';
import { ChatsCountState } from '~/modules/Settings/SettingContext';
import { BaseContainer, EmptyStateItem, FetchChildrenProps, IconProps, ItemDataWithPaging } from '../itemTypes';
import { InsightItem } from './InsightItem';
import { OfficeUserSettingManager } from '../stateManager';
import { ViewFilesItem } from '../itemTypes/ViewFilesItem';

// Allows sorting elements with 'date' or 'savedDate' properties.
interface WithDate {
  date?: string;
  savedDate?: string;
}
export const getSortByDate = () => {
  const collator = new Intl.Collator();
  return (a: WithDate, b: WithDate) => collator.compare(b.date || '', a.date || '');
};

export class TeamChatFiles extends BaseContainer {
  static ChatsCountState = ChatsCountState;
  constructor() {
    super({
      id: 'TeamsChatFiles',
      type: 'teamschatfiles',
      name: strings.lang.nodeNames.teamsChatFiles,
    });
  }

  get stateCount(): OfficeUserSettingManager<number> {
    return TeamChatFiles.ChatsCountState;
  }
  get isVirtual(): boolean {
    return true;
  }
  get canOpenInBrowser() {
    return false;
  }

  override get supportSearch(): boolean {
    return false;
  }

  override get fetchChildrenCount(): number {
    return 100;
  }

  override getNumberOfQuickActions(): number {
    return 1;
  }

  async fetchChildren(props: FetchChildrenProps): Promise<ItemDataWithPaging> {
    const { refreshStamp, itemCount, gcl, isDrilldown } = props;
    const account = gcl.msal.getActiveAccount() || gcl.msal.getAllAccounts()[0];
    const sharedChat: GValue<SharedInsight> = await gcl
      .api('/me/insights/shared')
      .cache(RecentExpirationWindow, refreshStamp)
      .top(itemCount || 25)
      .filter(
        "(resourceReference/type eq 'microsoft.graph.driveItem') AND (contains(resourceVisualization/containerWebUrl, '/Microsoft Teams Chat Files') OR (lastShared/sharingType eq 'Attachment') OR (resourceVisualization/type eq 'Fluid'))"
      )
      .get();
    const documents = sharedChat.value
      .map(v => InsightItem.fromInsight(v, account, true))
      .sort(getSortByDate())
      .slice(0, itemCount || TeamChatFiles.ChatsCountState.value);
    return {
      items: new EmptyStateItem({ name: 'chatsAndMeetings', nestingLevel: 2, componentProps: { mb: '2px' } }).apply(
        new ViewFilesItem({ name: strings.lang.nodeNames.viewAllFiles, parent: this }).apply(
          documents,
          itemCount || TeamChatFiles.ChatsCountState.value,
          !isDrilldown
        ),
        documents.length === 0
      ),
    };
  }

  override getIcon(_expanded: boolean): IconProps {
    return { icon: ChatMedium, isColorable: true };
  }
}
