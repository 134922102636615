import { setLanguage } from '@vendor/languages';
import { asyncSubscribeOnce } from '~/hooks/useSubscribe';
import { OnOfficeReady } from '~/taskpane';

const onOfficeReady = async () => {
  const { initOfficeMode, setupOfficeItemSelectionChangedListeners } = await import('~/misc');
  setLanguage(Office.context.displayLanguage);
  initOfficeMode();
  setupOfficeItemSelectionChangedListeners();
};

export const OfficeInit = () => {
  asyncSubscribeOnce(OnOfficeReady, onOfficeReady);
};
