import { Stack, styled } from '@mui/material';

const ItemNodeContainer = styled(Stack)`
  flex-direction: row;
  flex: 1;
  align-content: center;
  align-items: center;
  flex-direction: row;
  &.onHadle {
    cursor: pointer;
    > * {
      margin-bottom: 3px;
      max-width: 100%;
    }
  }
  &.disabled-cursor {
    cursor: default;
  }
`;

ItemNodeContainer.defaultProps = {
  spacing: 2,
};

export default ItemNodeContainer;
