import { ViewItem, AttachmentMedium } from '@storybook';
import { useGraphClient } from '@services';
import { strings } from '@vendor/languages';
import { OfficeMode, officeMode } from '~/misc';
import { ItemData } from '../itemTypes';
import { ActionRendererHookType, BaseReadActionClass } from './BaseAction';
import { emailWithAttachment } from './ActionsUtils';

const useEmailWithAttachment: ActionRendererHookType = ({ nodes, useSafeCallback }) => {
  const data = nodes[0].data;
  const gcl = useGraphClient();
  const onClick = useSafeCallback(
    async () => {
      await emailWithAttachment(gcl, data as ItemData);
    },
    [gcl, data],
    false,
    {
      errorTitle: strings.lang.notificationsError.emailWithAttachmentFailedTitle,
      errorMessage: strings.lang.notificationsError.emailWithAttachmentFailedMessage,
      onStartTitle: strings.lang.notifications.emailWithAttachmentStart,
      onSuccessTitle: strings.lang.notifications.emailWithAttachmentDone,
    }
  );
  return {
    title: strings.lang.actionToolTip.addAttachment,
    icon: AttachmentMedium,
    onClick,
  };
};

export class EmailWithAttachment extends BaseReadActionClass {
  readonly trackedName = 'EmailWithAttachment';
  readonly useRenderer = useEmailWithAttachment;

  isHandled(nodes: ViewItem<ItemData>[]): boolean {
    return (
      nodes.length === 1 &&
      nodes[0].data.canOpenInBrowser &&
      nodes[0].data.isDocument &&
      officeMode !== OfficeMode.Read &&
      nodes[0].id !== 'oneDrive:OneDrive:'
    );
  }
}
