import { InsightIdentity } from '@microsoft/microsoft-graph-types';
import { MediaKeysType, UsageType } from '@storybook';
import { GraphClient } from '@services';
import { AccessUrls, ItemData } from './ItemData';

export interface ThumbnailData {
  thumbNail?: string;
  name: string;
}

export interface DocumentAccessUrls extends AccessUrls {
  downloadUrl: string;
}

export interface DownloadData {
  blob: Blob;
  fileName: string;
}

export type SupportedOfficeAppTypes = 'Word' | 'PowerPoint' | 'Project' | 'Excel';

export interface DocumentItem extends ItemData {
  get date(): string;
  get usageType(): UsageType | undefined;
  get user(): InsightIdentity | undefined;
  get hasPreview(): boolean;
  get mediaType(): MediaKeysType;
  get subject(): string | undefined;
  get officeApp(): SupportedOfficeAppTypes | undefined;
  getThumbnail(gcl: GraphClient): Promise<ThumbnailData | undefined>;
  getAccessUrls(gcl: GraphClient): Promise<DocumentAccessUrls>;
  download(gcl: GraphClient): Promise<DownloadData>;
}

export const isOfficeAppDocument = (doc: ItemData) => Boolean(doc.isDocument && (doc as DocumentItem).officeApp);
