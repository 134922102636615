import { getSortByName } from '@vendor/utils/misc';
import { BaseItemProps } from '../itemTypes';
import { OfficeUserSettingManager } from '../stateManager';

interface RootSectionContent<T extends BaseItemProps> {
  registeredItems: T[];
  sortDir: boolean;
}

export class RootSectionManager<
  T extends BaseItemProps,
> extends OfficeUserSettingManager<RootSectionContent<T> | null> {
  remove(id: string) {
    if (this.value) {
      this.value.registeredItems = this.value.registeredItems.map(item => {
        if (item.id.toLowerCase() === id) {
          item.isNew = false;
          item.timeStamp = 0;
        }
        return item;
      });
      const oldValue = this.value;
      this.value = {
        registeredItems: this.value.registeredItems.filter(item => item.id !== id),
        sortDir: this.value.sortDir,
      };
      return {
        run: () => {
          this.value = oldValue;
        },
      };
    }
  }

  update(items: T[], hasNew: boolean) {
    if (this.value) {
      this.value.registeredItems = this.value.registeredItems?.map(item => {
        item.isNew = false;
        item.timeStamp = 0;
        return item;
      });
    }

    this.value = {
      registeredItems: items,
      sortDir: hasNew ? false : this.value ? this.value.sortDir : false,
    };
    this.save();
  }

  resetNewItems(): void {
    this.update(this.registeredItems, false);
  }

  get sortDir() {
    return this.value?.sortDir || true;
  }
  get registeredItems() {
    return this.value?.registeredItems || [];
  }
  reorderItems() {
    if (this.value) {
      const sortDir = !this.value.sortDir;
      this.value = {
        registeredItems: this.value.registeredItems.sort(getSortByName(sortDir)),
        sortDir: sortDir,
      };
    }
  }
}
