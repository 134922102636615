import React, { useContext, useEffect, useRef } from 'react';
import { Stack, styled } from '@mui/system';
import { TextField, Typography, useTheme } from '@mui/material';
import { LottieAnimation } from '../Lottie';
import miniLoader from '../Lottie/Animations/mini-loader/lottie/Loader_loop.json';
import { EmptyState } from '../EmptyState';
import { QuickSearchEmptyStateLight, QuickSearchEmptyStateDark } from '../../assets/images';
import { ShareTargetBodyProps, ShareTargetItem } from './interface';
import HighlightedTypography from '../HighlightedTypography/HighlightedTypography';
import { ScrollbarContext } from '../Scollbar';
import { getAvatarType, ShareAvatar } from './ShareAvatar';

const ItemComponent = styled(Stack)`
  align-items: center;
  outline: 0;
  height: 42px;
  border-radius: 8px;
  padding: ${({ theme }) => theme.spacing(1)};
  cursor: pointer;
  user-select: none;
  flex-direction: row;
  position: relative;
  color: ${({ theme }) => theme.palette.texts.primary};
  width: 100%;
    &.active,
    &:hover {
      color: ${({ theme }) => theme.palette.texts.accentSecondary};
      background-color: ${({ theme }) => theme.palette.backgrounds.secondary};
    }
  }
  & > * {
    pointer-events: none;
  }
`;

const LoadingState = () => (
  <Stack pb={5}>
    <LottieAnimation options={{ loop: true, autoplay: true, animationData: miniLoader }} height={50} width={100} />
  </Stack>
);

const ShareItem: React.FC<ShareTargetItem> = ({ item, onClick, highlightString, isActive }) => {
  const theme = useTheme();
  const componentRef = useRef<HTMLDivElement | null>(null);
  const type = getAvatarType(item);
  const { scrollTo } = useContext(ScrollbarContext);

  useEffect(() => {
    if (isActive && componentRef.current) {
      scrollTo(componentRef.current);
    }
  }, [isActive, scrollTo]);

  return (
    <ItemComponent
      ref={componentRef}
      className={isActive ? 'active' : ''}
      onClick={() => onClick?.(item)}
      direction="row"
      spacing={2}
    >
      <ShareAvatar type={type} avatar={item.avatar} />
      <Stack overflow="hidden" direction={'column'}>
        <HighlightedTypography overflow="hidden" highlight={highlightString} variant="H1Regular">
          {item.name}
        </HighlightedTypography>
        <HighlightedTypography
          overflow="hidden"
          highlight={highlightString}
          variant="H3Regular"
          color={theme.palette.texts.secondary}
        >
          {item.description}
        </HighlightedTypography>
      </Stack>
    </ItemComponent>
  );
};

const RenderedItems: React.FC<ShareTargetBodyProps> = ({
  renderItems,
  highlightString,
  setShareTarget,
  searchStrings,
  focusedItemIndex,
  clearIndex,
}) => (
  <Stack onMouseOver={clearIndex} p={2} alignItems="stretch">
    {!!renderItems?.persons?.length && (
      <>
        <Typography color="texts.secondary" variant="CapsLockRegular" mb={1}>
          {searchStrings.peoples}
        </Typography>
        {renderItems?.persons?.map((item, index) => (
          <ShareItem
            isActive={focusedItemIndex === index}
            highlightString={highlightString}
            onClick={setShareTarget}
            item={item}
            key={index}
          />
        ))}
      </>
    )}
    {!!renderItems?.chatGroups?.length && (
      <>
        <Typography color="texts.secondary" variant="CapsLockRegular" mb={1} mt={1}>
          {searchStrings.chatAndGroups}
        </Typography>
        {renderItems?.chatGroups?.map((item, index) => (
          <ShareItem
            isActive={focusedItemIndex === renderItems?.persons?.length + index}
            highlightString={highlightString}
            onClick={setShareTarget}
            item={item}
            key={index}
          />
        ))}
      </>
    )}
    {!!renderItems?.channelGroups?.length && (
      <>
        <Typography color="texts.secondary" variant="CapsLockRegular" mb={1} mt={1}>
          {searchStrings.channels}
        </Typography>
        {renderItems?.channelGroups?.map((item, index) => (
          <ShareItem
            isActive={focusedItemIndex === renderItems?.chatGroups?.length + renderItems?.persons?.length + index}
            highlightString={highlightString}
            onClick={setShareTarget}
            item={item}
            key={index}
          />
        ))}
      </>
    )}
  </Stack>
);

export const ShareTargetBody: React.FC<ShareTargetBodyProps> = props => {
  const { isSearching, renderItems } = props;
  if (isSearching) {
    return <LoadingState />;
  }
  if (!renderItems) return null;
  if (Object.values(renderItems).every(item => !item.length)) {
    return (
      <EmptyState
        nobackground
        centredText
        message={props.searchStrings.noResults}
        images={{ light: QuickSearchEmptyStateLight, dark: QuickSearchEmptyStateDark }}
      />
    );
  } else {
    return <RenderedItems {...props} />;
  }
};

export const SearchInputComponent = styled(TextField, {
  shouldForwardProp: prop => prop !== 'isWrapped',
})<{ isWrapped?: boolean }>`
  ${({ theme, isWrapped }) => `
  .MuiInputBase-root {
    padding: ${theme.spacing(2)};
    background: ${isWrapped ? 'transparent' : theme.palette.backgrounds.tertiary};
    border-radius: ${isWrapped ? 0 : 10}px;
    border-radius: ${10}px;
    width: 100%;
    cursor: default;

    &.Mui-disabled {
      background-color: ${theme.palette.backgrounds.tertiary};
      border-color:${theme.palette.backgrounds.tertiary};
    }

    & .MuiOutlinedInput-notchedOutline {
      display: none;
      box-shadow: none !important;
    }

    & input {
        font-size: 14px;
        line-height: 20px;
        cursor: default;
        font-weight: 440;
        padding: 0px 1px;
        color: ${theme.palette.texts.primary};
        :focus {
          outline: none;
        }
        &::placeholder {
            color: ${theme.palette.texts.placeHolderSecondary};
            opacity: 1;
          }
    };
    }
  `}
`;
