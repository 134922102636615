import { SearchEmptyStateDark, SearchEmptyStateLight, SharepointMedium, nameMatchesFilter } from '@storybook';
import { unionBy as _unionBy } from 'lodash';
import {
  SearchResponse,
  SearchRequest,
  Site,
  EntityType,
  ListItem,
  List,
  SharepointIds,
  NullableOption,
} from '@microsoft/microsoft-graph-types';
import { GBaseItem, GValue, GraphClient } from '@services';
import { oDirname } from '@vendor/utils/misc';
import { strings } from '@vendor/languages';
import { doesHighlightExist } from '@storybook';
import {
  decodeSpecialCharacters,
  encodeSpecialCharacters,
  getDocumentOdataIdFromUrl,
  getFolderOdataIdFromUrl,
  getLibraryOdataIdFromListId,
} from '../sharePointAPI';
import { SPDocument, SPFolder, SPList, SPSite } from '../sharePointTypes';
import {
  BaseContainer,
  ItemData,
  FetchChildrenProps,
  IconProps,
  MicrosoftApps,
  BaseItem,
  ItemDataWithPaging,
  ItemContainer,
  EmptyStateItem,
} from '../itemTypes';
import { AppsFilters, ContainerFilters, getOneDriveBaseUrl } from './filters';
import { Channel } from '../graphTypes';
import { createItemDataFromJson } from '../misc';
import { TeamsRoot } from '../virtualRoots';
import { SearchAllLocations } from '../actions';
const CHUNK = 50;
export interface SearchProps {
  searchTerm?: string;
  filters?: string;
  entityTypes?: EntityType[];
}

interface ExtItemSearchProps {
  contentTypeID?: string;
  uniqueID: string;
  fileName?: string;
  isContainer: boolean;
  isDocument: boolean;
  sitePath: string;
  siteId: string;
  listid: string;
  siteTemplate: string;
  size: number;
}

interface ExtListSearchProps {
  displayName?: string | null;
  siteTemplate?: string;
  listId: string;
  sharepointIds: NullableOption<SharepointIds> | undefined;
}

export class Search extends BaseContainer {
  constructor(
    readonly searchTerm: string,
    isAdvancedSearchItem: boolean,
    readonly location?: ItemContainer,
    name?: string,
    readonly filters?: string,
    readonly entityTypes?: EntityType[]
  ) {
    super({ type: 'SearchResults', id: 'root', name: name ? name : strings.lang.nodeNames.defaultSearch });
    this.isAdvancedSearchItem = isAdvancedSearchItem;
    this.location = location?.isAdvancedSearchContainer ? (location as Search).location : location;
    this.searchTerm = searchTerm;
    this.filters = filters;
    this.entityTypes = entityTypes;
  }

  get isRootSection(): boolean {
    return false;
  }
  get canRefresh(): boolean {
    return false;
  }
  get isVirtual(): boolean {
    return true;
  }

  override getNumberOfQuickActions() {
    return 1;
  }

  private static async searchItemsLocally(
    filters: string,
    isAdvancedSearchItem: boolean,
    gcl: GraphClient,
    searchTerm?: string,
    location?: ItemData
  ): Promise<ItemData[] | undefined> {
    const items: ItemData[] | undefined =
      ContainerFilters.Channel.name === filters
        ? (await Channel.getAllChannels(gcl)).filter(v => {
            const item = createItemDataFromJson(v);
            if (location && item?.parent?.apiIdKey === location.apiIdKey) {
              return item;
            } else if (location?.isAdvancedSearchContainer || location === undefined) {
              return item;
            }
          })
        : ContainerFilters.Team.name === filters && TeamsRoot.AllTeams.value
          ? TeamsRoot.AllTeams.value.map(v => createItemDataFromJson(v))
          : undefined;
    const matchNames = nameMatchesFilter(searchTerm);
    return (
      items &&
      items
        .filter(
          value =>
            matchNames(value.name) ||
            (ContainerFilters.Channel.name === filters &&
              value.name === 'General' &&
              matchNames((value as Channel).parent?.name || ''))
        )
        .map(value => {
          const item = createItemDataFromJson(value);
          item.isSearchItem = true;
          item.size = 55;
          item.isAdvancedSearchItem = isAdvancedSearchItem;
          return item;
        })
    );
  }

  static async searchItems(
    gcl: GraphClient,
    searchProps: SearchProps,
    location: ItemData | undefined,
    isAdvancedSearchItem: boolean,
    top = 100,
    from = 0
  ): Promise<{
    items: ItemData[];
    itemsCount: number;
    moreResultsAvailable?: boolean;
  }> {
    if (searchProps.entityTypes?.length === 0) {
      searchProps.entityTypes = ['site', 'list', 'listItem'];
    }
    const { searchTerm, filters, entityTypes } = searchProps;
    if (filters) {
      const items = await this.searchItemsLocally(filters, isAdvancedSearchItem, gcl, searchTerm, location);
      if (items !== undefined) {
        return {
          items: items,
          itemsCount: items.length,
        };
      }
    }
    const queryString = `(${searchTerm}) AND -SiteTemplate:POINTPUBLISHINGPERSONAL AND (contentclass:STS_Web OR contentclass:STS_Site OR contentclass:STS_List_DocumentLibrary OR contentclass:STS_ListItem_MySiteDocumentLibrary OR contentclass:STS_ListItem_DocumentLibrary OR isDocument:true) ${
      filters ? `AND ${filters}` : ''
    }`;
    const urls = await location?.getAccessUrls(gcl);
    const address = hrmProvisioning.searchLocation || urls?.address || urls?.webUrl;
    const aggFilters = (address && [`Path:"${address}"`]) || undefined;
    const oneDriveRootUrl = await getOneDriveBaseUrl(gcl);
    const request: SearchRequest = {
      entityTypes,
      query: {
        queryString,
      },
      sortProperties: [
        {
          name: 'rank',
          isDescending: true,
        },
      ],
      aggregationFilters: aggFilters,
      fields: [
        'rank',
        'isDocument',
        'isContainer',
        'contentTypeID',
        'displayName',
        'description',
        'fileName',
        'id',
        'lastModifiedBy',
        'lastModifiedDateTime',
        'listid',
        'name',
        'sitePath',
        'siteId',
        'uniqueID',
        'webUrl',
        'sharepointIds',
        'parentReference',
        'siteTemplate',
        'size',
      ],
      size: top,
      from,
    };
    const knownTeams = await TeamsRoot.collectAllTeamsLocations(gcl, undefined);
    const spSearch: GValue<SearchResponse> = await gcl
      .api('search/query')
      .skip(from)
      .post({
        Requests: [request],
      });
    return {
      items:
        _unionBy(
          spSearch.value[0]?.hitsContainers?.[0]?.hits?.map(h => {
            const baseItem = h.resource as BaseItem;
            if (h.summary) {
              baseItem.summary = h.summary;
            }
            baseItem.size = 55;
            return baseItem;
          }),
          'webUrl'
        )
          .map(d => {
            const fields = d['fields'] as ExtItemSearchProps;
            const webUrl = (d as GBaseItem).webUrl;
            const isKnownTeamSite =
              d['@odata.type'] === '#microsoft.graph.site' && knownTeams.bySiteUrl.has(webUrl || '');

            const officeAppLocated = (d as GBaseItem)?.webUrl?.includes(oneDriveRootUrl)
              ? MicrosoftApps.OneDrive
              : fields?.siteTemplate === 'GROUP' || filters === AppsFilters.TEAMS.filter || isKnownTeamSite
                ? MicrosoftApps.Teams
                : MicrosoftApps.SharePoint;
            const item = this.createFromSearchREesult(d, officeAppLocated);
            if (searchTerm && d.summary && !doesHighlightExist(item.name, searchTerm)) {
              item.summary = d.summary;
              item.size = 70;
            } else {
              item.summary = undefined;
              item.size = d.size;
            }
            return item;
          })
          .filter(i => i.apiIdKey !== location?.apiIdKey)
          .map(i => {
            i.searchedLocationId = location?.id;
            i.isSearchItem = true;
            i.isAdvancedSearchItem = isAdvancedSearchItem;
            i.setOwnPrefix();
            return i;
          }) || [],
      itemsCount: spSearch.value[0].hitsContainers?.[0].total || 0,
      moreResultsAvailable: spSearch.value[0].hitsContainers?.[0].moreResultsAvailable || false,
    };
  }

  private static spIdsFromResult(fields: ExtItemSearchProps, sharepointIds: NullableOption<SharepointIds> | undefined) {
    return { siteId: fields.siteId, siteUrl: fields.sitePath, ...sharepointIds };
  }

  private static createFromSearchREesult(res: GBaseItem, officeAppLocated: MicrosoftApps): BaseItem {
    const fields = res['fields'] as ExtItemSearchProps;
    const webUrl = encodeSpecialCharacters(decodeSpecialCharacters(res.webUrl || '') || '');

    const path = decodeURIComponent(new URL(webUrl).pathname).replace('/Forms', '');
    switch (res['@odata.type']) {
      case '#microsoft.graph.list':
        const lRes = res as List;
        return this.createList(
          {
            displayName: lRes.displayName || lRes.name,
            listId: res.id || '',
            sharepointIds: {
              siteId: lRes.parentReference?.siteId?.split(',')[1].trim() || '',
            },
            siteTemplate: fields?.siteTemplate,
          },
          webUrl || '',
          officeAppLocated
        );
      case '#microsoft.graph.site':
        return this.createSite(res as Site, officeAppLocated);
    }
    if (fields.isContainer && !fields.isDocument)
      return this.createFolder(
        fields,
        path,
        this.spIdsFromResult(fields, (res as ListItem).sharepointIds),
        officeAppLocated
      );

    return this.createDocument(res as ListItem, fields, path, officeAppLocated);
  }

  private static createSite(d: Site, officeAppLocated: MicrosoftApps) {
    return new SPSite({
      id: d.id || '',
      name: d.displayName || '',
      site: d.webUrl || '',
      graphRoot: '/sites',
      _isUserManaged: true,
      siteDescription: d.description,
      OfficeAppLocated: officeAppLocated,
    });
  }

  private static createList(fields: ExtListSearchProps, listUrl: string, officeAppLocated: MicrosoftApps) {
    if (listUrl.match(/\.aspx$/i)) listUrl = oDirname(oDirname(listUrl));
    const listPath = decodeURIComponent(new URL(listUrl).pathname);
    let site = oDirname(listUrl);
    if (site.match(/list$/i)) site = oDirname(site);
    const pathSegments = listPath.split('/');
    return new SPList({
      site: SPSite.fromSharepointIds({ siteUrl: oDirname(listUrl), ...fields.sharepointIds }, officeAppLocated),
      id: getLibraryOdataIdFromListId(site, fields.listId),
      name: fields.displayName || pathSegments[pathSegments.length - 1],
      serverRelativeUrl: listPath,
      OfficeAppLocated: officeAppLocated
        ? officeAppLocated
        : fields?.siteTemplate === 'GROUP'
          ? MicrosoftApps.Teams
          : MicrosoftApps.SharePoint,
    });
  }

  private static createFolder(
    fields: ExtItemSearchProps,
    folderPath: string,
    spIds: NullableOption<SharepointIds> | undefined,
    officeAppLocated: MicrosoftApps
  ) {
    const siteUrl = fields.sitePath;
    const pathSegments = folderPath.split('/').slice(new URL(siteUrl).pathname.replace(/\/$/, '').split('/').length);
    const list = this.createList(
      {
        displayName: pathSegments[0],
        listId: fields.listid,
        sharepointIds: spIds,
        siteTemplate: fields?.siteTemplate,
      },
      `${siteUrl}/${pathSegments[0]}`,
      officeAppLocated
    );
    return new SPFolder({
      list,
      name: fields.fileName || pathSegments[pathSegments.length - 1],
      serverRelativeUrl: folderPath,
      id: getFolderOdataIdFromUrl(siteUrl, folderPath),
      contentTypeId: fields.contentTypeID,
      listItemId: parseInt(spIds?.listItemId || ''),
    });
  }

  private static createDocument(
    res: ListItem,
    fields: ExtItemSearchProps,
    docPath: string,
    officeAppLocated: MicrosoftApps
  ): SPDocument {
    const parent = this.createFolder(
      { ...fields, contentTypeID: undefined, fileName: undefined },
      oDirname(docPath),
      this.spIdsFromResult(fields, res.sharepointIds),
      officeAppLocated
    );
    return new SPDocument({
      contentTypeId: fields.contentTypeID || '',
      date: new Date(res.lastModifiedDateTime || ''),
      relativeUrl: docPath,
      id: getDocumentOdataIdFromUrl(fields.sitePath, docPath) + fields.uniqueID,
      name: fields.fileName || '',
      parent,
      uniqueId: fields.uniqueID,
      user: { title: res.lastModifiedBy?.user?.displayName || '', email: res.lastModifiedBy?.user?.displayName || '' },
      listItemId: parseInt(res.sharepointIds?.listItemId || ''),
      itemSize: fields.size || undefined,
    });
  }

  override get isAdvancedSearchContainer(): boolean {
    return true;
  }

  override async fetchChildren({ gcl, pageToken }: FetchChildrenProps): Promise<ItemDataWithPaging> {
    const response = await Search.searchItems(
      gcl,
      { searchTerm: this.searchTerm, entityTypes: this.entityTypes, filters: this.filters },
      this.location,
      true,
      CHUNK,
      Number(pageToken) || 0
    );
    const hasMoreResult = response.moreResultsAvailable;
    const noSearchResult = response.items.length === 0 && pageToken === undefined;
    console.warn(`Result: ${String((Number(pageToken) | 0) + CHUNK)} of ${response.itemsCount}`);
    const emptyState = this.location
      ? new EmptyStateItem({
          name: noSearchResult ? 'noSearchResultInLocation' : 'searchResultLastItem',
          images: noSearchResult ? { light: SearchEmptyStateLight, dark: SearchEmptyStateDark } : undefined,
          action: new SearchAllLocations(),
          location: {
            id: this.location.id,
            data: new Search(this.searchTerm, true, undefined, '', this.filters, this.entityTypes),
          },
          size: 240,
        })
      : new EmptyStateItem({
          name: 'globalNoSearchResult',
          images: { light: SearchEmptyStateLight, dark: SearchEmptyStateDark },
          size: 170,
        });
    return {
      items: emptyState.apply(
        [...response.items],
        noSearchResult ||
          (this.location !== undefined && (response.items.length === 0 || !response.moreResultsAvailable))
      ),
      pageToken: (hasMoreResult && String((Number(pageToken) | 0) + CHUNK)) || undefined,
      resultCount: response.itemsCount,
      pagginationString: `Result: ${String(Math.min((Number(pageToken) | 0) + CHUNK, response.itemsCount))} of ${
        response.itemsCount
      }`,
    };
  }

  override getIcon(_expanded: boolean): IconProps {
    return { icon: SharepointMedium, isColorable: false };
  }
}
