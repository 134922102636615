import { useCallback } from 'react';
import { useGetChildren } from '~/hooks';
import { useInterval } from '~/hooks/useInterval';
import { RefreshAction } from '~/utilities';
import { TreeNodeRenderer } from './GetNodeRenderer';

export const renderWithAutoRefresh = (NextRenderer: TreeNodeRenderer): TreeNodeRenderer => {
  return props => {
    const { node, handlers } = props;
    const getChildren = useGetChildren();
    const refresh = useCallback(
      async () => node.isAsyncDataLoaded() && RefreshAction.refresh(node, handlers, getChildren, true),
      [getChildren, handlers, node]
    );

    useInterval(refresh, hrmProvisioning.autoRefreshDelay);
    return NextRenderer && NextRenderer(props);
  };
};
