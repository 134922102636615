import { template as _template } from 'lodash';
import { GraphClient } from '@services';
import { getLocationUrl } from '@services';
import { logError } from '@vendor/utils/misc';
import { strings } from '@vendor/languages';
import { OfficeMode, officeMode } from '~/misc';
import { SPDocument, SPFolder } from '../sharePointTypes';
import { SharePointClient, encodeSpecialCharacters, getDocumentOdataIdFromUrl } from '../sharePointAPI';
import { ItemData, DocumentItem, ItemContainer } from '../itemTypes';
import { ErrorTokens } from '../networkAndCache/ErrorTokens';

const MB10 = 10000000;
const encodeLinkSpaces = link => {
  return link.replaceAll(' ', '%20');
};

export const shareToTeams = async (gcl: GraphClient, data: ItemData) => {
  const urls = await data.getAccessUrls(gcl);
  const addressUrl = encodeLinkSpaces(urls?.address || urls?.webUrl || '');
  const targetUrl = `https://teams.microsoft.com/share?href=${encodeURIComponent(
    addressUrl
  )}&msgText=${encodeURIComponent(strings.lang.misc.shareToTeamsIntro({ name: urls?.['fileName'] || data.name }))}`;
  if (Office.context.platform == Office.PlatformType.Mac) {
    window.open(targetUrl);
    return;
  }
  Office.context.ui.displayDialogAsync(getLocationUrl(`/redirect?targetUrl=${encodeURIComponent(targetUrl)}`), {
    height: 60,
    width: 30,
    promptBeforeOpen: false,
  });
};

export const copyLink = async (gcl: GraphClient, data: ItemData) => {
  const urls = await data.getAccessUrls(gcl);
  const addressUrl = encodeLinkSpaces(
    urls?.address?.includes('aspx') ? urls.webUrl : urls?.address || urls?.webUrl || ''
  );
  try {
    urls && (await navigator.clipboard?.writeText(addressUrl));
    urls && (hrmProvisioning.searchLocation = addressUrl);
  } catch (e: any) {
    logError(e, `Clipboard API not supported`);
    const temporaryInput = document.createElement('input');
    temporaryInput.style.opacity = '0';
    temporaryInput.value = addressUrl;
    document.body.appendChild(temporaryInput);
    temporaryInput.select();
    document.execCommand('copy');
    document.body.removeChild(temporaryInput);
  }
};

export const emailWithLink = async (gcl: GraphClient, data: ItemData) => {
  const urls = await data.getAccessUrls(gcl);
  const addressUrl = urls?.address || urls?.webUrl || '';
  const sharepointLinksMessageBodyTmpl = _template(
    `<a title="${addressUrl}" href="${addressUrl}" target="_blank">${data.name}</a> <br></br>`
  );

  const htmlBody = sharepointLinksMessageBodyTmpl({ results: addressUrl });
  if (officeMode === OfficeMode.Read) {
    Office.context.mailbox.displayNewMessageForm({ htmlBody });
  } else {
    Office.context.mailbox.item?.body.setSelectedDataAsync(
      htmlBody,
      { coercionType: Office.CoercionType.Html },
      result => {
        console.warn(result);
      }
    );
  }
};

// TODO: move to utils
const promisify = (instance, method, ...args) => {
  return new Promise((resolve, reject) => {
    args.push(asyncResult => {
      if (asyncResult.status !== 'succeeded') {
        reject(asyncResult.error);
      } else {
        resolve(asyncResult.value);
      }
    });
    method.apply(instance, args);
  });
};

export const emailWithAttachment = async (gcl: GraphClient, data: ItemData) => {
  if (data instanceof SPDocument) {
    if (data.itemSize && data.itemSize > MB10) {
      throw new Error('File size is too big');
    }
  }
  const reader = new FileReader();
  const blobData = await (data as DocumentItem).download(gcl);
  const fileName = blobData.fileName;
  if (blobData.blob.size > MB10) {
    throw new Error('File size is too big');
  }
  reader.onload = async function (event) {
    if (event?.target?.readyState === FileReader.DONE) {
      const result = event?.target?.result;
      if (typeof result === 'string') {
        const base64String = result.split(',')[1];
        const item = Office.context.mailbox.item;
        if (officeMode !== OfficeMode.Read) {
          await promisify(item, item?.addFileAttachmentFromBase64Async, base64String, `${fileName}`, {
            asyncContext: result => {
              return result;
            },
          });
        }
      }
    }
  };
  reader.readAsDataURL(blobData.blob);
};

export enum DocumentTemplateType {
  Word = 1,
  Excel,
  PowerPoint,
}

export const createNewDocument = async (
  gcl: GraphClient,
  location: ItemContainer,
  name: string,
  templateID: DocumentTemplateType
): Promise<SPDocument> => {
  const folder = (await location.getFolder(gcl)) as SPFolder;
  const extension = ['.docx', '.xlsx', '.pptx'][templateID - 1];
  const folderPath = encodeSpecialCharacters(
    folder.serverRelativeUrl.replace(folder.list.rootFolder.serverRelativeUrl, '')
  );
  const url = `${
    folder.list.apiId
  }/CreateDocumentAndGetEditLink(fileName=@a2,folderPath=@a3,documentTemplateType=@a4)?@a2='${
    name + extension
  }'&@a3='${folderPath}'&@a4=${templateID}`;
  const sp = new SharePointClient(gcl, folder.rootSite);
  try {
    const docEditLink = await sp.directapi(url).post(null);
    const match = docEditLink.value.match(/\{([0-9a-fA-F\-]+)\}/);
    const contentInsideBraces = match ? match[1] : null;
    const newLink = docEditLink.value.replace('action=edit', 'action=editnew');
    window.open(newLink, '_blank');
    const parentPath = await location.getAccessUrls(gcl);
    const relativeUrl = `${decodeURIComponent(new URL(parentPath?.webUrl || '').pathname)}/${name}${extension}`;
    return new SPDocument({
      isNew: true,
      parent: folder as SPFolder,
      date: new Date(),
      relativeUrl,
      id: getDocumentOdataIdFromUrl(folder.list.siteUrl, relativeUrl),
      name: name + extension,
      uniqueId: contentInsideBraces,
    });
  } catch (e) {
    logError(e);
    throw e;
  }
};

export const hasOnlyAllowedCharacters = (inputString: string, isFolder: boolean) => {
  // Define a regular expression pattern for allowed characters

  // Define a blacklist for specific characters
  const blacklist = isFolder
    ? ['"', '*', ':', '<', '>', '?', '/', '\\', '|', '\t', '\b', '"', '\n', '\r', '\f']
    : ['"', '*', ':', '<', '>', '?', '/', '\\', '|'];

  // Check if the inputString contains only allowed characters
  return [...inputString].every(char => !blacklist.includes(char));
};

export const normalizeSubject = (subject: string) => {
  // Regex to match one, two, or three characters followed by a colon and blanks
  const regex = /^([^\d\s:]{1,3}):\s*(.*)$/;
  const match = subject.match(regex);
  if (match) {
    return match[2];
  }
};

export const isValidUrl = (url: string) => {
  try {
    new URL(url);
    return true;
  } catch (err) {}
  return false;
};

export const convertFileListToFileArray = (fileList: FileList): File[] => {
  const fileArray: File[] = [];
  // Loop through the FileList and fill the array
  for (let i = 0; i < fileList.length; i++) {
    if (fileList.item(i) !== null) {
      // item(i) can be null, so check it
      fileArray.push(fileList.item(i) as File);
    }
  }
  return fileArray;
};

export const validateError = (error: any) => {
  if (
    (error['odata.error']?.code !== undefined && error['odata.error']?.code === ErrorTokens.itemExist) ||
    error?.message?.code === ErrorTokens.itemExist
  ) {
    return ErrorTokens.itemExist;
  }
};
export const formatDateTime = (isoDate: string): string => {
  const date = new Date(isoDate);

  // Pad the month, day, hour, minute, and second to ensure two digits
  const pad = (num: number) => num.toString().padStart(2, '0');

  // Format the date and time
  const year = date.getFullYear();
  const month = pad(date.getMonth() + 1); // getMonth() returns 0-indexed month
  const day = pad(date.getDate());
  const hours = pad(date.getHours());
  const minutes = pad(date.getMinutes());
  const seconds = pad(date.getSeconds());

  // Combine into the desired format
  return `${year}-${month}-${day}_${hours}-${minutes}-${seconds}`;
};
