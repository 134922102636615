import { Theme, Components } from '@mui/material/styles';

export default (theme: Theme) => {
  const styleOverrides: Components['MuiAccordion'] = {
    styleOverrides: {
      root: {
        backgroundColor: theme.palette.backgrounds.primary,
        ['&.Mui-expanded&[data-fullheight]']: {
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
          ['& .MuiCollapse-vertical']: {
            flex: 1,
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
          },
        },
        [':before']: {
          display: 'none',
        },
      },
      region: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
      },
    },
  };
  return styleOverrides;
};
