import { OpenInBrowserSmall, ViewItem, OpenInBrowserMedium } from '@storybook';
import { useGraphClient } from '@services';
import { strings } from '@vendor/languages';
import { ItemData } from '../itemTypes';
import { ActionRendererHookType, BaseReadActionClass } from './BaseAction';

const useCopyAddress: ActionRendererHookType = ({ nodes, useCallback }) => {
  const gcl = useGraphClient();
  const node = nodes[0];
  const onClick = useCallback(async () => {
    const nData = node.data as ItemData;
    const urls = await nData.getAccessUrls(gcl);
    const addressUrl = urls?.address || urls?.webUrl || '';
    urls && navigator.clipboard?.writeText(addressUrl);
    urls && (hrmProvisioning.searchLocation = addressUrl);
  }, [gcl, node.data]);

  return {
    title: strings.lang.actionToolTip.copyAddress,
    icon: [OpenInBrowserSmall, OpenInBrowserMedium],
    onClick,
  };
};

// Right now this is only for testing purposes
export class CopyAddressAction extends BaseReadActionClass {
  readonly trackedName = 'CopyAddress';
  readonly useRenderer = useCopyAddress;

  isHandled(nodes: ViewItem<ItemData>[]): boolean {
    return nodes.length === 1 && nodes[0].data.canOpenInBrowser && hrmProvisioning.searchTerm;
  }
}
