import { Components, Theme } from '@mui/material/styles';

export default (_theme: Theme) => {
  const styleOverrides: Components['MuiBackdrop'] = {
    styleOverrides: {
      root: {
        backgroundColor: 'transparent',
      },
    },
  };
  return styleOverrides;
};
