import {
  ContainerHandlers,
  TreeNodeActions,
  ViewItem,
  itemFastActions,
  itemFastActionsOnSelect,
  TreeNodeActionsContainer,
} from '@storybook';
import React, { MouseEventHandler } from 'react';
import { getAllContextActions, getQuickActionsList } from './ActionManager';
import { ItemData, treeitemFirstParent } from '../itemTypes';
import { RenderActionByHook } from './ContextMenuAction';

interface QuickActionManagerProps {
  node: ViewItem<ItemData>;
  handlers: ContainerHandlers<ItemData>;
  onMouseOver?: MouseEventHandler | undefined;
  onMouseLeave?: MouseEventHandler | undefined;
  only3DotsAction?: boolean;
  isFavoriteItem?: boolean;
}

export const QuickActionManager: React.FunctionComponent<QuickActionManagerProps> = ({
  node,
  handlers,
  onMouseOver,
  onMouseLeave,
  isFavoriteItem,
}) => {
  if (handlers.isSelected(node) && handlers.getSelectedNodes().length > 1) return null;
  const { quickActions, contextAction } = getQuickActionsList([node], getAllContextActions(), !!node.data.isSearchItem);
  const actionVariant = handlers.isSelected(node) ? itemFastActionsOnSelect : itemFastActions;
  return (
    <TreeNodeActionsContainer onClick={e => !e['no-expand'] && e.stopPropagation()}>
      {[...quickActions, ...contextAction].map((a, ind) => (
        <TreeNodeActions
          key={`${node.id}-action-${a.constructor.name}-${ind}`}
          className={
            isFavoriteItem &&
            a?.['isFavorite'] &&
            node.data.itemFirstParent !== treeitemFirstParent.Favorite &&
            'is-favorite-action'
          }
          onMouseOver={onMouseOver}
          onMouseLeave={onMouseLeave}
        >
          <RenderActionByHook
            trigger={ind < quickActions.length ? 'Quick Action' : '3Dots'}
            action={a}
            nodes={[node]}
            actionVariant={actionVariant}
            handlers={handlers}
          />
        </TreeNodeActions>
      ))}
    </TreeNodeActionsContainer>
  );
};
