import * as React from 'react';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import { useEffect, useRef, useState } from 'react';
import ItemNodeBreadcrumbs from '../NavigationItem/components/ItemNodeBreadcrumbs';
import HighlightedTypography from '../HighlightedTypography/HighlightedTypography';
export interface BreadcrumbsProps {
  itemBreadcrumbs: string | undefined | React.ReactNode;
  color: string;
  highlightString?: string;
}

export default function Breadcrumb(props: { breadcrumbsProps: BreadcrumbsProps }) {
  const breadcrumbsRef = useRef<HTMLDivElement>(null);
  const [showBreadcrumbs, setShowBreadcrumbs] = useState(false);

  useEffect(() => {
    function handleResize() {
      if (
        breadcrumbsRef.current &&
        props.breadcrumbsProps.itemBreadcrumbs !== undefined &&
        typeof props.breadcrumbsProps.itemBreadcrumbs === 'string'
      ) {
        setShowBreadcrumbs(breadcrumbsRef.current.offsetWidth > 50);
      } else setShowBreadcrumbs(true);
    }
    setShowBreadcrumbs(true);
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => window.removeEventListener('resize', handleResize);
  }, [breadcrumbsRef, props.breadcrumbsProps.itemBreadcrumbs]);
  const isAttachmentBreadcrumbs =
    props.breadcrumbsProps.itemBreadcrumbs !== undefined && typeof props.breadcrumbsProps.itemBreadcrumbs !== 'string';
  return (
    <ItemNodeBreadcrumbs ref={breadcrumbsRef} isAttachmentBreadcrumbs={isAttachmentBreadcrumbs}>
      {showBreadcrumbs && (
        <Breadcrumbs
          aria-label="breadcrumb"
          sx={{
            color: props.breadcrumbsProps.color,
            whiteSpace: 'nowrap',
            overflowX: 'hidden',
            textOverflow: 'ellipsis',
          }}
          className={isAttachmentBreadcrumbs ? 'attachments-breadcrumbs' : ''}
        >
          {isAttachmentBreadcrumbs ? (
            <span>{props.breadcrumbsProps.itemBreadcrumbs}</span>
          ) : (
            <HighlightedTypography highlight={props.breadcrumbsProps.highlightString} variant="H3Regular" noWrap>
              {props.breadcrumbsProps.itemBreadcrumbs}
            </HighlightedTypography>
          )}
        </Breadcrumbs>
      )}
    </ItemNodeBreadcrumbs>
  );
}
