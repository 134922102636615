import { Stack, styled } from '@mui/material';

const TreeRootExpandIcon = styled(Stack)`
  margin-left: 0;
  .lottie-animation {
    padding-right: 7px;
    svg {
      margin-left: -3px;
    }
  }
  .isPinned & {
    opacity: 0;
  }
`;

export default TreeRootExpandIcon;
