import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Snackbar, Slide, LinearProgress, Paper, useTheme } from '@mui/material';
import { Box, Stack } from '@mui/system';
import { strings } from '@vendor/languages';
import paperFold from '../Lottie/Animations/Success/Main_PaperFold.json';
import { LottieAnimation } from '../Lottie';
import { IndicatorHeader } from './IndicatorHeader';
import { IndicatorBody } from './IndicatorBody';
import { IndicatorFooter } from './IndicatorFooter';

export type UploadStatus = 'error' | 'progress' | 'success' | 'canceled';

export interface UploadRequestHandler {
  updateProgress: (index: number, progress: number) => void;
  needToCancel: () => boolean;
  inProgress: () => boolean;
}

interface FileUploadProps {
  dateKey?: string;
  status: UploadStatus;
  fileProgress: boolean[];
  currentProgress: number;
  currentProgressIndex: number;
  activeFileIndex?: number;
  streamFiles?: string[];
  onComplete?: () => void;
  onCancel?: () => void;
  onClose: () => void;
  onExited?: () => void;
  open: boolean;
  isCanceling?: boolean;
  errorMessage?: string;
}

function SlideTransition(props) {
  return <Slide {...props} direction="up" />;
}

function calculateProgress(fileProgress: boolean[], currentProgress: number): number {
  const totalTasks = fileProgress.length;
  if (totalTasks === 1) return currentProgress;

  const countCompleted = fileProgress.filter(value => value).length;
  const fractionSize = 100 / totalTasks;
  const fractionCompleted = countCompleted / totalTasks;
  const fractionCurrentProgress = (fractionSize * currentProgress) / 10000;

  return Math.min((fractionCompleted + fractionCurrentProgress) * 100, 100);
}

const Indicator: React.FC<FileUploadProps> = ({
  currentProgress = 0,
  fileProgress = [],
  activeFileIndex,
  onCancel,
  onComplete,
  open,
  status,
  onClose,
  isCanceling,
  onExited,
  streamFiles,
  dateKey,
  currentProgressIndex,
  errorMessage,
}) => {
  const {
    uploadCanceled,
    itemsUploaded,
    uploadComplete,
    uploadingOneItem,
    progressUploaded,
    cancelUpload,
    faildUpload,
  } = strings.lang.uploadIndicator;
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);
  const [isHover, setIsHover] = useState(false);
  const [animationComplete, setAnimationComplete] = useState(false);

  const theme = useTheme();
  const isProgressShow = useMemo(() => status === 'success' || status === 'progress', [status]);
  const displayProgress = useMemo(
    () => calculateProgress(fileProgress, currentProgress),
    [currentProgress, fileProgress]
  );

  const readyToFinish = useMemo(() => {
    if (
      fileProgress.every(item => item) ||
      (fileProgress.slice(0, -1).every(item => item) && currentProgress === 100)
    ) {
      return true;
    } else {
      return false;
    }
  }, [fileProgress, currentProgress]);

  const description = useMemo(() => {
    if (status === 'canceled') {
      return itemsUploaded({ count: currentProgressIndex });
    }
    if (status === 'error' && errorMessage) {
      return errorMessage;
    }
    return streamFiles?.[activeFileIndex!] || ''; // Use the non-null assertion operator since activeFileIndex might be undefined
  }, [status, errorMessage, streamFiles, activeFileIndex, itemsUploaded, currentProgressIndex]);

  const title = useMemo(() => {
    const totalItems = fileProgress.length;
    const uploadedItems = fileProgress.filter(Boolean).length;
    if (status === 'error') {
      return faildUpload({ count: totalItems });
    }
    if (status === 'canceled') {
      return uploadCanceled;
    }
    if (fileProgress.length == 1) {
      if (status === 'success') {
        return uploadComplete;
      }
      return uploadingOneItem;
    } else {
      if (uploadedItems === totalItems) {
        return itemsUploaded({ count: totalItems });
      }
      return progressUploaded({ count: uploadedItems + 1, totals: totalItems });
    }
  }, [
    fileProgress,
    status,
    faildUpload,
    uploadCanceled,
    uploadingOneItem,
    uploadComplete,
    progressUploaded,
    itemsUploaded,
  ]);

  const progressColor = useMemo(() => (status === 'progress' || status === 'canceled' ? 'primary' : status), [status]);

  const onTransitionEnd = useCallback(() => {
    if (readyToFinish && onComplete) onComplete();
  }, [readyToFinish, onComplete]);

  const hasCancel = useCallback(() => {
    if (typeof activeFileIndex === 'number' && streamFiles) {
      return streamFiles.length > 1 && activeFileIndex !== streamFiles.length - 1;
    }
    return false;
  }, [activeFileIndex, streamFiles]);

  useEffect(() => {
    if (status !== 'progress' && !isHover) {
      timeoutRef.current = setTimeout(onClose, 8000);
    }
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, [isHover, onClose, status, fileProgress]);

  return (
    <Snackbar
      TransitionProps={{
        onExited: onExited,
      }}
      open={open}
      TransitionComponent={SlideTransition}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
      className="indicator"
    >
      <Box sx={{ width: '100%' }}>
        {status === 'success' && (
          <LottieAnimation
            options={{ loop: false, autoplay: true, animationData: paperFold }}
            width={animationComplete ? 0 : 290}
            height={animationComplete ? 0 : 128}
            handleAnimationComplete={() => setAnimationComplete(true)}
          />
        )}
        <Paper
          sx={{
            position: 'relative',
            zIndex: 10000,
            width: '100%',
            borderRadius: 2,
            overflow: 'hidden',
            marginTop: theme.spacing(-6),
            background: theme.palette.backgrounds.primary,
          }}
        >
          {isProgressShow && (
            <LinearProgress
              key={dateKey}
              variant="determinate"
              sx={{ height: 3 }}
              color={progressColor}
              value={displayProgress}
              onTransitionEnd={onTransitionEnd}
            />
          )}
          <Stack spacing={1} p={3}>
            <IndicatorHeader title={title} status={status} onClose={onClose} />
            <IndicatorBody status={status} description={description} streamFiles={streamFiles} />
            {status === 'progress' && (
              <IndicatorFooter
                cancel={cancelUpload}
                isCanceling={isCanceling}
                onCancel={onCancel}
                hasCancel={hasCancel()}
              />
            )}
          </Stack>
        </Paper>
      </Box>
    </Snackbar>
  );
};

export default Indicator;
