import { v4 as uuidv4 } from 'uuid';
import { EmailStrings } from '../networkAndCache/GraphApi';

export const SimpleAdaptiveCard = (
  link: string,
  name: string,
  channelName: string,
  channelId: string,
  body: string,
  attachmentGuid: string,
  mentionGuid?: string
) => {
  const attachmentCard = {
    subject: null,
    body: {
      contentType: 'html',
      content: mentionGuid
        ? `<at id="${mentionGuid}">${channelName}</at> ${body} <attachment id=${attachmentGuid}></attachment>`
        : ` ${body} <attachment id=${attachmentGuid}></attachment>`,
    },
    attachments: [
      {
        id: `${attachmentGuid}`,
        contentType: 'reference',
        contentUrl: `${link}`,
        name: `${name}`,
      },
    ],
    mentions: mentionGuid
      ? [
          {
            id: mentionGuid,
            mentionText: channelName,
            mentioned: {
              conversation: {
                id: channelId,
                displayName: channelName,
                conversationIdentityType: 'channel',
              },
            },
          },
        ]
      : [],
  };
  return attachmentCard;
};

export const EmailAdaptiveCard = (
  link: string,
  to: string,
  from: string,
  msgBody: string,
  channelName: string,
  channelId: string,
  strings: EmailStrings,
  itemName: string,
  date: string,
  body: string,
  subject: string,
  guid: string,
  mentionGuid?: string
) => {
  const emailCard = {
    type: 'AdaptiveCard',
    body: [
      {
        type: 'TextBlock',
        size: 'Small',
        weight: 'Bolder',
        style: 'heading',
        spacing: 'Padding',
        fontType: 'Default',
        text: `${strings.shareEmail}`,
      },
      {
        type: 'TextBlock',
        size: 'Large',
        weight: 'Bolder',
        style: 'heading',
        spacing: 'Padding',
        fontType: 'Default',
        text: `${subject}`,
      },
      {
        type: 'TextBlock',
        size: 'Medium',
        weight: 'Bolder',
        id: from,
        text: `${strings.from}: ${from}`,
      },
      {
        type: 'TextBlock',
        size: 'Medium',
        weight: 'Bolder',
        id: to,
        text: `${strings.to}: ${to}`,
      },
      {
        type: 'TextBlock',
        size: 'Medium',
        weight: 'Bolder',
        id: date,
        text: date,
      },
      {
        type: 'TextBlock',
        id: body,
        text: body,
        wrap: true,
        separator: true,
        maxLines: 4,
        weight: 'Lighter',
        spacing: 'ExtraLarge',
      },
    ],
    $schema: 'http://adaptivecards.io/schemas/adaptive-card.json',
    version: '1.5',
  };
  const adaptiveCardGuid = uuidv4();
  const adaptiveCard = {
    subject: null,
    body: {
      contentType: 'html',
      content: mentionGuid
        ? `
        <at id="${mentionGuid}">${channelName}</at> ${msgBody} <attachment id="${adaptiveCardGuid}"></attachment> <attachment id="${guid}"></attachment>
      `
        : ` ${msgBody} <attachment id="${adaptiveCardGuid}"></attachment> <attachment id="${guid}"></attachment>`,
    },
    attachments: [
      {
        id: adaptiveCardGuid,
        contentType: 'application/vnd.microsoft.card.adaptive',
        contentUrl: link,
        content: JSON.stringify(emailCard),
        name: subject,
        thumbnailUrl: link,
      },
      {
        id: `${guid}`,
        contentType: 'reference',
        contentUrl: `${link}`,
        name: `${itemName}`,
      },
    ],
    mentions: mentionGuid
      ? [
          {
            id: mentionGuid,
            mentionText: channelName,
            mentioned: {
              conversation: {
                id: channelId,
                displayName: channelName,
                conversationIdentityType: 'channel',
              },
            },
          },
        ]
      : [],
  };

  return adaptiveCard;
};
