import React, { FC, PropsWithChildren } from 'react';
import { useTheme } from '@mui/material/styles';
import { ScrollbarProps as CustomScrollbarProps, Scrollbars as CustomScrollbars } from 'react-custom-scrollbars-2';

export interface VerticalScrollbarsProps
  extends Omit<CustomScrollbarProps, 'renderView' | 'renderThumbVertical' | 'renderTrackVertical'> {
  thumbStyle?: React.CSSProperties;
  trackStyle?: React.CSSProperties;
}

export const ScrollbarVerticalThumb: FC<{ thumbStyle?: React.CSSProperties }> = ({ thumbStyle }) => {
  const theme = useTheme();

  return (
    <div
      style={{
        position: 'relative',
        display: 'block',
        width: '100%',
        cursor: 'pointer',
        backgroundColor: theme.palette.texts.tertiary,
        borderRadius: 3,
        ...thumbStyle,
      }}
    />
  );
};

export const ScrollTrackVertical: FC<{ trackStyle?: React.CSSProperties }> = ({ trackStyle }) => {
  return (
    <div
      style={{
        position: 'absolute',
        width: 3,
        right: 2,
        bottom: 2,
        top: 2,
        ...trackStyle,
      }}
    />
  );
};

export const VerticalScrollbars = ({
  children,
  thumbStyle,
  trackStyle,
  ...rest
}: PropsWithChildren<VerticalScrollbarsProps>) => {
  const theme = useTheme();

  return (
    <CustomScrollbars
      {...rest}
      renderView={() => (
        <div
          style={{
            position: 'absolute',
            overflow: 'scroll',
            inset: 0,
          }}
        ></div>
      )}
      renderThumbVertical={() => (
        <div
          style={{
            position: 'relative',
            display: 'block',
            width: '100%',
            cursor: 'pointer',
            backgroundColor: theme.palette.texts.tertiary,
            borderRadius: 3,
            ...thumbStyle,
          }}
        />
      )}
      renderTrackVertical={() => (
        <div
          style={{
            position: 'absolute',
            width: 3,
            right: 2,
            bottom: 2,
            top: 2,
            ...trackStyle,
          }}
        ></div>
      )}
    >
      {children}
    </CustomScrollbars>
  );
};

export default VerticalScrollbars;
