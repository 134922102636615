import { TextField } from '@mui/material';
import { styled } from '@mui/material/styles';

const SearchField = styled(TextField)`
  ${({ theme }) => `
  margin-bottom: 5px;
  
  & .MuiInputBase-root {
    background: ${theme.palette.backgrounds.tertiary};
    margin: 0;
    width: 100%;
  
    & .MuiOutlinedInput-notchedOutline {
        border-width: 0
    } 
    &.Mui-focused {
        border-color: ${theme.palette.texts.accentPrimary};
        & .MuiInputAdornment-root {
         & .SearchIcon {
 
         }
        }
        & .MuiOutlinedInput-notchedOutline {
            border-width: 1px;
        }
    };
    & input {
        padding: 8px 10px !important;
       :focus {
          outline: none;
        }
        padding: 0;
        color: ${theme.palette.texts.primary};
        &::placeholder {
            color: ${theme.palette.texts.placeHolderSecondary};
            opacity: 1;
        }
    };
    & svg {
        fill: ${theme.palette.texts.placeHolderSecondary};
    }
    & .MuiInputBase-root:before,
    & .MuiInputBase-root:after  {
        display: none;
    }
    &:hover {
      & input::placeholder{
        color: ${theme.palette.texts.quaternary};
      }
      & svg {
        fill: ${theme.palette.texts.quaternary};
      }
    };
  }  
  `}
`;

SearchField.defaultProps = {
  variant: 'outlined',
};

export default SearchField;
