import { publishSync, subscribe, subscribeOnce, unsubscribe } from 'pubsub-js';
import { useEffect } from 'react';

export function useSubscribe<T>(key: string, func: (data: T) => void) {
  useEffect(() => {
    const token = PubSub.subscribe(key, (_msd, data) => func(data));
    return () => {
      unsubscribe(token);
    };
  }, [key, func]);
}

type AsyncSubscriptionListner<T> = (data: T) => Promise<void>;

export async function asyncPublish<T>(key: string, data: T): Promise<void> {
  const callbacks: AsyncSubscriptionListner<T>[] = [];
  publishSync(key, callbacks);
  await Promise.all(callbacks.map(x => x(data)));
}

export function asyncSubscribe<T>(key: string, func: AsyncSubscriptionListner<T>) {
  return subscribe(key, (_key, callbacks: AsyncSubscriptionListner<T>[]) => callbacks.push(func));
}

export function asyncSubscribeOnce<T>(key: string, func: AsyncSubscriptionListner<T>) {
  subscribeOnce(key, (_key, callbacks: AsyncSubscriptionListner<T>[]) => {
    callbacks.push(func);
  });
}
