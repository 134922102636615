import { Stack, styled } from '@mui/material';

const TreeRootTopLevel = styled(Stack)`
  width: 100%;
  height: 100%;
  position: relative;
  display: block;

  .ht_hyperTreeView > .ht_hyperTreeNodeWrapper > .ht_hyperNodeWrapper.isPinned {
    box-shadow: ${({ theme }) => theme.palette.shadow.rootSectionSticky};
  }

  &:hover {
    .tree-node-switcher {
      opacity: 0.3;
    }
    .tree-line {
      opacity: 1;
    }
  }
`;

export default TreeRootTopLevel;
