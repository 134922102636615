import { useTheme } from '@mui/material';
import React, { FunctionComponent } from 'react';
import Stack from '../Stack/Stack';
import { Typography } from '../Typography';

export interface CounterProps {
  text: string;
}

const Counter: FunctionComponent<CounterProps> = props => {
  const theme = useTheme();
  return (
    <Stack
      background={theme.palette.backgrounds.tertiary}
      sx={{ height: theme.spacing(5), width: '100%', padding: theme.spacing(1, 4, 1, 4) }}
    >
      <Typography variant="CapsLockSemiBold" color={theme.palette.texts.secondary}>
        {props.text}
      </Typography>
    </Stack>
  );
};

export default Counter;
