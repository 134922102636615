import { styled } from '@mui/material';
import Stack from '@mui/system/Stack/Stack';
const NESTING_LIMIT = 2;

const ItemNodeRoot = styled(Stack, {
  shouldForwardProp: prop => prop !== 'nestingLevel',
})<{ nestingLevel: number }>`
  display: flex;
  align-items: center;
  margin: 0;
  padding-left: 0px;
  list-style: none;
  outline: 0;
  border-radius: 8px;
  cursor: pointer;
  user-select: none;
  align-content: center;
  flex-direction: row;
  margin-bottom: 3px;
  position: relative;

  &.isAdvancedSearchItem {
    padding-top: 3px;
    margin-top: 3px;
    margin-bottom: 3px;
    padding-bottom: 3px;
    padding-right: 3px;
    padding-left: ${({ theme }) => theme.spacing(1)};
    width: 100%;
  }
  color: ${({ theme }) => theme.palette.texts.primary};
  width: ${({ nestingLevel }) => {
      if (nestingLevel < 0) {
        return '100%;';
      }
      return `calc(100vw - ${Math.round(Math.min(nestingLevel, NESTING_LIMIT)) * 20 + 20}px);`;
    }}
    &.isRootSection {
    margin-top: 2px;
    position: sticky;
    left: 1px;
    background-color: ${({ theme }) => theme.palette.backgrounds.primary};
    &.isTeamsRoot {
      margin-top: ${({ theme }) => theme.spacing(3)};
    }
  }

  .isPinned & {
    border-radius: 0px;
    width: calc(100vw - 1px);
    margin-bottom: 0px;
  }

  &.isExpanded {
    color: ${({ theme }) => theme.palette.texts.accentPrimary};
    &.isRootSection {
      &:hover {
        background-color: ${({ theme }) => theme.palette.backgrounds.secondary};
        border-radius: 0 8px 8px 0;
      }
    }
  }

  &:hover {
    color: ${({ theme }) => theme.palette.texts.accentSecondary};
  }

  .isErrorItem {
    background-color: ${({ theme }) => theme.palette.backgrounds.tertiary};
    color: ${({ theme }) => theme.palette.status.dangerPrimary};
  }
  &.isSelected,
  &.secondLine.isSelected:hover {
    .HighlightSpan:after {
      background-color: ${({ theme }) => theme.palette.texts.onAccentPrimary};
    }
    .MuiTypography-H3Regular {
      color: ${({ theme }) => theme.palette.texts.onAccentPrimary};
    }
  }

  &.secondLine:hover {
    border-radius: 8px;
    background-color: ${({ theme }) => theme.palette.backgrounds.secondary};
    & .MuiTypography-H3Regular {
      color: ${({ theme }) => theme.palette.texts.secondary};
    }
    & .MuiTypography-H3Regular:hover {
      color: ${({ theme }) => theme.palette.texts.tertiary};
    }
  }
`;

export default ItemNodeRoot;
