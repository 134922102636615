import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import { Stack, StackProps } from '../Stack';

export interface ProgressProps extends StackProps {
  message: string;
  title: string;
  size?: number | string;
}

const Root = styled(Stack)`
  border-radius: 8px;
  padding: 16px 20px;
  max-width: 300px;
  text-align: center;
  width: 100%;
  height: 100%;
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
`;

const Mask = styled(Stack)`
  position: relative;
  width: 100%;
  height: 100%;
  background-color: ${({ theme }) => theme.palette.backgrounds.primary};
`;

const Progress = ({ title, message, size = 24, ...rest }: ProgressProps) => {
  return (
    <Mask>
      <Root direction="column" justifyContent="center" alignItems="center" spacing={4} {...rest}>
        <Typography variant="H1Regular">{title}</Typography>
        <CircularProgress size={size} />
        <Typography variant="H3Regular">{message}</Typography>
      </Root>
    </Mask>
  );
};

export default Progress;
