import React from 'react';
import { Snackbar as MuiSnackbar, SnackbarProps as MuiSnackbarProps } from '@mui/material';

export type SnackbarProps = MuiSnackbarProps;

const Snackbar = ({ children, ...rest }: SnackbarProps) => {
  return <MuiSnackbar {...rest}>{children}</MuiSnackbar>;
};

export default Snackbar;
