import { Stack, styled } from '@mui/material';

const TreeNodeActionsContainer = styled(Stack)`
  align-content: center;
  align-items: center;
  flex-direction: row;
  justify-content: space-around;
  display: flex;
`;

export default TreeNodeActionsContainer;
