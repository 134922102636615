import { each, max } from 'lodash';
import {
  FormMedium,
  GenericFileMedium,
  MultipleMedium,
  OneMedium,
  PdfMedium,
  PptxMedium,
  SwayMedium,
  VideoMedium,
  XlsxMedium,
  ZipMedium,
  URLLinkMedium,
  MailMedium,
  DocxMedium,
  ImageMedium,
  MusicMedium,
  FolderClosedMedium,
  SiteMedium,
  ProjectMedium,
  VisioMedium,
  MeetingNotesMedium,
} from '@harmon.ie-ce/storybook';
import { FunctionComponent, SVGProps } from 'react';

export interface IconProps {
  icon: FunctionComponent<SVGProps<SVGSVGElement>>;
  isColorable?: boolean;
  noPreview?: boolean;
}

export type MediaKeysType =
  | 'PowerPoint'
  | 'Word'
  | 'Excel'
  | 'Pdf'
  | 'OneNote'
  | 'OneNotePage'
  | 'InfoPath'
  | 'Visio'
  | 'Publisher'
  | 'Project'
  | 'Access'
  | 'Mail'
  | 'Csv'
  | 'Archive'
  | 'Xps'
  | 'Audio'
  | 'Video'
  | 'Image'
  | 'Web'
  | 'Text'
  | 'Xml'
  | 'Story'
  | 'ExternalContent'
  | 'Folder'
  | 'Spsite'
  | 'Fluid'
  | 'Other';

export interface FileIconProps extends IconProps {
  media: MediaKeysType;
}

interface CategoriesProps extends FileIconProps {
  extensions: string[];
}

const categories: CategoriesProps[] = [
  {
    icon: GenericFileMedium,
    extensions: ['accdb', 'mdb'],
    isColorable: true,
    media: 'Access',
  },
  {
    icon: ZipMedium,
    extensions: ['7z', 'ace', 'arc', 'arj', 'dmg', 'gz', 'iso', 'lzh', 'pkg', 'rar', 'sit', 'tgz', 'tar', 'z'],
    isColorable: true,
    media: 'Archive',
  },
  {
    icon: MusicMedium,
    extensions: [
      'aif',
      'aiff',
      'aac',
      'alac',
      'amr',
      'ape',
      'au',
      'awb',
      'dct',
      'dss',
      'dvf',
      'flac',
      'gsm',
      'm4a',
      'm4p',
      'mid',
      'mmf',
      'mp3',
      'oga',
      'ra',
      'rm',
      'wav',
      'wma',
      'wv',
    ],
    isColorable: true,
    media: 'Audio',
  },
  {
    icon: GenericFileMedium,
    extensions: ['ical', 'icalendar', 'ics', 'ifb', 'vcs'],
    isColorable: true,
    media: 'Other',
  },
  {
    icon: GenericFileMedium,
    extensions: ['classifier'],
    isColorable: true,
    media: 'Other',
  },
  {
    icon: GenericFileMedium,
    extensions: [
      'abap',
      'ada',
      'adp',
      'ahk',
      'as',
      'as3',
      'asc',
      'ascx',
      'asm',
      'asp',
      'awk',
      'bash',
      'bash_login',
      'bash_logout',
      'bash_profile',
      'bashrc',
      'bat',
      'bib',
      'bsh',
      'build',
      'builder',
      'c',
      'cbl',
      'c++',
      'capfile',
      'cc',
      'cfc',
      'cfm',
      'cfml',
      'cl',
      'clj',
      'cls',
      'cmake',
      'cmd',
      'coffee',
      'config',
      'cpp',
      'cpt',
      'cpy',
      'cs',
      'cshtml',
      'cson',
      'csproj',
      'css',
      'ctp',
      'cxx',
      'd',
      'ddl',
      'di',
      'disco',
      'dml',
      'dtd',
      'dtml',
      'el',
      'emakefile',
      'erb',
      'erl',
      'f',
      'f90',
      'f95',
      'fs',
      'fsi',
      'fsscript',
      'fsx',
      'gemfile',
      'gemspec',
      'gitconfig',
      'go',
      'groovy',
      'gvy',
      'h',
      'h++',
      'haml',
      'handlebars',
      'hbs',
      'hcp',
      'hh',
      'hpp',
      'hrl',
      'hs',
      'htc',
      'hxx',
      'idl',
      'iim',
      'inc',
      'inf',
      'ini',
      'inl',
      'ipp',
      'irbrc',
      'jade',
      'jav',
      'java',
      'js',
      'json',
      'jsp',
      'jsproj',
      'jsx',
      'l',
      'less',
      'lhs',
      'lisp',
      'log',
      'lst',
      'ltx',
      'lua',
      'm',
      'mak',
      'make',
      'manifest',
      'master',
      'md',
      'markdn',
      'markdown',
      'mdown',
      'mkdn',
      'ml',
      'mli',
      'mll',
      'mly',
      'mm',
      'mud',
      'nfo',
      'opml',
      'osascript',
      'p',
      'pas',
      'patch',
      'php',
      'php2',
      'php3',
      'php4',
      'php5',
      'phtml',
      'pl',
      'pm',
      'pod',
      'pp',
      'profile',
      'ps1',
      'ps1xml',
      'psd1',
      'psm1',
      'pss',
      'pt',
      'py',
      'pyw',
      'r',
      'rake',
      'rb',
      'rbx',
      'rc',
      'rdf',
      're',
      'reg',
      'rest',
      'resw',
      'resx',
      'rhtml',
      'rjs',
      'rprofile',
      'rpy',
      'rss',
      'rst',
      'ruby',
      'rxml',
      's',
      'sass',
      'scala',
      'scm',
      'sconscript',
      'sconstruct',
      'script',
      'scss',
      'sgml',
      'sh',
      'shtml',
      'sml',
      'svn-base',
      'swift',
      'sql',
      'sty',
      'tcl',
      'tex',
      'textile',
      'tld',
      'tli',
      'tmpl',
      'tpl',
      'vb',
      'vi',
      'vim',
      'vmg',
      'webpart',
      'wsp',
      'wsdl',
      'xhtml',
      'xoml',
      'xsd',
      'xslt',
      'yaml',
      'yaws',
      'yml',
      'zsh',
    ],
    isColorable: true,
    media: 'Text',
  },
  {
    icon: GenericFileMedium,
    extensions: ['vcf'],
    isColorable: true,
    media: 'Other',
  },
  /* css: {}, not broken out yet, snapping to 'code' for now */
  {
    icon: XlsxMedium,
    extensions: ['csv'],
    media: 'Csv',
  },
  {
    icon: GenericFileMedium,
    extensions: ['desktopfolder'],
    isColorable: true,
    media: 'Folder',
  },
  {
    icon: GenericFileMedium,
    extensions: ['docset'],
    isColorable: true,
    media: 'Folder',
  },
  {
    icon: FolderClosedMedium,
    extensions: ['documentsfolder'],
    isColorable: true,
    media: 'Folder',
  },
  {
    icon: DocxMedium,
    extensions: ['doc', 'docm', 'docx', 'docb'],
    media: 'Word',
  },
  {
    icon: DocxMedium,
    extensions: ['dot', 'dotm', 'dotx'],
    media: 'Word',
  },
  {
    icon: MailMedium,
    extensions: ['eml', 'msg', 'oft', 'ost', 'pst'],
    isColorable: true,
    media: 'Mail',
  },
  {
    icon: GenericFileMedium,
    extensions: ['application', 'appref-ms', 'apk', 'app', 'appx', 'exe', 'ipa', 'msi', 'xap'],
    isColorable: true,
    media: 'Other',
  },
  {
    icon: GenericFileMedium,
    extensions: ['favoritesfolder'],
    isColorable: true,
    media: 'Folder',
  },
  {
    icon: FolderClosedMedium,
    extensions: ['folder'],
    isColorable: true,
    media: 'Folder',
  },
  {
    icon: GenericFileMedium,
    extensions: ['ttf', 'otf', 'woff'],
    isColorable: true,
    media: 'Other',
  },
  {
    icon: FormMedium,
    extensions: ['form'],
    media: 'Other',
  },
  {
    icon: GenericFileMedium,
    extensions: ['genericfile'],
    isColorable: true,
    media: 'Other',
  },
  {
    icon: URLLinkMedium,
    extensions: ['htm', 'html', 'mht'],
    isColorable: true,
    media: 'Web',
  },
  {
    icon: GenericFileMedium,
    extensions: ['nnb', 'ipynb'],
    isColorable: true,
    media: 'Other',
  },
  {
    icon: URLLinkMedium,
    extensions: ['lnk', 'link', 'url', 'website', 'webloc'],
    isColorable: true,
    media: 'Web',
  },
  {
    icon: GenericFileMedium,
    extensions: ['linkedfolder'],
    isColorable: true,
    media: 'Folder',
  },
  {
    icon: GenericFileMedium,
    extensions: ['listitem'],
    isColorable: true,
    media: 'Other',
  },
  {
    icon: MeetingNotesMedium,
    extensions: ['fluid', 'loop'],
    isColorable: false,
    media: 'Fluid',
  },
  {
    icon: GenericFileMedium,
    extensions: ['osts'],
    isColorable: true,
    media: 'Other',
  },
  {
    icon: GenericFileMedium,
    extensions: ['splist'],
    isColorable: true,
    media: 'Other',
  },
  {
    icon: GenericFileMedium,
    extensions: [
      '3ds',
      '3mf',
      'blend',
      'cool',
      'dae',
      'df',
      'dwfx',
      'dwg',
      'dxf',
      'fbx',
      'glb',
      'gltf',
      'holo',
      'layer',
      'layout',
      'max',
      'mcworld',
      'mtl',
      'obj',
      'off',
      'ply',
      'skp',
      'stp',
      'stl',
      't',
      'thl',
      'x',
    ],
    isColorable: true,
    media: 'Other',
  },
  {
    icon: ProjectMedium,
    extensions: ['mpp'],
    isColorable: false,
    media: 'Project',
  },
  {
    icon: MultipleMedium,
    extensions: ['multiple'],
    media: 'Other',
  },
  {
    icon: OneMedium,
    // This represents a single Fluid Note, or a partial exported section/page of a notebook.
    extensions: ['note', 'one'],
    media: 'OneNotePage',
  },
  {
    icon: OneMedium,
    extensions: ['ms-one-stub', 'onetoc', 'onetoc2', 'onepkg'], // This represents a complete, logical notebook.
    media: 'OneNote',
  },
  {
    icon: GenericFileMedium,
    extensions: ['pbiapp'],
    isColorable: true,
    media: 'Other',
  },
  {
    icon: PdfMedium,
    extensions: ['pdf'],
    media: 'Pdf',
  },
  {
    icon: ImageMedium,
    extensions: [
      'arw',
      'bmp',
      'cr2',
      'crw',
      'dic',
      'dicm',
      'dcm',
      'dcm30',
      'dcr',
      'dds',
      'dib',
      'dng',
      'erf',
      'gif',
      'heic',
      'heif',
      'ico',
      'jfi',
      'jfif',
      'jif',
      'jpe',
      'jpeg',
      'jpg',
      'jxr',
      'kdc',
      'mrw',
      'nef',
      'orf',
      'pct',
      'pict',
      'png',
      'pns',
      'psb',
      'psd',
      'raw',
      'tga',
      'tif',
      'tiff',
      'wdp',
    ],
    isColorable: true,
    media: 'Image',
  },
  {
    icon: ImageMedium,
    extensions: ['photo360'],
    isColorable: true,
    media: 'Image',
  },
  {
    icon: GenericFileMedium,
    extensions: ['picturesfolder'],
    isColorable: true,
    media: 'Folder',
  },
  {
    icon: PptxMedium,
    extensions: ['pot', 'potm', 'potx'],
    media: 'PowerPoint',
  },
  {
    icon: GenericFileMedium,
    extensions: ['pbids', 'pbix'],
    isColorable: true,
    media: 'Other',
  },
  {
    icon: PptxMedium,
    extensions: ['pps', 'ppsm', 'ppsx'],
    media: 'PowerPoint',
  },
  {
    icon: PptxMedium,
    extensions: ['ppt', 'pptm', 'pptx', 'sldx', 'sldm'],
    media: 'PowerPoint',
  },
  {
    icon: GenericFileMedium,
    extensions: ['odp', 'gslides', 'key'],
    isColorable: true,
    media: 'Other',
  },
  {
    icon: GenericFileMedium,
    extensions: ['pub'],
    isColorable: true,
    media: 'Publisher',
  },
  {
    icon: GenericFileMedium,
    extensions: ['aspx'],
    isColorable: true,
    media: 'Web',
  },
  {
    icon: GenericFileMedium,
    extensions: ['sponews'],
    isColorable: true,
    media: 'Other',
  },
  {
    icon: GenericFileMedium,
    extensions: ['odc', 'ods', 'gsheet', 'numbers', 'tsv'],
    isColorable: true,
    media: 'Other',
  },
  {
    icon: GenericFileMedium,
    extensions: ['epub', 'gdoc', 'odt', 'rtf', 'wri', 'pages'],
    isColorable: true,
    media: 'Other',
  },
  {
    icon: GenericFileMedium,
    extensions: ['sharedfolder'],
    isColorable: true,
    media: 'Folder',
  },
  {
    icon: GenericFileMedium,
    extensions: ['playlist'],
    isColorable: true,
    media: 'Other',
  },
  {
    icon: SwayMedium,
    extensions: ['sway'],
    isColorable: true,
    media: 'Other',
  },
  {
    icon: GenericFileMedium,
    extensions: [
      'bak',
      'bin',
      'cab',
      'cache',
      'cat',
      'cer',
      'class',
      'dat',
      'db',
      'dbg',
      'dl_',
      'dll',
      'ithmb',
      'jar',
      'kb',
      'ldt',
      'lrprev',
      'pkpass',
      'ppa',
      'ppam',
      'pdb',
      'rom',
      'thm',
      'thmx',
      'vsl',
      'xla',
      'xlam',
      'xlb',
      'xll',
    ],
    isColorable: true,
    media: 'Other',
  },
  {
    icon: GenericFileMedium,
    extensions: ['dif', 'diff', 'readme', 'out', 'plist', 'properties', 'text', 'txt'],
    isColorable: true,
    media: 'Text',
  },
  {
    icon: GenericFileMedium,
    extensions: ['vaultclosed'],
    isColorable: true,
    media: 'Other',
  },
  {
    icon: GenericFileMedium,
    extensions: ['vaultopen'],
    isColorable: true,
    media: 'Other',
  },
  {
    icon: GenericFileMedium,
    extensions: [
      'ai',
      'ait',
      'cvs',
      'dgn',
      'gdraw',
      'pd',
      'emf',
      'eps',
      'fig',
      'ind',
      'indd',
      'indl',
      'indt',
      'indb',
      'ps',
      'svg',
      'svgz',
      'wmf',
      'oxps',
      'xps',
      'xd',
      'sketch',
    ],
    isColorable: true,
    media: 'Xps',
  },
  {
    icon: VideoMedium,
    extensions: [
      '3g2',
      '3gp',
      '3gp2',
      '3gpp',
      'asf',
      'avi',
      'dvr-ms',
      'flv',
      'm1v',
      'm4v',
      'mkv',
      'mod',
      'mov',
      'mm4p',
      'mp2',
      'mp2v',
      'mp4',
      'mp4v',
      'mpa',
      'mpe',
      'mpeg',
      'mpg',
      'mpv',
      'mpv2',
      'mts',
      'ogg',
      'qt',
      'swf',
      'ts',
      'vob',
      'webm',
      'wlmp',
      'wm',
      'wmv',
      'wmx',
    ],
    isColorable: true,
    media: 'Video',
  },
  {
    icon: VideoMedium,
    extensions: ['video360'],
    isColorable: true,
    media: 'Video',
  },
  {
    icon: VisioMedium,
    extensions: ['vdx', 'vsd', 'vsdm', 'vsdx', 'vsw', 'vdw'],
    isColorable: false,
    media: 'Visio',
  },
  {
    icon: VisioMedium,
    extensions: ['vss', 'vssm', 'vssx'],
    isColorable: false,
    media: 'Visio',
  },
  {
    icon: VisioMedium,
    extensions: ['vst', 'vstm', 'vstx', 'vsx'],
    isColorable: false,
    media: 'Visio',
  },
  {
    icon: GenericFileMedium,
    extensions: ['whiteboard', 'wbtx'],
    isColorable: true,
    media: 'Other',
  },
  {
    icon: XlsxMedium,
    extensions: ['xlc', 'xls', 'xlsb', 'xlsm', 'xlsx', 'xlw'],
    media: 'Excel',
  },
  {
    icon: XlsxMedium,
    extensions: ['xlt', 'xltm', 'xltx'],
    media: 'Excel',
  },
  {
    icon: GenericFileMedium,
    extensions: ['xaml', 'xml', 'xsl'],
    isColorable: true,
    media: 'Xml',
  },
  {
    icon: GenericFileMedium,
    extensions: ['xsn'],
    isColorable: true,
    media: 'Other',
  },
  {
    icon: ZipMedium,
    extensions: ['zip'],
    isColorable: true,
    media: 'Archive',
  },
];
interface ForTranspose {
  [key: string]: FileIconProps | undefined;
}
const transposed: ForTranspose = {};
each(categories, ({ icon, extensions, isColorable, media }) => {
  each(extensions, ext => {
    transposed[`.${ext}`] = { icon, isColorable, media };
  });
});

type ForMediaTypeMap = {
  [key in MediaKeysType]: IconProps | undefined;
};
const mimeTypeMap: ForMediaTypeMap = {
  PowerPoint: { icon: PptxMedium },
  Word: { icon: DocxMedium },
  Excel: { icon: XlsxMedium },
  Pdf: { icon: PdfMedium },
  OneNote: { icon: OneMedium },
  OneNotePage: { icon: OneMedium },
  InfoPath: { icon: GenericFileMedium, isColorable: true },
  Visio: { icon: VisioMedium, isColorable: false },
  Publisher: { icon: GenericFileMedium, isColorable: true },
  Project: { icon: ProjectMedium, isColorable: false },
  Access: { icon: GenericFileMedium, isColorable: true },
  Mail: { icon: MailMedium, isColorable: true },
  Csv: { icon: XlsxMedium },
  Archive: { icon: ZipMedium, isColorable: true, noPreview: true },
  Xps: { icon: GenericFileMedium, isColorable: true },
  Audio: { icon: MusicMedium, isColorable: true },
  Video: { icon: VideoMedium, isColorable: true },
  Image: { icon: ImageMedium, isColorable: true },
  Web: { icon: GenericFileMedium, isColorable: true },
  Text: { icon: GenericFileMedium, isColorable: true },
  Xml: { icon: GenericFileMedium, isColorable: true },
  Story: { icon: GenericFileMedium, isColorable: true },
  ExternalContent: { icon: GenericFileMedium, isColorable: true, noPreview: true },
  Folder: { icon: FolderClosedMedium, isColorable: true },
  Spsite: { icon: SiteMedium, isColorable: true },
  Fluid: { icon: MeetingNotesMedium, isColorable: false, noPreview: true },
  Other: { icon: GenericFileMedium, isColorable: true, noPreview: true },
};
export const getExtensionAndName = (nameOrPath: string) => {
  const name = nameOrPath.slice(max([nameOrPath.indexOf('/'), 0]));
  const idx = name.lastIndexOf('.');
  return idx > -1
    ? {
        extension: name.substring(idx),
        name: name.substring(0, idx),
      }
    : { name: name, extension: '' };
};

export const getExtension = (nameOrPath: string) => {
  return getExtensionAndName(nameOrPath).extension;
};

export const getFileMediaType = (name: string): MediaKeysType => {
  return transposed[getExtension(name)]?.media || 'Other';
};

export const getFileIcon = (nameOrPathOrExt?: string): FileIconProps => {
  const ext = nameOrPathOrExt && getExtension(nameOrPathOrExt);
  return (
    (ext && transposed[ext.toLowerCase()]) || {
      icon: GenericFileMedium,
      isColorable: true,
      media: 'Other',
    }
  );
};

export const getMediaTypeIcon = (mType: string): IconProps => {
  return mimeTypeMap[mType] || { icon: GenericFileMedium, isColorable: true };
};
