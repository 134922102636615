import { Theme, Components } from '@mui/material/styles';

export default (theme: Theme) => {
  const styleOverrides: Components['MuiSlider'] = {
    styleOverrides: {
      root: {
        height: '1px',
        padding: 0,
      },
      rail: {
        background: theme.palette.outlinesAndStrokes.secondary,
        borderRadius: '2px',
        height: '3px',
        opacity: 1,
      },
      track: {
        background: theme.palette.buttons.accentPrimary,
        borderColor: theme.palette.buttons.accentPrimary,
      },
      thumb: {
        background: theme.palette.backgrounds.primary,
        boxShadow: 'none',
        border: `2px solid ${theme.palette.buttons.accentPrimary}`,
        width: '16px',
        marginLeft: '7px',
        height: '16px',
        ['&:hover']: {
          boxShadow: `0px 0px 0px 6px rgba(144, 202, 249, 0.16) !important`,
        },
        ['&:active, &.Mui-focusVisible']: {
          boxShadow: `0px 0px 0px 6px rgba(144, 202, 249, 0.16) !important`,
        },
      },
    },
  };
  return styleOverrides;
};
