import { strings } from '@vendor/languages';
import { ItemContainer } from '../itemTypes';

export const locationHaveQuickSearch = (location: ItemContainer) => location.locationHaveQuickSearch;

export const locationPlaceholderQuickSearch = (location?: ItemContainer) =>
  location === undefined || location.isAdvancedSearchContainer
    ? strings.lang.quickSearch.generalSearch
    : !location.supportSearch
      ? strings.lang.quickSearch.chatAndFiles
      : location.isRootSection
        ? strings.lang.quickSearch.oneDrive
        : strings.lang.quickSearch.searchPlaceholder({ patch: location.type || 'location' });

export const isSameLocationForParent = (parent?: ItemContainer, searchedLocationId?: string) => {
  return parent?.id === searchedLocationId;
};
