import { MouseEventHandler, useCallback, useContext } from 'react';
import { MouseEventsInterface, MouseEventsContext, ContextMenuContext } from '@storybook';
import {
  AllowedItemType,
  useCombinedHandlers,
  getQuickActionsList,
  isSameLocationForParent,
  getAllContextActions,
  generateActionMenu,
} from '~/utilities';
import { useOpenContaingLocation } from '~/utilities/actions/OpenContainingLocation';
import { ItemDataRenderer } from './GetNodeRenderer';
import { TransientLocationLabelTooltip } from './TransientNodeRenderer';

export const searchItemHandler = (_type: AllowedItemType, nextRenderer: ItemDataRenderer): ItemDataRenderer => {
  return props => {
    const { node, onDoubleClick, onSelect } = props;
    const { data } = node;
    const onParentClick = useOpenContaingLocation(node, 'Breadcrumbs', props.handlers);
    const onSecondLineClick = useCallback<MouseEventHandler<HTMLDivElement>>(
      event => {
        event.preventDefault();
        event['no-open'] = true;
        onParentClick(event);
      },
      [onParentClick]
    );
    const secondLine = (data.secondLineContent && data.secondLineContent) || '';

    const { handleContextMenu } = useContext(ContextMenuContext);
    const { waitForAction, actionDone } = useContext<MouseEventsInterface>(MouseEventsContext);
    const handleClick = useCallback<MouseEventHandler<HTMLDivElement>>(
      event => {
        const openItemEnabled = !event['no-open'];
        const isContextMenu = event.type === 'contextmenu';
        if (isContextMenu) {
          event.preventDefault();
          const { actionsStructure } = getQuickActionsList([props.node], getAllContextActions(), true);
          const actions = generateActionMenu(actionsStructure, [props.node], 'Context Menu', props.handlers);

          if (actions.length) {
            waitForAction?.();
            handleContextMenu?.(event, actions, actionDone);
          }
        } else {
          openItemEnabled && !props.node.data.isAdvancedSearchItem && onDoubleClick?.(event);
        }
      },
      [actionDone, handleContextMenu, onDoubleClick, props.handlers, props.node, waitForAction]
    );

    return nextRenderer({
      ...props,
      ...(!data.isDocument ? { RenderTooltip: TransientLocationLabelTooltip } : {}),
      secondLine: {
        text: secondLine,
        handle: !isSameLocationForParent(data.parent, data.searchedLocationId) ? onSecondLineClick : null,
      },
      onSelect: useCombinedHandlers(onSelect, handleClick),
    });
  };
};
