import React, { useCallback, useMemo } from 'react';
import {
  Box,
  ButtonProps,
  ButtonsStack,
  Notifications,
  NotificationsTitle,
  NotificationsVariant,
  Scrollbar,
  Typography,
  useTheme,
} from '@harmon.ie-ce/storybook';
import { NavigateFunction } from 'react-router-dom';
import { strings } from '@vendor/languages';

export const DialogNotifications: React.FC<{
  navigate?: NavigateFunction;
  showError: boolean;
  setShowError: React.Dispatch<React.SetStateAction<boolean>>;
  hasAction: boolean;
  title: string;
  message: string;
}> = ({ navigate, showError, setShowError, hasAction, title, message }) => {
  const onClose = useCallback(() => {
    setShowError(false);
  }, [setShowError]);

  const goBack = useCallback(() => {
    onClose();
    navigate !== undefined ? navigate(-1) : '';
  }, [navigate, onClose]);

  const buttonRow: ButtonProps[] = useMemo(
    () => [
      {
        children: strings.lang.actiosNames.goBack,
        variant: 'primary',
        onClick: goBack,
      },
      {
        children: strings.lang.actiosNames.ok,
        variant: 'primary',
        onClick: onClose,
      },
    ],
    [goBack, onClose]
  );

  const theme = useTheme();

  return (
    <Notifications
      actions={hasAction ? <ButtonsStack buttons={[buttonRow[0]]} /> : <ButtonsStack buttons={[buttonRow[1]]} />}
      open={showError}
      onClose={!hasAction ? onClose : undefined}
      notificationsTitle={
        <NotificationsTitle
          variant={NotificationsVariant(theme)}
          title={title}
          hasCloseDilaog={!hasAction}
          onClose={onClose}
        />
      }
    >
      <Scrollbar>
        <Box maxHeight={'224px'} maxWidth={'245px'} mr="40px">
          <Typography
            variant="H3Regular"
            sx={{
              whiteSpace: 'pre-wrap', // Allows white spaces and line breaks
              wordWrap: 'break-word', // Breaks the words to prevent overflow
              overflowWrap: 'break-word', // Ensures words break to prevent overflow
            }}
          >
            {message}
          </Typography>
        </Box>
      </Scrollbar>
    </Notifications>
  );
};
