import { Theme } from '@mui/system';
import { ErrorSmall } from '@harmon.ie-ce/storybook';

export interface NotificationsVariantProps {
  icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  iconBackground: string;
  titleColor: string;
  checkBox: boolean;
}

export const NotificationsVariant = (theme: Theme): NotificationsVariantProps => {
  return {
    icon: ErrorSmall,
    iconBackground: theme.palette.status.dangerSecondary,
    titleColor: theme.palette.status.dangerPrimary,
    checkBox: true,
  };
};
