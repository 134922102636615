import React from 'react';
import { OpenInBrowserSmall, ViewItem, OpenInBrowserMedium, MediaKeysType } from '@storybook';
import { asType, useGraphClient } from '@services';
import { strings } from '@vendor/languages';
import { DocumentItem, isOfficeAppDocument, ItemData } from '../itemTypes';
import { ActionRendererHookType, BaseReadActionClass, TrackedEventType } from './BaseAction';
import { OpenAction } from '../misc/utils';

const useOpenInBrowserAction: ActionRendererHookType = ({
  nodes,
  useSafeCallback,
  actionVariant,
  action,
  actionRef,
}) => {
  const gcl = useGraphClient();
  const that = action as OpenInBrowserAction;
  const node = nodes[0];
  const onClick = useSafeCallback(
    async (e: React.MouseEvent<Element, MouseEvent>) => {
      if (!actionVariant.withText) e.stopPropagation();
      const nData = node.data as ItemData;
      actionRef.current = asType<OpenDocumentContext>({
        mode: OpenAction.OPEN,
        mediaType: nData.isDocument ? (nData as DocumentItem).mediaType : undefined,
      });
      const urls = await nData.getAccessUrls(gcl);
      urls && window.open(urls.webUrl);
    },
    [actionVariant.withText, node.data, actionRef, gcl],
    false,
    {
      onSuccessTitle: strings.lang.notifications.openInBrowser,
    }
  );

  return {
    title: that.title,
    icon: [OpenInBrowserSmall, OpenInBrowserMedium],
    onClick,
  };
};

abstract class BaseOpenInBrowserAction extends BaseReadActionClass {
  abstract get title(): string;

  readonly useRenderer = useOpenInBrowserAction;

  isHandled(nodes: ViewItem<ItemData>[]): boolean {
    return nodes.length === 1 && nodes[0].data.canOpenInBrowser;
  }
}

export class OpenInBrowserAction extends BaseOpenInBrowserAction {
  readonly trackedName = 'OpenInApp';
  readonly title = strings.lang.actionToolTip.openInBrowser;

  transformEvent({ data, eventName }: TrackedEventType) {
    return {
      eventName,
      data: { ...data, attribute: 'Browser' },
    } as TrackedEventType;
  }
  override isHandled(nodes: ViewItem<ItemData>[]): boolean {
    const data = nodes[0]?.data;
    return super.isHandled(nodes) && !data.isDocument;
  }
}

export interface OpenDocumentContext {
  mode: OpenAction;
  mediaType?: MediaKeysType;
}

export class OpenDocumentInBrowserAction extends BaseOpenInBrowserAction {
  readonly trackedName = 'OpenDocument';
  get title() {
    return this.forOfficeDocs ? strings.lang.actionToolTip.openInBrowserApp : strings.lang.actionToolTip.openInBrowser;
  }
  constructor(readonly forOfficeDocs: boolean | undefined) {
    super();
  }

  override transformEvent({ data }: TrackedEventType, ctx: OpenDocumentContext = { mode: OpenAction.OPEN }) {
    return {
      eventName: this.trackedName,
      data: { ...data, mode: ctx.mode == OpenAction.OPEN ? 'Browser' : 'Desktop', mediaType: ctx.mediaType },
    } as TrackedEventType;
  }
  override isHandled(nodes: ViewItem<ItemData>[]): boolean {
    const data = nodes[0]?.data;
    return (
      super.isHandled(nodes) &&
      data.isDocument &&
      (this.forOfficeDocs == undefined || this.forOfficeDocs == isOfficeAppDocument(data))
    );
  }
}
