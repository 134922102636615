import React from 'react';
import { Separator } from '@storybook';
import {
  AllowedItemType,
  RecentRoot,
  SharedWithMeRoot,
  TeamChatFiles,
  SharePointSitesRoot,
  TeamsRoot,
  FavoritesRoot,
} from '~/utilities';
import { RenderEmptyState } from './RenderEmptyState';
import { renderInsight } from './RenderInsight';
import { RenderViewFiles } from './RenderViewFiles';
import { renderWithAutoRefresh } from './RenderWithAutoRefresh';
import { renderWithReload } from './RenderWithReload';
import { ItemDataRenderer, TreeNodeRenderer } from './GetNodeRenderer';

type ForRender<T> = {
  [key in AllowedItemType]?: T;
};

export const byTypeItemRenderer = (type: AllowedItemType, nextRenderer: ItemDataRenderer): ItemDataRenderer => {
  const typeRenderer: ForRender<ItemDataRenderer> = {
    insight: renderInsight(nextRenderer),
    document: renderInsight(nextRenderer),
    empty: props => <RenderEmptyState {...props} />,
    separator: () => <Separator />,
    viewfiles: props => <RenderViewFiles {...props} />,
  };
  return typeRenderer[type] || nextRenderer;
};

export const byTypeTreeRenderer = (type: AllowedItemType, nextRenderer: TreeNodeRenderer): TreeNodeRenderer => {
  const typeRenderer: ForRender<TreeNodeRenderer> = {
    recentroot: renderWithReload(
      () => RecentRoot.RecentCountState,
      renderWithReload(() => RecentRoot.RecentFolders, renderWithAutoRefresh(nextRenderer))
    ),
    sharedwithmeroot: renderWithReload(
      () => SharedWithMeRoot.SharedFilterState,
      renderWithReload(() => SharedWithMeRoot.SharedCountState, renderWithAutoRefresh(nextRenderer))
    ),
    teamschatfiles: renderWithReload(() => TeamChatFiles.ChatsCountState, renderWithAutoRefresh(nextRenderer)),
    sharepointroot: renderWithReload(() => SharePointSitesRoot.Settings, nextRenderer),
    teamsroot: renderWithReload(() => TeamsRoot.Settings, nextRenderer),
    favoritesroot: renderWithReload(() => FavoritesRoot.AllFavorites, nextRenderer),
  };

  return typeRenderer[type] || nextRenderer;
};
