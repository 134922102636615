import React from 'react';
import { styled } from '@mui/material';
import { Loader } from 'react-hyper-tree';
import { Stack } from '../../Stack';

const LoaderRoot = styled(Stack)`
  flex-direction: row;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  min-width: 23px;
  height: 23px;
  justify-content: center;
`;

export const TreeViewLoader = () => {
  return (
    <LoaderRoot>
      <Loader />
    </LoaderRoot>
  );
};
