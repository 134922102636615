import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { SimpleDialogTexts } from '@vendor/languages';
import { ButtonsStack, Checkbox, Dialog, Stack, Typography, UndoAction } from '..';

interface YesNoDialogProps {
  texts: SimpleDialogTexts;
  blockOutsideClick: boolean;
  primaryActionCallback: () => Promise<void | UndoAction> | void;
  secondryActionCallback?: () => Promise<void | UndoAction> | void;
  hasCheckbox?: boolean;
  onChecked?: () => void;
  onCloseAction?: () => Promise<void>;
  isWarningAction?: boolean;
}

export const useYesNoDialog = ({
  texts,
  primaryActionCallback,
  secondryActionCallback,
  onChecked,
  hasCheckbox,
  onCloseAction,
  blockOutsideClick,
  isWarningAction = false,
}: YesNoDialogProps) => {
  const [isDialogVisible, setIsDialogVisible] = useState<boolean>(false);
  const [isChecked, setIsChecked] = useState<boolean>(false);

  const onClose = useCallback(
    async (runOnCloseAction?: boolean) => {
      setIsDialogVisible(false);
      setIsChecked(false);
      if (Boolean(runOnCloseAction)) onCloseAction && (await onCloseAction());
    },
    [onCloseAction]
  );

  const onCheckedClick = useCallback(() => {
    setIsChecked(!isChecked);
    onChecked && onChecked();
  }, [isChecked, onChecked]);

  const onPrimaryAction = useCallback(async () => {
    onClose();
    await primaryActionCallback();
  }, [primaryActionCallback, onClose]);
  const onSecondryAction = useCallback(async () => {
    onClose();
    secondryActionCallback && (await secondryActionCallback());
  }, [onClose, secondryActionCallback]);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'Enter' && isDialogVisible) {
        onPrimaryAction();
        setTimeout(() => {
          const activeElement = document.activeElement as HTMLElement;
          if (activeElement && typeof activeElement.blur === 'function') {
            activeElement.blur();
          }
        }, 500);
      }
    };
    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [isDialogVisible, onPrimaryAction]);

  const buttonInputRow: any[] = useMemo(
    () => [
      {
        children: texts.secondaryActionText,
        variant: isWarningAction ? 'text' : 'secondary',
        onClick: onSecondryAction,
      },
      {
        children: texts.primaryActionText,
        variant: isWarningAction ? 'outlinedWarning' : 'primary',
        onClick: onPrimaryAction,
      },
    ],
    [texts.secondaryActionText, texts.primaryActionText, isWarningAction, onSecondryAction, onPrimaryAction]
  );

  const YesNoDialog = (
    <Dialog
      actions={<ButtonsStack buttons={buttonInputRow} />}
      open={!!isDialogVisible}
      onClose={(event, reason) => {
        if (blockOutsideClick && reason === 'backdropClick') {
          // do nothing
        } else {
          onClose(true);
        }
      }}
      title={texts.dialogTitle}
      maxWidth="xs"
      titleClassName="user-question-dialog-title"
      hasCloseAction={!blockOutsideClick}
    >
      <Typography variant="H3Regular">{texts.dialogSubTitle}</Typography>
      {hasCheckbox && (
        <Stack mt={2}>
          <Checkbox
            checked={isChecked}
            onChange={onCheckedClick}
            label={texts.checkboxLabel}
            className="default-checkbox"
          />
        </Stack>
      )}
    </Dialog>
  );

  return {
    YesNoDialog,
    setIsDialogVisible,
  };
};
