import { EllipseSelected, Menu, MenuItem, Typography, Divider } from '@harmon.ie-ce/storybook';
import { FormControlLabel, styled } from '@mui/material';
import MuiCheckbox from '@mui/material/Checkbox';
import { rest } from 'lodash';
import React from 'react';
import { OrderByInfo, SortAction } from './SortAction';

export const SortCheckbox = styled(MuiCheckbox)`
  margin-left: ${({ theme }) => theme.spacing(3)};
  margin-right: ${({ theme }) => theme.spacing(1)};
  margin-top: 3px;
  & g {
    filter: none;
  }
  &:hover {
    background-color: transparent;
  }
`;

export const Ellipse = styled(EllipseSelected)`
  g rect {
    fill: ${({ theme }) => theme.palette.outlinesAndStrokes.primary};
  }

  g circle {
    fill: ${({ theme }) => theme.palette.backgrounds.overlaidComponentsPrimary};
    r: 7;
  }
`;

const SortMenu = styled(Menu)`
  margin-top: initail;
  display: flex;
  justify-content: flex-end;
  margin-top: ${({ theme }) => theme.spacing(2)};
  .MuiPaper-root {
    margin: ${({ theme }) => theme.spacing(0)};
    display: flex;
    justify-content: flex-end;
    left: unset !important;
    margin-right: ${({ theme }) => theme.spacing(4)};
    width: ${({ theme }) => theme.spacing(37)};
    .MuiList-root {
      width: 100%;
    }
  }
`;

export interface SortDialogProps {
  anchorEl: HTMLElement | null;
  items: any[];
  acionsNames: string[];
  orderBy: OrderByInfo;
  setAnchorEl: React.Dispatch<React.SetStateAction<HTMLElement | null>>;
  onSelect: (value: OrderByInfo) => void;
}

export const SortMenuAction = ({ anchorEl, items, acionsNames, onSelect, setAnchorEl, orderBy }: SortDialogProps) => {
  return (
    <SortMenu
      sx={{ width: '100%' }}
      open={Boolean(anchorEl)}
      {...rest}
      variant="menu"
      anchorEl={anchorEl}
      onClose={() => setAnchorEl(null)}
    >
      {items.map((item: any) => (
        <MenuItem key={item.displayName} sx={{ width: '100%' }}>
          <SortAction actionName={item.displayName} orderBy={orderBy} onSelect={onSelect} itemValue={item.value} />
        </MenuItem>
      ))}
      <Divider sx={{ width: '100%' }} />

      <MenuItem sx={{ width: '100%' }} key={acionsNames[0]}>
        <FormControlLabel
          sx={{ width: '100%' }}
          label={
            <Typography variant="H2Regular" noWrap>
              {acionsNames[0]}
            </Typography>
          }
          control={
            <SortCheckbox
              disableRipple
              checked={orderBy.dir}
              onClick={() => !orderBy.dir && onSelect({ field: orderBy.field || '', dir: true })}
              value={acionsNames[0]}
              icon={<Ellipse />}
              checkedIcon={<EllipseSelected />}
            />
          }
        />
      </MenuItem>
      <MenuItem sx={{ width: '100%' }} key={acionsNames[1]}>
        <FormControlLabel
          sx={{ width: '100%' }}
          label={
            <Typography variant="H2Regular" noWrap>
              {acionsNames[1]}
            </Typography>
          }
          control={
            <SortCheckbox
              checked={!orderBy.dir}
              onClick={() => orderBy.dir && onSelect({ field: orderBy.field || '', dir: false })}
              value={acionsNames[1]}
              icon={<Ellipse />}
              checkedIcon={<EllipseSelected />}
            />
          }
        />
      </MenuItem>
    </SortMenu>
  );
};
