import { Theme, Components } from '@mui/material/styles';

export default (theme: Theme) => {
  const styleOverrides: Components['MuiDialogContent'] = {
    styleOverrides: {
      root: {
        '&.error-dialog-content': {
          padding: theme.spacing(0, 0),
          marginBottom: theme.spacing(2),
          paddingRight: '13px !important',
        },
        padding: theme.spacing(0, 4),
        ' .MuiCheckbox-root': {
          padding: 0,
          marginLeft: 0,
          marginRight: theme.spacing(2),
        },
        ' input[type="checkbox"]': {
          padding: 0,
          width: '16px',
          height: '16px',
        },
      },
    },
  };
  return styleOverrides;
};
