import React from 'react';
import {
  Button,
  DeleteMedium,
  Divider,
  LogoutMedium,
  ResethMedium,
  Scrollbar,
  Slider,
  ThemeSelector,
  Typography,
  useTheme,
} from '@storybook';
import { AppConfig } from '@services';
import { strings } from '@vendor/languages';
import { Box, FormControlLabel, Radio, RadioGroup, Stack, Switch } from '@mui/material';
import { OpenAction } from '~/utilities';
import { TopBar } from '../../components';
import { useSettingsController } from './useSettingsController';
import { useSettings } from './SettingContext/SettingContext';

const Setting = () => {
  const uiTheme = useTheme();
  const {
    theme,
    changeTheme,
    changeGeneralNumber,
    changeOpenSettings,
    generaFilesCount,
    openFilesSettings,
    showTreeHistory,
    changeShowHistory,
    resetSetting,
    changeEmailNameSettings,
    emailNameSettings,
  } = useSettings();
  const { showDialog, YesNoDialog } = useSettingsController(resetSetting);
  return (
    <Stack sx={{ background: uiTheme.palette.backgrounds.primary }} minHeight="100%">
      <Stack
        alignItems="center"
        justifyContent="flex-start"
        px={3}
        py={2}
        sx={{
          position: 'sticky', // Set the position to sticky
          bottom: 0, // Stick to the bottom
          zIndex: 1000, // Ensure it's above other content (if necessary)
          width: '100%', // Full-width to align with its container
        }}
      >
        <TopBar isSettingsPage={true} />
      </Stack>
      <Scrollbar>
        <Stack minHeight="100%" px={3} py={2}>
          <Stack flex={1} spacing={7}>
            <ThemeSelector theme={theme} setTheme={theme => changeTheme?.(theme)} />
            <Stack>
              <Typography color={uiTheme.palette.texts.primary} variant="H1SemiBold">
                {strings.lang.userSetting.documentOpenTitle}
              </Typography>
              <Typography mb={2} color={uiTheme.palette.texts.secondary} variant="H3Regular">
                {strings.lang.userSetting.documentOpenDescription}
              </Typography>
              <RadioGroup
                aria-labelledby="open-document"
                name="open-document"
                value={openFilesSettings}
                onChange={e => changeOpenSettings?.(e.target.value as OpenAction)}
              >
                <FormControlLabel
                  value={OpenAction.IN_APP}
                  sx={{ flexDirection: 'row-reverse', margin: 0 }}
                  slotProps={{
                    typography: {
                      sx: { flex: 1 },
                    },
                  }}
                  control={<Radio />}
                  label={
                    <Typography sx={{ flex: 1 }} variant="H2Regular">
                      {strings.lang.doubleClickAction.openInApp}
                    </Typography>
                  }
                />
                <FormControlLabel
                  sx={{ flexDirection: 'row-reverse', margin: 0 }}
                  slotProps={{
                    typography: {
                      sx: { flex: 1 },
                    },
                  }}
                  value={OpenAction.OPEN}
                  control={<Radio />}
                  label={<Typography variant="H2Regular">{strings.lang.doubleClickAction.openInBrowser}</Typography>}
                />
                <FormControlLabel
                  sx={{ flexDirection: 'row-reverse', margin: 0 }}
                  slotProps={{
                    typography: {
                      sx: { flex: 1 },
                    },
                  }}
                  value={OpenAction.CHOOSE}
                  control={<Radio />}
                  label={<Typography variant="H2Regular">{strings.lang.doubleClickAction.askEveryTime}</Typography>}
                />
              </RadioGroup>
            </Stack>
            <Stack>
              <Slider
                titleVariant="H1SemiBold"
                messageVariant="H2Regular"
                withoutWrap={true}
                value={generaFilesCount}
                onChange={n => changeGeneralNumber?.(n)}
                min={5}
                max={25}
                message={strings.lang.userSetting.treeSettingFileDescription}
                title={strings.lang.userSetting.treeSettingFileTitle}
              />
            </Stack>
            <Stack>
              <Typography mb={2} color={uiTheme.palette.texts.primary} variant="H1SemiBold">
                {strings.lang.userSetting.treeSettingHistoryTitle}
              </Typography>
              <FormControlLabel
                value={true}
                sx={{ flexDirection: 'row-reverse', margin: 0 }}
                slotProps={{
                  typography: {
                    sx: { flex: 1 },
                  },
                }}
                control={<Switch checked={showTreeHistory} onClick={() => changeShowHistory?.(!showTreeHistory)} />}
                label={
                  <Typography sx={{ flex: 1 }} variant="H2Regular">
                    {strings.lang.userSetting.treeSettingHistoryDescription}
                  </Typography>
                }
              />
            </Stack>

            <Stack>
              <Typography color={uiTheme.palette.texts.primary} variant="H1SemiBold">
                {strings.lang.userSetting.emailNameTitle}
              </Typography>
              <Typography mb={2} color={uiTheme.palette.texts.secondary} variant="H3Regular">
                {strings.lang.userSetting.emailNamesDescription}
              </Typography>
              <RadioGroup
                aria-labelledby="open-document"
                name="open-document"
                value={emailNameSettings}
                onChange={e => changeEmailNameSettings?.(e.target.value === 'true')}
              >
                <FormControlLabel
                  value={'false'}
                  sx={{ flexDirection: 'row-reverse', margin: 0 }}
                  slotProps={{
                    typography: {
                      sx: { flex: 1 },
                    },
                  }}
                  control={<Radio />}
                  label={
                    <Typography sx={{ flex: 1 }} variant="H2Regular">
                      {strings.lang.userSetting.emailNamesDefault}
                    </Typography>
                  }
                />
                <FormControlLabel
                  sx={{ flexDirection: 'row-reverse', margin: 0 }}
                  slotProps={{
                    typography: {
                      sx: { flex: 1 },
                    },
                  }}
                  value={'true'}
                  control={<Radio />}
                  label={<Typography variant="H2Regular">{strings.lang.userSetting.emailNamesAdvanced}</Typography>}
                />
              </RadioGroup>
            </Stack>
            <Stack alignItems="flex-start">
              <Typography mb={1} color={uiTheme.palette.texts.primary} variant="H1SemiBold">
                Account
              </Typography>
              <Stack alignItems="flex-start" ml={-1}>
                <Button onClick={() => showDialog('clearDialog')} variant="text">
                  <DeleteMedium />
                  <Box width="5px" />
                  {strings.lang.userSetting.clearTitle}
                </Button>
                <Button onClick={() => showDialog('resetDialog')} variant="text">
                  <ResethMedium />
                  <Box width="5px" />
                  {strings.lang.userSetting.resetTitle}
                </Button>
                <Button onClick={() => showDialog('signoutDialog')} variant="text">
                  <LogoutMedium />
                  <Box width="5px" />
                  {strings.lang.signOut}
                </Button>
              </Stack>
            </Stack>
          </Stack>
          <Stack alignItems="flex-end" direction="row" justifyContent="center" spacing={2} flex={1} mt={6}>
            <Typography variant="H3Medium" sx={{ color: uiTheme.palette.texts.quaternary }}>
              {`Version ${AppConfig.getInstance().version}`}
            </Typography>
            {process.env.REACT_APP_GIT_COMMIT_HASH && (
              <>
                {' '}
                <Divider
                  sx={{ maxHeight: '16px', minHeight: '16px', color: uiTheme.palette.texts.secondary }}
                  orientation="vertical"
                />
                <Typography variant="H3Medium" sx={{ color: uiTheme.palette.texts.quaternary }}>
                  {`Build ${process.env.REACT_APP_GIT_COMMIT_HASH}`}
                </Typography>
              </>
            )}
          </Stack>
        </Stack>
        {YesNoDialog}
      </Scrollbar>
    </Stack>
  );
};

export default Setting;
