import { formatDateByTime } from '@vendor/utils/dates';
import { strings } from '@vendor/languages';
import { Channel, InsightItem, Team, TeamChatFiles } from '../graphTypes';
import { AllowedItemType, ErrorItem, ItemData, EmptyStateItem, DocumentItem } from '../itemTypes';
import { SeparatorItem } from '../itemTypes/SeparatorItem';
import { ViewFilesItem } from '../itemTypes/ViewFilesItem';
import { SPFolder, SPSite, SPList, SPDocument } from '../sharePointTypes';
import {
  FavoritesRoot,
  OneDriveRoot,
  RecentRoot,
  SharePointSitesRoot,
  TeamsRoot,
  SharedWithMeRoot,
} from '../virtualRoots';
import { Search } from '../search';
import { AllLocations } from '../virtualRoots/AllLocations';

type JsonReaderMap = {
  [key in AllowedItemType]: (res: any) => ItemData;
};

const jsonReaders: JsonReaderMap = {
  recentroot: (): ItemData => RecentRoot.instance,
  favoritesroot: (): ItemData => FavoritesRoot.instance,
  sharepointroot: () => new SharePointSitesRoot(),
  teamsroot: () => new TeamsRoot(),
  onedriveroot: () => new OneDriveRoot(),
  sharedwithmeroot: () => new SharedWithMeRoot(),

  error: res => new ErrorItem(res),
  separator: () => new SeparatorItem(),
  teamschatfiles: _res => new TeamChatFiles(),

  team: res => new Team(res),
  channel: res => {
    const team = jsonReaders['team'](res.team);
    return new Channel({ ...res, team });
  },

  site: res => new SPSite(res),
  list: res => {
    const site = jsonReaders['site'](res.site);
    return new SPList({ ...res, site });
  },
  folder: res => {
    if (res.id === 'TeamsChatFiles') return new TeamChatFiles();
    const list = jsonReaders['list'](res.list);
    return new SPFolder({ ...res, list });
  },
  document: res => {
    const parent = jsonReaders['folder'](res.parent);
    return new SPDocument({ ...res, parent });
  },
  insight: res => new InsightItem(res),
  empty: res => new EmptyStateItem(res),
  viewfiles: res => new ViewFilesItem(res),
  SearchResults: () => new Search('', true),
  home: () => AllLocations.instance,
};

export const createItemDataFromJson = (res: any): ItemData => {
  return jsonReaders[res.type](res);
};

export const cloneItemData = (i: ItemData) => {
  return createItemDataFromJson(i.toJson());
};

export const getModifiedByString = (data: DocumentItem) => {
  return `${data.user?.displayName} modified ${formatDateByTime(data.date, strings.lang.dateFormat)}`;
};
