import { Theme, Components } from '@mui/material/styles';

export default (_theme: Theme) => {
  const styleOverrides: Components['MuiTypography'] = {
    defaultProps: {
      variant: 'H3Regular',
    },
  };
  return styleOverrides;
};
