import React, { useEffect, useState } from 'react';
import {
  FavoriteOutlineSmall,
  FavoriteFillSmall,
  ViewItem,
  FavoriteMedium,
  FavoriteFillMedium,
  filledFavoriteAnimationData,
  emptyFavoriteAnimationData,
  LottieAnimation,
  itemFastActions,
  favoriteQuickActions,
} from '@storybook';
import { strings } from '@vendor/languages';
import {
  ActionItemType,
  ActionRendererHookType,
  BaseAddActionClass,
  TrackedEventName,
  TrackedEventType,
} from './BaseAction';
import { FavoritesRoot } from '../virtualRoots';
import { ItemData } from '../itemTypes';

const useFavoriteAction: ActionRendererHookType = ({ nodes: nodes, actionVariant, useSafeCallback, actionRef }) => {
  const allFavs = FavoritesRoot.AllFavorites;
  const isFavorite = allFavs.isFavorite(nodes[0].data as ItemData);
  const [isSelected, setIsSelected] = useState(isFavorite);
  const [playAnimation, setPlayAnimation] = useState(false);

  const onClick = useSafeCallback(
    async () => {
      //const keepFavs = new Map(allFavs.value);
      nodes.map(node => {
        const data = node.data as ItemData;
        const isFavorite = allFavs.isFavorite(data);
        if (isFavorite) allFavs.remove(data.apiIdKey);
        else {
          allFavs.add(data, Date.now());
          FavoritesRoot.AddNewItem(data);
        }
        setIsSelected(!isFavorite);
        setPlayAnimation(true);
      });
      actionRef.current = isFavorite;
      if (isFavorite) {
        return {
          run: () => {
            nodes.map(node => {
              const data = node.data as ItemData;
              allFavs.add(data, data.timeStamp);
            });
          },
        };
      }
    },
    [actionRef, allFavs, isFavorite, nodes],
    false,
    {
      onSuccessTitle: isFavorite
        ? strings.lang.notifications.renovedFromFavorite
        : strings.lang.notifications.addToFavorite,
    },
    undefined
  );

  useEffect(() => {
    if (isSelected !== isFavorite) setIsSelected(isFavorite);
    if (playAnimation) {
      setTimeout(() => {
        setPlayAnimation(false);
      }, 50); // Adjust the timeout duration to match the duration of your animation
    }
  }, [isFavorite, isSelected, playAnimation]);
  if (isSelected && actionVariant === itemFastActions) {
    actionVariant = favoriteQuickActions;
  }
  const fill = actionVariant.withText ? {} : { 'data-nofill': true };

  return {
    title: isFavorite ? strings.lang.actionToolTip.removefavorite : strings.lang.actionToolTip.addfavorite,
    icon: playAnimation ? (
      <LottieAnimation
        options={{
          loop: false,
          autoplay: true,
          animationData: isSelected ? filledFavoriteAnimationData : emptyFavoriteAnimationData,
          rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice',
          },
        }}
        height={16}
        width={16}
      />
    ) : isSelected ? (
      actionVariant.withText || actionVariant.useMediumIcon ? (
        <FavoriteFillMedium {...fill} />
      ) : (
        <FavoriteFillSmall />
      )
    ) : actionVariant.withText || actionVariant.useMediumIcon ? (
      <FavoriteMedium {...fill} />
    ) : (
      <FavoriteOutlineSmall />
    ),
    onClick,
  };
};

export class FavoriteAction extends BaseAddActionClass {
  readonly useRenderer = useFavoriteAction;
  transformEvent({ data }: TrackedEventType, isFavorite: boolean) {
    return {
      data: {
        ...data,
        itemType: 'Favorite' as ActionItemType,
      },
      eventName: (isFavorite ? 'DeleteItems' : 'AddItems') as TrackedEventName,
    };
  }

  isQuickAction(_data: ItemData, isTopBarItem?: boolean) {
    if (isTopBarItem) return undefined;
    return 'only';
  }

  isHandled(nodes: ViewItem<ItemData>[]): boolean {
    return nodes.every(item => !item.data.isVirtual && !item.data.isUserManaged && !item.data.isRootSection);
  }
}
