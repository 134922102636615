/* eslint-disable no-console */
import React, { useCallback, useMemo, useRef, useState, KeyboardEvent } from 'react';
import { InputAdornment } from 'material-ui-core';
import DropdownWithInput from '../DropdownWithInput/DropdownWithInput';
import { SearchInputComponent, ShareTargetBody } from './ShareTargeBody';
import { SearchResultChip } from '../ShareDialog';
import { useDebouncedEffect } from '../../utils/Misc/useDebouncedEffect';
import { ChannelGroup, ChatGroup, Person, SearchResulst, searchStrings } from './interface';

interface SearchComponentProps {
  setShareTarget?: React.Dispatch<React.SetStateAction<ChannelGroup | ChatGroup | Person | null>>;
  shareTarget: ChannelGroup | ChatGroup | Person | null;
  searchShareTo?: (searchTerm: any) => Promise<any | null>;
  itemTitle: string;
  searchStrings: searchStrings;
  isDragAndDropFlow?: boolean;
}

const ShareTargetSearch: React.FC<SearchComponentProps> = ({
  shareTarget,
  setShareTarget,
  searchShareTo,
  itemTitle,
  searchStrings,
  isDragAndDropFlow,
}) => {
  const [focusedItemIndex, setFoucedItem] = useState<number>(-1);
  const [searchResults, setSearchResults] = useState<SearchResulst | undefined>(undefined);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [searchTerm, setSearchTerm] = useState<string>('');
  const inputRef = useRef<HTMLInputElement>();

  const clearTarget = useCallback(() => {
    setShareTarget?.(null);
    setTimeout(() => inputRef.current?.focus(), 100);
  }, [setShareTarget]);

  const fullItemList = useMemo(
    () =>
      [
        ...(searchResults?.persons || []),
        ...(searchResults?.chatGroups || []),
        ...(searchResults?.channelGroups || []),
      ] as Array<ChannelGroup | ChatGroup | Person>,
    [searchResults]
  );
  const fixedHeight = useMemo(() => (fullItemList.length > 4 ? 250 : undefined), [fullItemList]);

  const onChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, openDrop: () => void, closeDrop: () => void) => {
      if (event.target.value.trim() !== '') {
        openDrop();
        setIsLoading(true);
      } else {
        closeDrop();
        setIsLoading(false);
      }
      setSearchTerm(event.target.value);
    },
    []
  );

  const getItems = useCallback(async () => {
    if (!searchShareTo || !searchTerm.trim().length) return null;
    try {
      const results = await searchShareTo(searchTerm);
      setSearchResults(results);
      console.log(results);
    } catch (e) {
      console.log(e);
    } finally {
      setIsLoading(false);
    }
  }, [searchShareTo, searchTerm]);

  const UserChip = useMemo(
    () =>
      shareTarget ? (
        <InputAdornment position="start">
          <SearchResultChip onClear={clearTarget} parentName={itemTitle} shareTarget={shareTarget} />
        </InputAdornment>
      ) : null,
    [clearTarget, itemTitle, shareTarget]
  );

  const onKeyPress = useCallback(
    (e: KeyboardEvent) => {
      if (e.key === 'ArrowDown') {
        setFoucedItem(item => Math.min(item + 1, fullItemList.length - 1));
      }
      if (e.key === 'ArrowUp') {
        setFoucedItem(item => Math.max(item - 1, 0));
      }
      if (e.key === 'Enter') {
        const item = fullItemList[focusedItemIndex];
        item && setShareTarget?.(item);
        setSearchTerm('');
        inputRef.current?.blur();
      }
    },
    [fullItemList, focusedItemIndex, setShareTarget]
  );

  useDebouncedEffect(getItems, [searchTerm], 500);

  return (
    <DropdownWithInput
      fixedHeight={fixedHeight}
      renderInput={({ openDrop, inputComponentRef, isDropOpen, closeDrop }) => (
        <SearchInputComponent
          onKeyDown={onKeyPress}
          onBlur={closeDrop}
          inputRef={inputRef}
          ref={inputComponentRef}
          isWrapped={isDropOpen}
          placeholder={!shareTarget ? searchStrings.placeHolder : ''}
          onFocus={openDrop}
          sx={{ zIndex: 101, width: '100%' }}
          disabled={!!shareTarget}
          InputProps={isDragAndDropFlow || shareTarget ? { startAdornment: UserChip } : {}}
          onChange={event => {
            onChange(event, openDrop, closeDrop);
          }}
          value={searchTerm}
          autoFocus={!isDragAndDropFlow}
        />
      )}
      renderBody={({ closeDrop }) => (
        <ShareTargetBody
          setShareTarget={item => {
            setShareTarget?.(item);
            closeDrop();
            setSearchTerm('');
          }}
          focusedItemIndex={focusedItemIndex}
          searchStrings={searchStrings}
          highlightString={searchTerm}
          renderItems={searchResults}
          isSearching={isLoading}
          clearIndex={() => setFoucedItem(-1)}
        />
      )}
    />
  );
};

export default ShareTargetSearch;
