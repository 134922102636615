import React from 'react';
import { Box, Stack, Typography, getFileIcon, Checkbox } from '@harmon.ie-ce/storybook';
import { TextField, styled } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { strings } from '@vendor/languages';
import { Adaptivecards } from '../Adaptivecard';
import { ShareTargetSearch } from '../ShareTargetSearch';
import { ChannelGroup, Person, SearchResulst, ChatGroup, searchStrings } from '../ShareTargetSearch/interface';

export interface SearchInputFields {
  channelAvatar?: Blob | string;
  avatar?: Blob | string;
  teamName: string;
}

interface ShareDialogProps {
  msgBody: string;
  needToNotifyState: React.MutableRefObject<boolean>;
  handleMsgBodyChange: (value: string) => void;
  searchInputField?: SearchInputFields;
  shareUrl: string;
  itemViewUrl?: string;
  itemTitle: string;
  itemName: string;
  folderCrumb: string;
  channelId?: string;
  channelName?: string;
  teamId?: string;
  lang: string;
  isEmail: boolean;
  emailFrom?: string;
  emailTo?: string;
  emailBodySnippet?: string;
  shareToText: string;
  fromText: string;
  toText: string;
  viewMailText: string;
  notifyText: string;
  emailHasAttachments?: boolean;
  emailDate?: string;
  searchShareTo?: (searchTerm: any) => Promise<SearchResulst | null>;
  setShareTarget?: React.Dispatch<React.SetStateAction<ChannelGroup | ChatGroup | Person | null>>;
  shareTarget: ChannelGroup | ChatGroup | Person | null;
  searchStrings: searchStrings;
  isDragAndDropFlow?: boolean;
}

const AttachmentCard = styled(Stack)`
  border-radius: 8px;
  padding: ${({ theme }) => theme.spacing(1, 2)};
  height: ${({ theme }) => theme.spacing(8)};
  max-width: ${({ theme }) => theme.spacing(56)};
  background: ${({ theme }) => theme.palette.backgrounds.tertiary};
  box-shadow: ${({ theme }) => theme.palette.shadow.attachment};
`;

export const ShareDialogBody = ({
  itemTitle,
  itemName,
  folderCrumb,
  shareToText,
  handleMsgBodyChange,
  msgBody,
  isEmail,
  emailFrom,
  emailBodySnippet,
  emailTo,
  needToNotifyState,
  emailHasAttachments,
  shareUrl,
  itemViewUrl,
  viewMailText,
  notifyText,
  emailDate,
  searchShareTo,
  channelName,
  fromText = strings.lang.emailProps.from,
  toText = strings.lang.emailProps.to,
  setShareTarget,
  shareTarget,
  searchStrings,
  isDragAndDropFlow,
}: ShareDialogProps) => {
  const theme = useTheme();
  const Icon = getFileIcon(itemName).icon;

  return (
    <Stack spacing={4} width={'100%'}>
      <Stack>
        <Typography variant="H2Regular" color={theme.palette.texts.secondary}>
          {shareToText}
        </Typography>
        <ShareTargetSearch
          itemTitle={channelName || ''}
          searchShareTo={searchShareTo}
          setShareTarget={setShareTarget}
          shareTarget={shareTarget}
          searchStrings={searchStrings}
          isDragAndDropFlow={isDragAndDropFlow}
        />
      </Stack>
      <Stack spacing={2} width={'100%'}>
        <TextField
          multiline
          rows={4}
          sx={{ minHeight: '80px' }}
          value={msgBody}
          onChange={e => handleMsgBodyChange(e.target.value)}
          onFocus={e => e.currentTarget.setSelectionRange(e.currentTarget.value.length, e.currentTarget.value.length)}
          inputRef={input => input && isDragAndDropFlow && input.focus()}
        />
        <Stack
          sx={{
            visibility:
              shareTarget !== null &&
              (shareTarget as ChannelGroup).teamId === undefined &&
              (shareTarget as ChatGroup).type === undefined
                ? 'hidden'
                : '',
          }}
        >
          <Checkbox
            alignItems="center"
            className="share-checkbox"
            value={needToNotifyState.current}
            onClick={() => (needToNotifyState.current = !needToNotifyState.current)}
            label={notifyText}
          />
        </Stack>
        <Stack direction={'row'} spacing={1} width="100%">
          {!isEmail ? (
            <AttachmentCard id="attachment" alignItems="center" spacing={2} direction="row">
              <Box minWidth={18}>
                <Icon />
              </Box>
              <Box maxWidth="calc( 100% - 23px)" flex={1}>
                <Stack flex={1}>
                  <Typography
                    color={theme.palette.texts.primary}
                    whiteSpace="nowrap"
                    textOverflow="ellipsis"
                    variant="H3SemiBold"
                    overflow="hidden"
                  >
                    {itemName}
                  </Typography>
                  <Typography
                    color={theme.palette.texts.secondary}
                    whiteSpace="nowrap"
                    textOverflow="ellipsis"
                    variant="H3Regular"
                    overflow="hidden"
                  >
                    {folderCrumb}
                  </Typography>
                </Stack>
              </Box>
            </AttachmentCard>
          ) : (
            <Adaptivecards
              viewMailText={viewMailText}
              shareUrl={itemViewUrl ?? shareUrl}
              Icon={Icon}
              itemTitle={itemTitle}
              emailHasAttachments={emailHasAttachments}
              mailAttribule={[`${fromText}: ${emailFrom}`, `${toText}: ${emailTo}`, `${emailDate}`]}
              text={emailBodySnippet || ''}
            />
          )}
        </Stack>
      </Stack>
    </Stack>
  );
};
