import { InsightIdentity } from '@microsoft/microsoft-graph-types';
import { UsageType, getFileIcon } from '@storybook';
import { GraphClient } from '@services';
import { formatDateByTime } from '@vendor/utils/dates';
import { oDirname } from '@vendor/utils/misc';
import { strings } from '@vendor/languages';
import { InsightItem } from '../graphTypes';
import {
  DocumentAccessUrls,
  BaseItem,
  BaseItemProps,
  DocumentItem,
  DownloadData,
  IconProps,
  SupportedOfficeAppTypes,
  ThumbnailData,
  treeitemFirstParent,
  ShareableItem,
} from '../itemTypes';
import { isUrlItemData } from '../misc';
import { SPFolder } from './SPFolder';
import {
  SPCUser,
  SharePointClient,
  encodeSpecialCharacters,
  getDocumentOdataIdFromId,
  getDriveIdFromUrl,
} from '../sharePointAPI';
import { LibraryItem } from '../itemTypes/LibraryItem';

interface DocProps extends BaseItemProps {
  contentTypeId?: string;
  relativeUrl: string;
  uniqueId: string;
  listItemId?: number;
  parent: SPFolder;
  date: Date;
  itemSize?: number;
  user?: SPCUser;
}

export class SPDocument extends BaseItem implements DocumentItem, LibraryItem, ShareableItem {
  private readonly _parent: SPFolder;
  private readonly contentTypeId?: string;
  private readonly relativeUrl: string;
  private readonly uniqueId: string;
  private readonly _date: Date;
  private readonly _user?: SPCUser;
  private readonly listItemId?: number;
  readonly itemSize?: number;

  constructor(props: DocProps) {
    super({ ...props, type: 'document' });
    this.contentTypeId = props.contentTypeId;
    this._parent = props.parent;
    this._date = props.date;
    this.relativeUrl = props.relativeUrl;
    this.uniqueId = props.uniqueId;
    this.listItemId = props?.listItemId;
    this._user = props.user;
    this.isNew = props.isNew;
    this.itemSize = props.itemSize;
  }
  get hasPreview(): boolean {
    return true;
  }

  protected override fillJson(res: DocProps): void {
    super.fillJson(res);
    res.contentTypeId = this.contentTypeId;
    res.parent = this.parent.toJson();
    res.date = this._date;
    res.relativeUrl = this.relativeUrl;
    res.user = this._user;
    res.uniqueId = this.uniqueId;
    res.listItemId = this.listItemId;
  }

  get OfficeAppLocated() {
    return this.list.OfficeAppLocated;
  }
  get parent() {
    return this._parent;
  }
  get list() {
    return this.parent.list;
  }
  override get isDocument(): boolean {
    return true;
  }
  get isLibraryItem(): boolean {
    return this.itemFirstParent !== treeitemFirstParent.Favorite;
  }
  get canRemove() {
    return this.isLibraryItem;
  }
  get canOpenInBrowser() {
    return true;
  }
  get mediaType() {
    return getFileIcon(this.name).media;
  }
  get officeApp(): SupportedOfficeAppTypes | undefined {
    return InsightItem.getOfficeType(this.mediaType);
  }
  get pathOrDescription(): string {
    let path = oDirname(this.relativeUrl.replace('/sites', ''));
    if (this.OfficeAppLocated === 'Teams') path = path.replace('/Shared Documents', '');
    if (this.OfficeAppLocated === 'OneDrive') {
      return `${`${this.OfficeAppLocated}${path.replace(
        `${this.list.siteUrl.replace(`${this.rootSite}`, '')}/Documents`,
        ''
      )}`}`;
    }
    return `${`${this.OfficeAppLocated}${path}`}`;
  }
  get secondLineContent(): string | null {
    const appName = this.OfficeAppLocated === 'SP' ? 'SharePoint' : this.OfficeAppLocated;
    if (this.OfficeAppLocated === 'OneDrive') {
      return `${appName} (${
        encodeSpecialCharacters(this.parent.serverRelativeUrl).split('/personal')[1].split('/')[1].split('_')[0]
      }) • ${this.parent.name} • ${formatDateByTime(this._date, strings.lang.dateFormat)}`;
    }
    return `${appName} • ${this.parent.name} • ${formatDateByTime(this._date, strings.lang.dateFormat)}`;
  }

  async removeItem(gcl: GraphClient) {
    const spc = new SharePointClient(gcl, this.rootSite);
    await spc.api(this.apiId).delete();
  }

  async rename(gcl: GraphClient, newName: string): Promise<LibraryItem> {
    const addressUrl = this.parent.list.rootSite + this.relativeUrl;
    const newRelative = `${oDirname(this.relativeUrl)}/${newName}`;
    const newUrl = this.parent.list.rootSite + newRelative;
    await this.list.site.moveFile(gcl, addressUrl, newUrl);
    const props: DocProps = { ...this.toJson(), name: newName, relativeUrl: newRelative, parent: this.parent };
    return new SPDocument(props);
  }

  async getAccessUrls(gcl: GraphClient): Promise<DocumentAccessUrls> {
    const downloadUrl = `${getDocumentOdataIdFromId(
      this.list.siteUrl,
      this.uniqueId
    )}/$value?binaryStringResponseBody=tru\e`;
    if (isUrlItemData(this)) {
      // Handle specifcally .url files by reading them & returning the link inside them...
      const blobData = await this.download(gcl, downloadUrl);
      const text = await blobData.blob.text();
      const urlLine = text.match(/^\s*URL\s*=\s*(.*)\s*$/m);
      if (urlLine !== null) return { webUrl: urlLine[1], downloadUrl };
    }
    const canView = isUrlItemData(this) || InsightItem.hasWebViewer(this.mediaType);
    const app = this.officeApp ? 'Doc.aspx' : canView ? 'Viewer.aspx' : 'Viewer.aspx';
    return {
      downloadUrl,
      webUrl: `${this.parent.list.siteUrl}/_layouts/15/${app}?sourceDoc=${this.uniqueId}`,
      appUrl: InsightItem.getAppUrl(this.officeApp, this.webUrl),
      address: this.relativeUrl.includes('aspx')
        ? `${this.parent.list.siteUrl}/_layouts/15/${app}?sourceDoc=${this.uniqueId}`
        : this.parent.list.rootSite + encodeSpecialCharacters(this.relativeUrl),
    };
  }

  async getUrlByType(type: string): Promise<string> {
    const typeMapper = {
      edit: 'EditForm',
      view: 'DispForm',
    };
    return `${this.rootSite}${this.parent.list.relativeUrl}/Forms/${typeMapper[type]}.aspx?ID=${this.listItemId}`;
  }
  async getEditDetailsUrl(): Promise<string> {
    return await this.getUrlByType('edit');
  }
  get subject() {
    return undefined;
  }

  async getViewDetailsUrl(): Promise<string> {
    return await this.getUrlByType('view');
  }

  async download(gcl: GraphClient, dUrl?: string): Promise<DownloadData> {
    dUrl = dUrl || (await this.getAccessUrls(gcl)).downloadUrl;
    const spc = new SharePointClient(gcl, this.rootSite);
    const fetchResult = await spc.api(dUrl).getStream();
    return { blob: await fetchResult.blob(), fileName: this.name };
  }

  get webUrl() {
    return this.rootSite + encodeSpecialCharacters(this.relativeUrl);
  }

  get date(): string {
    return this._date.toString();
  }

  get user(): InsightIdentity | undefined {
    return (
      this._user && {
        address: this._user.email,
        displayName: this._user.title,
      }
    );
  }

  get usageType(): UsageType | undefined {
    return 'edited';
  }

  get rootSite(): string {
    return this.parent.rootSite;
  }

  async getThumbnail(gcl: GraphClient): Promise<ThumbnailData | undefined> {
    return await InsightItem.getThumbnail(gcl, getDriveIdFromUrl(this.webUrl), this.name);
  }

  override getIcon(_expanded: boolean): IconProps {
    return getFileIcon(this.name);
  }
}
