import { logError } from '@vendor/utils/misc';
import PubSub from 'pubsub-js';
import { useEffect } from 'react';
import { useSubscribe } from '~/hooks/useSubscribe';

export type SelectedItemType = Office.SelectedItemDetails;

export let supportsOfficeSelection = false;
const OfficeSelection = 'OfficeSelection';
export const setupMailboxItemChangeListener = () => {
  if (!Office?.context) return;
  Office.context.mailbox?.addHandlerAsync(
    Office.EventType.ItemChanged,
    () => {
      if (!supportsOfficeSelection) {
        const item = Office.context.mailbox.item;
        PubSub.publish(OfficeSelection, (item && [item]) || []);
      }
    },
    result => {
      if (result.status === Office.AsyncResultStatus.Failed) {
        console.warn('Failed to add mailbox item change handler:', result.error);
      } else {
        console.warn('Mailbox item change handler added');
      }
    }
  );
};

export const setupOfficeItemSelectionChangedListeners = () => {
  if (!Office?.context) return;
  setupMailboxItemChangeListener();

  Office.context.mailbox?.addHandlerAsync(
    'olkSelectedItemsChanged',
    () => {
      try {
        Office.context.mailbox.getSelectedItemsAsync(ar => {
          supportsOfficeSelection = true;
          PubSub.publish(OfficeSelection, ar.value || []);
        });
      } catch (error: any) {
        supportsOfficeSelection = false;
        PubSub.publish(OfficeSelection, []);
      }
    },
    car => {
      supportsOfficeSelection = Boolean(
        car.status == Office.AsyncResultStatus.Succeeded && Office.context.mailbox.getSelectedItemsAsync
      );
      if (supportsOfficeSelection) {
        try {
          Office.context.mailbox.getSelectedItemsAsync(ar => (supportsOfficeSelection = ar?.value !== undefined));
        } catch (error: any) {
          supportsOfficeSelection = false;
          logError(error, 'Setting up office selection failed');
        }
      }
    }
  );
};

export const useSubscribeOfficeSelection = (func: (items: SelectedItemType[]) => void) => {
  useSubscribe(OfficeSelection, func);
  useEffect(() => {
    if (supportsOfficeSelection) {
      try {
        Office.context.mailbox.getSelectedItemsAsync(ar => {
          func(ar.value || []);
          supportsOfficeSelection = true;
        });
      } catch (error) {
        supportsOfficeSelection = false;
        func([]);
      }
    } else {
      const item = Office.context.mailbox?.item;
      func((item && [item as any as SelectedItemType]) || []);
    }
  }, [func]);
};

export function isAddInPinned() {
  return !!Office.context.roamingSettings.get('isPinned');
}
