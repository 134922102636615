import { Theme, Components } from '@mui/material/styles';

export default (theme: Theme) => {
  const styleOverrides: Components['MuiAccordionSummary'] = {
    styleOverrides: {
      root: {
        textTransform: 'uppercase',
        color: theme.palette.texts.quaternary,
        borderRadius: '8px',
        background: theme.palette.backgrounds.tertiary,
        minHeight: theme.spacing(6),
      },
      content: {
        margin: 0,
      },
      expandIconWrapper: {
        '&.Mui-expanded': {
          ['& > svg, path']: {
            color: theme.palette.texts.accentPrimary,
            fill: theme.palette.texts.accentPrimary,
          },
        },
      },
    },
  };
  return styleOverrides;
};
