import React, { useState } from 'react';
import { ViewItem, Stack, DrawerComponent, FeedbackMedium } from '@storybook';
import { strings } from '@vendor/languages';
import { ActionRendererHookType, BaseReadActionClass, ItemData } from '~/utilities';

const useFeedbackComponent: ActionRendererHookType = () => {
  const [hotjarDialogVisible, setHotjarDialogVisible] = useState<boolean | undefined>(false);

  return {
    title: strings.lang.allLocatiosToolbar.feedback,
    icon: FeedbackMedium,
    onClick: () => setHotjarDialogVisible(true),
    SideUI: (
      <DrawerComponent
        open={!!hotjarDialogVisible}
        toggleDrawer={function (_state: boolean): void {
          setHotjarDialogVisible(false);
        }}
      >
        <Stack id="feedbackButton"></Stack>
      </DrawerComponent>
    ),
  };
};

export class Feedback extends BaseReadActionClass {
  readonly trackedName = 'Feedback';
  readonly useRenderer = useFeedbackComponent;

  isHandled(_nodes: ViewItem<ItemData>[]): boolean {
    return true;
  }
}
