import React from 'react';
import { Stack, Typography, XSmallest } from '@harmon.ie-ce/storybook';
import { useTheme } from '@mui/material/styles';
import { Button } from '@mui/material';
import { ChannelGroup, ChatGroup, Person } from '../ShareTargetSearch/interface';
import { ShareAvatar, getAvatarType } from '../ShareTargetSearch/ShareAvatar';

export interface SearchResultChipProps {
  shareTarget: ChannelGroup | ChatGroup | Person;
  parentName: string;
  onClear: () => void;
}

const serializeData = (shareTarget: ChannelGroup | ChatGroup | Person) => ({
  text: `${shareTarget.name}`,
  src: shareTarget.avatar instanceof Blob ? URL.createObjectURL(shareTarget.avatar) : shareTarget.avatar,
});

export const SearchResultChip = ({ shareTarget, onClear }: SearchResultChipProps) => {
  const theme = useTheme();
  const { text, src } = serializeData(shareTarget);
  const avatarType = getAvatarType(shareTarget);
  return (
    <Stack
      direction={'row'}
      alignItems="center"
      background={theme.palette.chip.userchipSecondary}
      spacing={1}
      padding={1}
      borderRadius={'8px'}
    >
      <ShareAvatar type={avatarType} avatar={src} size={20} />
      <Typography variant="H2Regular" color={theme.palette.texts.accentSecondary}>
        {text}
      </Typography>
      <Button
        sx={{
          color: theme.palette.texts.primary,
          minWidth: '8px',
          marginRight: '3px !important',
          lineHeight: '8px',
          width: '8px',
          height: '8px',
        }}
        variant="IconButtons"
        onClick={onClear}
      >
        <XSmallest />
      </Button>
    </Stack>
  );
};
