import { GraphClient } from '@services';
import { OfficeUserSettingManager } from '../stateManager';
import { BaseItem, ExBaseItemProps } from './BaseItem';
import { FetchChildrenProps, Folder, ItemContainer, ItemDataWithPaging } from './ItemContainer';

export abstract class BaseContainer extends BaseItem implements ItemContainer {
  readonly location?: ItemContainer;
  newItemsCount?: number;

  protected constructor(props: ExBaseItemProps) {
    super(props);
  }

  get fetchChildrenCount(): number {
    return 50;
  }
  get supportSearch() {
    return true;
  }
  get stateCount(): OfficeUserSettingManager<number> | null {
    return null;
  }
  onChangeFilter(_checked: boolean) {
    return;
  }
  get filterState(): boolean | undefined {
    return undefined;
  }
  get supportsSortDirection() {
    return false;
  }

  abstract fetchChildren(props: FetchChildrenProps): Promise<ItemDataWithPaging>;

  getFolder(_: GraphClient): Promise<Folder> {
    throw new Error('Method not implemented.');
  }

  reorderItems(): void {
    // Implement the function as needed - share point and teams section will set the items a to z!
  }
}
