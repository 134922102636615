import React, { useRef, useState, useCallback, useMemo, useContext } from 'react';
import { ContainerHandlers, DragAndDropDrilldownWrapper, DragAndDropWrapper, ViewItem } from '@storybook';
import {
  EmailProps,
  ItemContainer,
  ItemData,
  convertFileListToFileArray,
  extractSubjectAndFrom,
  isItemContainer,
  UploadAction,
  createSafe2CallbackWrap,
} from '~/utilities';
import { useSafeCallback as useSafeCallbackOrig, useSafeCallbackType } from '~/hooks/UseSafeCallback';
import UploadContext from '~/context/UploadContext';
interface DragAndDropProps {
  node: ViewItem<ItemData>;
  isTreeItem: boolean;
  isDrilldownWrapper: boolean;
  expand?: () => void;
  isClose?: boolean;
  handlers: ContainerHandlers<ItemData>;
}

export const DragAndDrop: React.FC<React.PropsWithChildren & DragAndDropProps> = ({
  node,
  isTreeItem,
  expand,
  isDrilldownWrapper,
  isClose,
  handlers,
  children,
}) => {
  const data = node.data;
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);
  const [isOnDrag, setIsOnDrag] = useState(false);
  const counter = useRef<number>(0);
  const emailProps = useRef<EmailProps>({ from: undefined, isFromExist: false, subject: undefined });
  const actionRef = useRef<string[]>([]);
  const { handleFileUpload } = useContext(UploadContext);
  const useSafeCallback = useMemo(
    () =>
      createSafe2CallbackWrap(
        new UploadAction('Drag Drop'),
        'Other',
        [node],
        useSafeCallbackOrig,
        handlers,
        actionRef
      ) as useSafeCallbackType,
    [handlers, node]
  );
  const uploadFile = useSafeCallback(...handleFileUpload!);
  const onDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e['active'] = true;
    if (!isDrilldownWrapper) {
      e['isContainer'] = isItemContainer(data.type);
      e['hasFolder'] = data.hasFolder;
    }

    if (
      (!data.hasFolder && isTreeItem) ||
      (data.isSearchItem && !isItemContainer(data.type)) ||
      (!data.hasFolder && isItemContainer(data.type) && !Boolean(e['hasFolder']))
    ) {
      e.dataTransfer.dropEffect = 'none';
    }
  };

  const handleDragEnter = (e: React.DragEvent<HTMLDivElement>) => {
    if (!isDrilldownWrapper) {
      e['isContainer'] = isItemContainer(data.type);
      e['hasFolder'] = data.hasFolder;
    }
    onDragOver(e);
    if (isItemContainer(data.type)) {
      if (timeoutRef.current !== null) {
        clearTimeout(timeoutRef.current);
        timeoutRef.current = null;
      }
      counter.current += 1;
      if (!Boolean(e['isContainer']) || !isDrilldownWrapper) {
        setIsOnDrag(true);
      } else {
        setIsOnDrag(false);
      }
      if (expand) {
        timeoutRef.current = setTimeout(
          () => {
            if (timeoutRef.current !== null) {
              expand();
            }
          },
          isClose ? 1000 : 2000
        );
      }
    }
    e['active'] = true;
  };

  const handleDragLeave = useCallback(
    (e: React.DragEvent<HTMLDivElement>) => {
      counter.current -= 1;
      if (isItemContainer(data.type) && counter.current === 0) {
        if (!isTreeItem || timeoutRef.current !== null) {
          setIsOnDrag(false);
        }
        if (timeoutRef.current !== null) {
          clearTimeout(timeoutRef.current);
          timeoutRef.current = null;
        }
      }
      if (isTreeItem || (!isTreeItem && isItemContainer(data.type)) || isDrilldownWrapper) {
        e.preventDefault();
      }
    },
    [data.type, isDrilldownWrapper, isTreeItem]
  );

  const handleDrop = useCallback(
    async (event: React.DragEvent<HTMLDivElement>) => {
      emailProps.current = extractSubjectAndFrom(event.dataTransfer.getData('text'));
      const droppedFiles = event.dataTransfer.files;
      if (droppedFiles.length > 0) {
        const firstFile = droppedFiles[0];
        const name = firstFile.name.toLowerCase();
        const isMsgFile = name.endsWith('.msg');
        // If the D&D is from outlook
        if (handleFileUpload) {
          if (isMsgFile && emailProps.current.subject !== undefined) {
            await uploadFile(convertFileListToFileArray(droppedFiles), node.data as ItemContainer, actionRef, true);
          }
          // D&D from file-system
          else {
            await uploadFile(convertFileListToFileArray(droppedFiles), node.data as ItemContainer, actionRef);
          }
        }
      }
    },
    [handleFileUpload, node.data, uploadFile]
  );

  const onDrop = useCallback(
    async (e: React.DragEvent<HTMLDivElement>) => {
      if (!isDrilldownWrapper) {
        e['isContainer'] = data.hasFolder;
      }

      if (data.hasFolder && (!isDrilldownWrapper || (isDrilldownWrapper && !Boolean(e['isContainer'])))) {
        handleDragLeave(e);
        await handleDrop(e);
      }
    },
    [data.hasFolder, handleDragLeave, handleDrop, isDrilldownWrapper]
  );

  if (isDrilldownWrapper) {
    return (
      <DragAndDropDrilldownWrapper
        onDrop={onDrop}
        onDragEnter={handleDragEnter}
        onDragOver={onDragOver}
        onDragLeave={handleDragLeave}
        isDragging={isOnDrag}
        isSavebleLocation={data.hasFolder}
      >
        {children}
      </DragAndDropDrilldownWrapper>
    );
  }
  return (
    <DragAndDropWrapper
      onDrop={onDrop}
      onDragEnter={handleDragEnter}
      onDragOver={onDragOver}
      onDragLeave={handleDragLeave}
      isDragging={isOnDrag}
      isSavebleLocation={data.hasFolder}
    >
      <>{children}</>
    </DragAndDropWrapper>
  );
};
