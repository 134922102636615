import { Stack, styled } from '@mui/material';

const TreeExpandIcon = styled(Stack)`
  flex-direction: row;
  opacity: 0;
  align-items: center;
  justify-content: center;
  width: 25px;
  height: 23px;
  .navigationItem.isExpanded &{
    opacity: 1;
  }

  svg {
    cursor: pointer;
    height: 16px;
    width: 16px;
  }

  svg,
  svg path {
      fill: ${({ theme }) => theme.palette.texts.primary};
    },
  } 
  // TODO: fix this to be more specific for the icon conatiner and not apply to all icons in navigation item
  .navigationItem:hover & .tree-node-switcher{
     &:hover{
     opacity: 1;
      svg,
      svg path {
        fill: ${({ theme }) => theme.palette.texts.tertiary};
      }
    }

  }


  .navigationItem.isExpanded .tree-node-switcher{
    svg,
    svg path {
      fill: ${({ theme }) => theme.palette.texts.tertiary};
    }
    &:hover{
      svg,
      svg path {
        fill: ${({ theme }) => theme.palette.texts.tertiary};
      }
    }
  }
`;

TreeExpandIcon.defaultProps = {
  className: 'tree-node-switcher',
};

export default TreeExpandIcon;
