import React from 'react';
import { Typography as MuiTypography, TypographyProps as MuiTypographyProps } from '@mui/material';
export interface TypographyProps extends MuiTypographyProps {
  color?: string;
}

const Typography = ({ children, ...rest }: TypographyProps) => {
  return <MuiTypography {...rest}>{children}</MuiTypography>;
};

export default Typography;
