import { Theme, Components } from '@mui/material/styles';

export default (_theme: Theme) => {
  const styleOverrides: Components['MuiList'] = {
    styleOverrides: {
      root: {
        '&:MuiDivider-root': {
          marginTop: '8px',
          marginBottom: '8px',
        },
      },
    },
  };
  return styleOverrides;
};
