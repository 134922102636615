import { Theme, Components } from '@mui/material/styles';

export default (theme: Theme) => {
  const styleOverrides: Components['MuiTextField'] = {
    defaultProps: {
      autoComplete: 'off',
    },
    styleOverrides: {
      root: {
        borderRadius: '8px',
        padding: '8px, 10px',
        color: theme.palette.backgrounds.overlaidComponentsPrimary,
        borderColor: theme.palette.outlinesAndStrokes.secondary,
        '& label.Mui-focused': {
          borderColor: theme.palette.outlinesAndStrokes.secondary,
        },
        '& .MuiInput-underline:after': {
          borderBottomColor: theme.palette.buttons.accentPrimary,
        },
        '& .MuiOutlinedInput-root': {
          '&:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: theme.palette.outlinesAndStrokes.tertiary,
            boxShadow: '0px 0px 0px 0px',
          },
          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            boxShadow: theme.palette.boxshadow.inputFocus,
            borderColor: theme.palette.outlinesAndStrokes.accent,
          },
          '&.Mui-active .MuiOutlinedInput-notchedOutline': {
            boxShadow: theme.palette.boxshadow.inputFocus,
            color: theme.palette.texts.primary,
            borderColor: theme.palette.buttons.accentPrimary,
          },
          '&.Mui-error .MuiOutlinedInput-notchedOutline': {
            boxShadow: theme.palette.boxshadow.inputError,
            borderColor: theme.palette.status.dangerPrimary,
          },
        },
      },
    },
  };
  return styleOverrides;
};
