import React, { MouseEventHandler } from 'react';
import { strings } from '@vendor/languages';
import { useTheme } from '@mui/system';
import { MicrosoftLogoSmall } from '../../assets/icons';
import { Button } from '../Button';
import { Typography } from '../Typography';

const SignInButton = ({ onClick }: { onClick?: MouseEventHandler<any> | undefined }) => {
  const theme = useTheme();
  return (
    <Button
      startIcon={<MicrosoftLogoSmall />}
      variant="primary"
      sx={{
        width: '100%',
        paddingY: `${theme.spacing(2)} !important`,
        marginBottom: 4,
        height: theme.spacing(8),
      }}
      onClick={onClick}
    >
      <Typography variant="H2Medium" ml={theme.spacing(1)}>
        {strings.lang.signIn}
      </Typography>
    </Button>
  );
};

export default SignInButton;
