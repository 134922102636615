import React, { useCallback } from 'react';
import { ContextMenu2, Counter, OrderByInfo, Stack, useTheme, ViewItem } from '@storybook';
import { VirtualList } from '@storybook';
import { strings } from '@vendor/languages';
import { getViewItemRenderer } from '~/components/Tree/Renderers/GetNodeRenderer';
import {
  EmptyStateItem,
  ItemContainer,
  ItemData,
  ItemDataWithPaging,
  locationHaveQuickSearch,
  locationPlaceholderQuickSearch,
} from '~/utilities';
import QuickSearch from '~/components/QuickSearch/QuickSearch';
import { Search } from '~/utilities/search';
import {
  generateQuickSearchKey,
  generateVirtualListKey,
  useDrilldown,
} from '~/components/Tree/Renderers/DrilldownHandler';
import DrilldownToolbar from './DrilldownToolbar/DrilldownToolbar';
import KeyPressSubscribe from '../KeyPress/KeyPressSubscribe';
import { DragAndDrop } from '../DragAndDrop';

const moreThanAThousand = '1000+ ';

const Drilldown: React.FC<{
  getItems: (token: string | undefined) => Promise<ItemDataWithPaging>;
  location: ItemContainer;
  orderBy?: OrderByInfo[];
  newItems?: ItemData[];
}> = ({ getItems, location, orderBy, newItems: inpNewItems }) => {
  const searchProps =
    location && (location as ItemContainer).isAdvancedSearchContainer
      ? {
          searchTerm: (location as Search).searchTerm,
          filters: (location as Search).filters,
          entityTypes: (location as Search).entityTypes,
        }
      : { searchTerm: '', filters: '', entityTypes: [] };
  const theme = useTheme();
  const {
    listHandlers,
    selection,
    newItems,
    mappedItems,
    nextToken,
    loadingRef,
    fetchPage,
    counter,
    resultCount,
    items,
    pagginationText,
  } = useDrilldown(getItems, location, inpNewItems);
  const render = useCallback(
    (node: ViewItem<ItemData>) => {
      node.data.isVirtualItem = true;
      node.data.itemColorable = true;
      node.data.isAdvancedSearchItem = location.isAdvancedSearchContainer;
      return getViewItemRenderer(node.data.type)?.({
        node,
        handlers: listHandlers,
        className: selection.get(node.id) ? 'ht_selected' : undefined,
        onSelect: () => undefined,
        onToggle: () => undefined,
        highlightString:
          searchProps?.searchTerm?.length || 0 >= 3 ? searchProps?.searchTerm?.replace(/^"(.*)"$/, '$1') : undefined,
      });
    },
    [listHandlers, location.isAdvancedSearchContainer, searchProps?.searchTerm, selection]
  );

  const size = useCallback(
    (items: ViewItem<ItemData>[], index: number) =>
      newItems.length && newItems.length > index ? 32 : items[index]?.data?.size || 32,
    [newItems]
  );
  return (
    <KeyPressSubscribe handlers={listHandlers}>
      <ContextMenu2>
        <Stack sx={{ height: '100%' }} background={theme.palette.backgrounds.primary}>
          {locationHaveQuickSearch(location) && (
            <QuickSearch
              key={generateQuickSearchKey(location, searchProps)}
              placeholder={locationPlaceholderQuickSearch(
                location.isAdvancedSearchContainer ? (location as Search).location : location
              )}
              location={location}
              searchProps={searchProps || { searchTerm: '', filters: '', entityTypes: [] }}
              disabled={location.isAdvancedSearchContainer}
            />
          )}
          <DrilldownToolbar location={location} handlers={listHandlers}></DrilldownToolbar>
          <DragAndDrop
            node={{ data: location, id: location.id }}
            handlers={listHandlers}
            isTreeItem={false}
            isDrilldownWrapper={true}
          >
            <Stack sx={{ height: '100%' }} pl={2} pb={2} pr={1.6} onClick={() => listHandlers.unselectAll()}>
              <VirtualList
                key={generateVirtualListKey(
                  location,
                  counter.current,
                  items[0] instanceof EmptyStateItem,
                  orderBy,
                  searchProps
                )}
                size={size}
                items={mappedItems}
                render={render}
                hasNextPage={!!nextToken}
                isNextPageLoading={loadingRef.current}
                loadNextPage={() => fetchPage(nextToken, items)}
                withScrollToTop
              />
            </Stack>
          </DragAndDrop>
          {location.isAdvancedSearchContainer && (
            <Counter
              text={`${pagginationText ? pagginationText : resultCount > 1000 ? moreThanAThousand : resultCount} ${
                strings.lang.results.countResults
              }`}
            ></Counter>
          )}
        </Stack>
      </ContextMenu2>
    </KeyPressSubscribe>
  );
};

export default Drilldown;
