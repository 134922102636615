import React, { useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { applicationScopes, useGraphClient } from '@services';
import { logError } from '@vendor/utils/misc';
import { asyncPublish } from '~/hooks/useSubscribe';
import { ReactAppInit } from '~/taskpane';
import { onBoardingDone } from '~/modules/Onboarding/OnboardingFlow';

const ProtectedRoute = ({ children }: { children: React.ReactNode }) => {
  const gcl = useGraphClient();
  const navigate = useNavigate();
  const initialize = useCallback(async () => {
    try {
      const instance = gcl.msal;
      const accounts = instance.getAllAccounts();
      const account = instance.getActiveAccount() || (accounts && accounts[0]);
      if (account) {
        await instance.acquireTokenSilent({ account, ...applicationScopes });

        await asyncPublish(ReactAppInit, { account, gcl });

        if (onBoardingDone.value) {
          navigate('/home');
        } else {
          navigate('/onboarding');
        }
        return;
      }

      navigate('/login');
    } catch (error: any) {
      logError(error);
      navigate('/login');
    }
  }, [gcl, navigate]);

  useEffect(() => {
    const curPath = window.location.pathname;
    if (curPath !== '/') navigate(curPath);
    else initialize();
  }, [initialize, navigate]);

  return <>{children}</>;
};

export default ProtectedRoute;
