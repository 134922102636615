import { Divider as DividerMui, DividerProps as MuiDividerProps } from '@mui/material';
import React, { FunctionComponent } from 'react';

export type DividerProps = MuiDividerProps;

const Divider: FunctionComponent<DividerProps> = props => {
  return <DividerMui {...props} />;
};

export default Divider;
