/**
 * formattingDate is a function that takes in a date string as an argument and returns the local date string.
 */

export const formattingDate = (date_string: string) => {
  const date = new Date(Date.parse(date_string));
  const localDateString = date.toString().slice(0, date.toString().lastIndexOf(' GMT'));
  return localDateString;
};

// date - if today return time else return in this format 21.05.23
export const formatReceivedDate = (date?: Date) => {
  const today = new Date();
  if (date?.toDateString() === today.toDateString()) {
    // If the date is today, format with time like "14:25 PM"
    return date?.toLocaleTimeString('en-US', { hour: 'numeric', minute: '2-digit', hour12: false });
  } else {
    // If the date is not today, format with the full date
    return date?.toLocaleDateString('en-GB', { year: '2-digit', month: '2-digit', day: '2-digit' }).replace(/\//g, '.');
  }
};
