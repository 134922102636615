import React, { useState } from 'react';
import { SortMedium, ViewItem, OrderByInfo, SortMenuAction } from '@storybook';
import { useLocation, useNavigate } from 'react-router-dom';
import { strings } from '@vendor/languages';
import { DrilldownState, navigateDrilldown } from '~/components/Tree/Renderers/DrilldownHandler';
import { ActionRendererHookType, ItemContainer, BaseReadActionClass, ItemData } from '~/utilities';

const useLocationSort: ActionRendererHookType = ({ nodes, useCallback }) => {
  const location = nodes[0].data as ItemContainer;
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const navigate = useNavigate();
  const items = [
    {
      displayName: strings.lang.sortMemuActions.name,
      value: { field: 'LinkFilename', dir: true },
    },
    {
      displayName: strings.lang.sortMemuActions.dateModified,
      value: { field: 'Modified', dir: false },
    },
    {
      displayName: strings.lang.sortMemuActions.dateCreated,
      value: { field: 'Created', dir: false },
    },
  ];

  const onSelect = useCallback(
    (value: OrderByInfo) => {
      setAnchorEl(null);
      navigateDrilldown(navigate, location, true, [value]);
    },
    [navigate, location]
  );
  const { state } = useLocation() as DrilldownState;
  const orderBy = state.orderBy !== undefined ? state.orderBy[0] : { field: 'LinkFilename', dir: true };
  return {
    title: strings.lang.toolbarActions.sort,
    icon: SortMedium,
    onClick: (event: React.MouseEvent<HTMLElement>) => setAnchorEl(event.currentTarget),
    opened: Boolean(anchorEl),

    SideUI: (
      <SortMenuAction
        anchorEl={anchorEl}
        items={items}
        acionsNames={[strings.lang.sortMemuActions.ascending, strings.lang.sortMemuActions.descending]}
        orderBy={orderBy}
        setAnchorEl={setAnchorEl}
        onSelect={onSelect}
      />
    ),
  };
};

export class LocationSortAction extends BaseReadActionClass {
  readonly trackedName = 'Sort';
  readonly useRenderer = useLocationSort;

  isHandled(nodes: ViewItem<ItemData>[]): boolean {
    return nodes[0].data.hasFolder;
  }

  isQuickAction(_data: ItemData, isTopBarItem: boolean) {
    return isTopBarItem ? 'only' : undefined;
  }
}
