import React from 'react';
import { Outlet } from 'react-router-dom';
import { NotificationsAndErrorProvider, Stack, useTheme } from '@storybook';
import KeyPressPublish from '../KeyPress/KeyPressPublish';
import { UploadProvider } from '../../context/UploadProvider';
const Shell = () => {
  const theme = useTheme();
  return (
    <KeyPressPublish>
      <NotificationsAndErrorProvider>
        <UploadProvider>
          <Stack sx={{ height: '100%' }} background={theme.palette.backgrounds.primary}>
            <Outlet />
          </Stack>
        </UploadProvider>
      </NotificationsAndErrorProvider>
    </KeyPressPublish>
  );
};

export default Shell;
