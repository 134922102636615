import { DownloadSmall, ViewItem, DownloadMedium } from '@storybook';
import { GraphClient, useGraphClient } from '@services';
import { strings } from '@vendor/languages';
import { DocumentItem, DownloadData, ItemData } from '../itemTypes';
import { ActionRendererHookType, BaseReadActionClass } from './BaseAction';
import { isMacOsNative } from '../misc/utils';

export const crossPlatformDownload = async (nodeData: DocumentItem, gcl: GraphClient) => {
  if (isMacOsNative()) {
    const url = await (nodeData as DocumentItem).getAccessUrls(gcl);
    Office.context.ui.displayDialogAsync(url.downloadUrl);
  } else {
    const blobData = await (nodeData as DocumentItem).download(gcl);
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    DownloadAction.downloadBlob(blobData);
  }
};

const useDownloadRenderer: ActionRendererHookType = ({ nodes, useCallback }) => {
  const gcl = useGraphClient();
  const data = nodes[0].data;
  const onClick = useCallback(() => crossPlatformDownload(data as DocumentItem, gcl), [data, gcl]);

  return {
    title: strings.lang.actionToolTip.download,
    icon: [DownloadMedium, DownloadSmall],
    onClick,
  };
};

export class DownloadAction extends BaseReadActionClass {
  readonly trackedName = 'Download';
  static downloadBlob(blobData: DownloadData) {
    const url = URL.createObjectURL(blobData.blob);
    const link = document.createElement('a');
    link.style.display = 'none';
    link.href = url;
    link.setAttribute('download', blobData.fileName);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  }

  readonly useRenderer = useDownloadRenderer;

  isHandled(nodes: ViewItem<ItemData>[]): boolean {
    return nodes.length === 1 && nodes[0].data.canDownload;
  }
}
