import React, { useContext, useState } from 'react';
import { MouseEventsContext, MouseEventsInterface, Popover, Slider, ViewItem, SettingsSmall } from '@storybook';
import { strings } from '@vendor/languages';
import { ItemContainer, ItemData, isItemContainer } from '../itemTypes';
import { ActionRendererHookType, BaseReadActionClass } from './BaseAction';

const useManageItemCount: ActionRendererHookType = ({ nodes, useCallback }) => {
  const { actionDone, waitForAction } = useContext<MouseEventsInterface>(MouseEventsContext);
  const data = nodes[0].data as ItemContainer;
  const stateCount = data.stateCount;
  const [val, reset] = useState(true); // Force a state change
  const setNumber = useCallback(
    (num: number) => {
      if (stateCount !== null) stateCount.value = num;
      reset(!val);
    },
    [stateCount, val]
  );
  const [anchorEl1, setAnchorEl1] = React.useState<null | HTMLElement>(null);

  const onClick = (event: React.MouseEvent<HTMLElement>) => {
    waitForAction?.();
    setAnchorEl1(event?.currentTarget);
  };

  const handleClickClose = (event: React.MouseEvent<HTMLElement>) => {
    actionDone?.();
    event?.stopPropagation();
    setAnchorEl1(null);
  };
  return {
    SideUI: (
      <Popover open={Boolean(anchorEl1)} anchorEl={anchorEl1} onClose={handleClickClose}>
        <Slider
          value={stateCount?.value}
          onChange={setNumber}
          min={5}
          max={25}
          message={strings.lang.actionToolTip.itemCountDescription}
          title={strings.lang.actionToolTip.itemCountTitle}
          onChangeFilter={data.onChangeFilter}
          filterState={data.filterState}
        />
      </Popover>
    ),
    title: strings.lang.actionToolTip.viewSetting,
    icon: SettingsSmall,
    onClick,
  };
};

export class ManageItemCountAction extends BaseReadActionClass {
  readonly trackedName = 'ManageItemCount';
  readonly useRenderer = useManageItemCount;

  isQuickAction() {
    return 'only';
  }

  isHandled(nodes: ViewItem<ItemData>[]): boolean {
    return (
      nodes.length == 1 && isItemContainer(nodes[0].data.type) && (nodes[0].data as ItemContainer).stateCount !== null
    );
  }
}
