import { styled } from '@mui/system';
import { Stack, StackProps } from '../Stack';

interface DragAndDrowGlobalWrapperProps {
  isDragging: boolean;
}
interface DragAndDrowWrapperProps extends StackProps {
  isDragging: boolean;
  isSavebleLocation: boolean;
}

export const DragAndDropGlobalWrapper = styled(Stack, {
  shouldForwardProp: prop => prop !== 'isDragging',
})<DragAndDrowGlobalWrapperProps>`
  ${({ isDragging }) => `
  .tree-node-switcher {
    opacity: ${isDragging ? 0.3 : 0};
  }
  .tree-line {
    opacity: ${isDragging ? 1 : 0};
  }
`}
`;

export const DragAndDropWrapper = styled(Stack, {
  shouldForwardProp: prop => prop !== 'isDragging' && prop !== 'isSavebleLocation',
})<DragAndDrowWrapperProps>`
  ${({ isDragging, isSavebleLocation, theme }) => `
    .navigationItem .drag-and-drop {
      border-radius: 8px;
      border: 1px solid ${
        !isDragging
          ? 'transparent'
          : isSavebleLocation
            ? theme.palette.outlinesAndStrokes.accent
            : theme.palette.outlinesAndStrokes.disabled
      };
      background-color: ${
        !isDragging
          ? 'none'
          : isSavebleLocation
            ? theme.palette.backgrounds.secondary
            : theme.palette.backgrounds.tertiary
      };
    }
    .tree-node-switcher {
      opacity: ${isDragging ? 1 : 0};
      svg,
      svg path {
        fill: ${isDragging && isSavebleLocation ? theme.palette.texts.accentSecondary : theme.palette.texts.tertiary};
      }
    }
  `}
`;

export const DragAndDropDrilldownWrapper = styled(Stack, {
  shouldForwardProp: prop => prop !== 'isDragging' && prop !== 'isSavebleLocation',
})<DragAndDrowWrapperProps>`
  ${({ isDragging, isSavebleLocation, theme }) => `
    .drag-and-drop-drilldown {
      border-radius: 8px;
      border: 1px solid ${
        !isDragging
          ? 'transparent'
          : isSavebleLocation
            ? theme.palette.outlinesAndStrokes.accent
            : theme.palette.outlinesAndStrokes.disabled
      };
    }
    height: 100%;
  `}
`;
