import { Guid } from 'guid-typescript';
import { FolderClosedMedium, FolderOpenedMedium, OneMedium, UploadRequestHandler } from '@storybook';
import { GraphClient } from '@services';
import { oDirname } from '@vendor/utils/misc';
import { strings } from '@vendor/languages';
import { SPFilesCountState } from '~/modules/Settings/SettingContext';
import {
  decodeSpecialCharacters,
  encodeSpecialCharacters,
  getFolderOdataIdFromUrl,
  replaceSpecialCharsWithUnderscore,
  SharePointClient,
  SPCBaseItemKeys,
  SPCFolderKeys,
} from '../sharePointAPI';
import {
  AccessUrls,
  BaseContainer,
  BaseItemProps,
  DocumentItem,
  FetchChildrenProps,
  Folder,
  IconProps,
  ItemContainer,
  ItemDataWithPaging,
  ShareableItem,
  treeitemFirstParent,
} from '../itemTypes';
import { SPList } from './SPList';
import { ViewFilesItem } from '../itemTypes/ViewFilesItem';
import { SPListView } from './SPListViewFactory';
import { ListView } from '../itemTypes/ListView';
import { SPDocument } from './SPDocument';
import { RefreshAction } from '../actions';
import { LibraryItem } from '../itemTypes/LibraryItem';
import { getUploadParams } from '../misc/utils';
export const FORTY_MB = 40000000;

interface SPOUser {
  Email: string;
  Title: string;
}
interface SPOUploadResult {
  'odata.id': string;
  Author: SPOUser;
  ListItemAllFields: {
    ContentTypeId: string;
    Id: number;
  };
  Name: string;
  ServerRelativeUrl: string;
  TiemCreated: string;
  TimeLastModified: string;
  UniqueId: string;
}

interface fetchDocumentProps extends Pick<FetchChildrenProps, 'gcl' | 'refreshStamp'> {
  count?: number;
}

interface SPFolderProps extends BaseItemProps {
  list: SPList;
  progId?: string;
  listItemId?: number;
  contentTypeId?: string;
  serverRelativeUrl: string;
  filters?: string[];
}

export interface IProgressBar {
  readonly appCtx: any;
  startProgress(): void;
  progeressStarted(): boolean;
  setProgress(n: number): void;
  setText(str: string): void;
  isCanceled(): boolean;
  cancel(): void;
  finish(): void;
  setEnableCancel(state: boolean): void;
}

export interface UploadDocument {
  data: Blob;
  fileName: string;
  gcl: GraphClient;
  fileIndex: number;
  override?: boolean;
  progressRequestHandler?: UploadRequestHandler;
}

export class SPFolder extends BaseContainer implements Folder, ShareableItem {
  private readonly filters?: Set<string>;
  private readonly progId?: string;
  private readonly contentTypeId?: string;
  private readonly listItemId?: number;
  private _parent?: SPList | SPFolder;
  readonly serverRelativeUrl: string;
  readonly list: SPList;

  constructor(props: SPFolderProps) {
    super({ ...props, type: 'folder' });
    this.list = props.list;
    this.filters = props.filters && new Set(props.filters);
    this.progId = props.progId;
    this.listItemId = props?.listItemId;
    this.contentTypeId = props.contentTypeId;
    this.serverRelativeUrl = props.serverRelativeUrl;
    this.isNew = props.isNew;
    if (this.id.endsWith('/rootFolder')) this._parent = this.list;
  }

  protected fillJson(res: SPFolderProps): void {
    super.fillJson(res);
    if (this.filters) res.filters = [...this.filters];
    res.list = this.list.toJson();
    res.progId = this.progId;
    res.contentTypeId = this.contentTypeId;
    res.serverRelativeUrl = this.serverRelativeUrl;
    res.listItemId = this.listItemId;
  }

  get parent() {
    if (this._parent === undefined) {
      const parentRekativePath = oDirname(this.serverRelativeUrl);
      if (parentRekativePath == this.list.rootFolder.serverRelativeUrl) this._parent = this.list;
      else {
        this._parent = new SPFolder({
          list: this.list,
          serverRelativeUrl: parentRekativePath,
          name: decodeSpecialCharacters(parentRekativePath.substring(parentRekativePath.lastIndexOf('/') + 1), true),
          id: getFolderOdataIdFromUrl(this.list.siteUrl, parentRekativePath),
        });
      }
    }
    return this._parent;
  }

  get OfficeAppLocated() {
    return this.list.OfficeAppLocated;
  }
  override get hasFolder(): boolean {
    return true;
  }
  get isLibraryItem(): boolean {
    return this.isTransientLocation !== true && this.itemFirstParent !== treeitemFirstParent.Favorite;
  }
  override get rootSite(): string {
    return this.list.rootSite;
  }
  get isOneNote() {
    return this.progId === 'OneNote.Notebook';
  }
  get canRemove(): boolean {
    return this.isLibraryItem;
  }
  get isDocumentSet() {
    return this.progId === 'Sharepoint.DocumentSet' || this.contentTypeId?.startsWith('0x0120D520') || false;
  }

  override async getFolder() {
    return this;
  }

  override async getAccessUrls(): Promise<AccessUrls> {
    return {
      webUrl: this.rootSite + encodeSpecialCharacters(this.serverRelativeUrl),
    };
  }

  get pathOrDescription(): string {
    let path = oDirname(this.serverRelativeUrl.replace('/sites', ''));
    if (this.OfficeAppLocated === 'Teams') path = path.replace('/Shared Documents', '');
    if (this.OfficeAppLocated === 'OneDrive') {
      return `${`${this.OfficeAppLocated}${path.replace(
        `${this.list.siteUrl.replace(`${this.rootSite}`, '')}/Documents`,
        ''
      )}`}`;
    }
    return `${`${this.OfficeAppLocated}${path}`}`;
  }
  get secondLineContent(): string | null {
    const appName = this.OfficeAppLocated === 'SP' ? 'SharePoint' : this.OfficeAppLocated;
    let parent = this.parent.name;
    if (parent === 'Shared Documents') {
      parent = this.parent.parent.name;
    }
    if (this.OfficeAppLocated === 'OneDrive') {
      return `${appName} (${
        encodeSpecialCharacters(this.serverRelativeUrl).split('/personal')[1].split('/')[1].split('_')[0]
      }) • ${parent}`;
    }
    return `${appName} • ${parent}`;
  }

  async rename(gcl: GraphClient, newName: string): Promise<LibraryItem> {
    const webUrl = this.rootSite + this.serverRelativeUrl;
    const newRelative = `${oDirname(this.serverRelativeUrl)}/${newName}`;
    const id = getFolderOdataIdFromUrl(this.list.siteUrl, newRelative);
    const newUrl = this.list.rootSite + newRelative;
    await this.list.site.moveFolder(gcl, webUrl, newUrl);
    const props: SPFolderProps = { ...this.toJson(), name: newName, list: this.list };
    return new SPFolder({ ...props, id, name: newName, serverRelativeUrl: newRelative });
  }

  async createNewFolder(gcl: GraphClient, folderName: string): Promise<Folder> {
    const spc = new SharePointClient(gcl, this.rootSite);
    const res: SPOUploadResult = await spc
      .api(`${this.apiId}/folders/AddUsingPath(DecodedUrl=@a1,overwrite=@a2)?@a1=%27${folderName}%27&@a2=false`)
      .expand(['Author', 'ListItemAllFields'])
      .post(null);
    const listItem = res.ListItemAllFields;
    return new SPFolder({
      isNew: true,
      contentTypeId: listItem.ContentTypeId,
      name: res.Name,
      list: this.list,
      id: res['odata.id'],
      serverRelativeUrl: res.ServerRelativeUrl,
      listItemId: res.ListItemAllFields.Id,
    });
  }
  encodeFileName4Upload = (filename: string) => {
    const newFileName = replaceSpecialCharsWithUnderscore(filename.trim());
    return encodeSpecialCharacters(newFileName, true).replaceAll('&', '%26').replaceAll('+', '%2B');
  };

  async upload({
    gcl,
    data,
    fileName,
    fileIndex,
    override,
    progressRequestHandler,
  }: UploadDocument): Promise<DocumentItem> {
    const spc = new SharePointClient(gcl, this.rootSite);
    const controller = new AbortController();
    const fileNameEncoded = `'${this.encodeFileName4Upload(fileName)}'`;
    const isLargeFile = data.size >= FORTY_MB;
    let res = {} as SPOUploadResult;
    let listId;
    try {
      const id = Guid.create().toString();
      const guid = encodeURIComponent(`guid'${id}'`);
      const uploadParams = getUploadParams(fileNameEncoded, isLargeFile, guid, override);
      if (!isLargeFile) {
        const stream = await data?.arrayBuffer();
        res = await spc
          .directapi(`${this.apiId}${uploadParams}`)
          .expand(['Author', 'ListItemAllFields'])
          .upload(controller, stream, 0, data.size, progressRequestHandler, fileIndex);
        await spc.directapi(`${this.apiId}`).cancelRequest(controller, false, progressRequestHandler);
        listId = res.ListItemAllFields.Id;
      } // large file
      else {
        res = await spc.directapi(`${this.apiId}${uploadParams}`).expand(['Author', 'ListItemAllFields']).post(null);
        listId = res.ListItemAllFields.Id;
        let bufferRead = FORTY_MB;
        let offset = 0;
        let stream = await data.slice(0, 0).arrayBuffer();
        let done = false;
        while (!done) {
          if (bufferRead + offset >= data.size) {
            bufferRead = data.size - offset;
            done = true;
          }
          stream = await data.slice(offset, offset + bufferRead).arrayBuffer();
          await spc
            .directapi(`${res['odata.id']}/ContinueUpload(uploadId=@a2,fileOffset=@a3)?@a2=${guid}&@a3=${offset}`)
            .upload(controller, stream, offset, data.size, progressRequestHandler, fileIndex);
          offset += bufferRead;
        }
        await spc
          .directapi(`${res['odata.id']}/FinishUpload(uploadId=@a2,fileOffset=@a3)?@a2=${guid}&@a3=${data.size}`)
          .post(null);
      }
      const docItem = res.ListItemAllFields;
      return new SPDocument({
        isNew: true,
        contentTypeId: docItem.ContentTypeId,
        uniqueId: `{${res.UniqueId}}`,
        name: res.Name,
        parent: this,
        date: new Date(res.TimeLastModified),
        id: res['odata.id'],
        relativeUrl: res.ServerRelativeUrl,
        user: {
          email: res.Author.Email,
          title: res.Author.Title,
        },
        listItemId: listId,
        itemSize: data.size,
      });
    } catch (error: any) {
      throw error;
    }
  }

  async getItemsFromView(
    gcl: GraphClient,
    view: ListView,
    count: number,
    refreshStamp?: number,
    next?: string
  ): Promise<ItemDataWithPaging> {
    // Handle cases where root folders (of libraries & channels) are marked for refresh on deleting items.
    refreshStamp = await RefreshAction.markRefreshStamp(this, refreshStamp);
    const spc = new SharePointClient(gcl, this.rootSite);
    return await this.list.renderAsStream(spc, this, view, count, refreshStamp, next);
  }

  override async fetchChildren(
    { gcl, refreshStamp }: FetchChildrenProps,
    parent?: ItemContainer
  ): Promise<ItemDataWithPaging> {
    if (hrmProvisioning.virtualFoldersCount > 0) return { items: await this.fetchVirtualFolders() };
    const view = new SPListView()
      .combineWithFields(SPCFolderKeys)
      .combineWithOrderBy(SPListView.OrderByName)
      .combineWithQuery(SPListView.FolderOnlyQuery);
    const pres = this.getItemsFromView(gcl, view, hrmProvisioning.foldersCount, refreshStamp);
    // Use promise so we can execute in parallel.
    const pDocs = this.fetchDocuments({ gcl, refreshStamp });
    const [fRes, dRes] = await Promise.all([pres, pDocs]);

    let value = fRes.items;
    if (this.filters) value = value.filter(n => !this.filters?.has(n.name));
    return {
      items: new ViewFilesItem({ name: strings.lang.nodeNames.viewAllFiles, parent: parent || this }).apply(
        [...value, ...dRes.items],
        0,
        Boolean(fRes.pageToken || dRes.pageToken)
      ),
    };
  }

  private async fetchVirtualFolders(): Promise<Folder[]> {
    return new Array(hrmProvisioning.virtualFoldersCount).fill(0).map(
      (_e, i) =>
        new SPFolder({
          list: this.list,
          listItemId: this.listItemId,
          id: `${this.id}-${1}`,
          name: `${this.name}-${i}`,
          serverRelativeUrl: this.serverRelativeUrl,
        })
    );
  }

  private async fetchDocuments({ gcl, refreshStamp, count }: fetchDocumentProps): Promise<ItemDataWithPaging> {
    count = count || SPFilesCountState.value;
    const view = new SPListView()
      .combineWithFields(SPCBaseItemKeys)
      .combineWithOrderBy(SPListView.OrderByLastModified)
      .combineWithQuery(SPListView.DocumentOnlyQuery);
    const res = await this.getItemsFromView(gcl, view, count, refreshStamp);
    return { items: res.items.slice(0, count), pageToken: res.pageToken };
  }

  async removeItem(gcl: GraphClient): Promise<void> {
    const spc = new SharePointClient(gcl, this.rootSite);
    await spc.api(this.apiId).delete();
  }

  override getIcon(expanded: boolean): IconProps {
    if (this.isDocumentSet)
      if (expanded) return { icon: FolderOpenedMedium, isColorable: true };
      else return { icon: FolderClosedMedium, isColorable: true };
    if (this.isOneNote) return { icon: OneMedium, isColorable: false };
    if (expanded) return { icon: FolderOpenedMedium, isColorable: true };
    return { icon: FolderClosedMedium, isColorable: true };
  }

  async getUrlByType(type: string): Promise<string> {
    const typeMapper = {
      edit: 'EditForm',
      view: 'DispForm',
    };
    return `${this.rootSite}${this.list.relativeUrl}/Forms/${typeMapper[type]}.aspx?ID=${this.listItemId}`;
  }
  async getEditDetailsUrl(): Promise<string> {
    return await this.getUrlByType('edit');
  }

  async getViewDetailsUrl(): Promise<string> {
    return await this.getUrlByType('view');
  }
}
