import { useTheme } from '@mui/material';
import React from 'react';
import Breadcrumb from './Breadcrumbs';

interface BreadCrumbsLocationsLabelProps {
  isSelected: boolean;
  itemBreadcrumbs?: string | React.ReactNode;
  highlightString?: string;
}

export const BreadCrumbsLocationsLabel: React.FunctionComponent<BreadCrumbsLocationsLabelProps> = ({
  isSelected,
  itemBreadcrumbs,
  highlightString,
}) => {
  const theme = useTheme();

  return (
    <Breadcrumb
      breadcrumbsProps={{
        itemBreadcrumbs,
        highlightString,
        color: isSelected ? theme.palette.texts.onAccentSecondary : theme.palette.texts.secondary,
      }}
    ></Breadcrumb>
  );
};
