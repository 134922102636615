import { Avatar, Skeleton, useTheme } from '@mui/material';
import React, { useMemo } from 'react';
import {
  CalendarMedium,
  CalendarSmall,
  GroupTeamsMedium,
  GroupTeamsSmall,
  AvatarPersonMedium,
  AvatarPersonSmall,
} from '../../assets/icons';
import { ChannelGroup, ChatGroup, Person, ShareAvatarInterface } from './interface';

const IconComponent = ({ type, size, avatar }: ShareAvatarInterface) => {
  if (avatar instanceof Blob) return null;

  const iconSize = size === 32 ? 'Medium' : 'Small';

  switch (type) {
    case 'meeting':
      return iconSize === 'Medium' ? <CalendarMedium /> : <CalendarSmall />;
    case 'group':
      return iconSize === 'Medium' ? <GroupTeamsMedium /> : <GroupTeamsSmall />;
    case 'MyAvatar':
      return <>{avatar}</> || null;
    default:
      return iconSize === 'Medium' ? <AvatarPersonMedium /> : <AvatarPersonSmall />;
  }
};

export const ShareAvatar: React.FC<ShareAvatarInterface> = ({ avatar, size = 32, type }) => {
  const theme = useTheme();

  const backgroundColor = useMemo(() => {
    if (type === 'meeting') return theme.palette.texts.teams;
    if (type === 'MyAvatar' && avatar) return theme.palette.status.defaultSecondary;
    return '#E3EEFA';
  }, [type, avatar, theme]);

  const avatarSrc = avatar instanceof Blob ? URL.createObjectURL(avatar) : avatar;

  if (avatar === 'isLoading') {
    return <Skeleton width={size} height={size} variant="circular" />;
  }

  return (
    <Avatar
      variant={type === 'ChannelGroup' ? 'rounded' : 'circular'}
      src={avatarSrc}
      sx={{ backgroundColor, width: size, height: size }}
    >
      <IconComponent type={type} size={size} avatar={avatar} />
    </Avatar>
  );
};

export const getAvatarType = (item: ChannelGroup | ChatGroup | Person) => {
  if ((item as ChatGroup).type) return (item as ChatGroup).type;
  if ((item as ChannelGroup).teamId) return 'ChannelGroup';
  return 'Person';
};
