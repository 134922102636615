import React, { FC, PropsWithChildren } from 'react';
import Typography from '@mui/material/Typography';
import { Stack, Box } from '@mui/system';
import { Grid } from '@mui/material';

export type ChipBoxType = {
  label?: string;
  maxItem?: number;
};

const ChipBox: FC<PropsWithChildren<ChipBoxType>> = ({ label, children, maxItem }) => {
  return (
    <Stack direction="column" pl={2} pr={2} pt={1} pb={1} spacing={1} mt={2}>
      <Typography color="texts.secondary" variant="CapsLockRegular">
        {label?.toUpperCase()}
      </Typography>
      <Box ml={-1} mr={-1}>
        <Grid container spacing={1}>
          {React.Children.map(children, (child, index) => {
            if ((maxItem && maxItem <= index) || !child) return null;
            if (React.isValidElement(child)) {
              return (
                <>
                  <Grid item xs="auto">
                    {child}
                  </Grid>
                  {child?.key === 'newLine' && <Grid item xs={12} sx={{ paddingTop: '0px !important' }} />}
                </>
              );
            }
            return child;
          })}
        </Grid>
      </Box>
    </Stack>
  );
};

export default ChipBox;
