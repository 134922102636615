// @ts-nocheck
import { Box, styled } from '@mui/material';
import LoaderGif from '../../assets/images/preloader.gif';

export const LOADER_HEIGHT = 35;

const Loader = styled(Box)`
  height: ${LOADER_HEIGHT}px;
  width: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
`;

Loader.defaultProps = {
  style: {
    backgroundImage: `url(${LoaderGif})`,
  },
};

export default Loader;
