/* eslint-disable react/display-name */
/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  ForwardedRef,
  PropsWithChildren,
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import { debounce, throttle } from 'lodash';
import { calculateThumbPosition, ScrollBarContextInterface, ScrollbarsProps, ScrollShot } from './common';
import { ScrollbarWrap, ScrollbarContainer, ScrollBarIndicator, ScrollIndicatorBody, Debugger } from './elements';

export const ScrollbarContext = React.createContext<ScrollBarContextInterface>({
  resizeObserver: null,
  scrollTo: () => null,
});

const Scrollbar = forwardRef(
  (
    {
      onScroll,
      noHorizontal,
      style,
      withThrottling,
      children,
      horizontalOffset,
      verticalOffset,
      showVertical,
      showDebugger,
      className,
    }: PropsWithChildren<ScrollbarsProps>,
    ref: ForwardedRef<HTMLInputElement>
  ) => {
    const [horizontalScrollShown, setHorizontalScrollShown] = useState<boolean>(false);
    const timemout = useRef<NodeJS.Timeout | null | number>(null);
    const verticalRef = useRef<HTMLDivElement | null>(null);
    const horizontalRef = useRef<HTMLDivElement | null>(null);
    const containerRef = useRef<HTMLDivElement | null>(null);
    const wrapperRef = useRef<HTMLDivElement | null>(null);
    const debugRef = useRef<HTMLDivElement | null>(null);

    const scrollShot = useRef<ScrollShot>({});
    const thumbTop = useRef<number>(0);
    const thumbLeft = useRef<number>(0);

    const recalculateVerticalScroll = useCallback(() => {
      if (containerRef.current && verticalRef.current) {
        timemout.current && clearTimeout(timemout.current);
        const container = containerRef.current;
        const { scrollHeight, clientHeight } = container;

        if (scrollHeight <= clientHeight) {
          verticalRef.current.classList.remove('onScroll');
        } else {
          verticalRef.current.classList.add('onScroll');
          timemout.current = setTimeout(
            () => verticalRef.current && verticalRef.current.classList.remove('onScroll'),
            3000
          );
        }
      }
    }, []);

    const recalculateHorizontalScroll = useCallback(() => {
      if (containerRef.current && horizontalRef.current) {
        const container = containerRef.current;
        const { scrollWidth, clientWidth } = container;
        if (scrollWidth <= clientWidth) {
          setHorizontalScrollShown(false);
          containerRef.current.scrollLeft = 0;
        } else {
          setHorizontalScrollShown(true);
        }
      }
    }, []);

    const handleResize = debounce(() => {
      recalculateHorizontalScroll();
      recalculateVerticalScroll();
    }, 100);

    const resizeObserver = useRef<ResizeObserver>(new ResizeObserver(handleResize));

    useImperativeHandle(ref, () => containerRef.current as HTMLInputElement);

    const handleMouseDown = (event: React.MouseEvent<HTMLDivElement, MouseEvent>, type: 'horizontal' | 'vertical') => {
      if (containerRef.current) {
        if (type === 'horizontal' && horizontalRef.current) {
          horizontalRef.current.classList.add('onPress');
        }

        if (type !== 'horizontal' && verticalRef.current) {
          verticalRef.current.classList.add('onPress');
        }
        scrollShot.current =
          type === 'horizontal'
            ? {
                type,
                containerScroll: containerRef.current.scrollLeft,
                lastScroll: event.clientX,
              }
            : {
                type,
                containerScroll: containerRef.current.scrollTop,
                lastScroll: event.clientY,
              };
      }
    };

    const handleMouseMove = useCallback((event: MouseEvent) => {
      const { containerScroll, lastScroll, type } = scrollShot.current;
      if (
        lastScroll === undefined ||
        !wrapperRef.current ||
        containerScroll === undefined ||
        !verticalRef.current ||
        !containerRef.current
      )
        return;

      if (type === 'vertical') {
        const deltaY = event.clientY - lastScroll;
        const { clientHeight } = wrapperRef.current;
        const prop = deltaY / clientHeight;
        containerRef.current.scrollTop = Math.round(
          containerScroll + prop * (containerRef.current.scrollHeight - containerRef.current.clientHeight)
        );
        return;
      }

      if (type === 'horizontal') {
        const deltaX = event.clientX - lastScroll;
        const clientWidth = wrapperRef.current.clientWidth;
        const prop = deltaX / clientWidth;
        containerRef.current.scrollLeft =
          containerScroll + prop * (containerRef.current.scrollWidth - containerRef.current.clientWidth);
        return;
      }
    }, []);

    const scrollTo = useCallback((element?: HTMLElement) => {
      if (!containerRef.current || !element) return null;
      containerRef.current.scrollTop =
        containerRef.current.scrollTop +
        element.getBoundingClientRect().bottom -
        containerRef.current.getBoundingClientRect().bottom;
      return null;
    }, []);

    const handleMouseUp = useCallback(() => {
      scrollShot.current = {};
    }, []);

    const scrollEvent = e => {
      containerRef.current && onScroll?.({ ...e, currentTarget: containerRef.current });
      window.requestAnimationFrame(() => {
        if (containerRef.current && verticalRef.current && wrapperRef.current) {
          const container = containerRef.current;
          const { scrollWidth: scrollWrapWidth, scrollHeight: scrollWrapHeight } = wrapperRef.current;
          const { scrollWidth, clientWidth, scrollHeight, clientHeight, scrollTop, scrollLeft } = container;

          const newThumbLeft = calculateThumbPosition(scrollLeft, scrollWidth - clientWidth, scrollWrapWidth);
          const newThumbTop = calculateThumbPosition(scrollTop, scrollHeight - clientHeight, scrollWrapHeight);

          if (debugRef.current) {
            const debugInfo = {
              scrollWrapWidth,
              scrollWidth,
              clientWidth,
              scrollHeight,
              clientHeight,
              scrollTop,
              scrollLeft,
              newThumbLeft,
              newThumbTop,
            };
            debugRef.current.innerHTML = Object.keys(debugInfo)
              .map(item => `<div><span>${item}:</span><span>${debugInfo[item]}</span></div>`)
              .join('');
          }

          if (horizontalRef.current && !isNaN(newThumbLeft) && newThumbLeft !== thumbLeft.current) {
            horizontalRef.current.style.transform = `translateX(${newThumbLeft}px)`;
            thumbLeft.current = newThumbLeft;
            verticalRef.current.classList.remove('onScroll');
          }

          if (newThumbTop !== thumbTop.current) {
            thumbTop.current = newThumbTop;
            timemout.current && clearTimeout(timemout.current);
            verticalRef.current.classList.add('onScroll');
            timemout.current = setTimeout(
              () => verticalRef.current && verticalRef.current.classList.remove('onScroll'),
              3000
            );
          }
          verticalRef.current.style.transform = `translateY(${newThumbTop}px)`;
        }
      });
    };

    const throttleScroll = throttle(scrollEvent, 100);

    useEffect(() => {
      window.addEventListener('mousemove', handleMouseMove);
      window.addEventListener('mouseup', handleMouseUp);

      if (showVertical && verticalRef.current) {
        verticalRef.current.classList.add('onScroll');
        timemout.current = setTimeout(
          () => verticalRef.current && verticalRef.current.classList.remove('onScroll'),
          3000
        );
      }

      return () => {
        window.removeEventListener('mousemove', handleMouseMove);
        window.removeEventListener('mouseup', handleMouseUp);
        timemout.current && clearTimeout(timemout.current);
      };
    }, [showVertical]);

    return (
      <ScrollbarContext.Provider value={{ scrollTo, resizeObserver: resizeObserver.current }}>
        <ScrollbarWrap className={className} noHorizontal={!horizontalScrollShown} style={style} ref={wrapperRef}>
          <ScrollbarContainer onScroll={withThrottling ? throttleScroll : scrollEvent} ref={containerRef}>
            {children}
          </ScrollbarContainer>
          <ScrollBarIndicator
            vertical
            withThrottling={withThrottling}
            horizontalOffset={horizontalOffset}
            ref={verticalRef}
          >
            <ScrollIndicatorBody onMouseDown={e => handleMouseDown(e, 'vertical')} />
          </ScrollBarIndicator>
          {!noHorizontal && (
            <ScrollBarIndicator
              horizontal
              className={horizontalScrollShown ? 'onScroll' : ''}
              withThrottling={withThrottling}
              verticalOffset={verticalOffset}
              ref={horizontalRef}
            >
              <ScrollIndicatorBody onMouseDown={e => handleMouseDown(e, 'horizontal')} />
            </ScrollBarIndicator>
          )}
          {showDebugger && <Debugger ref={debugRef} />}
        </ScrollbarWrap>
      </ScrollbarContext.Provider>
    );
  }
);

export default Scrollbar;
