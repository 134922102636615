import { Theme, Components } from '@mui/material/styles';

export default (theme: Theme) => {
  const styleOverrides: Components['MuiChip'] = {
    styleOverrides: {
      root: {
        padding: theme.spacing(1, 2),
        borderRadius: '8px',
        cursor: 'pointer',
        backgroundColor: theme.palette.chip.primary,
        '&:hover': {
          backgroundColor: theme.palette.chip.secondary,
        },
        '&.active': {
          backgroundColor: theme.palette.chip.tertiary,
        },
      },
      icon: {
        marginLeft: 0,
        marginRight: theme.spacing(1),
        fontSize: '16px',
        width: '16px',
        height: '16px',
      },
      label: {
        padding: 0,
        fontFamily: 'Urbanist',
        fontSize: '11px',
        lineHeight: '16px',
        color: theme.palette.texts.primary,
      },
      sizeSmall: {
        display: 'flex',
        height: '26px',
        width: '26px',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: '6px',
        padding: 0,
        ' .MuiChip-icon': {
          margin: 0,
        },
      },
    },
  };
  return styleOverrides;
};
