import { Theme, Components } from '@mui/material/styles';
export default (theme: Theme) => {
  const styleOverrides: Components['MuiTooltip'] = {
    defaultProps: {
      placement: 'bottom',
      enterDelay: 500,
      enterNextDelay: 500,
      leaveDelay: 0,
    },
    styleOverrides: {
      tooltip: {
        ...theme.typography.H3Regular,
        borderRadius: '12px',
        backgroundColor: theme.palette.tooltips.primary,
        color: theme.palette.texts.onAccentPrimary,
        textTransform: 'none',
        margin: 0,
        padding: `${theme.spacing(0)} ${theme.spacing(0)}`,
      },
      arrow: {
        color: theme.palette.tooltips.primary,
        '&:before': {
          border: `1px solid ${theme.palette.tooltips.primary}`,
        },
      },
      popper: {
        '&.default-tooltip': {
          '.MuiTooltip-tooltip': {
            boxShadow: `${theme.palette.shadow.tooltip}`,
            paddingTop: `2px`,
            paddingBottom: `2px`,
            marginTop: `${theme.spacing(2)} !important`,
            paddingLeft: `6px`,
            paddingRight: `6px`,
            borderRadius: `${theme.spacing(1)}`,
          },
          '.MuiTooltip-arrow': {},
        },
        '&.preview-tooltip': {
          marginTop: `${theme.spacing(0)}`,
          minWidth: `${theme.spacing(50)}`,
          maxWidth: `${theme.spacing(56)}`,
          '.MuiTooltip-tooltip': {
            boxShadow: `${theme.palette.shadow.tooltipPreview}`,
            padding: `${theme.spacing(0)} ${theme.spacing(0)}`,
            marginTop: `${theme.spacing(2)} !important`,
            marginBottom: `12px !important`,
            marginLeft: `${theme.spacing(4)} !important`,
            marginRight: `${theme.spacing(4)} !important`,
          },
          '.MuiTooltip-arrow': {},
        },
        '&.location-label-tooltip': {
          marginTop: `${theme.spacing(0)}`,
          '.MuiTooltip-tooltip': {
            boxShadow: `${theme.palette.shadow.tooltip}`,
            marginTop: `${theme.spacing(1)} !important`,
            marginLeft: `${theme.spacing(2)} !important`,
            marginRight: `${theme.spacing(2)} !important`,
            paddingTop: `${theme.spacing(1)}`,
            paddingBottom: `${theme.spacing(1)}`,
            paddingLeft: `${theme.spacing(2)}`,
            paddingRight: `${theme.spacing(2)}`,
            borderRadius: `${theme.spacing(1)} !important`,
            maxHeight: `${theme.spacing(24)}`,
            maxWidth: '280px',
          },

          '.MuiTooltip-arrow': {},
        },
        '&.location-label-tooltip-manage-item': {
          marginTop: `${theme.spacing(0)} !important`,
          '.MuiTooltip-tooltip': {
            marginTop: `18px !important`,
            boxShadow: `${theme.palette.shadow.tooltip}`,
            marginLeft: `${theme.spacing(2)} !important`,
            marginRight: `${theme.spacing(4)} !important`,
            paddingTop: `${theme.spacing(1)}`,
            paddingBottom: `${theme.spacing(1)}`,
            paddingLeft: `${theme.spacing(2)}`,
            paddingRight: `${theme.spacing(2)}`,
            borderRadius: `${theme.spacing(1)} !important`,
            maxHeight: `${theme.spacing(24)}`,
            maxWidth: '280px',
          },
          '.MuiTooltip-arrow': {},
        },
      },
    },
  };
  return styleOverrides;
};
