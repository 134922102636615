export const convertToEwsId = n =>
  n ? n.replace(new RegExp('[/]', 'g'), '-').replace(new RegExp('[+]', 'g'), '_') : '';

export enum OfficeMode {
  Read,
  Compose,
}

export let officeMode: OfficeMode = OfficeMode.Read;
const detectOfficeMode = () => {
  if (Office.context.mailbox && !Office.context.mailbox?.displayNewMessageForm) officeMode = OfficeMode.Compose;
  else officeMode = OfficeMode.Read;
};
export const initOfficeMode = () => {
  detectOfficeMode();
};
