import { TypographyOptions } from '@mui/material/styles/createTypography';

const fontFamily = 'Urbanist,  sans-serif';
const typography: TypographyOptions = {
  fontFamily,
  fontSize: 14,
  OnBoardingBoldTitle: {
    fontFamily,
    fontSize: '22px',
    fontWeight: '740',
    lineHeight: '22px',
  },
  H1Regular: {
    fontFamily,
    fontSize: '14px',
    fontWeight: '440',
    lineHeight: '20px',
  },
  H1SemiBold: {
    fontFamily,
    fontSize: '14px',
    fontWeight: '640',
    lineHeight: '20px',
  },
  H1Bold: {
    fontFamily,
    fontSize: '14px',
    fontWeight: '740',
    lineHeight: '20px',
  },
  H2Regular: {
    fontFamily,
    fontSize: '12px',
    fontWeight: '440',
    lineHeight: '18px',
  },
  H2Medium: {
    fontFamily,
    fontSize: '11px',
    fontWeight: '540',
    lineHeight: '16px',
  },
  H3Regular: {
    fontFamily,
    fontSize: '11px',
    fontWeight: '440',
    lineHeight: '16px',
  },
  H3Medium: {
    fontFamily,
    fontSize: '11px',
    fontWeight: '540',
    lineHeight: '16px',
  },
  H3SemiBold: {
    fontFamily,
    fontSize: '11px',
    fontWeight: '640',
    lineHeight: '16px',
  },
  H3Italic: {
    fontFamily,
    fontWeight: '400',
    fontSize: '11px',
    lineHeight: '16px',
  },
  CapsLockRegular: {
    fontFamily,
    fontSize: '11px',
    fontWeight: '540',
    lineHeight: '16px',
    letterSpacing: '0.33px',
    textTransform: 'uppercase',
  },
  CapsLockSemiBold: {
    fontFamily,
    fontSize: '11px',
    fontWeight: '640',
    lineHeight: '16px',
    letterSpacing: '0.33px',
    textTransform: 'uppercase',
  },
  CapsLockBold: {
    fontFamily,
    fontSize: '11px',
    fontWeight: '740',
    lineHeight: '16px',
    letterSpacing: '0.33px',
    textTransform: 'uppercase',
  },
  H1UnderlineRegular: {
    fontFamily,
    fontSize: '14px',
    fontWeight: '440',
    lineHeight: '20px',
    letterSpacing: '0.42px',
    textDecorationLine: 'underline',
  },
  H1UnderlineMedium: {
    fontFamily,
    fontSize: '14px',
    fontWeight: '640',
    lineHeight: '20px',
    letterSpacing: '0.42px',
    textDecorationLine: 'underline',
  },
  H1UnderlineBold: {
    fontFamily,
    fontSize: '14px',
    fontWeight: '740',
    lineHeight: '20px',
    letterSpacing: '0.42px',
    textDecorationLine: 'underline',
  },
  H2UnderlineRegular: {
    fontFamily,
    fontSize: '12px',
    fontWeight: '440',
    lineHeight: '18px',
    letterSpacing: '0.36px',
    textDecorationLine: 'underline',
  },
  H2UnderlineMedium: {
    fontFamily,
    fontSize: '12px',
    fontWeight: '540',
    lineHeight: '18px',
    letterSpacing: '0.36px',
    textDecorationLine: 'underline',
  },
  H2UnderlineSemiBold: {
    fontFamily,
    fontSize: '12px',
    fontWeight: '640',
    lineHeight: '18px',
    letterSpacing: '0.36px',
    textDecorationLine: 'underline',
  },
  H3UnderlineRegular: {
    fontFamily,
    fontSize: '11px',
    fontWeight: '440',
    lineHeight: '16px',
    letterSpacing: '0.33px',
    textDecorationLine: 'underline',
  },
  H3UnderlineMedium: {
    fontFamily,
    fontSize: '11px',
    fontWeight: '540',
    lineHeight: '16px',
    letterSpacing: '0.33px',
    textDecorationLine: 'underline',
  },
  H3UnderlineSemiBold: {
    fontFamily,
    fontSize: '11px',
    fontWeight: '640',
    lineHeight: '16px',
    letterSpacing: '0.33px',
    textDecorationLine: 'underline',
  },

  h1: undefined,
  h2: undefined,
  h3: undefined,
  h4: undefined,
  h5: undefined,
  h6: undefined,
  subtitle1: undefined,
  subtitle2: undefined,
  body1: undefined,
  body2: undefined,
  button: {
    fontFamily,
    fontSize: '12px',
    lineHeight: '18px',
  },
  caption: undefined,
  overline: undefined,
};

export default typography;
