import { useCallback, useEffect, useState } from 'react';
import { AppConfig, getUserEmail, useGraphClient } from '@services';
import { IuseWhatsNewNotification, Notification, useTheme } from '@storybook';
import { logError } from '@vendor/utils/misc';
import { WhatsNewNotificationSeen } from '~/modules/Settings/SettingContext';

const getSeenNotifications = (): string[] => {
  const storageType = AppConfig.getInstance().whatsNewNotificationStorageType || 'OfficeUserSettingManager';
  switch (storageType) {
    case 'localStorage':
      return JSON.parse(localStorage.getItem('seenNotifications') || '[]');
    case 'OfficeUserSettingManager':
      return WhatsNewNotificationSeen.value || [];
    default:
      return [];
  }
};
const setSeenNotifications = (notifications: string[]) => {
  const storageType = AppConfig.getInstance().whatsNewNotificationStorageType || 'OfficeUserSettingManager';
  switch (storageType) {
    case 'localStorage':
      localStorage.setItem('seenNotifications', JSON.stringify(notifications));
      break;
    case 'OfficeUserSettingManager':
      WhatsNewNotificationSeen.value = notifications;
      break;
    default:
      break;
  }
};
export const useWhatsNewNotifications = (): IuseWhatsNewNotification => {
  const [notifications, setNotifications] = useState<Notification[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [currentNotification, setCurrentNotification] = useState<Notification | null>(null);

  const gcl = useGraphClient();
  const theme = useTheme();

  const getNotificationData = useCallback(async () => {
    return {
      email: btoa(await getUserEmail(gcl)),
      version: 'cloud',
      language: Office.context.displayLanguage || navigator.language,
      theme: theme.palette.mode,
    };
  }, [gcl, theme.palette.mode]);

  useEffect(() => {
    const fetchNotifications = async () => {
      setIsLoading(true);
      try {
        // Fetch the notifications from the server
        const response = await fetch(
          `${AppConfig.getInstance().whatsNewNotificationUrl.replace(/\/$/, '')}/whatsnew.json`,
          {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(await getNotificationData()),
          }
        );
        const data: Notification[] = await response.json();

        const seenNotifications = getSeenNotifications();
        const unseenNotifications = data.filter(notification => !seenNotifications.includes(notification.id));
        if (unseenNotifications.length > 0) {
          setNotifications(unseenNotifications);
          const urlWithQueryParam = new URL(unseenNotifications[0].url);
          urlWithQueryParam.searchParams.set('theme', theme.palette.mode);
          setCurrentNotification({ ...unseenNotifications[0], url: urlWithQueryParam.toString() }); // Initially show the first unseen notification
        }
      } catch (error) {
        logError(`Failed to fetch notifications: ${error}`);
      } finally {
        setIsLoading(false);
      }
    };

    fetchNotifications();
  }, [getNotificationData, theme.palette.mode]);

  const markNotificationAsSeen = (id: string) => {
    const seenNotifications = getSeenNotifications();
    if (!seenNotifications.includes(id)) {
      setSeenNotifications([...seenNotifications, id]);
    }

    // Update the notifications state to filter out the seen notification
    const updatedNotifications = notifications.filter(notification => notification.id !== id);
    setNotifications(updatedNotifications);
    setCurrentNotification(updatedNotifications.length > 0 ? updatedNotifications[0] : null);
  };

  return { currentNotification, isLoading, markNotificationAsSeen };
};
