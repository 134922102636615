import { GraphRequestCallback } from '@microsoft/microsoft-graph-client';
import md5 from 'md5';
import { logError } from '@vendor/utils/misc';
import { CacheWindow, NetworkCacheDB } from './IndexDB';
import { NetworkError } from './NetworkError';
import { OdataRequest } from './OdataRequest';

export class CachedOdataRequest implements OdataRequest {
  private readonly keyParts: string[] = [];
  private selectedProperties: string[] | undefined;
  private cacheWindow: CacheWindow = {};
  private refreshStamp?: number;
  private enableCache = false;
  private valueToCache?: any;

  constructor(
    private readonly theRequest: OdataRequest,
    provider: string,
    path: string
  ) {
    !path.startsWith(provider) && this.keyParts.push(provider);
    this.keyParts.push(path);
  }

  configureCache(enable, valueToCache?: any) {
    this.enableCache = enable;
    this.valueToCache = valueToCache;
    return this;
  }

  cache(window: CacheWindow, refreshStamp?: number) {
    this.refreshStamp = refreshStamp;
    this.cacheWindow = window;
    this.enableCache = true;
    return this;
  }

  skip(n: number): OdataRequest {
    this.keyParts.push('skip', n.toString());
    this.theRequest.skip(n);
    return this;
  }

  select(properties: string | string[], clientOnly?: boolean): OdataRequest {
    this.keyParts.push('select', properties.toString());
    if (!clientOnly) this.theRequest.select(properties);
    this.selectedProperties = Array.isArray(properties) ? (properties as string[]) : [properties as string];
    return this;
  }

  headers(headers: HeadersInit) {
    this.keyParts.push('header', JSON.stringify(headers));
    this.theRequest.headers(headers);
    return this;
  }

  async patch(content: any, callback?: GraphRequestCallback): Promise<void> {
    return await this.theRequest.patch(content, callback);
  }

  expand(properties: string | string[]): OdataRequest {
    this.keyParts.push('expand', properties.toString());
    this.theRequest.expand(properties);
    return this;
  }

  orderby(properties: string | string[]): OdataRequest {
    this.keyParts.push('orderby', properties.toString());
    this.theRequest.orderby(properties);
    return this;
  }

  filter(filterStr: string): OdataRequest {
    this.keyParts.push('filter', filterStr);
    this.theRequest.filter(filterStr);
    return this;
  }

  search(searchStr: string): OdataRequest {
    this.keyParts.push('search', searchStr);
    this.theRequest.search(searchStr);
    return this;
  }

  top(n: number): OdataRequest {
    this.keyParts.push('top', n.toString());
    this.theRequest.top(n);
    return this;
  }

  protected async applyResult(key: string, res: any, isCached: boolean) {
    if (isCached) return res;
    const error = res.error || res['odata.error'];
    if (error) throw new NetworkError(error);
    if (res.value) res.value = this.filterValues(res.value);
    else res = this.filterValues(res);
    await this.putDBValue(key, res);
    return res;
  }

  async getStream(callback?: GraphRequestCallback | undefined): Promise<any> {
    return await this.theRequest.getStream(callback);
  }

  private async handleRequest(handler: () => Promise<any>) {
    const key = this.getKey();
    let res = this.valueToCache ? undefined : await this.getDBValue(key);
    if (res?.value) return await this.applyResult(key, res.value, true);
    res = this.valueToCache || (await handler());
    return await this.applyResult(key, res, false);
  }

  async get(callback?: GraphRequestCallback | undefined): Promise<any> {
    return await this.handleRequest(async () => await this.theRequest.get(callback));
  }

  async post(content: any, callback?: GraphRequestCallback | undefined): Promise<any> {
    if (content) this.keyParts.push(md5(JSON.stringify(content)));
    return await this.handleRequest(async () => await this.theRequest.post(content, callback));
  }

  async delete(callback?: GraphRequestCallback | undefined): Promise<any> {
    return await this.theRequest.delete(callback);
  }

  private filterValues(value: any): any {
    if (!this.selectedProperties) return value;

    if (Array.isArray(value)) return (value as []).map(v => this.filterValues(v));
    return this.selectedProperties.reduce((res: any, v: string) => {
      // v can be of the form a/b/c/d. We need to add res.a.b.c.d from value.a.b.c.d
      v.split('/').reduce(
        (res, v, i, a) => {
          if (res.res && res.value.hasOwnProperty(v)) {
            if (i == a.length - 1) res.res[v] = res.value[v];
            else if (!res.res[v]) res.res[v] = {};
            return { res: res.res[v], value: res.value[v] };
          }
          return { res: undefined, value: undefined };
        },
        { value, res }
      );
      return res;
    }, {});
  }

  private getKey(): string {
    return this.keyParts.toString();
    // Test using md5 if we see that the DB is huge - return md5(key);
  }

  private async getDBValue(key: string): Promise<any> {
    try {
      if (this.enableCache && NetworkCacheDB) return await NetworkCacheDB.get(key, this.refreshStamp);
    } catch (error: any) {
      logError(error, `Failed reading '${key}' from DB`);
    }
  }

  private async putDBValue(key: string, value: any): Promise<void> {
    try {
      if (this.enableCache) await NetworkCacheDB.put({ key, value }, this.cacheWindow);
    } catch (error: any) {
      logError(error, `Failed saving '${key}' to DB: ${error.message}`);
    }
  }
}

const oneDay = 24;
export const FolderExpirationWindow: CacheWindow = { expires: oneDay * 7, sliding: oneDay };
export const SiteExpirationWindow: CacheWindow = { expires: oneDay * 7 };
export const TeamExpirationWindow: CacheWindow = { expires: oneDay * 7 };
export const RecentExpirationWindow: CacheWindow = { expires: oneDay * 7, sliding: oneDay };
export const ThumbnailExpirationWindow: CacheWindow = { expires: 0.2, sliding: 0.2 };
export const EternalWindow: CacheWindow = { expires: oneDay * 10000, sliding: oneDay * 30 };
