import { escapeRegExp } from 'lodash';

export const nameMatchesFilter = (filter?: string) => {
  if (!filter) return () => true;
  const startWithFilter = new RegExp(`^${escapeRegExp(filter)}`, 'i');
  const afterNonWordFilter = new RegExp(`\\W${escapeRegExp(filter)}`, 'i');
  return (name: string) => {
    return Boolean(name.match(startWithFilter) || name.match(afterNonWordFilter));
  };
};

interface FileNameAndExtension {
  fileName: string;
  extension: string;
}

export const splitFilename = (filename: string): FileNameAndExtension => {
  const dotIndex = filename.lastIndexOf('.');
  const name = filename.slice(0, dotIndex);
  const extension = filename.slice(dotIndex);
  return { fileName: name, extension: extension };
};
