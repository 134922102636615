import { Theme, Components } from '@mui/material/styles';
import React from 'react';
import { RadioNotSelected, RadioSelected } from '../../assets/icons';

export default (theme: Theme) => {
  const styleOverrides: Components['MuiRadio'] = {
    defaultProps: {
      disableRipple: true,
      icon: <RadioNotSelected />,
      checkedIcon: <RadioSelected />,
    },
    styleOverrides: {
      root: {
        backgroundColor: 'transparent', // Prevent default background color on hover
        'svg rect': {
          stroke: theme.palette.outlinesAndStrokes.secondary,
        },
        '&.Mui-checked svg rect': {
          fill: theme.palette.buttons.accentPrimary,
          stroke: 'none',
        },

        '&:hover': {
          backgroundColor: 'transparent', // Prevent default background color on hover
          'svg rect': {
            stroke: theme.palette.outlinesAndStrokes.tertiary,
          },
          '&.Mui-checked svg rect': {
            fill: theme.palette.buttons.accentSecondary,
            stroke: 'none',
          },
        },
        // Additional styles can be applied here
      },
    },
  };
  return styleOverrides;
};
