import { DocLibraryMedium } from '@storybook';
import { FolderExpirationWindow } from '@services';
import {
  getFolderOdataIdFromUrl,
  getDocumentOdataIdFromUrl,
  SharePointClient,
  SPCBaseItem,
  SPCFolder,
} from '../sharePointAPI';
import {
  AccessUrls,
  BaseContainer,
  BaseItemProps,
  FetchChildrenProps,
  IconProps,
  ItemDataWithPaging,
  MicrosoftApps,
} from '../itemTypes';
import { ListView } from '../itemTypes/ListView';
import { SPDocument } from './SPDocument';
import { SPFolder } from './SPFolder';
import { SPSite } from './SPSite';

interface RenderAsListProps {
  Row: SPCBaseItem[];
  NextHref?: string;
}

interface SPListProps extends BaseItemProps {
  site: SPSite;
  serverRelativeUrl: string;
  OfficeAppLocated: MicrosoftApps;
}

export class SPList extends BaseContainer {
  readonly site: SPSite;
  readonly rootFolder: SPFolder;
  readonly OfficeAppLocated: MicrosoftApps;

  constructor(props: SPListProps) {
    super({ ...props, type: 'list' });
    const { site, OfficeAppLocated } = props;
    this.site = site;
    this.OfficeAppLocated = OfficeAppLocated || MicrosoftApps.SharePoint;
    this.rootFolder = new SPFolder({
      id: `${this.apiId}/rootFolder`,
      list: this,
      name: 'root folder',
      serverRelativeUrl: props.serverRelativeUrl,
      filters: ['Forms', 'Microsoft Teams Chat Files', 'Microsoft Teams Data'],
    });
  }

  protected fillJson(res: SPListProps & { site: string }): void {
    super.fillJson(res);
    res.site = this.site.toJson();
    res.serverRelativeUrl = this.rootFolder.serverRelativeUrl;
    res.OfficeAppLocated = this.OfficeAppLocated;
  }

  get parent() {
    return this.site;
  }

  get siteUrl() {
    return this.site.site;
  }
  get pathOrDescription(): string {
    return `${this.rootFolder.pathOrDescription}`;
  }
  get secondLineContent(): string | null {
    const appName = this.OfficeAppLocated === 'SP' ? 'SharePoint' : this.OfficeAppLocated;
    return `${appName} • ${this.parent.name}`;
  }

  get relativeUrl(): string {
    return this.rootFolder.serverRelativeUrl;
  }

  override async getAccessUrls(): Promise<AccessUrls | undefined> {
    return await this.rootFolder.getAccessUrls();
  }

  override async getFolder(): Promise<SPFolder> {
    return this.rootFolder;
  }

  override get hasFolder(): boolean {
    return true;
  }

  override get rootSite(): string {
    return this.site.rootSite;
  }

  private async renderAsStreamStep(
    spc: SharePointClient,
    folder: SPFolder,
    view: ListView,
    count: number,
    next?: string,
    refreshStamp?: number,
    valueToCache?: RenderAsListProps
  ): Promise<RenderAsListProps> {
    const orderByField = view.orderBy?.[0];
    const orderBy = orderByField ? `&SortField=${orderByField.field}&SortDir=${orderByField.dir ? 'Asc' : 'Desc'}` : '';
    const enableCache = next === undefined;
    next = next || `?RootFolder=${encodeURIComponent(folder.serverRelativeUrl)}${orderBy}`;
    return await spc
      .api(`${this.apiId}/RenderListDataAsStream${next}`)
      .cache(FolderExpirationWindow, refreshStamp)
      .configureCache(enableCache, valueToCache)
      .post({
        parameters: {
          DatesInUtc: true,
          AddRequiredFields: false,
          RenderOptions: 0x4002,
          ViewXml: view.exportXml(count),
        },
      });
  }

  async renderAsStream(
    spc: SharePointClient,
    folder: SPFolder,
    view: ListView,
    origCount: number,
    refreshStamp?: number,
    next?: string
  ): Promise<ItemDataWithPaging> {
    const rows: SPCBaseItem[] = [];
    let count = origCount;
    const isFirstRequest = next === undefined;
    let requestCount = 0;
    let singleRes;
    do {
      singleRes = await this.renderAsStreamStep(spc, folder, view, count, next, refreshStamp);
      rows.push(...singleRes.Row);
      next = singleRes.NextHref;
      count -= singleRes.Row.length;
      requestCount++;
    } while (next && count > 0);

    if (isFirstRequest && requestCount > 1) {
      // Cache value in DB if we had multiple requests
      await this.renderAsStreamStep(spc, folder, view, origCount, undefined, undefined, {
        ...singleRes,
        Row: rows,
      });
    }
    return {
      items: rows.map(v =>
        v.ContentTypeId.startsWith('0x0101')
          ? new SPDocument({
              id: getDocumentOdataIdFromUrl(this.siteUrl, v.FileRef),
              name: v.FileLeafRef,
              contentTypeId: v.ContentTypeId,
              parent: folder,
              date: v.Modified,
              relativeUrl: v.FileRef,
              user: v.Editor?.[0] || v.Author[0],
              uniqueId: v.UniqueId,
              listItemId: v.ID,
              itemSize: Number(v.File_x0020_Size),
            })
          : new SPFolder({
              id: getFolderOdataIdFromUrl(this.siteUrl, v.FileRef),
              name: v.FileLeafRef,
              serverRelativeUrl: v.FileRef,
              contentTypeId: v.ContentTypeId,
              list: this,
              progId: (v as SPCFolder).ProgId,
              listItemId: v.ID,
            })
      ),
      pageToken: next,
    };
  }

  override fetchChildren(props: FetchChildrenProps): Promise<ItemDataWithPaging> {
    return this.rootFolder.fetchChildren(props, this);
  }
  override getIcon(_expanded: boolean): IconProps {
    return { icon: DocLibraryMedium, isColorable: true };
  }
}
