import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import {
  ButtonsStack,
  NewItemDialog,
  ViewItem,
  NotificationContext,
  XlsxMedium,
  PptxMedium,
  DocxMedium,
  ButtonProps,
  MediaKeysType,
} from '@storybook';
import { GraphClient, useGraphClient } from '@services';
import { NewItemDialogTexts, strings } from '@vendor/languages';
import { useSafeCallbackType } from '~/hooks/UseSafeCallback';
import { ErrorTokens } from '../networkAndCache/ErrorTokens';
import { ItemContainer, ItemData } from '../itemTypes';
import { RefreshAction } from './RefreshAction';
import { hasOnlyAllowedCharacters, createNewDocument, DocumentTemplateType } from './ActionsUtils';
import { ActionItemType, ActionRendererHookType, BaseAddActionClass, TrackedEventType } from './BaseAction';
import { publishItemListChanged } from './UploadAction';

interface NewItemProps {
  location: ItemContainer;
  texts: NewItemDialogTexts;
  actionCallback: (gcl: GraphClient, itemName: string) => Promise<void>;
  secondFieldValidate?: (itemName: string) => boolean;
  secondFieldReset?: () => void;
  initialValue?: string;
  useSafeCallback: useSafeCallbackType;
}

export const useNewItemDialog = ({
  location,
  texts,
  actionCallback,
  secondFieldValidate = () => true,
  secondFieldReset,
  initialValue = '',
  useSafeCallback,
}: NewItemProps) => {
  const gcl = useGraphClient();
  const [newItemDialogVisible, setNewItemDialogVisible] = useState<boolean>(false);
  const [itemName, setItemName] = useState('');
  const [itemNameValid, setItemNameValid] = useState(true);
  const [errorMessage, setErrorMessage] = useState<string>();
  const { showNotification } = useContext(NotificationContext);

  const handleItemNameChange = useCallback((value: string) => {
    const validName = hasOnlyAllowedCharacters(value, false);
    setItemNameValid(validName);
    setItemName(value);
    setErrorMessage(
      !validName
        ? strings.lang.itemDialogTexts.itemNameNotValid
        : value === ''
          ? strings.lang.itemDialogTexts.requiredField
          : undefined
    );
  }, []);
  const onClose = useCallback(() => {
    setItemName(initialValue);
    setItemNameValid(true);
    setNewItemDialogVisible(false);
    setErrorMessage(undefined);
    secondFieldReset?.();
  }, [secondFieldReset, initialValue]);
  const onAction = useSafeCallback(
    async () => {
      try {
        const fileName = itemName.trim();
        const extraValid = secondFieldValidate(itemName);
        if (fileName !== '' && itemNameValid && extraValid) {
          await actionCallback(gcl, fileName);
          onClose();
          RefreshAction.markRefreshStamp(location, Date.now());
          showNotification(texts.successNote);
        } else if (extraValid) {
          setErrorMessage(
            fileName === '' ? strings.lang.itemDialogTexts.requiredField : strings.lang.itemDialogTexts.itemNameNotValid
          );
        }
      } catch (err: any) {
        if (err.message.code === ErrorTokens.itemExist || err.message.code === ErrorTokens.fileLock) {
          setErrorMessage(strings.lang.itemDialogTexts.itemExistError);
        } else {
          onClose();
          throw err;
        }
      }
    },
    [
      gcl,
      itemName,
      secondFieldValidate,
      itemNameValid,
      actionCallback,
      onClose,
      location,
      showNotification,
      texts.successNote,
    ],
    false,
    texts
  );

  useEffect(() => setItemName(initialValue), [initialValue]);
  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'Enter' && newItemDialogVisible) {
        onAction();
        setTimeout(() => {
          const activeElement = document.activeElement as HTMLElement;
          if (activeElement && typeof activeElement.blur === 'function') {
            activeElement.blur();
          }
        }, 500);
      }
    };
    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [newItemDialogVisible, onAction]);

  const buttonInputRow: ButtonProps[] = useMemo(
    () => [
      {
        children: strings.lang.itemDialogTexts.cancel,
        variant: 'text',
        onClick: onClose,
      },
      {
        children: texts.createButton ?? strings.lang.newItemDialogTexts.defaults.createButton,
        variant: 'primary',
        onClick: onAction,
        disabled: itemName === '',
      },
    ],
    [itemName, onAction, onClose, texts.createButton]
  );

  return {
    setNewItemDialogVisible,
    errorMessage,
    handleItemNameChange,
    itemName,
    open: !!newItemDialogVisible,
    onClose,
    actions: <ButtonsStack buttons={buttonInputRow} spacing={2} />,
    subTitle: texts.subTitle,
  };
};

const useNewOfficeDocumentAction: ActionRendererHookType = ({ nodes, action, useSafeCallback }) => {
  const actionRef = useRef<MediaKeysType>('Other');
  const location = nodes[0].data as ItemContainer;
  const that = action as NewOfficeDocumentAction;
  const title = that.name;
  const templateId = that.templateId;
  const createOfficeFile = useCallback(
    async (gcl: GraphClient, fileName: string) => {
      const file = await createNewDocument(gcl, location, fileName, templateId);
      publishItemListChanged({ added: { [file.apiIdKey]: file }, location });
    },
    [location, templateId]
  );
  const { setNewItemDialogVisible, ...props } = useNewItemDialog({
    location,
    texts: strings.lang.newItemDialogTexts.document,
    actionCallback: createOfficeFile,
    useSafeCallback,
  });

  const fill = { 'data-nofill': true };
  const IconMedium = [DocxMedium, XlsxMedium, PptxMedium][templateId - 1];
  const dlgTexts = strings.lang.itemDialogTexts;
  return {
    title,
    icon: <IconMedium {...fill} key={templateId} />,
    onClick: () => setNewItemDialogVisible(true),
    actionRef,
    SideUI: (
      <NewItemDialog
        {...props}
        title={
          [dlgTexts.wordDocumentTitle, dlgTexts.excelDocumentTitle, dlgTexts.powerPointDocumentTitle][templateId - 1]
        }
        maxWidth="xs"
        extensions={['.docx', '.xlsx', '.pptx'][templateId - 1]}
      ></NewItemDialog>
    ),
  };
};

export class NewOfficeDocumentAction extends BaseAddActionClass {
  readonly name: string;
  readonly templateId: DocumentTemplateType;

  constructor(templateId: DocumentTemplateType, name: string) {
    super();
    this.templateId = templateId;
    this.name = name;
  }

  readonly useRenderer = useNewOfficeDocumentAction;
  transformEvent({ data, eventName }: TrackedEventType, ctx: MediaKeysType) {
    return {
      eventName,
      data: {
        ...data,
        itemType: 'Document' as ActionItemType,
        documentType: ctx,
      },
    };
  }

  isHandled(nodes: ViewItem<ItemData>[]): boolean {
    const data = nodes[0]?.data;
    return nodes.length === 1 && data.hasFolder && !data.isSearchItem;
  }
}
