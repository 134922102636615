import MuiSlider, { SliderProps as MuiSliderProps } from '@mui/material/Slider';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import { FormControlLabel, Paper, RadioGroup, Switch, useTheme } from '@mui/material';
import { Stack } from '@mui/system';
import React, { useState } from 'react';
import { strings } from '@vendor/languages';
export interface SliderProps extends Omit<MuiSliderProps, 'onChange'> {
  title?: string;
  titleVariant?: any;
  messageVariant?: any;
  message?: string;
  onChange: (num: number) => void;
  min: number;
  max: number;
  withoutWrap?: boolean;
  onChangeFilter?: (checked: boolean) => void;
  filterState?: boolean;
}

const Root = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3, 4),
  backgroundColor: theme.palette.backgrounds.overlaidComponentsPrimary,
  width: theme.spacing(47),
  minHeight: theme.spacing(21),
  borderRadius: '8px',
  overflow: 'hidden',
}));

const Slider = ({
  title,
  message,
  onChange,
  min,
  max,
  withoutWrap,
  titleVariant = 'H2Medium',
  messageVariant = 'H3Regular',
  onChangeFilter,
  filterState,
  ...rest
}: SliderProps) => {
  const theme = useTheme();
  const [checked, setChecked] = useState(filterState);

  const onChangeFilterState = () => {
    onChangeFilter && onChangeFilter(!checked);
    setChecked(!checked);
  };

  const renderBody = () => (
    <Stack direction="column" spacing={withoutWrap ? 0 : 1}>
      {title && (
        <Typography color={theme.palette.texts.primary} variant={titleVariant}>
          {title}
        </Typography>
      )}
      {message && (
        <Typography color={theme.palette.texts.secondary} variant={messageVariant}>
          {message}
        </Typography>
      )}
      <Stack direction="row" alignItems="center" spacing={3} pt={2}>
        <MuiSlider
          {...rest}
          onClick={e => e.stopPropagation()}
          onChange={(_, e) => onChange(Array.isArray(e) ? e[0] : e)}
          min={min}
          max={max}
          sx={{ padding: 2 }}
        />
        <Typography textAlign="right" width={'35px'}>
          {rest.value}
        </Typography>
      </Stack>
      {filterState !== undefined && (
        <Stack direction={'column'} spacing={2} pt={2}>
          <Typography variant="H2Medium" color={theme.palette.texts.primary}>
            {strings.lang.slider.filter}
          </Typography>
          <RadioGroup aria-labelledby="open-document" name="open-document" value={false}>
            <FormControlLabel
              value={false}
              sx={{ flexDirection: 'row-reverse', margin: 0 }}
              slotProps={{
                typography: {
                  sx: { flex: 1 },
                },
              }}
              control={<Switch checked={checked} onChange={onChangeFilterState} />}
              label={
                <Typography sx={{ flex: 1 }} variant="H2Regular" color={theme.palette.texts.primary}>
                  {strings.lang.slider.showAttachments}
                </Typography>
              }
            />
          </RadioGroup>
        </Stack>
      )}
    </Stack>
  );

  return withoutWrap ? renderBody() : <Root onClick={e => e.stopPropagation()}>{renderBody()}</Root>;
};

Slider.defaultProps = {
  min: 0,
  max: 100,
  step: 5,
};

export default Slider;
