import { createContext } from 'react';

export type CallbackFunction = () => void | undefined;
export interface UndoAction {
  run: CallbackFunction;
}

type ShowErrorFunction = (title: string, message: string, hasAction: boolean) => void;
type ShowNotificationFunction = (title: string, time?: number, cancelAction?: UndoAction, className?: string) => void;

export interface NotificationContextType {
  showError: ShowErrorFunction;
  showNotification: ShowNotificationFunction;
}

export const NotificationContext = createContext<NotificationContextType>({
  showError: () => undefined,
  showNotification: () => undefined,
});

export default NotificationContext;
